import { generatePath } from 'react-router';
import { ThankYouPageType } from '../hooks';
import { Config } from '../config';

const GF = '/transport_request';
const BUSINESS = '/business';

export const r = {
  generalFlow: {
    index: () => GF,
    contact: {
      index: () => `${GF}/contact`,
      delivery: () => `${GF}/contact/delivery`,
      pickup: () => `${GF}/contact/pickup`,
    },
    date: {
      index: () => `${GF}/date`,
      delivery: () => `${GF}/date/delivery`,
    },
    delivery: {
      index: () => `${GF}/delivery`,
      floor: () => `${GF}/delivery/floor`,
    },
    destination: {
      index: () => GF,
    },
    help: {
      index: () => `${GF}/help`,
    },
    homeSituation: {
      index: () => `${GF}/home_situation`,
    },
    items: {
      index: () => `${GF}/items`,
      add: () => `${GF}/items/add`,
      configure: () => `${GF}/items/add/configure`,
      edit: (args: { uuid?: string }) => {
        const p = `${GF}/items/edit/:uuid`;
        if (!args.uuid) return p;
        return generatePath(p, args);
      },
    },
    oppPayment: (args: { id?: string }) => {
      const p = `${GF}/opp_payment/:id`;
      if (!args.id) return p;
      return generatePath(p, args);
    },
    pickup: {
      index: () => `${GF}/pickup`,

      auction: () => `${GF}/pickup/auctions`,
      floor: () => `${GF}/pickup/floor`,
      invoice: () => `${GF}/pickup/invoice`,
    },
    productPayment: {
      index: () => `${GF}/product_payment`,
      amount: () => `${GF}/product_payment/amount`,
    },
    terms: {
      index: (args?: { canceled: boolean }) => {
        return withCanceledParam({ p: `${GF}/terms`, canceled: !!args?.canceled });
      },
    },
    time: {
      index: () => `${GF}/time`,
      delivery: () => `${GF}/time/delivery`,
    },
  },
  user: {
    dashboard: () => `/dashboard`,
    login: (args?: { trId: string | null }) => {
      const p = `/login`;
      if (!args?.trId) return p;
      return addTrParam(p, args.trId);
    },
    loginCheck: () => `/login/check`,
    loginError: () => `/login/error`,
    register: (args?: { trId: string | null }) => {
      const p = `/register`;
      if (!args?.trId) return p;
      return addTrParam(p, args.trId);
    },
    passwordReset: () => `/password_reset`,
    passwordToken: (args: { token?: string }) => {
      const p = `/password_reset/:token`;
      if (!args.token) return p;
      return generatePath(p, args);
    },
    invoices: () => `/invoices`,
    apiSettings: () => `/api_settings`,
  },
  payment: {
    canceled: () => `/payment_status_canceled`,
    thankYou: (args: { type?: ThankYouPageType; id?: string }) => {
      const p = `/partner/:type/:id/thank_you`;
      if (!args.id || !args.type) return p;
      return generatePath(p, args);
    },
  },
  tools: {
    productSelection: () => `/nothing_to_see_here/ps`,
  },
  businessFlow: {
    index: () => BUSINESS,
    destination: () => `${BUSINESS}/transport_request/destination`,
    items: () => `${BUSINESS}/transport_request/items`,
    preview: () => `${BUSINESS}/transport_request/preview`,
  },
  external: {
    trackingPage: (args: { id?: string; canceled?: boolean }) => {
      let url = Config.TRACKING_BASE_URL;
      if (!args.id) return url;
      url = `${url}/${args.id}`;
      return withCanceledParam({ p: url, canceled: !!args.canceled });
    },
  },
};

const withCanceledParam = (args: { p: string; canceled: boolean }): string => {
  if (!args.canceled) {
    return args.p;
  }
  const params = new URLSearchParams();
  params.append('payment_status', 'canceled');
  return `${args.p}?${params.toString()}`;
};

const addTrParam = (p: string, id: string): string => {
  const params = new URLSearchParams();
  params.append('transport_request', id);
  return `${p}?${params.toString()}`;
};

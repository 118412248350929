import { change, clearFields, untouch } from 'redux-form';
import { all, put, takeLatest } from 'typed-redux-saga';
import { ItemFields } from '../../modules/GeneralFlow/interface';
import { BaseActionTypes, SelectProductDimensionAction } from '../../typings';

function* handlePopularItemSelect(action: SelectProductDimensionAction): Generator {
  const [itemSet, item] = action.meta.field.split('.');
  const target = `${itemSet}.${item}.`;
  // Dimension can either come from ProductDimension or a PopularItem, but can also be null (when the user chooses manual input)
  const dimension = action.payload;

  /**
   * First handle manual input (so the dimension is null)
   */
  if (dimension === null) {
    // We leave title and allready filled dimensions untouched, we only reset product selection fields
    yield* put(
      clearFields(
        action.meta.form,
        false,
        false,
        `${target}${ItemFields.PRODUCT_SELECTION_GROUP}`,
        `${target}${ItemFields.HEAVY}`,
        `${target}${ItemFields.FRAGILE}`
      )
    );
    return;
  }

  /**
   * We are not resetting, so let's fill the values
   */
  const label = dimension.title;

  // Update dimension fields
  const fields = [`width`, `height`, `length`];
  yield* all(fields.map(field => put(change(action.meta.form, target + field, dimension[field]))));

  // Make the dimenision fields untouched, so we know if the customer interacted with the fields after we updated
  const effects = [put(untouch(action.meta.form, ...fields.map(field => target + field)))];
  // If the input value is shorter the product dimension label, we update the input value
  if (action.meta.value.length < label.length) {
    effects.push(put(change(action.meta.form, action.meta.field, label)));
  }
  yield* all(effects);
}

export function* productDimensionSaga(): Generator {
  yield* takeLatest(BaseActionTypes.SELECT_PRODUCT_DIMENSION, handlePopularItemSelect);
}

import React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { useMutation } from 'react-query';
import { IconCamera, IconCross } from '../../../../brenger-shared-ui';
import { getResizedImageAsBlob } from '../../../../state/sagas/uploadSaga';
import { coreClient, userDataClient } from '../../../../utils/request';
import cn from 'classnames';
import { JobImage } from '@brenger/api-client';

interface Props {
  /**
   * IRI
   */
  image: string | null;
  updateImage(args: JobImage | null): void;
  required?: boolean;
  showError?: boolean;
}

export const ItemImage: React.FC<Props> = ({ image, updateImage, required, showError }) => {
  if (image) {
    return <DisplayImage image={image} updateImage={updateImage} />;
  }
  return <EditImage updateImage={updateImage} required={required} showError={showError} />;
};

const DisplayImage: React.FC<Props & { image: string }> = ({ image, updateImage }) => {
  return (
    <div className="ps-config--image-display">
      <div className="ps-config--image-display--remove trigger" onClick={() => updateImage(null)}>
        <IconCross />
      </div>
      <img src={userDataClient.getBaseForIri(image)} />
    </div>
  );
};

const EditImage: React.FC<Pick<Props, 'updateImage' | 'required' | 'showError'>> = ({
  updateImage,
  required,
  showError,
}) => {
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const createJobImage = useMutation(coreClient.images.createJobImage, {
    onSuccess: data => updateImage(data),
  });

  const handleFile = async (file: string, mimeType: string): Promise<void> => {
    const blob = await getResizedImageAsBlob(file, mimeType, 1000);
    const imageData = new FormData();
    imageData.append('file', blob);
    createJobImage.mutate({ imageData });
  };

  return (
    <>
      <input
        accept=".jpg,.jpeg,.png"
        multiple={false}
        onChange={event => {
          const file = event.target?.files?.[0];
          if (file) {
            const reader = new FileReader();
            reader.addEventListener('load', e => handleFile(e.target?.result as string, file.type));
            reader.readAsDataURL(file);
          }
        }}
        ref={fileInputRef}
        type="file"
        className="hidden"
      />
      <div
        className={cn('ps-config--image-upload', 'flex', 'flex--vc', 'trigger', { required, showError })}
        onClick={() => {
          fileInputRef?.current?.click();
        }}
      >
        <IconCamera />
        <div>
          <div className="text--primary">
            <b>
              <Translate id={'request_flow.product_selection.upload_picture'} />
              {required && ' *'}
            </b>
          </div>
          <Translate id="request_flow.product_selection.upload_picture_why" />
        </div>
      </div>
      {showError && required && (
        <div className="text--red py-1" style={{ marginTop: '.5rem' }}>
          <Translate id={'request_flow.product_selection.error_upload_picture'} />
        </div>
      )}
    </>
  );
};

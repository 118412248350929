import React from 'react';
import classNames from 'classnames';
import { Translate } from 'react-localize-redux-dep-updated';

import { useTypedSelector } from '../../../../hooks';
import { CirclePerson2, Toast } from '../../../../brenger-shared-ui';

import { priceAsString, getValidDiscount, getOldPrice } from '../../../../utils/price';
import { translate } from '../../../../utils/localization';
import { useNotification } from '../../../../hooks/useNotification';

export const DefaultSpecialPrice: React.FC = () => {
  const notification = useNotification('default-special-price');
  const progressStep = useTypedSelector(state => state.generalTransport.layout.step);
  const amount = useTypedSelector(state => state.generalTransport.price?.amount || 0);

  // Just look for first match and move on. That is why order of validDiscounts above matters.
  const discount = getValidDiscount(useTypedSelector(state => state.generalTransport.quote?.notes || []));

  React.useEffect(() => {
    if (
      progressStep === 3 &&
      amount &&
      discount &&
      // AB test - only show when body has NOT specific class
      !document.body.classList.contains('show-savings')
    ) {
      notification.add();
    } else {
      notification.remove();
    }
  }, [progressStep, amount, discount?.note, discount?.type, discount?.data]);

  // Translations for discounts of type "percentage" or "fixed" accept a data prop.
  const translationData = {} as { data?: { discount_amount: string } };

  // The translations for the percentage-based discounts are templatized,
  // so must provide some data to make it effective.
  if (discount?.type === 'percentage') {
    translationData.data = { discount_amount: `${discount?.data}%` };
  }

  const icon = (): JSX.Element | undefined => {
    if (discount?.icon) {
      return <img style={{ width: 80, marginTop: 8, borderRadius: '50%' }} src={discount.icon} />;
    }
    if (discount?.svg === 'circle-person') {
      return <CirclePerson2 />;
    }
  };

  const discountTranslation = discount?.type !== 'percentage' ? discount?.note : 'default_10_percent_campaign';

  return (
    <div className={classNames('info-message-container', { 'info-message--visible': notification.isVisible })}>
      <Toast prefix={icon()} handleClose={notification.removeForOneSession}>
        <div style={{ paddingRight: '15px' }}>
          <b>
            <Translate id={`request_flow.price_notifications.${discountTranslation}.title`} {...translationData} />
          </b>
        </div>
        <Translate id={`request_flow.price_notifications.${discountTranslation}.text`} {...translationData} />
        {/* Discounts of type "percentage" have an old price that can be derived and displayed to users. */}
        {discount?.type === 'percentage' && (
          <div className="discount-price-display first-letter--caps">
            {translate('request_flow.price.special_from')}{' '}
            <b className={'old-price'}>{getOldPrice({ amount }, discount?.data || 0)}</b>{' '}
            <Translate id="request_flow.price.special_to" />{' '}
            <b>{priceAsString({ price: { amount }, addPlusMinusIndicator: false })}</b>
          </div>
        )}
      </Toast>
    </div>
  );
};

import { push } from 'connected-react-router';
import React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { connect } from 'react-redux';
import { useQuery } from 'react-query';
import { Dispatch } from 'redux';
import '../../../assets/business/dashboard.scss';
import { Card, Col, Heading, IconShopBlue, Label, Row, SectionWrapper } from '../../../brenger-shared-ui';
import { Config } from '../../../config';
import { CacheKey, RootState } from '../../../typings';
import { coreClient } from '../../../utils/request';
import { actions as generalFlowActions } from '../../GeneralFlow/ducks';
import { getLoggedInUser } from '../ducks';
import { actions as businessActions, getNewTransportRequestLink } from '../../BusinessFlow/ducks';
import { r } from '../../../routes';
import { Account } from '@brenger/api-client';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export const Dashboard: React.FC<Props> = props => {
  const { userDetails, goToPath, hasDedicatedBusinessFlow, newTrLink, resetBusiness, resetGeneral } = props;
  React.useEffect(() => {
    // check if logged in
    if (!userDetails.loggedIn) {
      goToPath('/login');
      return;
    }
  }, []);

  const transportRequests = useQuery([CacheKey.RETRIEVE_TRANSPORT_REQUEST_LIST], () =>
    coreClient.transportRequests.list()
  );
  const invoices = useQuery([CacheKey.RETRIEVE_INVOICES], () => coreClient.invoices.list());
  const accountName = (userDetails?.userData?.account as Account | undefined)?.name;
  return (
    <Col xs={12}>
      <SectionWrapper sectionStyle={'primary'} centerContent={true} fullScreen={true}>
        <Col xs={12}>
          <div className={'pt-1 pb-1'} style={{ marginTop: '60px' }}>
            <div className={'dashboard-welcome'}>
              <div className={'icon-wrapper'}>
                <div className={'dashboard-welcome--logo'}>
                  <IconShopBlue />
                </div>
              </div>
              <Heading size={2} extraClasses={'text--center no-margin'}>
                <Translate id={`request_flow.customer_dashboard.title`} />
              </Heading>
              {accountName && <div className={'business-name text--center'}>{accountName}</div>}
            </div>
          </div>
          <Row>
            <Col xs={6} extraClasses={'pb-1'}>
              {/* NOTE: THE TRACKING BASE URL TAKES LOGGED IN USERS TO THE TR LIST */}
              <Card onClick={() => window.location.assign(Config.TRACKING_BASE_URL)} withBrengerSmile={true}>
                <div className={'pb-0-5 text--bold'}>
                  <Translate
                    id={`request_flow.customer_dashboard.orders${hasDedicatedBusinessFlow ? '_business' : ''}`}
                  />
                </div>
                {transportRequests.data?.['hydra:totalItems'] || 0}
              </Card>
            </Col>
            <Col xs={6} extraClasses={'pb-1'}>
              <Card onClick={() => goToPath(r.user.invoices())} withBrengerSmile={true}>
                <div className={'pb-0-5 text--bold'}>
                  <Translate id={`request_flow.customer_dashboard.invoices`} />
                </div>
                {invoices.data?.['hydra:totalItems'] || 0}
              </Card>
            </Col>
            <Col xs={12} extraClasses={'pb-1'}>
              <Card
                onClick={() => {
                  resetBusiness();
                  resetGeneral();
                  goToPath(newTrLink);
                }}
                withBrengerSmile={true}
              >
                <Label type={'green'}>
                  <Translate id={`request_flow.customer_dashboard.new_tr.greeting`} />
                </Label>
                <Heading size={5} extraClasses={'no-margin'}>
                  <Translate id={`request_flow.customer_dashboard.new_tr.title`} />
                </Heading>
                <Translate id={`request_flow.customer_dashboard.new_tr.sub_title`} />
              </Card>
            </Col>
          </Row>
        </Col>
      </SectionWrapper>
    </Col>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState) => ({
  newTrLink: getNewTransportRequestLink(state),
  userDetails: getLoggedInUser(state),
  hasDedicatedBusinessFlow: state.business.has_business_flow,
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: Dispatch) => ({
  goToPath: path => dispatch(push(path)),
  resetBusiness: () => dispatch(businessActions.resetFlow()),
  resetGeneral: () => dispatch(generalFlowActions.resetFlow(false)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

import React from 'react';

export const useIntersection = (ref: React.RefObject<HTMLElement>): boolean => {
  const [isIntersecting, setIntersecting] = React.useState(false);

  const observer = React.useMemo(
    () => new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting)),
    [ref]
  );

  React.useEffect(() => {
    if (!ref.current) {
      return;
    }
    observer.observe(ref.current);
    return () => observer.disconnect();
  }, [ref.current]);

  return isIntersecting;
};

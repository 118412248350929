import React from 'react';
import './simpleswitch.scss';
import cn from 'classnames';

interface Props {
  options: string[];
  activeValue: string;
  setValue(value: string): void;
}

export const SimpleSwitch = ({ options, activeValue, setValue }: Props): JSX.Element => {
  const toValue = options.find(option => option !== activeValue) || activeValue;
  const isActive = options.findIndex(option => option === activeValue) === 0;
  return (
    <div className={'simple-switch trigger'} onClick={() => setValue(toValue)}>
      <div className={cn('simple-switch--button', { 'simple-switch--button--active': isActive })}></div>
      {activeValue}
    </div>
  );
};

export default SimpleSwitch;

import React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import '../../../../assets/product-selection/itemConfig.scss';
import { useTypedSelector } from '../../../../hooks';
import { ItemWithPSAndLot } from '../../interface';
import { ItemForm } from './ItemForm';
import { Modal } from './Modal';
import { useDispatch } from 'react-redux';
import { actions } from '../../ducks';
import { r } from '../../../../routes';

export const ItemEdit: React.FC<RouteComponentProps<{ uuid: string }>> = ({ match }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const item: ItemWithPSAndLot | undefined = useTypedSelector(
    state => state.generalTransport.product_selection.items
  ).find(psItem => psItem.ps_temp.uuid === match.params.uuid);
  React.useEffect(() => {
    if (item && !item.ps_temp.reviewed) {
      // When this screen loads, and a product is found, we can assume that users have reviewed the item
      // This is important to know when the item was PREFILLED based on landing page.
      dispatch(actions.reviewedItemPs({ uuid: match.params.uuid, reviewed: true }));
    }
  }, [item]);

  if (!item) {
    return (
      <Modal isActive={true} closeHandler={() => history.replace(r.generalFlow.items.index())}>
        <div className="ps-modal--section">
          <Translate id={'request_flow.product_selection.item_not_found'} />
        </div>
      </Modal>
    );
  }
  return (
    <Modal isActive={true} closeHandler={() => history.replace(r.generalFlow.items.index())}>
      <ItemForm productGroup={item.ps_temp.group} existingItem={item} />
    </Modal>
  );
};

import { DatetimePeriodOption, Quote, QuoteCreationParams } from '@brenger/api-client';
import { formatLocalDate } from '@brenger/utils';
import { getLocation } from 'connected-react-router';
import { addDays, parse } from 'date-fns';
import * as React from 'react';
import { IconEcoFriendly } from '../brenger-shared-ui';
import { InputOption } from '../brenger-shared-ui/components/reduxForm/inputtiles/InputTiles';
import {
  getFlowTitleByStepAndSituation,
  getPriceRequestParams,
  getTbAuctionCollectionDays,
  isRequestAboveMaxDistance,
} from '../modules/GeneralFlow/ducks';
import { r } from '../routes';
import { RootState, StopType } from '../typings';
import { logError, logException } from '../utils/basics';
import { formatDate } from '../utils/datetime';
import { getActiveLanguageSettings } from '../utils/localization';
import { priceAsString } from '../utils/price';
import { priceClient } from '../utils/request';
import { useTypedSelector } from './useTypedSelector';

interface UseDateOptions {
  loading: boolean;
  options: InputOption[];
  getMore?(): void;
  showDifferentDeliveryDate: boolean;
  meta: {
    aboveMaxDistance: boolean;
    hasSingleCollectionDay: boolean;
    stopType: StopType;
    title: string;
  };
}

export const useDateOptions = (): UseDateOptions => {
  const aboveMaxDistance = useTypedSelector((state: RootState) => isRequestAboveMaxDistance(state, true));
  const quoteParams = useTypedSelector(getPriceRequestParams);
  const [loading, setLoading] = React.useState(true);
  const [options, setOptions] = React.useState<InputOption[]>([]);
  const { pathname } = useTypedSelector(getLocation);
  const stopType = pathname === r.generalFlow.date.index() ? StopType.PICKUP : StopType.DELIVERY;
  const title = useTypedSelector((state: RootState) =>
    getFlowTitleByStepAndSituation(state, `date_${stopType}${aboveMaxDistance ? '_longDistance' : ''}`)
  );
  const [startFromDate, setStartFromDate] = React.useState<string | undefined>();
  const tbAuctionCollectionDays = useTypedSelector(getTbAuctionCollectionDays);
  const isTbAuctionStep = !!tbAuctionCollectionDays?.length && stopType === StopType.PICKUP;
  const showDifferentDeliveryDate = stopType === StopType.PICKUP && !aboveMaxDistance;
  const langSettings = getActiveLanguageSettings();
  const activeLang = { locale: langSettings.labels.short, timeZone: langSettings.timeZone };

  // for debugging purposes we keep track of the quote call
  const sentryData = React.useRef<null | { args: QuoteCreationParams; quote: Quote }>(null);

  /**
   * Triggers when mounted and when startFromDate changes
   */
  React.useEffect(() => {
    if (isTbAuctionStep) {
      setTbAuctionOptions();
      return;
    }
    if (!quoteParams) {
      return;
    }
    getDtpOptions();
  }, [startFromDate]);

  /**
   * Check if we end up with zero options
   */
  React.useEffect(() => {
    // If we are not loading, we ended up with zero results and we have sentry data, then log to sentry
    if (loading === false && options.length === 0 && sentryData.current) {
      logException(`Date step ${stopType} - Ended up with zero results`, sentryData.current);
    }
  }, [loading, options]);

  /**
   * Set TB auctions options from collection days
   */
  const setTbAuctionOptions = (): void => {
    setOptions(
      (tbAuctionCollectionDays || []).map(day => {
        const date = new Date(day.start);
        return {
          value: formatDate(date, 'api-date'),
          title: `${formatLocalDate(date, activeLang, { showRelative: true })}`,
        };
      })
    );
    setLoading(false);
  };

  /**
   * Sets new 'start from now date'
   */
  const getMore = (): void => {
    if (options.length === 0) {
      return;
    }
    // Get last option, and use that + 1 as start from date
    const lastDate = options[options.length - 1].value;
    setStartFromDate(formatDate(addDays(new Date(lastDate), 1), 'api-date'));
  };

  /**
   * Calling pricing and handles result
   */
  const getDtpOptions = async (): Promise<void> => {
    // When quote params are not ready
    if (!quoteParams) {
      return;
    }
    // Args for quote
    const args = startFromDate
      ? {
          ...quoteParams,
          delivery_dates_start_from: startFromDate,
          pickup_dates_start_from: startFromDate,
        }
      : quoteParams;
    // set loading
    await setLoading(true);
    try {
      const quote = await priceClient.quotes.create(args);
      // Setup debug data for later
      sentryData.current = { args, quote };
      /**
       * Save the result formatted
       */
      const newOptions =
        (stopType === StopType.PICKUP
          ? quote.pickup_datetime_period_options.options
          : quote.delivery_datetime_period_options.options) || [];
      if (startFromDate) {
        await setOptions(options.concat(formatting(newOptions)));
      }
      if (!startFromDate) {
        await setOptions(formatting(newOptions));
      }
    } catch (e) {
      logError(e);
    }
    // we are done
    await setLoading(false);
  };

  const formatting = (rawDtpOptions: DatetimePeriodOption[]): InputOption[] => {
    // we don't want empty time options, or instant booking as option.
    const dateOptions = rawDtpOptions.filter(dtpOption => {
      return dtpOption.time_options?.length && dtpOption.time_options.some(timeOption => timeOption.type !== 'instant');
    });
    // Reset debug data if we have valid options to show to the user
    if (dateOptions.length) {
      sentryData.current = null;
    }
    // We need to know what the most expensive option is, so we can determine label color
    const maxAmount = Math.max(...dateOptions.map(option => option.price_change?.incl_vat.amount || 0));

    // map result to formatted options
    return dateOptions.map(dateOption => {
      const amount = dateOption.price_change?.incl_vat.amount || 0;
      // label: maxAmount = red, the others above 0 = orange, rest = green
      const label = amount > 0 && amount === maxAmount ? 'red' : amount > 0 ? 'orange' : 'green';
      /**
       * !!!
       * BIG FAT NOTE
       * Safari (The new IE on block) doesn't like the way how pricing formats dates (with dashes) so we need to parse it explicitly.
       * !!!
       */
      const date = parse(dateOption.dates[0], 'yyyy-MM-dd', new Date());
      return {
        value: dateOption.dates[0],
        // If above max distance the user has to select two sequential dates
        title: !aboveMaxDistance
          ? `${formatLocalDate(date, activeLang, { showRelative: true })}`
          : `${formatLocalDate(date, activeLang)} + ${formatLocalDate(addDays(date, 1), activeLang)}`,
        suffix: (
          <span className={`text--${label}`}>
            {amount <= 0 && <IconEcoFriendly />}
            {priceAsString({ price: { amount } })}
          </span>
        ),
      };
    });
  };

  return {
    loading,
    options,
    getMore: isTbAuctionStep ? undefined : getMore,
    showDifferentDeliveryDate,
    meta: {
      aboveMaxDistance,
      hasSingleCollectionDay: (tbAuctionCollectionDays?.length || 0) === 1,
      stopType,
      title,
    },
  };
};

/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  BigDecimal: { input: any; output: any; }
  /** The Long scalar type represents a signed 64-bit numeric non-fractional value */
  Long: { input: any; output: any; }
  /** The Short scalar type represents a signed 16-bit numeric non-fractional value */
  Short: { input: any; output: any; }
  TbaDate: { input: any; output: any; }
  TbaUuid: { input: any; output: any; }
};

/** Access level for bidding deposits */
export enum AccessType {
  Auction = 'AUCTION',
  Channel = 'CHANNEL',
  Lot = 'LOT',
  Platform = 'PLATFORM'
}

export type AdditionalCost = {
  __typename?: 'AdditionalCost';
  type: CostType;
  value: Money;
};

/** Additional costs object */
export type AdditionalCosts = {
  __typename?: 'AdditionalCosts';
  costs: Array<AdditionalCost>;
};

export type AdditionalInformationInput = {
  auctionId: Scalars['TbaUuid']['input'];
  locale: Scalars['String']['input'];
  platform: Platform;
};

export type AdditionalInformationResponse = {
  __typename?: 'AdditionalInformationResponse';
  additionalInformation?: Maybe<Scalars['String']['output']>;
};

export type AllAuctionsFacet = {
  identifier: AllAuctionsFacetName;
  values: Array<Scalars['String']['input']>;
};

/** Supported facets on all auctions page */
export enum AllAuctionsFacetName {
  Countries = 'countries'
}

export type AllAuctionsInput = {
  biddingStatuses: Array<BiddingStatus>;
  facets: Array<AllAuctionsFacet>;
  locale: Scalars['String']['input'];
  nameFilter?: InputMaybe<Scalars['String']['input']>;
  pageNumber: Scalars['Long']['input'];
  pageSize: Scalars['Long']['input'];
  sortBy: AuctionsSortingOption;
};

/** Auction object */
export type Auction = {
  __typename?: 'Auction';
  additionalInformation: Scalars['Boolean']['output'];
  auctionType: Scalars['String']['output'];
  biddingStatus: BiddingStatus;
  collectionDays: Array<AuctionLocation>;
  deliveryCountries: Array<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  displayId: Scalars['String']['output'];
  documents: Array<Document>;
  endDate: Scalars['TbaDate']['output'];
  id: Scalars['TbaUuid']['output'];
  image: Image;
  isNeedsDelivery: Scalars['Boolean']['output'];
  lotCount: Scalars['Long']['output'];
  lotsClosing: Scalars['Boolean']['output'];
  mainImage?: Maybe<Image>;
  maxEndDate: Scalars['TbaDate']['output'];
  minEndDate: Scalars['TbaDate']['output'];
  name: Scalars['String']['output'];
  onBoarding: Scalars['Boolean']['output'];
  otherUrlSlugs: Array<LocalizedValue>;
  showFrom: Scalars['TbaDate']['output'];
  showUntil: Scalars['TbaDate']['output'];
  startDate: Scalars['TbaDate']['output'];
  urlSlug: Scalars['String']['output'];
  viewingDays: Array<AuctionLocation>;
};


/** Auction object */
export type AuctionCollectionDaysArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
};


/** Auction object */
export type AuctionDeliveryCountriesArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
};


/** Auction object */
export type AuctionViewingDaysArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
};

/** Auction Facets result object */
export type AuctionFacetsResponse = {
  __typename?: 'AuctionFacetsResponse';
  appliedValueFacets: Array<AuctionValueFacetRequest>;
  valueFacets: Array<AuctionValueFacet>;
};

/** Auction Location object */
export type AuctionLocation = {
  __typename?: 'AuctionLocation';
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  id: Scalars['TbaUuid']['output'];
  locationDays?: Maybe<Array<AuctionLocationDay>>;
  name?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
};

/** Auction Location Day object */
export type AuctionLocationDay = {
  __typename?: 'AuctionLocationDay';
  email?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['Long']['output']>;
  forkliftCapacity?: Maybe<Scalars['Int']['output']>;
  isByAppointment: Scalars['Boolean']['output'];
  isDeliverySchedule?: Maybe<Scalars['Boolean']['output']>;
  isForkliftAvailable?: Maybe<Scalars['Boolean']['output']>;
  showOnWebsite?: Maybe<Scalars['Boolean']['output']>;
  startDate?: Maybe<Scalars['Long']['output']>;
};

/** Object containing next & previous lots' url slugs */
export type AuctionNavigation = {
  __typename?: 'AuctionNavigation';
  nextLotUrlSlug: Scalars['String']['output'];
  prevLotUrlSlug: Scalars['String']['output'];
};

export type AuctionNavigatorInput = {
  auctionDisplayId: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  lotDisplayId: Scalars['String']['input'];
  lotNumber: Scalars['String']['input'];
  platform: Platform;
};

/** All auction value facets for the result set. */
export type AuctionValueFacet = {
  __typename?: 'AuctionValueFacet';
  filters: Array<Filter>;
  identifier: AuctionValueFacetName;
};

export type AuctionValueFacetInput = {
  filters: Array<Scalars['String']['input']>;
  identifier: AuctionValueFacetName;
};

/** Supported facets on auctions page */
export enum AuctionValueFacetName {
  Countries = 'countries'
}

/** The auction value facets to be applied. */
export type AuctionValueFacetRequest = {
  __typename?: 'AuctionValueFacetRequest';
  filters: Array<Scalars['String']['output']>;
  identifier: AuctionValueFacetName;
};

/** Auction object containing paged auction's lots with limited data */
export type AuctionWithListingLots = {
  __typename?: 'AuctionWithListingLots';
  auction: Auction;
  lots: ListingLots;
};

/** Auction object containing paged auction's lots */
export type AuctionWithLots = {
  __typename?: 'AuctionWithLots';
  auction: Auction;
  lots: Lots;
};

export type AuctionWithLotsInput = {
  displayId: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  lotBiddingStatuses: Array<BiddingStatus>;
  nestedValueFacetInputs: Array<LotsByAuctionNestedValueFacetInput>;
  pageNumber: Scalars['Long']['input'];
  pageSize: Scalars['Long']['input'];
  rangeFacetInputs: Array<LotRangeFacetInput>;
  sortBy: LotsSortingOption;
  valueFacetInputs: Array<LotsByAuctionValueFacetInput>;
};

/** Supported auctioneers */
export enum Auctioneer {
  Dechow = 'DECHOW',
  Kda = 'KDA',
  Tba = 'TBA',
  TwkBe = 'TWK_BE',
  TwkCh = 'TWK_CH',
  TwkFr = 'TWK_FR',
  TwkIt = 'TWK_IT',
  TwkRestOfWorld = 'TWK_REST_OF_WORLD',
  Vavato = 'VAVATO'
}

/** Auctions list object */
export type Auctions = {
  __typename?: 'Auctions';
  hasNext: Scalars['Boolean']['output'];
  pageNumber: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  results: Array<Auction>;
  totalSize: Scalars['Long']['output'];
};

/** Sort property for the all auctions query */
export enum AuctionsSortingOption {
  EndDateAsc = 'END_DATE_ASC',
  EndDateDesc = 'END_DATE_DESC',
  StartDateAsc = 'START_DATE_ASC',
  StartDateDesc = 'START_DATE_DESC'
}

export type BidRequest = {
  amountInCents: Scalars['Long']['input'];
  bidType: BidType;
  currency: Currency;
  lotId: Scalars['TbaUuid']['input'];
};

/** Current bid status of a buyerId for a lot */
export enum BidStatus {
  Highest = 'HIGHEST',
  NoBid = 'NO_BID',
  Outbid = 'OUTBID'
}

/** The type of bid, enum REGULAR_BID or AUTO_BID */
export enum BidType {
  AutoBid = 'AUTO_BID',
  RegularBid = 'REGULAR_BID'
}

/** Filter on auctions and lots where the bidding is open, not yet open or closed */
export enum BiddingStatus {
  All = 'ALL',
  BiddingClosed = 'BIDDING_CLOSED',
  BiddingNotYetOpened = 'BIDDING_NOT_YET_OPENED',
  BiddingOpen = 'BIDDING_OPEN'
}

/** The sellers type of business */
export enum BusinessType {
  Company = 'COMPANY',
  Individual = 'INDIVIDUAL',
  NonProfit = 'NON_PROFIT'
}

/** Category object */
export type Category = {
  __typename?: 'Category';
  id: Scalars['TbaUuid']['output'];
  name: Scalars['String']['output'];
  otherUrlSlugs: Array<LocalizedValue>;
  parentId: Scalars['TbaUuid']['output'];
  urlSlug: Scalars['String']['output'];
};

/** An Category attribute on a lot */
export type CategoryAttribute = {
  __typename?: 'CategoryAttribute';
  name: Scalars['String']['output'];
  unit?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

/** The level of category, level 1 is main, 2 is category and 3 is sub category */
export enum CategoryLevel {
  Level1 = 'LEVEL1',
  Level2 = 'LEVEL2',
  Level3 = 'LEVEL3'
}

/** Type of additional costs */
export enum CostType {
  Delivery = 'DELIVERY'
}

/** Currency identifier */
export enum Currency {
  Aed = 'AED',
  Afn = 'AFN',
  All = 'ALL',
  Amd = 'AMD',
  Ang = 'ANG',
  Aoa = 'AOA',
  Ars = 'ARS',
  Aud = 'AUD',
  Awg = 'AWG',
  Azn = 'AZN',
  Bam = 'BAM',
  Bbd = 'BBD',
  Bdt = 'BDT',
  Bgn = 'BGN',
  Bhd = 'BHD',
  Bif = 'BIF',
  Bmd = 'BMD',
  Bnd = 'BND',
  Bob = 'BOB',
  Brl = 'BRL',
  Bsd = 'BSD',
  Btn = 'BTN',
  Bwp = 'BWP',
  Byn = 'BYN',
  Bzd = 'BZD',
  Cad = 'CAD',
  Cdf = 'CDF',
  Chf = 'CHF',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Crc = 'CRC',
  Cup = 'CUP',
  Cve = 'CVE',
  Czk = 'CZK',
  Djf = 'DJF',
  Dkk = 'DKK',
  Dop = 'DOP',
  Dzd = 'DZD',
  Egp = 'EGP',
  Ern = 'ERN',
  Etb = 'ETB',
  Eur = 'EUR',
  Fjd = 'FJD',
  Fkp = 'FKP',
  Gbp = 'GBP',
  Gel = 'GEL',
  Ghs = 'GHS',
  Gip = 'GIP',
  Gmd = 'GMD',
  Gnf = 'GNF',
  Gtq = 'GTQ',
  Gyd = 'GYD',
  Hkd = 'HKD',
  Hnl = 'HNL',
  Htg = 'HTG',
  Huf = 'HUF',
  Idr = 'IDR',
  Ils = 'ILS',
  Inr = 'INR',
  Iqd = 'IQD',
  Irr = 'IRR',
  Isk = 'ISK',
  Jmd = 'JMD',
  Jod = 'JOD',
  Jpy = 'JPY',
  Kes = 'KES',
  Kgs = 'KGS',
  Khr = 'KHR',
  Kmf = 'KMF',
  Kpw = 'KPW',
  Krw = 'KRW',
  Kwd = 'KWD',
  Kyd = 'KYD',
  Kzt = 'KZT',
  Lak = 'LAK',
  Lbp = 'LBP',
  Lkr = 'LKR',
  Lrd = 'LRD',
  Lyd = 'LYD',
  Mad = 'MAD',
  Mdl = 'MDL',
  Mga = 'MGA',
  Mkd = 'MKD',
  Mmk = 'MMK',
  Mnt = 'MNT',
  Mop = 'MOP',
  Mru = 'MRU',
  Mur = 'MUR',
  Mvr = 'MVR',
  Mwk = 'MWK',
  Mxn = 'MXN',
  Myr = 'MYR',
  Mzn = 'MZN',
  Nad = 'NAD',
  Ngn = 'NGN',
  Nio = 'NIO',
  Nok = 'NOK',
  Npr = 'NPR',
  Nzd = 'NZD',
  Omr = 'OMR',
  Pab = 'PAB',
  Pen = 'PEN',
  Pgk = 'PGK',
  Php = 'PHP',
  Pkr = 'PKR',
  Pln = 'PLN',
  Pyg = 'PYG',
  Qar = 'QAR',
  Ron = 'RON',
  Rsd = 'RSD',
  Rub = 'RUB',
  Rwf = 'RWF',
  Sar = 'SAR',
  Sbd = 'SBD',
  Scr = 'SCR',
  Sdg = 'SDG',
  Sek = 'SEK',
  Sgd = 'SGD',
  Shp = 'SHP',
  Sle = 'SLE',
  Sos = 'SOS',
  Srd = 'SRD',
  Ssp = 'SSP',
  Stn = 'STN',
  Syp = 'SYP',
  Szl = 'SZL',
  Thb = 'THB',
  Tjs = 'TJS',
  Tmt = 'TMT',
  Tnd = 'TND',
  Top = 'TOP',
  Try = 'TRY',
  Ttd = 'TTD',
  Twd = 'TWD',
  Tzs = 'TZS',
  Uah = 'UAH',
  Ugx = 'UGX',
  Usd = 'USD',
  Uyu = 'UYU',
  Uzs = 'UZS',
  Ves = 'VES',
  Vnd = 'VND',
  Vuv = 'VUV',
  Wst = 'WST',
  Xaf = 'XAF',
  Xcd = 'XCD',
  Xof = 'XOF',
  Xpf = 'XPF',
  Xxx = 'XXX',
  Yer = 'YER',
  Yum = 'YUM',
  Zar = 'ZAR',
  Zmw = 'ZMW'
}

/** A custom attribute on a lot for internal use */
export type CustomAttribute = {
  __typename?: 'CustomAttribute';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** Defines the date and time of the viewing or collection day of the lot */
export type Day = {
  __typename?: 'Day';
  email?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['TbaDate']['output']>;
  forkliftCapacity?: Maybe<Scalars['Int']['output']>;
  isByAppointment: Scalars['Boolean']['output'];
  isDeliverySchedule: Scalars['Boolean']['output'];
  isForkliftAvailable: Scalars['Boolean']['output'];
  startDate?: Maybe<Scalars['TbaDate']['output']>;
};

/** Returns deposit type & amount */
export type Deposit = {
  __typename?: 'Deposit';
  amount: Money;
  type: DepositType;
};

/** Non personalised deposit badge information */
export enum DepositBadge {
  DepositNotRequired = 'DEPOSIT_NOT_REQUIRED',
  DepositRequired = 'DEPOSIT_REQUIRED',
  DepositRequirementNotReached = 'DEPOSIT_REQUIREMENT_NOT_REACHED'
}

export type DepositStatusInput = {
  platform: Platform;
};

/** Response for the buyer deposit status */
export type DepositStatusResponse = {
  __typename?: 'DepositStatusResponse';
  access: Scalars['Boolean']['output'];
  accessType: AccessType;
};

/** Type of deposit */
export enum DepositType {
  Platform = 'PLATFORM'
}

/** Sort order */
export enum Direction {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Auction & lot document object */
export type Document = {
  __typename?: 'Document';
  name: Scalars['String']['output'];
  type?: Maybe<DocumentType>;
  url: Scalars['String']['output'];
};

/** Type of the document */
export enum DocumentType {
  Catalogue = 'CATALOGUE',
  DeliverySchedule = 'DELIVERY_SCHEDULE',
  TermsConditions = 'TERMS_CONDITIONS',
  Various = 'VARIOUS'
}

/** Returns vat estimations based on bid amount and lot */
export type EstimatedFullPrice = {
  __typename?: 'EstimatedFullPrice';
  additionalCosts: Array<AdditionalCost>;
  additionalCostsVat?: Maybe<Money>;
  auctionCosts: Money;
  auctionCostsPercentage: Scalars['Long']['output'];
  auctionCostsVat: Money;
  auctionCostsVatPercentage: Scalars['Long']['output'];
  bpm?: Maybe<Money>;
  deposit?: Maybe<Deposit>;
  saleTerm: SaleTerm;
  subtotal: Money;
  total: Money;
  totalVat: Money;
  vat: Money;
  vatPercentage: Scalars['Long']['output'];
};

export type Filter = {
  __typename?: 'Filter';
  count: Scalars['Long']['output'];
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type FilterValues = {
  filters: Array<Scalars['String']['input']>;
  parent?: InputMaybe<Scalars['String']['input']>;
};

export type FilterValuesRequest = {
  __typename?: 'FilterValuesRequest';
  filters: Array<Scalars['String']['output']>;
  parent?: Maybe<Scalars['String']['output']>;
};

/** Image object */
export type Image = {
  __typename?: 'Image';
  alt?: Maybe<Scalars['String']['output']>;
  order: Scalars['Int']['output'];
  url: Scalars['String']['output'];
};

/** Listing Lot object */
export type ListingLot = {
  __typename?: 'ListingLot';
  auctionId?: Maybe<Scalars['TbaUuid']['output']>;
  biddingStatus: BiddingStatus;
  bidsCount: Scalars['Int']['output'];
  currentBidAmount: Money;
  depositBadge: DepositBadge;
  description?: Maybe<Scalars['String']['output']>;
  displayId: Scalars['String']['output'];
  endDate: Scalars['TbaDate']['output'];
  id: Scalars['TbaUuid']['output'];
  image?: Maybe<Image>;
  itemId: Scalars['TbaUuid']['output'];
  location: ListingLotLocation;
  saleTerm: SaleTerm;
  startDate: Scalars['TbaDate']['output'];
  title: Scalars['String']['output'];
  urlSlug: Scalars['String']['output'];
};

/** Defines the location of the viewing day or the collection day of the lot */
export type ListingLotLocation = {
  __typename?: 'ListingLotLocation';
  city?: Maybe<Scalars['String']['output']>;
  countryCode: Scalars['String']['output'];
};

/** Listing Lots list object */
export type ListingLots = {
  __typename?: 'ListingLots';
  hasNext: Scalars['Boolean']['output'];
  pageNumber: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  results: Array<ListingLot>;
  totalSize: Scalars['Long']['output'];
};

/** Localized value object */
export type LocalizedValue = {
  __typename?: 'LocalizedValue';
  locale: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type Location = {
  __typename?: 'Location';
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  countryCode: Scalars['String']['output'];
  id: Scalars['TbaUuid']['output'];
  name: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
};

/** Lot object */
export type Lot = {
  __typename?: 'Lot';
  additionalCosts?: Maybe<AdditionalCosts>;
  appearance?: Maybe<Scalars['String']['output']>;
  assignedExplicitly: Scalars['Boolean']['output'];
  attributes: Array<CategoryAttribute>;
  auctionId: Scalars['TbaUuid']['output'];
  auctioneerId?: Maybe<Auctioneer>;
  biddingStatus: BiddingStatus;
  bidsCount: Scalars['Int']['output'];
  companyId: Scalars['Long']['output'];
  companyType?: Maybe<BusinessType>;
  condition?: Maybe<Scalars['String']['output']>;
  currentBidAmount?: Maybe<Money>;
  customAttributes: Array<CustomAttribute>;
  depositBadge: DepositBadge;
  description: LotDescription;
  displayId: Scalars['String']['output'];
  documents: Array<Document>;
  endDate: Scalars['TbaDate']['output'];
  externalReference?: Maybe<Scalars['String']['output']>;
  followersCount: Scalars['Int']['output'];
  highestBidder?: Maybe<Scalars['TbaUuid']['output']>;
  id: Scalars['TbaUuid']['output'];
  images: Array<Image>;
  initialAmount: Money;
  insolvency: Scalars['Boolean']['output'];
  itemId: Scalars['TbaUuid']['output'];
  location: Location;
  marginGood: Scalars['Boolean']['output'];
  markupPercentage: Scalars['BigDecimal']['output'];
  minimumBidAmountMet: MinimumBidAmountMet;
  nextBidStepInCents: Scalars['Long']['output'];
  nextMinimalBid: Money;
  onboarding: Scalars['Boolean']['output'];
  otherUrlSlugs: Array<LocalizedValue>;
  packaging?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Long']['output'];
  remarks?: Maybe<Scalars['String']['output']>;
  saleTerm: SaleTerm;
  startDate: Scalars['TbaDate']['output'];
  title: Scalars['String']['output'];
  urlSlug: Scalars['String']['output'];
  vat: Scalars['BigDecimal']['output'];
  videos: Array<Video>;
};


/** Lot object */
export type LotImagesArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type LotCategoryFacetsInput = {
  categoryId: Scalars['TbaUuid']['input'];
  categoryLevel: CategoryLevel;
  locale: Scalars['String']['input'];
  nestedValueFacetInputs: Array<LotNestedValueFacetInput>;
  rangeFacetInputs: Array<LotRangeFacetInput>;
  valueFacetInputs: Array<LotValueFacetInput>;
};

/** Lot description object */
export type LotDescription = {
  __typename?: 'LotDescription';
  additionalInformation?: Maybe<Scalars['String']['output']>;
  deliveryText?: Maybe<Scalars['String']['output']>;
  deliveryUrl?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
};

/** A lot details object containing one lot and its auction */
export type LotDetails = {
  __typename?: 'LotDetails';
  auction: Auction;
  collectionDays?: Maybe<LotLocation>;
  estimatedFullPrice: EstimatedFullPrice;
  lot: Lot;
  viewingDays?: Maybe<LotLocation>;
};

export type LotFacetsInput = {
  auctionDisplayId?: InputMaybe<Scalars['String']['input']>;
  categoryId?: InputMaybe<Scalars['TbaUuid']['input']>;
  locale: Scalars['String']['input'];
  nestedValueFacetInputs: Array<LotNestedValueFacetInput>;
  rangeFacetInputs: Array<LotRangeFacetInput>;
  valueFacetInputs: Array<LotValueFacetInput>;
};

/** Lot Facets result object */
export type LotFacetsResponse = {
  __typename?: 'LotFacetsResponse';
  appliedRangeFacets: Array<LotRangeFacetRequest>;
  appliedValueFacets: Array<LotValueFacetRequest>;
  rangeFacets: Array<LotRangeFacet>;
  valueFacets: Array<LotValueFacet>;
};

export type LotFacetsResponseV2 = {
  __typename?: 'LotFacetsResponseV2';
  appliedNestedValueFacets: Array<LotNestedValueFacetRequest>;
  appliedRangeFacets: Array<LotRangeFacetRequest>;
  appliedValueFacets: Array<LotValueFacetRequest>;
  rangeFacets: Array<LotRangeFacet>;
  valueFacets: Array<LotValueFacet>;
};

/** Defines the location of the viewing day or the collection day of the lot */
export type LotLocation = {
  __typename?: 'LotLocation';
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  countryCode: Scalars['String']['output'];
  days: Array<Day>;
  name?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
};

/** Lot nested Facets result object */
export type LotNestedFacetsResponse = {
  __typename?: 'LotNestedFacetsResponse';
  valueNestedFacets: Array<LotNestedValueFacet>;
};

export type LotNestedValueFacet = {
  __typename?: 'LotNestedValueFacet';
  filters: Array<Filter>;
  identifier: LotValueNestedFacetName;
  parent: Scalars['String']['output'];
};

export type LotNestedValueFacetInput = {
  identifier: LotValueFacetName;
  values: Array<FilterValues>;
};

/** The lot value facets applied */
export type LotNestedValueFacetRequest = {
  __typename?: 'LotNestedValueFacetRequest';
  identifier: LotValueFacetName;
  values: Array<FilterValuesRequest>;
};

/** All lot range facets for the result set. */
export type LotRangeFacet = {
  __typename?: 'LotRangeFacet';
  identifier: LotRangeFacetName;
  max: Scalars['Long']['output'];
  min: Scalars['Long']['output'];
};

export type LotRangeFacetInput = {
  identifier: LotRangeFacetName;
  max: Scalars['Long']['input'];
  min: Scalars['Long']['input'];
};

/** Supported range facets on category landing page */
export enum LotRangeFacetName {
  Amounts = 'amounts',
  YearsBuilt = 'yearsBuilt'
}

/** The lot range facets to be applied. */
export type LotRangeFacetRequest = {
  __typename?: 'LotRangeFacetRequest';
  identifier: LotRangeFacetName;
  max: Scalars['Long']['output'];
  min: Scalars['Long']['output'];
};

export type LotRequestedNestedValueFacetInput = {
  facetName: LotValueNestedFacetName;
  parent: Scalars['String']['input'];
};

/** All lot value facets for the result set. */
export type LotValueFacet = {
  __typename?: 'LotValueFacet';
  filters: Array<Filter>;
  identifier: LotValueFacetName;
};

export type LotValueFacetInput = {
  filters: Array<Scalars['String']['input']>;
  identifier: LotValueFacetName;
};

/** Supported value facets on category landing page */
export enum LotValueFacetName {
  Auctions = 'auctions',
  Brands = 'brands',
  CategoryLevel1 = 'categoryLevel1',
  CategoryLevel2 = 'categoryLevel2',
  CategoryLevel3 = 'categoryLevel3',
  Countries = 'countries'
}

/** The lot value facets to be applied */
export type LotValueFacetRequest = {
  __typename?: 'LotValueFacetRequest';
  filters: Array<Scalars['String']['output']>;
  identifier: LotValueFacetName;
};

/** Supported nested value facets */
export enum LotValueNestedFacetName {
  CategoryLevel3 = 'categoryLevel3'
}

/** Lots list object */
export type Lots = {
  __typename?: 'Lots';
  hasNext: Scalars['Boolean']['output'];
  pageNumber: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  results: Array<Lot>;
  totalSize: Scalars['Long']['output'];
};

export type LotsByAuctionFacet = {
  identifier: LotsByAuctionFacetName;
  values: Array<Scalars['String']['input']>;
};

/** Supported facets on auction detail page */
export enum LotsByAuctionFacetName {
  Brands = 'brands',
  CategoryLevel2 = 'categoryLevel2',
  CategoryLevel3 = 'categoryLevel3',
  Cities = 'cities',
  Countries = 'countries'
}

export type LotsByAuctionNestedValueFacetInput = {
  identifier: LotsByAuctionFacetName;
  values: Array<FilterValues>;
};

export type LotsByAuctionValueFacetInput = {
  identifier: LotsByAuctionFacetName;
  values: Array<Scalars['String']['input']>;
};

export type LotsByCategoryIdsFacet = {
  identifier: LotsByCategoryIdsFacetName;
  values: Array<Scalars['String']['input']>;
};

/** Supported facets on category landing page */
export enum LotsByCategoryIdsFacetName {
  Auctions = 'auctions',
  Brands = 'brands',
  CategoryLevel2 = 'categoryLevel2',
  CategoryLevel3 = 'categoryLevel3',
  Cities = 'cities',
  Countries = 'countries'
}

export type LotsByCategoryIdsInput = {
  categories: Array<Scalars['TbaUuid']['input']>;
  categoryLevel: CategoryLevel;
  locale: Scalars['String']['input'];
  nestedValueFacetInputs: Array<LotsByCategoryNestedValueFacetInput>;
  pageNumber: Scalars['Long']['input'];
  pageSize: Scalars['Long']['input'];
  rangeFacets: Array<LotRangeFacetInput>;
  sortBy: LotsSortingOption;
  valueFacets: Array<LotsByCategoryIdsFacet>;
};

export type LotsByCategoryNestedValueFacetInput = {
  identifier: LotsByCategoryIdsFacetName;
  values: Array<FilterValues>;
};

/** Lot sorting order */
export enum LotsSortingOption {
  BidsCountAsc = 'BIDS_COUNT_ASC',
  BidsCountDesc = 'BIDS_COUNT_DESC',
  EndDateAsc = 'END_DATE_ASC',
  EndDateDesc = 'END_DATE_DESC',
  LotNumberAsc = 'LOT_NUMBER_ASC',
  LotNumberDesc = 'LOT_NUMBER_DESC',
  PriceAsc = 'PRICE_ASC',
  PriceDesc = 'PRICE_DESC'
}

/** Main category object */
export type MainCategory = {
  __typename?: 'MainCategory';
  id: Scalars['TbaUuid']['output'];
  name: Scalars['String']['output'];
  otherUrlSlugs: Array<LocalizedValue>;
  urlSlug: Scalars['String']['output'];
};

/** Shows if the minimum bid amount has been met */
export enum MinimumBidAmountMet {
  MinimumBidAmountMet = 'MINIMUM_BID_AMOUNT_MET',
  MinimumBidAmountNotMet = 'MINIMUM_BID_AMOUNT_NOT_MET',
  NoMinimumBidAmount = 'NO_MINIMUM_BID_AMOUNT'
}

/** Money object */
export type Money = {
  __typename?: 'Money';
  cents: Scalars['Long']['output'];
  currency: Currency;
};

/** Mutation object */
export type Mutation = {
  __typename?: 'Mutation';
  /** Save lot as favourite */
  favouriteLot: Scalars['Boolean']['output'];
  /** Attempt a bid on a lot */
  placeBid: Scalars['Boolean']['output'];
  /** Initiate platform deposit payment */
  platformDeposit: Scalars['Boolean']['output'];
  /** Request a refund for a deposit that has been paid */
  refundDeposit: Refund;
  /** Subscribe to platform deposit updates */
  subscribeDeposit: Scalars['Boolean']['output'];
  /** Subscribe to lot(s) realtime updates */
  subscribeToLots: Scalars['Boolean']['output'];
  /** Remove lot from favourites */
  unfavouriteLot: Scalars['Boolean']['output'];
  /** Unsubscribe from realtime updates for all lot(s) or a subsets of lot(s) */
  unsubscribe: Scalars['Boolean']['output'];
  /** Unsubscribe to platform deposit updates */
  unsubscribeDeposit: Scalars['Boolean']['output'];
};


/** Mutation object */
export type MutationFavouriteLotArgs = {
  id: Scalars['String']['input'];
};


/** Mutation object */
export type MutationPlaceBidArgs = {
  bidRequest: BidRequest;
};


/** Mutation object */
export type MutationPlatformDepositArgs = {
  lotId?: InputMaybe<Scalars['String']['input']>;
};


/** Mutation object */
export type MutationRefundDepositArgs = {
  request: RefundDepositInput;
};


/** Mutation object */
export type MutationSubscribeDepositArgs = {
  connectionId: Scalars['String']['input'];
};


/** Mutation object */
export type MutationSubscribeToLotsArgs = {
  connectionId: Scalars['String']['input'];
  lotIds: Array<Scalars['TbaUuid']['input']>;
};


/** Mutation object */
export type MutationUnfavouriteLotArgs = {
  id: Scalars['String']['input'];
};


/** Mutation object */
export type MutationUnsubscribeArgs = {
  connectionId: Scalars['String']['input'];
  lotIds: Array<Scalars['TbaUuid']['input']>;
};


/** Mutation object */
export type MutationUnsubscribeDepositArgs = {
  connectionId: Scalars['String']['input'];
};

/** My Listing Lot object (my lots pages) */
export type MyListingLot = {
  __typename?: 'MyListingLot';
  auctionId?: Maybe<Scalars['TbaUuid']['output']>;
  biddingStatus: BiddingStatus;
  bidsCount: Scalars['Int']['output'];
  currentBidAmount: Money;
  deliveryCountries: Array<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayId: Scalars['String']['output'];
  endDate: Scalars['TbaDate']['output'];
  id: Scalars['TbaUuid']['output'];
  image?: Maybe<Image>;
  itemId: Scalars['TbaUuid']['output'];
  location: LotLocation;
  personalizedData: PersonalizedData;
  saleTerm: SaleTerm;
  startDate: Scalars['TbaDate']['output'];
  title: Scalars['String']['output'];
  urlSlug: Scalars['String']['output'];
};

/** My Listing Lots list object (my lots pages) */
export type MyListingLots = {
  __typename?: 'MyListingLots';
  hasNext: Scalars['Boolean']['output'];
  pageNumber: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  results: Array<MyListingLot>;
  totalSize: Scalars['Long']['output'];
};

export type MyLotsInput = {
  locale: Scalars['String']['input'];
  pageNumber: Scalars['Long']['input'];
  pageSize: Scalars['Long']['input'];
  scope: MyLotsScope;
  sortBy: LotsSortingOption;
};

/** Filter for lots user has interacted with */
export enum MyLotsScope {
  All = 'ALL',
  Lost = 'LOST',
  Negotiable = 'NEGOTIABLE',
  Won = 'WON'
}

export type NestedFacetRequestInput = {
  auctionDisplayId?: InputMaybe<Scalars['String']['input']>;
  categoryId?: InputMaybe<Scalars['TbaUuid']['input']>;
  locale: Scalars['String']['input'];
  rangeFacetInputs: Array<LotRangeFacetInput>;
  requestedNestedValueFacetInputs: Array<LotRequestedNestedValueFacetInput>;
  valueFacetInputs: Array<LotValueFacetInput>;
};

/** Personalised deposit badge information */
export enum PersonalisedDepositBadge {
  DepositNotRequired = 'DEPOSIT_NOT_REQUIRED',
  DepositPaid = 'DEPOSIT_PAID',
  DepositRequired = 'DEPOSIT_REQUIRED',
  DepositRequirementNotReached = 'DEPOSIT_REQUIREMENT_NOT_REACHED'
}

/** Current buyerId data for a lot */
export type PersonalizedData = {
  __typename?: 'PersonalizedData';
  bidStatus: BidStatus;
  isFavourite: Scalars['Boolean']['output'];
  maxAutoBidAmount?: Maybe<Money>;
  personalisedDepositBadge: PersonalisedDepositBadge;
  personalizedLotStatus: PersonalizedLotStatus;
};

/** Personalized lot object with the buyerId data */
export type PersonalizedLot = {
  __typename?: 'PersonalizedLot';
  collectionDays?: Maybe<LotLocation>;
  deliveryCountries?: Maybe<Array<Scalars['String']['output']>>;
  lot: Lot;
  personalizedData: PersonalizedData;
};

/** A lot details object containing one lot  with the buyerId data and its auction */
export type PersonalizedLotDetails = {
  __typename?: 'PersonalizedLotDetails';
  auction: Auction;
  collectionDays?: Maybe<LotLocation>;
  estimatedFullPrice: EstimatedFullPrice;
  lot: Lot;
  personalizedData: PersonalizedData;
  viewingDays?: Maybe<LotLocation>;
};

/** Personalized lot data per lot id */
export type PersonalizedLotId = {
  __typename?: 'PersonalizedLotId';
  lotId: Scalars['TbaUuid']['output'];
  personalizedData: PersonalizedData;
};

/** Personalized lot status for given buyerId */
export enum PersonalizedLotStatus {
  Bid = 'BID',
  Favourite = 'FAVOURITE',
  Lost = 'LOST',
  NoRelations = 'NO_RELATIONS',
  Outbid = 'OUTBID',
  UnderAllocation = 'UNDER_ALLOCATION',
  Won = 'WON'
}

/** Lot list object with the buyerId data per lot */
export type PersonalizedLots = {
  __typename?: 'PersonalizedLots';
  hasNext: Scalars['Boolean']['output'];
  pageNumber: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  results: Array<PersonalizedLot>;
  totalSize: Scalars['Long']['output'];
};

/** Platform of lots & auctions */
export enum Platform {
  Auk = 'AUK',
  Bva = 'BVA',
  Epic = 'EPIC',
  KvkFi = 'KVK_FI',
  Twk = 'TWK',
  Vavato = 'VAVATO'
}

/** Query object */
export type Query = {
  __typename?: 'Query';
  /** Return auction additional information by auction Id */
  additionalInformation: AdditionalInformationResponse;
  /** Returns auctions where the bidding is open, not yet open or already closed */
  allAuctions: Auctions;
  /** Returns auctions where the bidding is open, not yet open or already closed */
  allAuctionsV2: Auctions;
  /** Return all lots */
  allLots: ListingLots;
  /** Return auction by id or by display id */
  auction: Auction;
  /** Return Facets belonging to auctions. */
  auctionFacets: AuctionFacetsResponse;
  /** Returns the previous and next lot url slugs */
  auctionNavigator: AuctionNavigation;
  /** Return auction by display id together with its paged lots */
  auctionWithLots: AuctionWithLots;
  /** Return auction by display id together with its paged lots version 2 */
  auctionWithLotsV2: AuctionWithLots;
  /** Return auction by display id together with its paged lots version 3 (return only limited data) */
  auctionWithLotsV3: AuctionWithListingLots;
  /** Return auctions by display ids */
  auctionsByDisplayIds: Array<Auction>;
  /** Returns all level 2 categories by main category id */
  categories: Array<Category>;
  /** Returns category level 2 by id */
  category: Category;
  /** Returns a buyers deposit status */
  depositStatus: DepositStatusResponse;
  /** Returns estimated full price of a bid, including vat */
  estimatedFullPrice: EstimatedFullPrice;
  /** Returns Facets for  categoryLevel1 or categoryLevel2 */
  lotCategoryFacets: LotFacetsResponseV2;
  /** Returns the lot details */
  lotDetails: LotDetails;
  /**
   * Return Facets belonging to lots.
   * @deprecated This query is deprecated as it does not support category 3 filters. Use lotFacetsV2
   */
  lotFacets: LotFacetsResponse;
  /** Return Facets belonging to lots allowing category level 3 */
  lotFacetsV2: LotFacetsResponseV2;
  /** Returns the personalized data of lots by their ids */
  lotPersonalizedDataByIds: Array<PersonalizedLotId>;
  /** Return lots by auction id or by auction display id */
  lots: Lots;
  /** Return lots belonging to provided main categories */
  lotsByCategoryIds: Lots;
  /** Return lots belonging to provided main categories */
  lotsByCategoryIdsV2: Lots;
  /** Return lots belonging to provided main categories */
  lotsByCategoryIdsV3: ListingLots;
  /** Return lots by ids or by display ids */
  lotsByIds: Array<Lot>;
  /** Returns all main categories */
  mainCategories: Array<MainCategory>;
  /** Returns main category by id */
  mainCategory: MainCategory;
  /** Returns all the lots a user has interacted with, filtered by a selected scope */
  myLots: PersonalizedLots;
  /** Returns all the lots a user has interacted with, filtered by a selected scope */
  myLotsV2: MyListingLots;
  /** Returns nested facets belonging to lots */
  nestedLotFacets: LotNestedFacetsResponse;
  /** Returns the personalized lot details */
  personalizedLotDetails: PersonalizedLotDetails;
};


/** Query object */
export type QueryAdditionalInformationArgs = {
  request: AdditionalInformationInput;
};


/** Query object */
export type QueryAllAuctionsArgs = {
  auctionBiddingStatuses?: Array<BiddingStatus>;
  facets?: Array<AllAuctionsFacet>;
  locale: Scalars['String']['input'];
  pageNumber?: Scalars['Long']['input'];
  pageSize?: Scalars['Long']['input'];
  platform: Platform;
  sortBy?: AuctionsSortingOption;
};


/** Query object */
export type QueryAllAuctionsV2Args = {
  platform: Platform;
  request: AllAuctionsInput;
};


/** Query object */
export type QueryAllLotsArgs = {
  platform: Platform;
  request: SingleLotsInput;
};


/** Query object */
export type QueryAuctionArgs = {
  displayId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['TbaUuid']['input']>;
  locale: Scalars['String']['input'];
  platform: Platform;
};


/** Query object */
export type QueryAuctionFacetsArgs = {
  auctionBiddingStatuses?: Array<BiddingStatus>;
  locale: Scalars['String']['input'];
  platform: Platform;
  valueFacetInputs?: Array<AuctionValueFacetInput>;
};


/** Query object */
export type QueryAuctionNavigatorArgs = {
  request: AuctionNavigatorInput;
};


/** Query object */
export type QueryAuctionWithLotsArgs = {
  displayId: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  lotBiddingStatuses?: Array<BiddingStatus>;
  pageNumber?: Scalars['Long']['input'];
  pageSize?: Scalars['Long']['input'];
  platform: Platform;
  rangeFacets?: Array<LotRangeFacetInput>;
  sortBy?: LotsSortingOption;
  valueFacets?: Array<LotsByAuctionFacet>;
};


/** Query object */
export type QueryAuctionWithLotsV2Args = {
  platform: Platform;
  request: AuctionWithLotsInput;
};


/** Query object */
export type QueryAuctionWithLotsV3Args = {
  platform: Platform;
  request: AuctionWithLotsInput;
};


/** Query object */
export type QueryAuctionsByDisplayIdsArgs = {
  displayIds?: Array<Scalars['String']['input']>;
  locale: Scalars['String']['input'];
  platform: Platform;
  sortBy?: AuctionsSortingOption;
};


/** Query object */
export type QueryCategoriesArgs = {
  locale: Scalars['String']['input'];
  mainCategoryId: Scalars['TbaUuid']['input'];
};


/** Query object */
export type QueryCategoryArgs = {
  id: Scalars['TbaUuid']['input'];
  locale: Scalars['String']['input'];
};


/** Query object */
export type QueryDepositStatusArgs = {
  request: DepositStatusInput;
};


/** Query object */
export type QueryEstimatedFullPriceArgs = {
  bidAmount: Scalars['Long']['input'];
  currency?: Currency;
  lotId: Scalars['TbaUuid']['input'];
  platform: Platform;
};


/** Query object */
export type QueryLotCategoryFacetsArgs = {
  platform: Platform;
  request: LotCategoryFacetsInput;
};


/** Query object */
export type QueryLotDetailsArgs = {
  displayId: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  platform: Platform;
};


/** Query object */
export type QueryLotFacetsArgs = {
  auctionDisplayId?: InputMaybe<Scalars['String']['input']>;
  categoryId?: InputMaybe<Scalars['TbaUuid']['input']>;
  locale: Scalars['String']['input'];
  platform: Platform;
  rangeFacetInputs?: Array<LotRangeFacetInput>;
  valueFacetInputs?: Array<LotValueFacetInput>;
};


/** Query object */
export type QueryLotFacetsV2Args = {
  platform: Platform;
  request: LotFacetsInput;
};


/** Query object */
export type QueryLotPersonalizedDataByIdsArgs = {
  ids: Array<Scalars['TbaUuid']['input']>;
  platform: Platform;
};


/** Query object */
export type QueryLotsArgs = {
  auctionDisplayId?: InputMaybe<Scalars['String']['input']>;
  auctionId?: InputMaybe<Scalars['TbaUuid']['input']>;
  locale: Scalars['String']['input'];
  lotBiddingStatuses?: Array<BiddingStatus>;
  pageNumber?: Scalars['Long']['input'];
  pageSize?: Scalars['Long']['input'];
  platform: Platform;
  sortBy?: LotsSortingOption;
};


/** Query object */
export type QueryLotsByCategoryIdsArgs = {
  categories: Array<Scalars['TbaUuid']['input']>;
  categoryLevel: CategoryLevel;
  locale: Scalars['String']['input'];
  pageNumber?: Scalars['Long']['input'];
  pageSize?: Scalars['Long']['input'];
  platform: Platform;
  rangeFacets?: Array<LotRangeFacetInput>;
  sortBy?: LotsSortingOption;
  valueFacets?: Array<LotsByCategoryIdsFacet>;
};


/** Query object */
export type QueryLotsByCategoryIdsV2Args = {
  platform: Platform;
  request: LotsByCategoryIdsInput;
};


/** Query object */
export type QueryLotsByCategoryIdsV3Args = {
  platform: Platform;
  request: LotsByCategoryIdsInput;
};


/** Query object */
export type QueryLotsByIdsArgs = {
  displayIds?: Array<Scalars['String']['input']>;
  ids?: Array<Scalars['TbaUuid']['input']>;
  locale: Scalars['String']['input'];
  platform: Platform;
  sortBy?: LotsSortingOption;
};


/** Query object */
export type QueryMainCategoriesArgs = {
  locale: Scalars['String']['input'];
};


/** Query object */
export type QueryMainCategoryArgs = {
  id: Scalars['TbaUuid']['input'];
  locale: Scalars['String']['input'];
};


/** Query object */
export type QueryMyLotsArgs = {
  locale: Scalars['String']['input'];
  pageNumber?: Scalars['Long']['input'];
  pageSize?: Scalars['Long']['input'];
  platform: Platform;
  scope?: MyLotsScope;
  sortBy?: LotsSortingOption;
};


/** Query object */
export type QueryMyLotsV2Args = {
  platform: Platform;
  request: MyLotsInput;
};


/** Query object */
export type QueryNestedLotFacetsArgs = {
  platform: Platform;
  request: NestedFacetRequestInput;
};


/** Query object */
export type QueryPersonalizedLotDetailsArgs = {
  displayId: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  platform: Platform;
};

/** Response for the refund deposit request */
export type Refund = {
  __typename?: 'Refund';
  refundStatus: RefundStatus;
  rejectionReason?: Maybe<Scalars['String']['output']>;
};

export type RefundDepositInput = {
  accessType: AccessType;
  depositId: Scalars['TbaUuid']['input'];
};

/** Refund status for refund deposit request */
export enum RefundStatus {
  Denied = 'DENIED',
  Pending = 'PENDING'
}

/** Sale status of the lot */
export enum SaleTerm {
  ClosedAllocated = 'CLOSED_ALLOCATED',
  ClosedAllocationDeclined = 'CLOSED_ALLOCATION_DECLINED',
  ClosedAllocationPending = 'CLOSED_ALLOCATION_PENDING',
  ClosedReservePriceAllocated = 'CLOSED_RESERVE_PRICE_ALLOCATED',
  ClosedReservePriceAllocationDeclined = 'CLOSED_RESERVE_PRICE_ALLOCATION_DECLINED',
  ClosedReservePriceAllocationPending = 'CLOSED_RESERVE_PRICE_ALLOCATION_PENDING',
  FutureAllocationSet = 'FUTURE_ALLOCATION_SET',
  FutureReservePriceSet = 'FUTURE_RESERVE_PRICE_SET',
  GuaranteedSale = 'GUARANTEED_SALE',
  GuaranteedSaleClosed = 'GUARANTEED_SALE_CLOSED',
  OpenAllocationSet = 'OPEN_ALLOCATION_SET',
  OpenReservePriceAchieved = 'OPEN_RESERVE_PRICE_ACHIEVED',
  OpenReservePriceNotAchieved = 'OPEN_RESERVE_PRICE_NOT_ACHIEVED'
}

export type SingleLotsInput = {
  locale: Scalars['String']['input'];
  lotBiddingStatuses: Array<BiddingStatus>;
  pageNumber: Scalars['Long']['input'];
  pageSize: Scalars['Long']['input'];
  sortBy: LotsSortingOption;
};

/** Video object */
export type Video = {
  __typename?: 'Video';
  alt: Scalars['String']['output'];
  thumbnail?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

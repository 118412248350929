import React from 'react';

const CirclePerson2 = (): JSX.Element => {
  return (
    <svg width="100" height="100" viewBox="0 0 100 100">
      <g fill="none" fillRule="evenodd">
        <g transform="translate(0 .475)">
          <mask id="b" fill="#fff">
            <path id="a" d="M0 .001h99.999v93.19H0z" />
          </mask>
          <path
            fill="#00C8FF"
            d="M0 49.762C0 22.28 22.386.001 50 .001c27.613 0 49.999 22.28 49.999 49.761 0 17.21-8.78 32.378-22.124 41.314-4.267-6.054-10.53-10.615-17.857-12.69v6.878c0 4.378-3.566 7.927-7.965 7.927s-7.964-3.549-7.964-7.927c0-.134.013-.266.02-.4V82.92c0-1.708-.455-3.329-1.238-4.767-8.056 2.052-14.91 7.08-19.329 13.829C9.41 83.189 0 67.573 0 49.762"
            mask="url(#b)"
          />
        </g>
        <path
          fill="#013B3D"
          d="M42.871 78.627c.782 1.439 1.238 3.059 1.238 4.768v1.945c-.007.133-.02.265-.02.4 0 4.378 3.566 7.926 7.964 7.926 4.399 0 7.965-3.548 7.965-7.926v-6.88c7.328 2.076 13.59 6.637 17.857 12.691C69.911 96.885 60.321 100 50 100a49.93 49.93 0 0 1-26.457-7.542c4.42-6.75 11.273-11.777 19.33-13.83M52.42 19.188c1.629-1.687 4.055-2.606 6.678-2.152 3.074.534 5.529 3.053 5.994 6.125a7.334 7.334 0 0 1-.491 3.987c-.624 1.47-1.06 3.028-.957 4.62.017.248.025.498.025.75 0 3.938-1.865 7.436-4.756 9.69-2.245-5.842-7.705-10.154-14.285-10.362-2-.064-3.911.26-5.69.869l-.008-.006c-.096-6.36 5.969-12.062 11.343-12.492a3.338 3.338 0 0 0 2.147-1.029"
        />
        <path
          fill="#00EB9D"
          d="M38.938 32.714a15.932 15.932 0 0 1 5.69-.868c6.58.208 12.04 4.52 14.285 10.361A12.343 12.343 0 0 1 51.3 44.83c-6.766 0-12.254-5.407-12.36-12.116M52.053 93.666c-4.399 0-7.965-3.548-7.965-7.927 0-.134.014-.266.021-.399v-1.945c0-1.71-.455-3.33-1.238-4.768a32.982 32.982 0 0 1 8.157-1.018c3.118 0 6.132.442 8.99 1.251v6.88c0 4.378-3.566 7.926-7.965 7.926M59.363 47.15c0-.692.654-1.185 1.332-1.03 2.622.6 4.577 2.938 4.577 5.73 0 2.791-1.955 5.129-4.577 5.73-.678.155-1.332-.34-1.332-1.031v-9.398z"
        />
        <path
          fill="#00A8E0"
          d="M44.523 51.282a2.31 2.31 0 0 0 2.316 2.305 2.31 2.31 0 0 0 2.317-2.305 2.31 2.31 0 0 0-2.317-2.306 2.311 2.311 0 0 0-2.316 2.306M30.963 51.282a2.31 2.31 0 0 0 2.317 2.305 2.31 2.31 0 0 0 2.317-2.305 2.311 2.311 0 0 0-2.317-2.306 2.311 2.311 0 0 0-2.317 2.306"
        />
        <path
          fill="#FCCC0D"
          d="M41.099 59.82v-5.852c0-.56-.682-.841-1.08-.445l-1.771 1.762a2.269 2.269 0 0 0 0 3.219l1.77 1.762c.399.396 1.08.115 1.08-.446"
        />
        <path
          fill="#FFF"
          d="M28.236 58.426l-.015-9.93C27.85 41.2 32.45 34.937 38.938 32.714c.106 6.708 5.595 12.116 12.36 12.116 2.875 0 5.515-.985 7.615-2.623a16.425 16.425 0 0 1 1.105 5.9V78.86a32.933 32.933 0 0 0-8.99-1.251c-2.816 0-5.55.353-8.157 1.018a10.262 10.262 0 0 0-5.004-4.554c-5.993-2.547-10.097-8.651-9.63-15.647"
        />
        <path
          fill="#00A8E0"
          d="M45.09 51.282a2.31 2.31 0 0 0 2.316 2.305 2.31 2.31 0 0 0 2.316-2.305 2.31 2.31 0 0 0-2.316-2.306 2.311 2.311 0 0 0-2.317 2.306M31.53 51.282a2.31 2.31 0 0 0 2.317 2.305 2.31 2.31 0 0 0 2.316-2.305 2.311 2.311 0 0 0-2.316-2.306 2.311 2.311 0 0 0-2.317 2.306"
        />
        <path
          fill="#00EB9D"
          d="M41.759 60.622l.167-7.491c.016-.719-.849-1.06-1.37-.54l-2.317 2.306c-1.169 1.163-1.21 3.008-.092 4.12l2.216 2.206c.499.496 1.38.117 1.396-.601"
        />
        <path
          fill="#00A8E0"
          d="M34.804 63.44h2.643a.33.33 0 0 1 .328.258 3.484 3.484 0 0 0 6.835 0 .33.33 0 0 1 .327-.258h2.644c.198 0 .358.169.336.365-.388 3.352-3.254 5.963-6.725 5.963-3.47 0-6.335-2.612-6.724-5.963a.334.334 0 0 1 .336-.365"
        />
      </g>
    </svg>
  );
};

export default CirclePerson2;

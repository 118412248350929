import React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from '../../../brenger-shared-ui/components/layout/grid';
import { Button, Heading, RfInput, RfInputPassword, SectionWrapper } from '../../../brenger-shared-ui';
import { push } from 'connected-react-router';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { actions } from '../ducks';
import '../../../assets/user/user.scss';
import { PersonSchema } from '../schema';
import { UserProperties } from '../typings';
import { translate } from '../../../utils/localization';
import { FlashMessage } from '../../../brenger-shared-ui/';
import SocialButtons from '../components/SocialButtons';
import { Translate } from 'react-localize-redux-dep-updated';
import { urlParser } from '../../../utils/basics';
import { RootState } from 'src/typings';
import { Dispatch } from 'redux';
import { r } from '../../../routes';

// FIX ME: Inject a proper form value interface
type FormValues = Record<string, unknown>;

type ReduxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

type Form = InjectedFormProps<FormValues, ReduxProps>;

type Props = ReduxProps & Form;

const Login: React.FC<Props> = props => {
  return (
    <>
      <SectionWrapper extraClasses={'login-wrapper'} sectionStyle={'white'} centerContent={true}>
        <form onSubmit={props.handleSubmit}>
          <Heading size={2}>
            <Translate id={'authentication.login.headline'} />
          </Heading>
          <p>
            <Translate id={'authentication.login.no_account'} />{' '}
            <span
              className={'trigger text--underline text--primary'}
              onClick={() => props.push(r.user.register({ trId: urlParser().get('transport_request') }))}
            >
              <Translate id={'authentication.register.button'} />
            </span>
          </p>
          <div className={'pb-1'}>
            <SocialButtons socialAuth={props.socialAuth} />
          </div>
          <Row>
            <Col xs={12} extraClasses={'pt-1'}>
              <Field
                validate={PersonSchema[UserProperties.EMAIL]}
                name={'user.email'}
                type="text"
                component={RfInput}
                label={translate('form.fields.user.email.label')}
              />
            </Col>
            <Col xs={12} sm={12}>
              <Field
                validate={PersonSchema[UserProperties.PASSWORD]}
                name={'user.password'}
                type="password"
                component={RfInputPassword}
                label={translate('form.fields.user.password.label')}
              />
            </Col>
            <Col xs={12} sm={12} extraClasses={'login-errors'}>
              {typeof props.errors === 'string' && props.errors !== '' && (
                <FlashMessage type={'error'} message={props.errors} />
              )}
            </Col>
            <Col xs={12} sm={12}>
              <Button
                fullWidth={true}
                loading={props.submitting}
                disabled={props.submitting || props.pristine || !props.valid}
                buttonStyle={'secondary'}
              >
                <Translate id={'authentication.login.button'} />
              </Button>
            </Col>
            <Col xs={12} extraClasses={'pt-0-5'}>
              <p>
                <Translate id={'authentication.reset.lost_password'} />{' '}
                <span
                  className={'trigger text--underline text--primary'}
                  onClick={() => props.push(r.user.passwordReset())}
                >
                  <Translate id={'authentication.general.click_here'} />
                </span>
              </p>
            </Col>
          </Row>
        </form>
      </SectionWrapper>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState) => ({
  submitting: state.user.loading,
  errors: state.user.errors,
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: Dispatch) => ({
  socialAuth: service => dispatch(actions.socialAuthStart(service, true)),
  push: (url: string) => dispatch(push(url)),
  login: user => dispatch(actions.login(user)),
});

export default reduxForm<FormValues, Props>({
  form: 'login',
  destroyOnUnmount: false,
  onSubmit: (user, dispatch) => dispatch(actions.login(user)),
})(connect(mapStateToProps, mapDispatchToProps)(Login));

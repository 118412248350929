import * as React from 'react';
import InputWrapper from '../input/InputWrapper';
import InputFeedback from '../input/InputFeedback';
import './select.scss';
import { IconSmile } from '../../basics/icons';
import { InputProps } from '../input/Input';

export interface SelectOption {
  value: string;
  label: string;
}

interface Props extends InputProps {
  label?: string;
  description?: string;
  border?: boolean;
  successMessage: string;
  options: SelectOption[];
  emptyFirstOption?: boolean;
}

const Select = ({
  input,
  meta,
  label,
  border,
  successMessage,
  options,
  required,
  emptyFirstOption = true,
}: Props): JSX.Element => (
  <InputWrapper label={label} input={input} meta={meta} type={'select'} border={border}>
    {label && (
      <label className={'input-el--label' + (required ? ' input-el--label--required' : '')} htmlFor={input.name}>
        {label}
      </label>
    )}
    <select {...input} id={input.name} value={input.value}>
      {emptyFirstOption && <option />}
      {options.map((item, index) => (
        <option key={index} value={item.value}>
          {item.label}
        </option>
      ))}
    </select>
    <label htmlFor={input.name}>
      <IconSmile />
    </label>
    <InputFeedback input={input} meta={meta} error={meta.error} successMessage={successMessage} />
  </InputWrapper>
);

export default Select;

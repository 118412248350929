import React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import classNames from 'classnames';

import { useTypedSelector } from '../../../../hooks';
import { useNotification } from '../../../../hooks/useNotification';
import { CirclePerson2, Toast } from '../../../../brenger-shared-ui';

export const ExtraCareScooterNotification: React.FC = () => {
  const notification = useNotification('extra-care--scooter');
  const progressStep = useTypedSelector(state => state.generalTransport.layout.step);
  const items = useTypedSelector(state => state.generalTransport.product_selection.items);
  const hasExtraCareScooter = items.some(i => {
    return (i.product_groups || []).some(iri =>
      ['ProductGroup#Mopeds|Scooter', 'ProductGroup#Mopeds|Moped'].includes(iri)
    );
  });

  React.useEffect(() => {
    if (progressStep === 3 && hasExtraCareScooter) {
      notification.add();
    } else {
      notification.remove();
    }
  }, [progressStep, hasExtraCareScooter]);

  return (
    <div className={classNames('info-message-container', { 'info-message--visible': notification.isVisible })}>
      <Toast prefix={<CirclePerson2 />} handleClose={notification.removeForOneSession}>
        <div style={{ paddingRight: '15px' }}>
          <b>
            <Translate id="request_flow.extra_care.scooter.notification.title" />
          </b>
        </div>
        <div>
          <Translate id="request_flow.extra_care.scooter.notification.text" />
        </div>
      </Toast>
    </div>
  );
};

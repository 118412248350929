import React from 'react';
import { InputMeta, ModifiedInput } from '.';
import cn from 'classnames';
import './input.scss';
import { IconSmile } from '../basics/icons';
import { InputWrapper } from './InputWrapper';

interface InputTextProps extends ModifiedInput {
  isLoading?: boolean;
  onChange?: (value: string) => void;
  meta?: InputMeta;
}

export const InputText: React.FC<InputTextProps> = ({ isLoading, onChange, className, meta, ...unused }) => {
  return (
    <InputWrapper {...meta}>
      <div className={cn({ 'input-loading': isLoading }, 'w-full')}>
        <input
          type="text"
          className={cn('input-default', className)}
          onChange={(event: React.FormEvent<HTMLInputElement>): void => {
            onChange?.(event.currentTarget.value);
          }}
          {...unused}
        />
        {isLoading && (
          <div className="loader">
            <IconSmile spinning={true} />
          </div>
        )}
      </div>
    </InputWrapper>
  );
};

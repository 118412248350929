import React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { Col, IconSmile, Row, SectionWrapper } from '../../../../brenger-shared-ui';

export const Loading: React.FC = () => {
  return (
    <SectionWrapper sectionStyle={'white'} extraClasses={'profile-dashboard-wrapper'} centerContent={true}>
      <Row>
        <Col xs={12} md={8} extraClasses={'offset-md-2 pb-1'}>
          <IconSmile spinning={true} /> <Translate id={'request_flow.thank_you.verifying_payment'} />
        </Col>
      </Row>
    </SectionWrapper>
  );
};

import React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { connect } from 'react-redux';

import {
  Button,
  Col,
  Container,
  IconArrow,
  IconSmile,
  PriceDisplay,
  Row,
  SheetLayoutFooter,
} from '../../../brenger-shared-ui';
import { actions, getPriceLoading, getProgressStep, getSheetState } from '../ducks';
import { RootState } from '../../../typings';
import { Account } from '@brenger/api-client';
import { Dispatch } from 'redux';

type ReduxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const FooterLayout: React.FC<ReduxProps> = props => {
  const isDisabled =
    (props.step === 3 &&
      (props.price.incl_vat.amount === 0 || props.accountDisabled || props.rejectedReason !== null)) ||
    props.priceIsLoading ||
    !props.depotAddresses.length;
  return (
    <SheetLayoutFooter>
      <Container>
        <Row>
          <Col xs={6} extraClasses={'footer-action-bar hidden--lg-up business--footer'}>
            <div onClick={() => props.setSheetState(!props.sheetState)}>
              <PriceDisplay
                header={
                  <div>
                    <IconSmile />
                    <Translate id={'request_flow.price.total_ex_vat'} />
                  </div>
                }
                quotePrice={props.price}
                exclVat={true}
                loading={props.priceIsLoading}
              />
            </div>
          </Col>
          <Col xs={6} extraClasses={'offset-lg-6'}>
            <Button
              loading={props.priceIsLoading}
              disabled={isDisabled}
              buttonStyle={'secondary'}
              iconPos={'right'}
              fullWidth={true}
              type="button"
              onClick={props.step !== 3 ? () => props.submitForm() : () => props.createTr()}
            >
              <Translate id={props.step === 3 ? 'request_flow.business.submit_request' : 'onboarding.step.next'} />
              <IconArrow />
            </Button>
          </Col>
        </Row>
      </Container>
    </SheetLayoutFooter>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState, ownProps) => ({
  price: state.business.transport_request.price,
  priceIsLoading: getPriceLoading(state),
  sheetState: getSheetState(state),
  step: getProgressStep(state),
  depotAddresses: state.business.depots.options,
  submitForm: ownProps.submitForm,
  accountDisabled: Boolean((state.user?.userData?.account as Account | undefined)?.disable_submitting_trs),
  rejectedReason: state.business.transport_request.price.rejected_reason,
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: Dispatch) => ({
  setSheetState: state => dispatch(actions.setSheetState(state)),
  createTr: () => dispatch(actions.createNewTr()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FooterLayout);

import { useQuery } from 'react-query';
import { CacheKey } from '../typings';
import { accountingClient } from '../utils/request';
import { useDebounce } from './useDebounce';

export interface VatNumberValidator {
  isVatLoading: boolean;
  isVatValid: boolean;
}

export const useVatNumberValidator = (inputNumber: string): VatNumberValidator => {
  const vatNumber = useDebounce(inputNumber, 250);
  const { isLoading, data } = useQuery(
    [CacheKey.VAT_NUMBER_VALIDATOR, vatNumber],
    () => accountingClient.vat.isVatNumberValid({ vatNumber }),
    {
      enabled: vatNumber.length > 8,
    }
  );
  /**
   * Loading
   */
  if (isLoading) return { isVatLoading: true, isVatValid: false };

  /**
   * Success
   */
  if (data === true) return { isVatLoading: false, isVatValid: true };

  /**
   * Failed
   */
  return { isVatLoading: false, isVatValid: false };
};

import React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { useDispatch } from 'react-redux';

import {
  FlashMessage,
  Heading,
  IconSituation2dehands,
  IconSituationAcquaintances,
  IconSituationFacebookMarketplace,
  IconSituationMarktplaats,
  IconSituationOther,
  ImageCdn,
  InputTiles,
  SheetLayoutContent,
  SheetLayoutContentWrapper,
  SheetLayoutHeader,
} from '../../../brenger-shared-ui';
import { InputOption } from '../../../brenger-shared-ui/components/reduxForm/inputtiles/InputTiles';
import { useTypedSelector } from '../../../hooks';
import { getActiveLanguageSettings, translate } from '../../../utils/localization';
import FooterLayout from '../containers/FooterLayout';
import { actions } from '../ducks';
import { proceed } from '../proceed';
import { MarktPlaatsBanner } from '../../../brenger-shared-ui/components/layout/banners/MarktPlaats';

export type HomeSituationType =
  | 'acquaintances'
  | 'other'
  | 'ebay'
  | 'gumtree'
  | 'craigslist'
  | 'preloved'
  | 'shpock'
  | 'ebay_kleinanzeigen'
  | 'markt'
  | 'quoka'
  | 'shpock'
  | 'kalaydo'
  | 'hood'
  | 'wallapop'
  | 'milanuncios'
  | 'vibbo'
  | 'todocoleccion'
  | 'tablon-de-anuncios'
  | 'marktplaats'
  | 'facebook_marketplace'
  | '2dehands';

export const SubStepHomeSituation: React.FC = () => {
  const dispatch = useDispatch();
  const error = useTypedSelector(state => state.generalTransport.error);
  const homeSituation = useTypedSelector(state => state.generalTransport.choices?.home_situation);

  React.useEffect(() => {
    dispatch(actions.setProgress(2));
    dispatch(actions.resetChoice(['home_situation']));
  }, []);

  const acquaintancesOption: InputOption = {
    title: translate('request_flow.home_situation.fields.type.options.acquaintances.title'),
    desc: translate('request_flow.home_situation.fields.type.options.acquaintances.text'),
    value: 'acquaintances',
    prefix: <IconSituationAcquaintances />,
  };
  const otherOption: InputOption = {
    title: translate('request_flow.home_situation.fields.type.options.other.title'),
    desc: translate('request_flow.home_situation.fields.type.options.other.text'),
    value: 'other',
    prefix: <IconSituationOther />,
  };
  const options: InputOption[] = {
    de: [
      {
        title: translate('request_flow.home_situation.fields.type.options.ebay_kleinanzeigen.title'),
        desc: translate('request_flow.home_situation.fields.type.options.you_bought_or_sell'),
        value: 'leinanzeigen',
        prefix: <ImageCdn src={'partners/kleinanzeigen.svg'} height={30} width={30} alt={'Kleinanzeigen'} />,
      },
      {
        title: translate('request_flow.home_situation.fields.type.options.ebay.title'),
        desc: translate('request_flow.home_situation.fields.type.options.you_bought_or_sell'),
        value: 'ebay',
        prefix: <ImageCdn src={'partners/ebay.svg'} height={30} width={30} alt={'eBay'} />,
      },
      {
        title: translate('request_flow.home_situation.fields.type.options.markt.title'),
        desc: translate('request_flow.home_situation.fields.type.options.you_bought_or_sell'),
        value: 'markt',
        prefix: <ImageCdn src={'partners/markt_de.svg'} height={30} width={30} alt={'markt'} />,
      },
      {
        title: translate('request_flow.home_situation.fields.type.options.quoka.title'),
        desc: translate('request_flow.home_situation.fields.type.options.you_bought_or_sell'),
        value: 'quoka',
        prefix: <ImageCdn src={'partners/quoka.png'} height={30} width={30} alt={'Quoka'} />,
      },
      {
        title: translate('request_flow.home_situation.fields.type.options.etsy.title'),
        desc: translate('request_flow.home_situation.fields.type.options.you_bought_or_sell'),
        value: 'etsy',
        prefix: <ImageCdn src={'partners/etsy.png'} height={30} width={30} alt={'Etsy'} />,
      },
      {
        title: translate('request_flow.home_situation.fields.type.options.shpock.title'),
        desc: translate('request_flow.home_situation.fields.type.options.you_bought_or_sell'),
        value: 'shpock',
        prefix: <ImageCdn src={'partners/shpock.jpeg'} height={30} width={30} alt={'Shpock'} />,
      },
      {
        title: translate('request_flow.home_situation.fields.type.options.kalaydo.title'),
        desc: translate('request_flow.home_situation.fields.type.options.you_bought_or_sell'),
        value: 'kalaydo',
        prefix: <ImageCdn src={'partners/kalaydo.png'} height={30} width={30} alt={'Kalaydo'} />,
      },
      {
        title: translate('request_flow.home_situation.fields.type.options.hood.title'),
        desc: translate('request_flow.home_situation.fields.type.options.you_bought_or_sell'),
        value: 'hood',
        prefix: <ImageCdn src={'partners/hood.png'} height={30} width={30} alt={'Hood'} />,
      },
    ],
  }[getActiveLanguageSettings().labels.short] || [
    {
      title: translate('request_flow.home_situation.fields.type.options.marktplaats.title'),
      desc: translate('request_flow.home_situation.fields.type.options.you_bought_or_sell'),
      value: 'marktplaats',
      prefix: <IconSituationMarktplaats />,
      after: <MarktPlaatsBanner />,
    },
    {
      title: translate('request_flow.home_situation.fields.type.options.facebook_marketplace.title'),
      desc: translate('request_flow.home_situation.fields.type.options.you_bought_or_sell'),
      value: 'facebook_marketplace',
      prefix: <IconSituationFacebookMarketplace />,
    },
    {
      title: translate('request_flow.home_situation.fields.type.options.2dehands.title'),
      desc: translate('request_flow.home_situation.fields.type.options.you_bought_or_sell'),
      value: '2dehands',
      prefix: <IconSituation2dehands />,
    },
  ];

  return (
    <SheetLayoutContentWrapper>
      <SheetLayoutHeader>
        <Heading size={2} extraClasses={'no-margin pb-0-5'}>
          <Translate id="request_flow.home_situation.title" />
        </Heading>
      </SheetLayoutHeader>
      <SheetLayoutContent>
        <InputTiles
          value={homeSituation}
          id={'situation'}
          name={'situation'}
          options={[acquaintancesOption, ...options, otherOption]}
          onChange={newHomeSituation => {
            dispatch(actions.setChoice({ home_situation: newHomeSituation as HomeSituationType }));
            proceed();
          }}
        />
        {error && (
          <div className={'general-errors-wrapper'}>
            <FlashMessage type={'error'} message={error} />
          </div>
        )}
      </SheetLayoutContent>
      <FooterLayout customSubmitFunction={proceed} />
    </SheetLayoutContentWrapper>
  );
};

/*
/* BRENGER DATE UTILS
/* ================================================== */

import { DateFormatType, formatDate as formatDateUtil } from '@brenger/utils';
import { isAfter, set } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { DEFAULT_TIME_ZONE } from './global';
import { getActiveLanguageSettings, translate } from './localization';

export const formatDateUTCToISO = (date: Date, defaultTime: number): string => {
  date.setHours(defaultTime);
  return zonedTimeToUtc(date, DEFAULT_TIME_ZONE).toISOString();
};

/** Format function that relies on the native browser function */
export const formatDate = (date: Date | string, dateFormat: DateFormatType): string => {
  const { labels, timeZone } = getActiveLanguageSettings();
  return formatDateUtil(date, dateFormat, { locale: labels.full, timeZone });
};

/** Formats only unique dates */
export const printDatesAsString = (dates: string[], conjunctionTrans = 'default.and'): string | undefined => {
  // Filtered, plus unique dates
  const dateStrings = Array.from(new Set(dates.filter(Boolean)));
  if (dateStrings.length === 0) {
    return '';
  }

  // Format unique dates
  const formatedDates = dateStrings.map(date => formatDate(new Date(date), 'day-month-short'));
  const lastDateString = formatedDates.pop();
  if (formatedDates.length === 0) {
    return lastDateString;
  }

  return `${formatedDates.join(',')} ${translate(conjunctionTrans)} ${lastDateString}`;
};

/**  Checks if the current time is after the business hours */
export const isAfterDeadlineToday = (hourDeadline: number): boolean => {
  const deadline = set(new Date(), { hours: hourDeadline, minutes: 0, seconds: 0 });
  return isAfter(new Date(), deadline);
};

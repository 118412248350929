import { Account, AddressCreateParams } from '@brenger/api-client';
import { getHouseNumberIncludingSuffixFromLine1, getIdFromIri } from '@brenger/utils';
import { isEqual } from 'lodash';
import * as React from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { Route } from 'react-router';
import { IconSmile, SheetLayoutContent, SheetLayoutContentWrapper } from '../../../../brenger-shared-ui';
import { useTypedSelector } from '../../../../hooks';
import { r } from '../../../../routes';
import { CacheKey, StopType } from '../../../../typings';
import { coreClient } from '../../../../utils/request';
import { getLoggedInUser } from '../../../User/ducks';
import { actions, getPickupSituation, showDutchAddresLayout } from '../../ducks';
import { ContactCustomer } from './ContactCustomer';
import { ContactStop } from './ContactStop';
import { LocalityChangeModal } from './LocalityChangeModal';

export const NewContact: React.FC = () => {
  const dispatch = useDispatch();
  const { isLoading } = useMassageContactDetails();

  React.useEffect(() => {
    dispatch(actions.setProgress(6));
  }, []);

  if (isLoading) {
    return (
      <SheetLayoutContentWrapper>
        <SheetLayoutContent>
          <IconSmile spinning={true} />
        </SheetLayoutContent>
      </SheetLayoutContentWrapper>
    );
  }
  return (
    <SheetLayoutContentWrapper>
      <Route exact={true} path={r.generalFlow.contact.index()} component={ContactCustomer} />
      <Route exact={true} path={r.generalFlow.contact.pickup()} render={() => <ContactStop type={StopType.PICKUP} />} />
      <Route
        exact={true}
        path={r.generalFlow.contact.delivery()}
        render={() => <ContactStop type={StopType.DELIVERY} />}
      />
      <LocalityChangeModal />
    </SheetLayoutContentWrapper>
  );
};

/**
 * Hook to prefill contact details for rest of the pages
 * Decided to keep it here because it is hook that is closesly tide to these pages and not very reusable.
 */
const useMassageContactDetails = (): { isLoading: boolean } => {
  const { userData } = useTypedSelector(getLoggedInUser);
  const addressId = getIdFromIri((userData?.account as Account).address);
  const situation = useTypedSelector(getPickupSituation);
  const pickup = useTypedSelector(state => state.generalTransport.contact.pickup);
  const delivery = useTypedSelector(state => state.generalTransport.contact.delivery);
  const pickupAccountSlug = useTypedSelector(state => state.generalTransport.destination.pickup_meta?.utm_account);
  const deliveryAccountSlug = useTypedSelector(state => state.generalTransport.destination.delivery_meta?.utm_account);
  const isDutchLayout = useTypedSelector(showDutchAddresLayout);
  const [isHistoricalDone, setIsHistoricalDone] = React.useState(false);
  const dispatch = useDispatch();

  const address = useQuery(
    [CacheKey.RETRIEVE_ADDRESS, addressId],
    () =>
      coreClient.addresses.retrieve({
        id: addressId as string,
      }),
    {
      enabled: !!addressId,
    }
  );

  const historicalAddresses = useQuery(
    [CacheKey.RETRIEVE_HISTORICAL_ADDRESS_LIST],
    () => coreClient.users.listStopAddressesForCurrentUser(),
    {
      enabled: !!userData,
    }
  );
  const pickupPublicAccount = useQuery(
    [CacheKey.RETRIEVE_PUBLIC_DATA, 'pickup', pickupAccountSlug],
    () =>
      coreClient.accounts.retrievePublicDataBySlug({
        slug: pickupAccountSlug as string,
      }),
    {
      enabled: isHistoricalDone && !!pickupAccountSlug && (!pickup?.contact_details || !pickup?.address),
    }
  );

  const deliveryPublicAccount = useQuery(
    [CacheKey.RETRIEVE_PUBLIC_DATA, 'delivery', deliveryAccountSlug],
    () =>
      coreClient.accounts.retrievePublicDataBySlug({
        slug: deliveryAccountSlug as string,
      }),
    {
      enabled: isHistoricalDone && !!deliveryAccountSlug && (!delivery?.contact_details || !delivery?.address),
    }
  );

  const [isDetailsLoading, setIsDetailsLoading] = React.useState(true);
  const auctionDetails = {
    first_name: 'veiling',
    last_name: 'veiling',
    email: 'noreply@brenger.nl',
    phone: '0000000000',
  };

  /**
   * PREFILL CUSTOMER DETAILS
   */
  React.useEffect(() => {
    if (!userData) return;
    dispatch(
      actions.setContactDetails({
        contact_type: 'customer',
        details: {
          first_name: userData?.first_name || '',
          last_name: userData?.last_name || '',
          email: userData?.email || '',
          phone: userData?.phone || '',
        },
      })
    );
    if (!addressId) setIsDetailsLoading(false);
  }, [userData]);

  /**
   * PREFILL COMPANY DETAILS
   */
  React.useEffect(() => {
    if (address.isLoading) return;
    const account = userData?.account as Account | undefined;
    if (
      !account?.name ||
      !account?.coc_number ||
      !account?.vat_number ||
      !address.data?.line1 ||
      !address.data?.postal_code ||
      !address.data?.locality ||
      !address.data?.country_code
    ) {
      setIsDetailsLoading(false);
      return;
    }
    if (account) {
      dispatch(
        actions.setCompanyDetails({
          company_name: account.name,
          coc_number: account.coc_number,
          vat_number: account.vat_number,
          address: {
            line1: address.data?.line1,
            postal_code: address.data?.postal_code,
            locality: address.data?.locality,
            country_code: address.data?.country_code,
          },
        })
      );
    }
    setIsDetailsLoading(false);
  }, [address.isLoading, address.data]);

  /**
   * SAVE HISTORICAL ADDRESSESS
   */
  React.useEffect(() => {
    if (!userData) {
      setIsHistoricalDone(true);
      return;
    }
    if (historicalAddresses.isLoading) return;
    dispatch(actions.setHistoricalAddresses(historicalAddresses.data?.['hydra:member'] || []));
    setIsHistoricalDone(true);
  }, [historicalAddresses.isLoading, historicalAddresses.data]);

  /**
   * PREFILL PICKUP CONTACT
   * 1) Dealing with auctions
   */
  React.useEffect(() => {
    if (situation === 'auction') {
      dispatch(
        actions.setContactDetails({
          contact_type: StopType.PICKUP,
          details: auctionDetails,
        })
      );
    }
    const detailsEqualToPlaceholder = isEqual(pickup?.contact_details, auctionDetails);
    if (situation !== 'auction' && detailsEqualToPlaceholder) {
      dispatch(
        actions.setContactDetails({
          contact_type: StopType.PICKUP,
          details: null,
        })
      );
    }
  }, [situation]);

  /**
   * PREFILL PICKUP CONTACT
   * 2) Dealing with account data
   */
  React.useEffect(() => {
    const { data } = pickupPublicAccount;
    if (!data) return;
    const { account, ...contactDetails } = data;
    if (!pickup?.contact_details && typeof contactDetails === 'object' && contactDetails.email) {
      dispatch(
        actions.setContactDetails({
          contact_type: StopType.PICKUP,
          details: contactDetails,
        })
      );
    }
    if (!pickup?.address && typeof account === 'object' && account?.address) {
      dispatch(
        actions.setStopAddress({
          stop_type: StopType.PICKUP,
          address: parseLine1Line2(account.address, isDutchLayout),
          address_is_prefilled: true,
        })
      );
    }
  }, [pickupPublicAccount.data]);

  /**
   * PREFILL DELIVERY CONTACT
   */
  React.useEffect(() => {
    const { data } = deliveryPublicAccount;
    if (!data) return;
    const { account, ...contactDetails } = data;
    if (!delivery?.contact_details && typeof contactDetails === 'object' && contactDetails.email) {
      dispatch(
        actions.setContactDetails({
          contact_type: StopType.DELIVERY,
          details: contactDetails,
        })
      );
    }
    if (!delivery?.address && typeof account === 'object' && account?.address) {
      dispatch(
        actions.setStopAddress({
          stop_type: StopType.DELIVERY,
          address: parseLine1Line2(account.address, isDutchLayout),
          address_is_prefilled: true,
        })
      );
    }
  }, [deliveryPublicAccount.data]);

  return {
    isLoading: isDetailsLoading,
  };
};

const parseLine1Line2 = (a: AddressCreateParams, isDutchLayout: boolean): AddressCreateParams => {
  if (!isDutchLayout) {
    // no parsing needed, because there is only line 1
    return a;
  }
  // dutch addresses need parsing because the fields are separate in the UI, but saved together when sent to core
  const { line1, ...rest } = a;
  const line2 = getHouseNumberIncludingSuffixFromLine1(line1);
  const newLine1 = line1.replace(line2, '').trim();
  return {
    ...rest,
    line1: newLine1,
    line2, // line2 gets overwritten this way
  };
};

import * as React from 'react';
import '../input/input.scss';
import './input-file-uploads.scss';
import { Field, Validator } from 'redux-form';
import InputFile from './InputFile';
import { InputHidden } from '../inputhidden';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
  actions as baseActions,
  getIsUploadingByFormAndFieldName,
  getUploadedFileDataByFormAndFieldName,
  getUploadFeedbackByFormAndFieldName,
} from '../../../../state/ducks/baseReducer';
import CheckMarkList from '../../layout/checkmarklist/CheckMarkList';
import { IconCamera, IconTrash } from '../../basics/icons';
import { GenericExtraInputFileFields, GenericInputFileMethods } from '../../../../typings/enums';
import { FormNames, RootState } from 'src/typings';
import { ASSETS_PATH } from '../../../../utils/global';
import { Dispatch } from 'redux';

interface OwnProps {
  fieldName: string;
  formName: FormNames;
  texts?: {
    // when texts are not set, we render a camera icon instead
    button: {
      default: string;
      add: string;
      delete: string;
      change: string;
    };
    header?: string;
  };
  uploadTwice?: boolean;
  multiple?: boolean;
  accept?: string;
  validate?: Validator | Validator[];
  headerBold?: boolean;
  headerShowAfterUpload?: boolean;
  renderPreview?: boolean; // Flag to render uploaded images as preview instead of list
}

type InputFileUploadsProps = OwnProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class InputFileUploads extends React.Component<InputFileUploadsProps> {
  public getButtonText(): string | React.ReactElement {
    const texts = this.props.texts;
    if (!texts) {
      return <IconCamera />;
    }
    const isMultiple = this.props.multiple;
    const uploadedFilesLength = this.props.uploadedFileNames.length;
    if (uploadedFilesLength > 0 && isMultiple) {
      return texts.button.add;
    }
    return texts.button.default;
  }

  public render(): JSX.Element {
    const fileNames = this.props.uploadedFileNames;
    const uploadedFileSources = this.props.uploadedFileSources;
    const showUploadButton: boolean = this.props.multiple || (fileNames.length === 0 && !this.props.multiple);
    const headerVisible = this.props.headerShowAfterUpload ? fileNames.length !== 0 : true;
    return (
      <div
        className={classNames([
          'input-file-uploads--wrapper',
          {
            'input-file-uploads--with-preview': !this.props.texts,
          },
        ])}
      >
        {this.props.texts?.header && headerVisible && (
          <div
            className={classNames([
              {
                'text--bold': this.props.headerBold,
              },
            ])}
          >
            {this.props.texts.header}
          </div>
        )}
        {fileNames.length > 0 && !this.props.renderPreview && (
          <>
            <CheckMarkList
              list={fileNames.map((fileName, index) => {
                return (
                  <div className="uploaded-file" key={index}>
                    {`${fileName} `}
                    <div
                      className="uploaded-file--remove"
                      onClick={() => this.props.removeFile(this.props.formName, this.props.fieldName, index)}
                    >
                      <IconTrash /> {this.props.texts?.button.delete}
                    </div>
                  </div>
                );
              })}
            />
          </>
        )}
        <Field
          validate={this.props.validate}
          name={this.props.fieldName}
          multiple={this.props.multiple}
          accept={this.props.accept}
          component={InputFile}
          isUploading={this.props.isUploading}
          label={this.getButtonText()}
          showButton={showUploadButton}
        />
        <Field
          name={`${this.props.fieldName}_${GenericExtraInputFileFields.COLLECTION}`}
          component={InputHidden}
          defaultValue={[]}
        />
        {this.props.uploadTwice && (
          <Field
            name={`${this.props.fieldName}_${GenericExtraInputFileFields.COLLECTION_DUP}`}
            component={InputHidden}
            defaultValue={[]}
          />
        )}
        <Field
          name={`${this.props.fieldName}_${GenericExtraInputFileFields.UPLOADING}`}
          component={InputHidden}
          defaultValue={false}
        />
        <Field
          name={`${this.props.fieldName}_${GenericExtraInputFileFields.UPLOAD_FEEDBACK}`}
          component={InputHidden}
          defaultValue={''}
        />
        {this.props.renderPreview &&
          uploadedFileSources.map((source, index) => {
            return (
              <div key={index} className={'input-el--file-photo-display--image'}>
                <label
                  className={'input-el--file-photo-display--image-src'}
                  style={{
                    backgroundImage: `url('${ASSETS_PATH()}${source}?w=150&h=150)`,
                  }}
                />
                <div
                  className={'input-el--file-photo-display--image-delete'}
                  onClick={() => this.props.removeFile(this.props.formName, this.props.fieldName, index)}
                >
                  &times;
                </div>
                <div className={'input-el--file-photo-display--image-value'}>
                  <div className={'value-wrap'}>{fileNames[index]}</div>
                </div>
              </div>
            );
          })}
        {this.props.uploadFeedback !== '' && (
          <div className="text--orange mt-0-5 mb-1">
            <small>{this.props.uploadFeedback}</small>
          </div>
        )}
      </div>
    );
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  uploadedFileNames: getUploadedFileDataByFormAndFieldName(
    state,
    ownProps.formName,
    ownProps.fieldName,
    'original_name'
  ),
  uploadedFileSources: getUploadedFileDataByFormAndFieldName(state, ownProps.formName, ownProps.fieldName, '@id'),
  isUploading: getIsUploadingByFormAndFieldName(state, ownProps.formName, ownProps.fieldName),
  uploadFeedback: getUploadFeedbackByFormAndFieldName(state, ownProps.formName, ownProps.fieldName),
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: Dispatch) => ({
  removeFile: (formName, fieldName, index) =>
    dispatch(
      baseActions.removeUploadedFile({
        formName,
        fieldName,
        index,
        input_file_method: GenericInputFileMethods.DELETE,
      })
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(InputFileUploads);

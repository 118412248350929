import * as React from 'react';
import { WrappedFieldProps } from 'redux-form';
import InputFeedback from './InputFeedback';
import InputWrapper from './InputWrapper';
import cn from 'classnames';

// Typed Custom
export interface DefaultCustom {
  type?: string;
  label?: string;
  description?: string;
  suffix?: string;
  required?: boolean;
  disabled?: boolean;
  border?: boolean;
  prepend?: string;
  append?: string;
  blockEnterSubmit?: boolean;
  inputMode?: 'numeric';
  autocompleteOff?: boolean;
  className?: string; // rename naar react className
  inputClassName?: string; // inputClassName
  successMessage?: string | boolean;
  error?: string | boolean;
}

export type InputProps = WrappedFieldProps & DefaultCustom;

const Input = ({
  input,
  meta,
  border = false,
  disabled = false,
  label,
  description,
  suffix,
  type,
  successMessage,
  inputClassName,
  required,
  prepend,
  append,
  inputMode,
  blockEnterSubmit = false,
  autocompleteOff,
}: InputProps): JSX.Element => (
  <InputWrapper label={label} input={input} meta={meta} type={type} border={border}>
    <div className={'input-el--inner'}>
      {prepend && <span className={'input-el--prepend'}>{prepend}</span>}
      <div className={'input-el--tag'}>
        <input
          className={inputClassName}
          data-clarity-unmask="true"
          disabled={disabled}
          id={input.name}
          type={type}
          inputMode={inputMode}
          {...input}
          /**
           * HACK ALERT: This seems to be best practice to prevent auto fill per field, according to MDN
           * https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion#Preventing_autofilling_with_autocompletenew-password
           */
          autoComplete={autocompleteOff ? 'new-password' : undefined}
          /**
           * Also disable last pass when autocomplete should be off
           */
          data-lpignore={autocompleteOff ? 'true' : undefined}
          onKeyPress={
            !blockEnterSubmit
              ? undefined
              : e => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                  }
                }
          }
        />
        {label && (
          <label
            className={cn('input-el--label', {
              'input-el--label--required': required,
              'input-el--with-suffix': suffix,
            })}
            htmlFor={input.name}
          >
            <span className={'input-el--label-text'}>{label}</span>
            {suffix && <div className={'input-el--suffix'}>{suffix}</div>}
            {description && <div className={'input-el--description'}>{description}</div>}
          </label>
        )}
      </div>
      {append && <span className={'input-el--append'}>{append}</span>}
    </div>

    <InputFeedback input={input} meta={meta} error={meta.error} successMessage={successMessage} />
  </InputWrapper>
);

export default Input;

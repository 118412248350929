import { IconClose, IconPencil } from '@brenger/react';
import * as React from 'react';
import { Card, Heading, IconInfo } from '../../../../brenger-shared-ui';
import { useTypedSelector } from '../../../../hooks';
import { StopType } from '../../../../typings';
import { ContactFields } from './ContactFields';
import { Modal } from './Modal';
import '../../../../assets/contact/fieldsModal.scss';
import { Translate } from 'react-localize-redux-dep-updated';

export type ContactType = 'customer' | StopType;

interface Props {
  type: ContactType;
}

export const ContactFieldsWithCard: React.FC<Props> = ({ type }) => {
  const [editOpen, setIsEditOpen] = React.useState(false);
  const contact = useTypedSelector(state => state.generalTransport.contact[type])?.contact_details;
  const contactCustomer = useTypedSelector(state => state.generalTransport.contact.customer)?.contact_details;

  return (
    <>
      <Heading size={4}>
        <Translate id={'request_flow.contact.who_details'} />
      </Heading>
      <Card withBorder={false}>
        <div className={'flex flex--sb gap-c-1 flex--vs'}>
          <b>
            {contact?.first_name || contactCustomer?.first_name} {contact?.last_name || contactCustomer?.last_name}
          </b>
          <button
            onClick={() => setIsEditOpen(true)}
            className="btn-no-style flex flex--vc text--primary gap-c-0-25 trigger"
          >
            <div style={{ fontSize: '.8em', lineHeight: '0.8em' }}>
              <IconPencil />
            </div>
            <Translate id="form_edit_contact.edit" />
          </button>
        </div>
        <div>{contact?.email || contactCustomer?.email}</div>
        <div>{contact?.phone || contactCustomer?.phone}</div>
      </Card>
      <small className="flex flex--vc gap-c-0-25 text--primary">
        <IconInfo color="blue" />
        <Translate id={`request_flow.contact.optional_details_${type}`} />
      </small>
      <Modal isActive={editOpen} closeHandler={() => setIsEditOpen(false)}>
        <div className="contact-modal--header flex flex--vc">
          <div className="w-full text--center text--grey-darker text--bold">
            <Translate id="form_edit_contact.heading.modal" />
          </div>
          <IconClose className="text--primary trigger" onClick={() => setIsEditOpen(false)} />
        </div>
        <div>
          <Heading size={4}>
            <Translate id={`form_edit_contact.heading_${type}.modal`} />
          </Heading>
          <p>
            <Translate id={`request_flow.contact.explain_${type}_details`} />
          </p>
          <ContactFields
            withButton={true}
            withColumns={false}
            type={type}
            submitCallBack={() => setIsEditOpen(false)}
          />
        </div>
      </Modal>
    </>
  );
};

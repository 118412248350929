import { push } from 'connected-react-router';
import React from 'react';
import { useDispatch } from 'react-redux';
import { ThankYouPageType } from '../../../../hooks';
import { r } from '../../../../routes';
import { Loading } from './Loading';

interface Props {
  id?: string;
  type?: ThankYouPageType;
}

export const FailedRedirect: React.FC<Props> = ({ id, type }) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (!id || !type || type === 'business') {
      dispatch(push(r.payment.canceled()));
      return;
    }
    if (type === 'general' || type === 'opp') {
      dispatch(push(r.generalFlow.terms.index({ canceled: true })));
      return;
    }
    if (type === 'upsell' || type === 'payment_link') {
      window.location.assign(r.external.trackingPage({ id, canceled: true }));
      return;
    }
  }, []);

  return <Loading />;
};

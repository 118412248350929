import { getActiveLanguageSettings } from './localization';

export const floorAsString = (floorCount?: number | string): string => {
  if (!floorCount || floorCount === 0) {
    return '';
  }
  // For basements to be displayed as 2nd basement instead of -2nd basement
  if (typeof floorCount === 'number') {
    floorCount = Math.abs(floorCount);
  }
  const currentLanguage = getActiveLanguageSettings().labels;
  const pr = new Intl.PluralRules(currentLanguage.full, { type: 'ordinal' });
  const rule = pr.select(Number(floorCount));
  const suffix = currentLanguage.number_suffixes.get(rule);
  return `${floorCount}${suffix}`;
};

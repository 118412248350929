import React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { connect } from 'react-redux';
import {
  FlashMessage,
  Heading,
  SheetLayoutContent,
  SheetLayoutContentWrapper,
  SheetLayoutHeader,
} from '../../../brenger-shared-ui';
import { CarryingHelpChoices, RootState, StopType } from '../../../typings';
import '../../../assets/user/user.scss';
import {
  getAddressDetails,
  getClientReference,
  getContactDelivery,
  getInstructions,
  getIsReturnTransport,
  getSelectedDatetimePeriod,
  getValidItemSets,
} from '../ducks';
import FooterLayout from './FooterLayout';
import { BusinessFields, BusinessForms } from '../interface';
import { getUploadedFileDataByFormAndFieldName } from '../../../state/ducks/baseReducer';
import { formatDate } from '../../../utils/datetime';
import { Account, Address, StopDetails } from '@brenger/api-client';
import { getActiveLanguageSettings } from '../../../utils/localization';

type ReduxProps = ReturnType<typeof mapStateToProps>;

type Props = ReduxProps;

const TransportPreview: React.FC<Props> = ({
  businessAddress,
  customerAddress,
  customerContact,
  date,
  itemSets,
  clientReference,
  invoices,
  isReturn,
  instructions,
  disabledReason,
  rejectedReason,
}) => {
  const services: string[] = [];
  if (customerAddress.details.carrying_help === CarryingHelpChoices.EXTRA_DRIVER) {
    services.push(CarryingHelpChoices.EXTRA_DRIVER);
  }
  if (itemSets.some(itemSet => itemSet.services?.includes('assembly'))) {
    services.push('assembly');
  }
  const languageSettings = getActiveLanguageSettings();
  const transVars = {
    phone: <a href={'tel:' + languageSettings.contact.phone.business}>{languageSettings.contact.phone.business}</a>,
    email: <a href={'mailto:' + languageSettings.contact.email.business}>{languageSettings.contact.email.business}</a>,
    link: (
      <a
        target="_blank"
        href={`https://www.brenger.nl/${getActiveLanguageSettings().labels.contentSite}/business-contact`}
        rel="noreferrer"
      >
        <Translate id="request_flow.actions.contact" />
      </a>
    ),
  };

  return (
    <SheetLayoutContentWrapper>
      <SheetLayoutHeader>
        <Heading size={2}>
          <Translate id={'request_flow.business.' + (isReturn ? 'return_label' : 'order_header_normal')} />
        </Heading>
      </SheetLayoutHeader>
      <SheetLayoutContent className={'business-preview'}>
        {disabledReason && (
          <div className="pb-1">
            <FlashMessage type={'error'}>
              <Translate id={disabledReason} data={transVars} />
            </FlashMessage>
          </div>
        )}
        {rejectedReason && (
          <div className="pb-1">
            <FlashMessage type={'error'}>
              <Translate id={'request_flow.business_tr_rejected.explain.' + rejectedReason} data={transVars} />
            </FlashMessage>
          </div>
        )}
        <div>
          <Heading size={5}>
            <Translate id="request_flow.business.headings.preview_customer" />
          </Heading>
        </div>
        <div>
          <b>
            <Translate id={'form.fields.user.name.label'} />:{' '}
          </b>
          {customerContact.first_name} {customerContact.last_name}
        </div>
        <div>
          <b>
            <Translate id={`request_flow.contact.address`} />:{' '}
          </b>
          {customerAddress?.address?.line1 || ''}, {customerAddress?.address?.postal_code || ''},{' '}
          {customerAddress?.address?.locality || ''}
        </div>
        <div>
          <b>
            <Translate id={'communication.phone_short'} />:{' '}
          </b>
          {customerContact.phone}
        </div>
        <div>
          <b>
            <Translate id={'communication.email'} />:{' '}
          </b>
          {customerContact.email}
        </div>
        {instructions && (
          <div>
            <b>
              <Translate id={'request_flow.summary.instructions'} />:{' '}
            </b>
            {instructions}
          </div>
        )}
        <div className={'pt-1'}>
          {date?.length && (
            <div>
              <b>
                <Translate id={'datetime.date'} />:{' '}
              </b>
              {formatDate(date[0].start, 'digit-date')} ({formatDate(date[0].start, 'weekday')})
            </div>
          )}
          <div className={'flex'}>
            <b>
              <Translate id={'request_flow.business.step_2.title_small'} />: &nbsp;
            </b>
            <div>
              {itemSets.map((itemSet, itemSetIndex) => {
                return itemSet.items.map((item, index) => {
                  return (
                    <div key={itemSetIndex + index}>
                      &nbsp;
                      {item.title} - {item.width}x{item.height}x{item.length} ({item.count}x)
                    </div>
                  );
                });
              })}
            </div>
          </div>
          {clientReference && (
            <div>
              <b>
                <Translate id={'request_flow.business.client_reference'} />:{' '}
              </b>
              {clientReference}
            </div>
          )}
          {invoices.length > 0 && (
            <div>
              <b>
                <Translate id={'request_flow.business.invoice_header'} />{' '}
              </b>
              {invoices.join(',')}
            </div>
          )}
          {services.length !== 0 && (
            <div className={'flex'}>
              <b>
                <Translate id={'request_flow.business.headings.extra_services'} />: &nbsp;
              </b>
              <div>
                {services.map(service => {
                  return (
                    <div key={service}>
                      <Translate id={`request_flow.business.fields.${service}.label`} />
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
        <div className={'pt-1'}>
          <Heading size={5}>
            <Translate id="request_flow.business.headings.preview_company" />
          </Heading>
        </div>
        <div className={'pb-1'}>
          {businessAddress.address.line1}, {businessAddress.address.postal_code}, {businessAddress.address.locality}
        </div>
      </SheetLayoutContent>
      <FooterLayout />
    </SheetLayoutContentWrapper>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState) => ({
  businessAddress: getAddressDetails(state, StopType.PICKUP) as { address: Address; details: StopDetails },
  customerAddress: getAddressDetails(state, StopType.DELIVERY) as { address: Address; details: StopDetails },
  customerContact: getContactDelivery(state),
  isReturn: getIsReturnTransport(state),
  itemSets: getValidItemSets(state),
  date: getSelectedDatetimePeriod(state, StopType.PICKUP),
  clientReference: getClientReference(state),
  invoices: getUploadedFileDataByFormAndFieldName(
    state,
    BusinessForms.DESTINATION,
    BusinessFields.INVOICE,
    'original_name'
  ),
  instructions: getInstructions(state, StopType.DELIVERY),
  disabledReason: (state.user?.userData?.account as Account | undefined)?.disable_submitting_trs_reason,
  rejectedReason: state.business.transport_request.price.rejected_reason,
});

export default connect(mapStateToProps)(TransportPreview);

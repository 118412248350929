declare global {
  interface Window {
    clarity?(action: 'set', key: string, value: string): void;
  }
}
/**
 * See Google Tag Manager for:
 * - we load a config file
 * - we set a user id there
 *
 * From the widget we pass along the clarity id, to tie up sessions
 */

export const clarity = {
  setTag: (key: string, value?: string) => {
    if (window.clarity && typeof window.clarity === 'function') {
      window.clarity('set', key, value || 'true');
    }
  },
};

import classNames from 'classnames';
import React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';

import { CirclePerson2, Toast } from '../../../../brenger-shared-ui';
import { useTypedSelector } from '../../../../hooks';
import { useNotification } from '../../../../hooks/useNotification';
import { getPriceStructure } from '../../ducks';

export const ExtraCareGlassNotification: React.FC = () => {
  const notification = useNotification('extra-care--glass-insurance');
  const progressStep = useTypedSelector(state => state.generalTransport.layout.step);
  const structure = useTypedSelector(getPriceStructure);
  const hasExtraCareGlass = structure.global?.some(globalItem => {
    return globalItem.charge === 'product_selection.fragile';
  });

  React.useEffect(() => {
    if (progressStep === 3 && hasExtraCareGlass) {
      notification.add();
    } else {
      notification.remove();
    }
  }, [progressStep, hasExtraCareGlass]);

  return (
    <div className={classNames('info-message-container', { 'info-message--visible': notification.isVisible })}>
      <Toast prefix={<CirclePerson2 />} handleClose={notification.removeForOneSession}>
        <div style={{ paddingRight: '15px' }}>
          <b>
            <Translate id="request_flow.extra_care.glass_insurance.notification.title" />
          </b>
        </div>
        <div>
          <Translate id="request_flow.extra_care.glass_insurance.notification.text" />
        </div>
      </Toast>
    </div>
  );
};

import React from 'react';
import { InputMeta, InputSelect, ModifiedInput } from '.';
import cn from 'classnames';
import './inputPhone.scss';
import { SupportedLanguagesShort } from '../../../utils/localization';
import { InputWrapper } from './InputWrapper';

interface InputPhoneProps extends ModifiedInput {
  defaultCountryCode: SupportedLanguagesShort;
  isLoading?: boolean;
  onChange: (value: string) => void;
  meta?: InputMeta;
}

export const InputPhone: React.FC<InputPhoneProps> = ({
  defaultCountryCode,
  onChange,
  className,
  value,
  meta,
  ...unused
}) => {
  const { phoneNumber, landCode } = parsePhoneNumber(value as string, defaultCountryCode);
  const save = (pn: string, newC: string): void => {
    const normNumber = normalizePhoneNumber(pn, newC, landCode);
    onChange(normNumber);
  };

  return (
    <InputWrapper {...meta}>
      <div className={cn('input-phone flex', className)}>
        <InputSelect
          addEmptyOption={false}
          value={landCode}
          onChange={newLandCode => save(phoneNumber, newLandCode)}
          options={countries.map(c => {
            return {
              label: `${c.flag} ${c.landCode}`,
              value: c.landCode,
            };
          })}
        />
        <input
          type="text"
          className="input-default"
          value={phoneNumber}
          onChange={(event: React.FormEvent<HTMLInputElement>): void => {
            save(event.currentTarget.value, landCode);
          }}
          {...unused}
        />
      </div>
    </InputWrapper>
  );
};

const parsePhoneNumber = (value: string, defaultCountryCode: string): { landCode: string; phoneNumber: string } => {
  let landCode = countries.find(c => c.countryCodeShort === defaultCountryCode)?.landCode || '+31';
  let phoneNumber = '';
  countries.forEach(c => {
    if ((value || '').includes(c.landCode)) {
      landCode = c.landCode;
      phoneNumber = value.replace(c.landCode, '').trim();
    }
  });
  return { landCode, phoneNumber: `${landCode} ${phoneNumber}` };
};

const normalizePhoneNumber = (phoneNumber: string, landCode: string, oldLandCode): string => {
  // no action needed
  if (!phoneNumber) {
    return landCode;
  }
  // Has correct format
  if (phoneNumber.includes(`${landCode} `)) {
    return formatPhoneNumber(landCode, phoneNumber.replace(landCode, '').trim());
  }
  // the user switched landcode by select
  if (landCode !== oldLandCode && phoneNumber.includes(oldLandCode)) {
    const number = phoneNumber.replace(oldLandCode, '');
    return formatPhoneNumber(number, landCode);
  }
  // the user pasted/auto-fill a phone number, so let's try to match it to a supported country
  if (landCode === oldLandCode && !phoneNumber.includes(landCode)) {
    let matchedLandCode = null as null | string;
    let number = null as null | string;
    countries.forEach(c => {
      if (phoneNumber.includes(c.landCode)) {
        matchedLandCode = c.landCode;
        number = phoneNumber.replace(matchedLandCode, '').trim();
      }
      // no match? It could be landCode with leading zeroes
      if (!number && phoneNumber.includes(c.landCode.replace('+', '00'))) {
        matchedLandCode = c.landCode;
        number = phoneNumber.replace(c.landCode.replace('+', '00'), '').trim();
      }
    });
    if (matchedLandCode && number) {
      return formatPhoneNumber(matchedLandCode, number);
    }
  }
  // We don't know, so hopefully the user knows
  return phoneNumber;
};

const formatPhoneNumber = (landCode: string, phoneNumber: string): string => {
  const number = phoneNumber.trim().replace(/[^[0-9]\s()+-]/, '');
  const code = landCode.trim();
  if (code === '+31') {
    // dutch numbers shouldn't have a leading zero, when the landcode is included
    return `${code} ${number.replace(/^0+/, '')}`;
  }
  return `${code} ${number}`;
};

const countries = [
  {
    countryCodeShort: 'nl',
    flag: '🇳🇱',
    landCode: '+31',
  },
  {
    countryCodeShort: 'be',
    flag: '🇧🇪',
    landCode: '+32',
  },
  {
    countryCodeShort: 'de',
    flag: '🇩🇪',
    landCode: '+49',
  },
  {
    countryCodeShort: 'at',
    flag: '🇦🇹',
    landCode: '+43',
  },
  {
    countryCodeShort: 'ch',
    flag: '🇨🇭',
    landCode: '+41',
  },
  {
    countryCodeShort: 'dk',
    flag: '🇩🇰',
    landCode: '+45',
  },
  {
    countryCodeShort: 'es',
    flag: '🇪🇸',
    landCode: '+34',
  },
  {
    countryCodeShort: 'fr',
    flag: '🇫🇷',
    landCode: '+33',
  },
  {
    countryCodeShort: 'gb',
    flag: '🇬🇧',
    landCode: '+44',
  },
  {
    countryCodeShort: 'se',
    flag: '🇸🇪',
    landCode: '+46',
  },
  {
    countryCodeShort: 'ie',
    flag: '🇮🇪',
    landCode: '+353',
  },
  {
    countryCodeShort: 'it',
    flag: '🇮🇹',
    landCode: '+39',
  },
];

import {
  Account,
  Address,
  AddressCreateParams,
  CarryingHelp,
  ContactCreateParams,
  Currency,
  CustomerCreateParams,
  DatetimePeriodOption,
  DateTimePeriodParams,
  GeoAutocompleteAddress,
  InstructionSuspicion,
  ItemCreationParams,
  ItemSetAttributes,
  ItemSetCreationParams,
  ProductPaymentCreationParams,
  Quote,
  QuoteCreationParams,
  QuoteNotGuaranteedReason,
  QuoteStop,
  QuoteTimeOption,
  SourceFlow,
  StopDetails,
  StopSituation,
  TimeOptionType,
  TransportOriginType,
  TransportRequestCreationParams,
  TransportRequestInternalAttributes,
  TransportRequestPricingAttributes,
} from '@brenger/api-client';
import { anonymizeCoord, getFloorLevelFromHouseNumberSuffix } from '@brenger/utils';
import { getLocation } from 'connected-react-router';
import { addDays, differenceInHours, isBefore, isSameDay, parse, set } from 'date-fns';
import _get from 'lodash/get';
import { matchPath } from 'react-router';
import { getFormValues } from 'redux-form';
import { Platform } from '../../generated/graphql';
import { r } from '../../routes';
import { getUploadedFileDataByFormAndFieldName } from '../../state/ducks/baseReducer';
import { CarryingHelpChoices, RootState, StopType } from '../../typings';
import { getAddressLabel } from '../../utils/address';
import { logException } from '../../utils/basics';
import { formatDate, printDatesAsString } from '../../utils/datetime';
import { getSessionIds, uuidv4 } from '../../utils/eventTracking';
import { floorAsString } from '../../utils/floors';
import {
  LONG_DISTANCE_IN_KM,
  LONG_DISTANCE_IN_KM_EXTRA_DATE,
  MAX_ITEM_COUNT_FOR_FLOOR_LEVEL_SERVICE,
  PRODUCT_PAYMENT_MAX_AMOUNT,
} from '../../utils/global';
import { getItemSetsVolumeAsString } from '../../utils/itemSet';
import { translate } from '../../utils/localization';
import { EQUIPMENT_CHOICES } from '../../utils/request';
import { TransformedLot } from '../../utils/tbAuctionsMethods';
import { getLoggedInUser } from '../User/ducks';
import { quoteReasonPathBlockedMap } from './components/notifications/NotGuaranteedNotification';
import { getDateFormValues } from './containers/Date';
import { getProductPaymentFormValues } from './containers/ProductPayment';
import { getTimeFormValues } from './containers/Time';
import {
  AbTest,
  Addition,
  AuctionTypes,
  FieldNamesDate,
  FloorData,
  GeneralFlowForms,
  GeneralTransport,
  GroupAddress,
  HomeSituations,
  ItemWithPSAndLot,
  PriceListTypes,
  SubStepFieldNamesDelivery,
  SubStepFieldNamesPickup,
  TitleStepName,
} from './interface';
import {
  AddItemPsAction,
  BasementOptionsWithFees,
  ContactCustomer,
  FloorOption,
  FloorOptionsWithFees,
  GeneralFlowAction,
  GeneralFlowActionTypes,
  GfChoicesKey,
  QuoteRejectedReason,
  RejectedByQuotesAction,
  RemoveItemPsAction,
  ResetChoiceAction,
  ResetFlowAction,
  ReviewedItemPsAction,
  SetChoiceAction,
  SetCompanyDetailsAction,
  SetContactDetailsAction,
  SetDestinationDetailsAction,
  SetDestinationMetaAction,
  SetInstructionSuspicionAction,
  SetInstructionSuspicionPayload,
  SetLocalityChangeAction,
  SetLocalityChangePayload,
  SetPrefillPsAction,
  SetQueryPsAction,
  SetQuoteAction,
  SetSearchResultPsAction,
  SetShowModalAction,
  SetStopAddressAction,
  SetTRValidationErrorAction,
  SetUtmDetails,
  SubmitForm,
  SubmitFormAction,
  SubmitValues,
  ToggleStepLoadingAction,
  TransportRequestValidationError,
  UpdateItemPsAction,
} from './typings';
import { correctItemSetProductGroups } from '../../utils/checkForStringifiedPsGroups';

export const defaultState: GeneralTransport = {
  request: {
    uuid: null,
  },
  destination: {
    pickup: null,
    pickup_meta: null,
    delivery: null,
    delivery_meta: null,
  },
  error: null,
  price: {
    amount: 0,
    isLoading: false,
    retryAttempt: 0,
    failedAttempts: false,
    rejected: null,
  },
  priceStructure: {
    base: 0,
    global: [],
    pickup: [],
    delivery: [],
  },
  priceList: null,
  notifications: {
    message: '',
    type: '',
    visible: false,
  },
  layout: {
    sheet: false,
    step: 1,
    modal_time: false,
    modal_items: false,
    modal_flex_dates: false,
    step_loading: false,
  },
  referrer: '',
  isSubmitting: false,
  quote: null,
  historicalAddresses: [],
  lastVisitTimes: [],
  localityChange: null,
  instructionSuspicion: {
    pickup: [],
    delivery: [],
  },
  vat_number: {
    loading: false,
    value: '',
  },
  tr_validation_error: null,
  product_selection: {
    query: null,
    items: [],
    searchResult: null,
    prefill: null,
  },
  contact: {
    customer: null,
    pickup: null,
    delivery: null,
  },
  choices: {
    carrying_help: 'not_needed',
  },
};

// actions
export const actions = {
  setStepLoading: (payload: boolean): ToggleStepLoadingAction => ({
    type: GeneralFlowActionTypes.SET_STEP_LOADING,
    payload,
  }),
  submitSellerForm: () => ({ type: GeneralFlowActionTypes.SUBMIT_MP_SELLER_FORM }),
  setHistoricalAddresses: payload => ({ type: GeneralFlowActionTypes.SET_HISTORICAL_ADDRESSES, payload }),
  setDestinationDetails: (payload: GeoAutocompleteAddress | null, stopType: StopType): SetDestinationDetailsAction => ({
    type: GeneralFlowActionTypes.SET_DESTINATION_DETAILS,
    payload,
    stopType,
  }),
  setDestinationMeta: (payload: SetDestinationMetaAction['payload'], stopType: StopType): SetDestinationMetaAction => ({
    type: GeneralFlowActionTypes.SET_DESTINATION_META,
    payload,
    stopType,
  }),
  resetDestinationDetails: () => ({ type: GeneralFlowActionTypes.RESET_DESTINATION_DETAILS }),
  popNotification: payload => ({ type: GeneralFlowActionTypes.POP_FOOTER_NOTIFICATION, payload }),
  hideNotification: () => ({ type: GeneralFlowActionTypes.HIDE_FOOTER_NOTIFICATION }),
  resetRetryAttempts: () => ({ type: GeneralFlowActionTypes.RESET_RETRY_ATTEMPTS }),
  createTransportRequest: details => ({
    type: GeneralFlowActionTypes.CREATE_TRANSPORT_REQUEST_START,
    payload: details,
  }),
  failedTransportRequest: err => ({
    type: GeneralFlowActionTypes.CREATE_TRANSPORT_REQUEST_FAILURE,
    payload: err,
  }),
  pushNotification: message => ({ type: GeneralFlowActionTypes.PUSH_NOTIFICATION, payload: message }),
  setQuote: (quote: Quote, removePrice = false): SetQuoteAction => ({
    type: GeneralFlowActionTypes.SET_QUOTE,
    payload: {
      quote,
      removePrice,
    },
  }),
  setProgress: step => ({ type: GeneralFlowActionTypes.SET_PROGRESS_STEP, payload: step }),
  setPrice: price => ({ type: GeneralFlowActionTypes.SET_PRICE, payload: price }),
  setPriceStructure: priceStructure => ({
    type: GeneralFlowActionTypes.SET_PRICE_STRUCTURE,
    payload: priceStructure,
  }),
  setPriceLoading: loading => ({ type: GeneralFlowActionTypes.SET_PRICE_LOADING, payload: loading }),
  resetPrice: () => ({ type: GeneralFlowActionTypes.RESET_PRICE }),
  toggleSheet: () => ({ type: GeneralFlowActionTypes.TOGGLE_SHEET }),
  submitPickup: formResults => ({ type: GeneralFlowActionTypes.SUBMIT_PICKUP, formResults }),
  submitDelivery: formResults => ({ type: GeneralFlowActionTypes.SUBMIT_DELIVERY, formResults }),
  submitWeight: formResults => ({ type: GeneralFlowActionTypes.SUBMIT_WEIGHT, formResults }),
  resetFlow: (resetDestination: boolean): ResetFlowAction => ({
    type: GeneralFlowActionTypes.RESET_FLOW,
    payload: { resetDestination },
  }),
  setReferrer: referrer => ({ type: GeneralFlowActionTypes.SET_REFERRER, payload: referrer }),
  toggleSubmitting: isSubmitting => ({
    type: GeneralFlowActionTypes.SET_SUBMITTING_STATUS,
    payload: isSubmitting,
  }),
  setPriceList: priceList => ({ type: GeneralFlowActionTypes.SET_PRICE_LIST, payload: priceList }),
  getPrice: () => ({ type: GeneralFlowActionTypes.GET_PRICE }),
  addLastVisitTime: () => ({ type: GeneralFlowActionTypes.ADD_LAST_VISIT_TIME }),
  postDraftQuote: (): GeneralFlowAction => ({ type: GeneralFlowActionTypes.POST_DRAFT_QUOTES }),
  submitForm: (form: SubmitForm, values?: SubmitValues): SubmitFormAction => ({
    type: GeneralFlowActionTypes.SUBMIT_FORM,
    meta: {
      form,
    },
    payload: values,
  }),
  setLocalityChange: (payload: SetLocalityChangePayload | null): SetLocalityChangeAction => ({
    type: GeneralFlowActionTypes.LOCALITY_CHANGE,
    payload,
  }),
  setInstructionSuspicion: (payload: SetInstructionSuspicionPayload): SetInstructionSuspicionAction => ({
    type: GeneralFlowActionTypes.SET_INSTRUCTION_SUSPICION,
    payload,
  }),
  setVatNumber: (payload: string) => ({
    type: GeneralFlowActionTypes.SET_VAT_NUMBER,
    payload,
  }),
  setVatNumberLoading: (payload: boolean) => ({
    type: GeneralFlowActionTypes.SET_VAT_NUMBER_LOADING,
    payload,
  }),
  setRejectedByPricing: (payload: QuoteRejectedReason | null): RejectedByQuotesAction => ({
    type: GeneralFlowActionTypes.SET_REJECTED_BY_QUOTES,
    payload,
  }),
  setShowTimeModal: (payload: boolean): SetShowModalAction => ({
    type: GeneralFlowActionTypes.SET_SHOW_TIME_MODAL,
    payload,
  }),
  setShowFlexDatesModal: (payload: boolean): SetShowModalAction => ({
    type: GeneralFlowActionTypes.SET_SHOW_FLEX_DATES_MODAL,
    payload,
  }),
  setTrValidationError: (payload: TransportRequestValidationError | null): SetTRValidationErrorAction => ({
    type: GeneralFlowActionTypes.SET_TR_VALIDATION_ERROR,
    payload,
  }),
  setQueryPs: (payload: string | null): SetQueryPsAction => ({
    type: GeneralFlowActionTypes.SET_QUERY_PS,
    payload,
  }),
  setSearchResultPs: (payload: SetSearchResultPsAction['payload']): SetSearchResultPsAction => ({
    type: GeneralFlowActionTypes.SET_SEARCH_RESULT_PS,
    payload,
  }),
  addItemPs: (payload: ItemWithPSAndLot): AddItemPsAction => ({
    type: GeneralFlowActionTypes.ADD_ITEM_PS,
    payload,
  }),
  removeItemPs: (payload: { uuid: string } | { all: boolean }): RemoveItemPsAction => ({
    type: GeneralFlowActionTypes.REMOVE_ITEM_PS,
    payload,
  }),
  updateItemPs: (payload: { uuid: string; item: ItemWithPSAndLot }): UpdateItemPsAction => ({
    type: GeneralFlowActionTypes.UPDATE_ITEM_PS,
    payload,
  }),
  reviewedItemPs: (payload: { uuid: string; reviewed: boolean }): ReviewedItemPsAction => ({
    type: GeneralFlowActionTypes.REVIEWED_ITEM_PS,
    payload,
  }),
  setPrefillPs: (payload: SetPrefillPsAction['payload']): SetPrefillPsAction => ({
    type: GeneralFlowActionTypes.SET_PREFILL_PS,
    payload,
  }),
  setContactDetails: (payload: SetContactDetailsAction['payload']): SetContactDetailsAction => ({
    type: GeneralFlowActionTypes.SET_CONTACT_DETAILS,
    payload,
  }),
  setCompanyDetails: (payload: SetCompanyDetailsAction['payload']): SetCompanyDetailsAction => ({
    type: GeneralFlowActionTypes.SET_COMPANY_DETAILS,
    payload,
  }),
  setStopAddress: (payload: SetStopAddressAction['payload']): SetStopAddressAction => ({
    type: GeneralFlowActionTypes.SET_STOP_ADDRESS,
    payload,
  }),
  setChoice: (payload: SetChoiceAction['payload']): SetChoiceAction => ({
    type: GeneralFlowActionTypes.SET_CHOICE,
    payload,
  }),
  resetChoice: (payload: ResetChoiceAction['payload']): ResetChoiceAction => ({
    type: GeneralFlowActionTypes.RESET_CHOICE,
    payload,
  }),
  setAb: (payload: AbTest[]) => ({
    type: GeneralFlowActionTypes.SET_AB,
    payload,
  }),
  resetAb: () => ({
    type: GeneralFlowActionTypes.RESET_AB,
  }),
  setUtm: (payload: SetUtmDetails['payload']): SetUtmDetails => ({
    type: GeneralFlowActionTypes.SET_UTM_DETAILS,
    payload,
  }),
};

// destination selectors
export const getPickupDestinationDetails = (state: RootState): GeoAutocompleteAddress | null =>
  getStopDestinationDetails(state, StopType.PICKUP);
export const getDeliveryDestinationDetails = (state: RootState): GeoAutocompleteAddress | null =>
  getStopDestinationDetails(state, StopType.DELIVERY);
export const getStopDestinationDetails = (state: RootState, type: StopType): null | GeoAutocompleteAddress => {
  return state.generalTransport.destination[type];
};

export const showDutchAddresLayout = (state: RootState): boolean => {
  const hasHistoricalOptions = hasHistoricalAddressOptionsForCurrentTr(state);
  // Because we chose to merge street and house number to line1, there is no proper way to unmerge.
  // Therefor don't show the dutch layout when the user has historical address options
  if (hasHistoricalOptions) {
    return false;
  }
  const pickup = getPickupDestinationDetails(state);
  const delivery = getDeliveryDestinationDetails(state);
  if (!pickup || !delivery) {
    return false;
  }
  return pickup.country_code === 'NL' && delivery.country_code === 'NL';
};

// selectors
export const getPriceCallRetryAttempts = (state: RootState): number => {
  return state.generalTransport.price.retryAttempt;
};

export const getIsDateSelectionNotAvailable = (state: RootState): boolean => {
  /**
   * This is the substitute for checking if the TR is international.
   * Except, calling it international didn't make sense anymore.
   * The area where we ask for date selection is broader than just national, and will evolve maybe further.
   *  */
  if (!state.generalTransport.quote) {
    return false;
  }
  return !state.generalTransport.quote.guaranteed.dates_available;
};

export const getSkipTimeOptions = (state: RootState, stopType: StopType): boolean => {
  /**
   * This selector should actually listen to flow_config.{pickup | deliver}_time_options
   * But there were some challenges implenting it on the pricing side, so we do a frontend check.
   * We want to proceed to time selection, when:
   * - We are pickup AND auction/store
   * - Above max distance or TbAuction
   */
  if (stopType === StopType.PICKUP && getIsAuctionOrStore(state)) {
    if (isRequestAboveMaxDistance(state) || getTbAuctionCollectionDays(state)?.length) {
      return true;
    }
    return false;
  }
  /**
   * Default scenario: We check if there are any custom time slots to select from
   */
  return getStopTimeOptions(state, stopType, 'custom').length === 0;
};

export const getPriceAmount = (state: RootState): number => {
  return state.generalTransport.price.amount;
};

export const getPriceStructure = (state: RootState): Quote['price_structure'] => {
  // This selector is in use to get the price structure acros the whole GF
  // Important to tackle "too transparent" side effects at this point, to prevent it comes to the surface ever.
  const structure = state.generalTransport.priceStructure;

  // These charges should be swept under the rug
  const tooTransparentCharges = ['rounding', 'product_selection.elasticity_multiplier'];
  const chargesToBeAdded = structure?.global?.filter(addition => tooTransparentCharges.includes(addition.charge));

  // If nothing is too transparent, just return the structure as is
  if (!chargesToBeAdded.length) {
    return structure;
  }

  // Add the charges to the base price
  let base = structure?.base || 0;
  chargesToBeAdded.forEach(addition => {
    base = base + addition.amount;
  });

  // Return the new structure, but remove the charges from the list
  return {
    ...structure,
    base,
    global: structure?.global.filter(addition => !tooTransparentCharges.includes(addition.charge)),
  };
};

export const getAuctionType = (state: RootState): AuctionTypes | undefined => {
  const pickupSituation = getPickupSituation(state);
  if (pickupSituation !== 'auction') {
    return undefined;
  }
  const pickupFormValues = getFormValues(GeneralFlowForms.PICKUP)(state);
  return _get(pickupFormValues, SubStepFieldNamesPickup.AUCTION_TYPE);
};

/**
 * Returns correct platform type for the TB Auction API, based on auction choice
 */
export const getTbAuctionPlatform = (state: RootState): Platform | null => {
  const auctionType = getAuctionType(state);
  if (auctionType === AuctionTypes.VAVATO) {
    return Platform.Vavato;
  }
  if (auctionType === AuctionTypes.TROOSTWIJK) {
    return Platform.Twk;
  }
  return null;
};

export const getTbAuctionCollectionDays = (state: RootState): TransformedLot['collectionDays']['days'] | null => {
  return state.generalTransport.product_selection.items?.[0]?.lot_temp?.collectionDays?.days || null;
};

export const getBasePrice = (state: RootState): number => {
  const structure = getPriceStructure(state);
  const itemSets = getValidItemSets(state);
  const baseNumber = structure.base || 0;
  return itemSets.length > 0 && baseNumber ? baseNumber : 0;
};

export const isSpecialPriceActive = (state, type: string): boolean => {
  const isSpecialPrice: boolean | null = _get(state.generalTransport, 'price.special.' + type, null);
  if (isSpecialPrice === null) {
    throw new Error('Requested special price type does not exist');
  }
  return isSpecialPrice;
};

export const getFeeFloor = (state: RootState): number | null => {
  const hasHelp = transportRequestHasHelp(state);
  if (hasHelp) {
    return getExtraFeeByType(state, PriceListTypes.PER_FLOOR_WITH_EXTRA_HELP);
  }
  return getExtraFeeByType(state, PriceListTypes.PER_FLOOR_WITHOUT_EXTRA_HELP);
};

export const getFeeBasement = (state: RootState): number | null => {
  const hasHelp = transportRequestHasHelp(state);
  if (hasHelp) {
    return getExtraFeeByType(state, PriceListTypes.PER_BASEMENT_WITH_EXTRA_HELP);
  }
  return getExtraFeeByType(state, PriceListTypes.PER_BASEMENT_WITHOUT_EXTRA_HELP);
};

export const getExtraFeeByType = (state: RootState, type: PriceListTypes): number | null => {
  const priceList = state.generalTransport.priceList;
  if (priceList === null) {
    return null;
  }
  const fee = _get(priceList, type, null);
  if (fee === null) {
    logException(`"${type}" is a non existing fee in the price list.`);
  }
  return fee;
};

export const getCarryHelpExtraCharge = (state): number => {
  if (!getPriceStructure(state)?.global) {
    return 0;
  }
  let carryHelpCharges = getPriceStructure(state).global.filter(add => add.charge === 'carrying_help');
  carryHelpCharges = carryHelpCharges.filter(add => add.amount > 0);
  if (carryHelpCharges.length === 0) {
    return 0;
  }
  return carryHelpCharges[0].amount;
};

export const getGeneralTransportRequest = (state: RootState): GeneralTransport => {
  return state.generalTransport;
};

export const getProgressStep = (state: RootState): number => {
  return state.generalTransport.layout.step;
};

export const isSheetOpen = (state: RootState): boolean => {
  return state.generalTransport.layout.sheet;
};

export const getDistance = (state: RootState): number => {
  return state.generalTransport.quote?.distance || 0;
};

export const isRequestAboveMaxDistance = (state: RootState, extraDateDistance = false): boolean => {
  /* Does not apply to international transports */
  if (getIsDateSelectionNotAvailable(state)) {
    return false;
  }
  /*
    The difference between a long distance & extra date distance:
    - _LONG_DISTANCE_IN_KM < _LONG_DISTANCE_IN_KM_EXTRA_DATE
    - _LONG_DISTANCE_IN_KM: is the distance where we stop asking for specific timeslots (at this point)
    - _LONG_DISTANCE_IN_KM_EXTRA_DATE: is the distance where people are forced to select two dates.
  */
  const distance = extraDateDistance ? LONG_DISTANCE_IN_KM_EXTRA_DATE : LONG_DISTANCE_IN_KM;
  return getDistance(state) > distance;
};

export const getTbAuctionDateTime = (state): DateTimePeriodParams | null => {
  const selectedDay = getPickupAvailableDate(state);
  if (!selectedDay) {
    return null;
  }
  const selectedDayAsDate = parse(selectedDay[0], 'yyyy-MM-dd', new Date());
  const selectedCollectionDay = getTbAuctionCollectionDays(state)?.find(day => {
    return isSameDay(new Date(day.start), selectedDayAsDate);
  });
  if (!selectedCollectionDay) {
    return null;
  }
  return {
    start: selectedCollectionDay.start,
    end: selectedCollectionDay.end,
  };
};

export const getTbAuctionPickupDateAsString = (state): string => {
  const days = getTbAuctionCollectionDays(state);
  if (days === null) {
    return '';
  }
  const date = formatDate(days[0].start, 'digit-date');
  const from = formatDate(days[0].start, 'hour-minute');
  const to = formatDate(days[0].end, 'hour-minute');
  return `${date} ${String(translate('datetime.from'))} ${from} ${String(translate('datetime.until'))} ${to}`;
};

export const getIsAuctionOrStore = (state: RootState): boolean => {
  const situation = getPickupSituation(state);
  return situation === 'auction' || situation === 'store';
};

export const expiredHours = (state: RootState): number => {
  const cacheUntill = state.generalTransport.cacheUntil;
  if (!cacheUntill) {
    return -1;
  }
  return differenceInHours(new Date(), new Date(cacheUntill));
};

export const isGuaranteed = (state: RootState): boolean => {
  return _get(state.generalTransport.quote, 'guaranteed.result', false);
};

export const getQuote = (state: RootState): Quote | null => {
  return state.generalTransport.quote;
};

export const getQuoteItems = (state: RootState): ItemCreationParams[] => {
  return state.generalTransport.quote?.item_sets?.[0].items || [];
};

export const getBlockedTransportRequestReasons = (
  state: RootState
): (QuoteNotGuaranteedReason | QuoteRejectedReason)[] => {
  const reasons: (QuoteNotGuaranteedReason | QuoteRejectedReason)[] = [];

  // Does the active reason block TR submits?
  const activeNotGuaranteedReason = state.generalTransport.quote?.guaranteed.reason;
  if (activeNotGuaranteedReason && quoteReasonPathBlockedMap[activeNotGuaranteedReason]?.isBlocked) {
    reasons.push(activeNotGuaranteedReason);
  }

  // Is the quote rejecting it? resulting in a 400
  const rejectedByPricing = state.generalTransport.price.rejected;
  if (rejectedByPricing) {
    reasons.push(rejectedByPricing);
  }
  return reasons;
};

export const getNotGuaranteedReason = (state: RootState): QuoteNotGuaranteedReason | undefined => {
  return state.generalTransport.quote?.guaranteed?.reason;
};

export const getTransportPrice = (state: RootState): number => {
  return state.generalTransport.price.amount;
};

export const getItemSetTotalSizeAsString = (state: RootState): string => {
  return getItemSetsVolumeAsString(getValidItemSets(state));
};

export const getToPaymentButtonTranslation = (state: RootState): string => {
  const pathname = state.router.location.pathname;
  switch (pathname) {
    case r.generalFlow.terms.index():
      if (getIsDirectlyPayable(state)) {
        return 'request_flow.payment.button';
      } else {
        return 'request_flow.actions.create_first_request';
      }
    default:
      return 'request_flow.steps.next';
  }
};

export const getStopDateOptions = (state: RootState, stopType: StopType): DatetimePeriodOption[] => {
  return (
    (stopType === StopType.PICKUP
      ? state.generalTransport?.quote?.pickup_datetime_period_options.options
      : state.generalTransport?.quote?.delivery_datetime_period_options.options) || []
  );
};

export const getDifferentDeliveryDate = (state: RootState): boolean => {
  if (isRequestAboveMaxDistance(state, true)) {
    return false;
  }
  const dateFormValues = getDateFormValues(state);
  // Read form value, but assume false, since that is 95% of the cases.
  return dateFormValues?.different_delivery_date || false;
};

export const getFlowTitleByStepAndSituation = (state, stepName: TitleStepName): string => {
  const situation = getPickupSituation(state); // we don't ask for delivery situation, so it is always pickup situation
  // no suffix for 'home' or 'book_a_van' situation
  const noSuffixSituations: StopSituation[] = ['home', 'book_a_van'];
  const situationSuffix: string = noSuffixSituations.includes(situation as StopSituation)
    ? ''
    : '_' + (situation || '').toLowerCase();
  switch (stepName) {
    case 'itemSet':
      return 'request_flow.headers.what' + situationSuffix;
    case 'weight':
      return 'request_flow.headers.weight';
    case 'date_pickup':
      return 'request_flow.date.title_question_pickup' + situationSuffix;
    case 'date_delivery':
      return 'request_flow.date.title_question_delivery';
    case 'date_pickup_longDistance':
      return 'request_flow.date.title_question_pickup_long_distance' + situationSuffix;
    case 'date_delivery_longDistance':
      return 'request_flow.date.title_question_delivery_long_distance';
    case 'timePickup':
      return 'request_flow.time.title_question_pickup' + situationSuffix;
    case 'timeDelivery':
      return 'request_flow.time.title_question_delivery';
    case 'timeCustomPickup':
      return 'request_flow.time.second_option.label' + situationSuffix;
    case 'timeCustomDelivery':
      return 'request_flow.time.second_option.label';
    case 'contactPickup':
      return 'request_flow.headers.contact_pickup' + situationSuffix;
    case 'contactDelivery':
      return 'request_flow.headers.contact_delivery';
    default:
      // Worst case, fall back to 'Your transport' as title
      return 'request_flow.headers.transport_request_title';
  }
};
/**
 * Refactor after product selection test
 */
export const getRequestTitle = (state, withDefault = true): string => {
  const itemSets = getValidItemSets(state);
  return getTitleOfItemsAsString(itemSets, withDefault);
};

/**
 * Refactor after product selection test
 */
export const getTitleOfItemsAsString = (itemSets: ItemSetCreationParams[], withDefault = true): string => {
  const defaultTitle = withDefault ? String(translate('request_flow.headers.transport_request_title')) : '';
  // no items
  if (!itemSets) {
    return defaultTitle;
  }

  if (!(itemSets?.[0]?.items?.[0] as ItemCreationParams | undefined)?.title) {
    return defaultTitle;
  }

  let titles: string[] = [];
  itemSets.map(itemSet => (itemSet.items as ItemCreationParams[]).map(item => titles.push(item.title)));

  titles = titles.filter((a, b) => titles.indexOf(a) === b);
  if (titles.length === 0) {
    return defaultTitle;
  }
  return `${titles.join(', ')}`;
};

export const getAddressTextLabel = (state: RootState, stopType: StopType): string | null => {
  const widgetDetails = getStopDestinationDetails(state, stopType);
  if (widgetDetails === null) {
    return null;
  }
  const a = getContactAddress(stopType, state);
  if (a?.locality && a?.line1) {
    return `${a.line1}, ${a.locality}`;
  }
  return widgetDetails.locality;
};

export const getContactAddress = (stopType: StopType, state: RootState): AddressCreateParams | null => {
  const address = state.generalTransport.contact?.[stopType]?.address;
  if (!address) {
    return null;
  }
  const { line1, line2, ...rest } = address;
  return {
    ...rest,
    line1: showDutchAddresLayout(state) ? `${line1} ${line2}`.trim() : line1,
  };
};

export const getContactPerson = (
  stopType: StopType,
  state: RootState
): { details: ContactCreateParams; skipped: boolean } => {
  const contact = state.generalTransport.contact;
  const stopContact = contact[stopType]?.contact_details;
  return {
    details: stopContact || (contact.customer?.contact_details as ContactCreateParams),
    skipped: !stopContact,
  };
};

export const getTransportRequestPricingAttributes = (state: RootState): TransportRequestPricingAttributes => {
  const pickup = getPickupDestinationDetails(state);
  const delivery = getDeliveryDestinationDetails(state);
  return {
    pickup: {
      lat: pickup?.latitude || 0,
      lng: pickup?.longitude || 0,
    },
    delivery: {
      lat: delivery?.latitude || 0,
      lng: delivery?.longitude || 0,
    },
    last_visit_times: state.generalTransport.lastVisitTimes || [],
    dolphins: getDolphins(state),
    transport_origin: getTransportOrigin(state),
  };
};

export const getTransportRequestInternalAttributes = (
  state: RootState,
  args: { with_contact: boolean }
): TransportRequestInternalAttributes => {
  const attributes: TransportRequestInternalAttributes = {
    instruction_suspicion: {
      pickup: getStopInstructionSuspicion(state, StopType.PICKUP),
      delivery: getStopInstructionSuspicion(state, StopType.DELIVERY),
    },
    product_payment: {
      asked: getShowProductPayment(state),
      used: getProductPaymentOptIn(state),
      amount: getProductPaymentAmount(state),
    },
  };

  if (args.with_contact) {
    attributes.contact_pickup_skipped = getContactPerson(StopType.PICKUP, state).skipped;
    attributes.contact_delivery_skipped = getContactPerson(StopType.DELIVERY, state).skipped;
  }
  // Add route details
  attributes.route = {
    distance: getDistance(state),
  };
  const homeSituation = state.generalTransport.choices?.home_situation;
  if (homeSituation) {
    attributes.pickup_situation_detail = homeSituation;
  }
  const referrer = getReferrer(state);
  if (referrer) {
    attributes.referrer_url = referrer;
  }
  const items = state.generalTransport.product_selection.items;
  if (items[0]?.lot_temp?.auctionId) {
    attributes.auction = {
      auctionId: items[0].lot_temp?.auctionId,
      platform: getAuctionType(state) as string,
      lots: items.map(item => {
        return {
          id: item.lot_temp?.lotId as string,
          length: item.lot_temp?.length || null,
          width: item.lot_temp?.width || null,
          height: item.lot_temp?.height || null,
          weight: item.lot_temp?.weight || null,
        };
      }),
    };
  }

  // Pass on felxible dates
  if (getIsFlexDates(state)) {
    attributes.flexible_dates = getQuote(state)?.flexible_dates;
  }

  return attributes;
};

export const getCustomerDetails = (state: RootState): CustomerCreateParams => {
  const contact = { ...(state.generalTransport.contact?.customer?.contact_details || {}) };
  const company = state.generalTransport.contact?.customer?.company_details;

  if (!company) {
    return contact as CustomerCreateParams;
  }
  return {
    ...contact,
    company_details: company,
  } as CustomerCreateParams;
};

export const getPickupFloorData = (state): FloorData => getFloorData(state, StopType.PICKUP);
export const getDeliveryFloorData = (state): FloorData => getFloorData(state, StopType.DELIVERY);

export const getFloorData = (state, stopType: StopType): FloorData => {
  const formValues = getFormValues(stopType === StopType.PICKUP ? GeneralFlowForms.PICKUP : GeneralFlowForms.DELIVERY)(
    state
  );
  if (!formValues) {
    return {
      floor: undefined,
      isCustom: false,
      floorString: '',
      hasElevator: false,
    };
  }
  // in case of equipment (at pickup or delivery) then the floor level is set to 0
  if (!isFloorServiceAvailable(state)) {
    return {
      floor: 0,
      isCustom: false,
      floorString: '',
      hasElevator: false,
    };
  }
  const situationAndFloor = _get(
    formValues,
    stopType === StopType.PICKUP
      ? SubStepFieldNamesPickup.SITUATION_HOME_FLOORS_COMBINED
      : SubStepFieldNamesDelivery.SITUATION_HOME_FLOORS_COMBINED,
    HomeSituations.OUTSIDE
  );
  const situation = situationAndFloor.replace(/\[-?[0-9]{1,2}\]/g, '');

  switch (situation) {
    case String(HomeSituations.GROUND_FLOOR_AND_READY):
    case String(HomeSituations.GROUND_FLOOR_NOT_READY):
      return {
        floor: 0,
        isCustom: false,
        floorString: '',
        hasElevator: false,
      };
    case String(HomeSituations.FLOOR_WITHOUT_ELEVATOR):
    case String(HomeSituations.BASEMENT_WITHOUT_ELEVATOR): {
      const regExpMatch = situationAndFloor.match(/-?[0-9]{1,2}/g);
      const situationFloor = regExpMatch ? regExpMatch : [];
      const floor = Number(situationFloor[0]);
      return {
        floor,
        isCustom: false,
        floorString: floorAsString(floor),
        hasElevator: false,
      };
    }
    case String(HomeSituations.FLOOR_WITH_ELEVATOR): {
      return {
        floor: 5,
        isCustom: false,
        floorString: floorAsString(5),
        hasElevator: true,
      };
    }
  }
  return {
    isCustom: false,
    floorString: '',
    hasElevator: false,
  };
};

export const validateSameAddress = (state: RootState): boolean => {
  const pAddress = getContactAddress(StopType.PICKUP, state);
  const dAddress = getContactAddress(StopType.DELIVERY, state);
  if (!pAddress || !dAddress) {
    return false;
  }
  return (
    pAddress.line1?.toLowerCase() === dAddress.line1?.toLowerCase() &&
    pAddress.country_code?.toLowerCase() === dAddress.country_code?.toLowerCase() &&
    pAddress.locality?.toLowerCase() === dAddress.locality?.toLowerCase() &&
    pAddress.postal_code?.toLowerCase() === dAddress.postal_code?.toLowerCase() &&
    pAddress.municipality?.toLowerCase() === dAddress.municipality?.toLowerCase() &&
    pAddress.administrative_area?.toLowerCase() === dAddress.administrative_area?.toLowerCase()
  );
};

export const hasValidCoordinates = (state: RootState, stop_type: StopType): boolean => {
  const address = getContactAddress(stop_type, state);
  return !!(address?.lat && address?.lng);
};

export const validateTransportRequest = (state: RootState): TransportRequestValidationError | null => {
  // Address
  if (!hasValidCoordinates(state, StopType.PICKUP)) {
    return 'pickup_has_invalid_coordinates';
  }
  if (!hasValidCoordinates(state, StopType.DELIVERY)) {
    return 'delivery_has_invalid_coordinates';
  }
  const isAddressSame = validateSameAddress(state);
  if (isAddressSame) {
    return 'same_address_for_pickup_delivery';
  }

  // We don't do checks for dates or time when flex-dates is active
  if (getIsFlexDates(state)) {
    return null;
  }

  // Date selection
  const pickupDate = getStopAvailableDate(state, StopType.PICKUP);
  const deliveryDate = getStopAvailableDate(state, StopType.DELIVERY);
  const datesAvailable = Boolean(state.generalTransport?.quote?.guaranteed.dates_available);
  if ((pickupDate.length || deliveryDate.length) && !datesAvailable) {
    return 'selected_date_not_available';
  }
  if ((!pickupDate.length || !deliveryDate.length) && datesAvailable) {
    return 'should_select_date';
  }

  // we don't check time for:
  // - long distances (they will have default time)
  // - Auction or store have custom times
  if (isRequestAboveMaxDistance(state) || getIsAuctionOrStore(state)) {
    return null;
  }
  // Time selection
  const pickupTime = getStopSelectedTime(state, StopType.PICKUP);
  const deliveryTime = getStopSelectedTime(state, StopType.DELIVERY);
  const skipPickupTime = getSkipTimeOptions(state, StopType.PICKUP);
  const skipDeliveryTime = getSkipTimeOptions(state, StopType.DELIVERY);
  if ((pickupTime && skipPickupTime) || (deliveryTime && skipDeliveryTime)) {
    return 'selected_time_not_available';
  }
  if ((!pickupTime && !skipPickupTime) || (!deliveryTime && !skipDeliveryTime)) {
    return 'should_select_time';
  }

  // Nothing to see here, move along
  return null;
};

export const getTransportRequestCreationParams = (state: RootState): TransportRequestCreationParams => {
  const pickupDetails = getPickupDetails(state);
  const deliveryDetails = getDeliveryDetails(state);
  let pickupDTPs = getSelectedDateTime(state, StopType.PICKUP, true);
  let deliveryDTPs = getSelectedDateTime(state, StopType.DELIVERY, true);

  /**
   * To not be bothered with changes in price structure, we will only send the date range when creating a transport and not to pricing
   * That's why we overwrite pickupDTPs and deliveryDTPs here.
   */
  if (getIsFlexDates(state)) {
    const dateRange = getFlexDatesRange(state);
    if (dateRange.length) {
      pickupDTPs = dateRange;
      deliveryDTPs = dateRange;
    }
  }

  const itemSets = getValidItemSets(state);
  /* Gather attributes, and add them to the itemset */
  const extraItemSetAttributes: ItemSetAttributes = {};
  const externalInvoices = getUploadedFileDataByFormAndFieldName(
    state,
    GeneralFlowForms.PICKUP,
    SubStepFieldNamesPickup.INVOICE,
    '@id'
  );
  if (externalInvoices.length > 0 && itemSets[0]) {
    itemSets[0]['external_invoices'] = externalInvoices;
  }
  const auctionType = getAuctionType(state);
  if (auctionType) {
    extraItemSetAttributes.auction_type = auctionType;
  }
  if (Object.keys(extraItemSetAttributes).length) {
    itemSets.forEach(itemSet => (itemSet.attributes = { ...itemSet.attributes, ...extraItemSetAttributes }));
  }
  const productPaymentAmount = getProductPaymentAmount(state);
  if (productPaymentAmount && itemSets[0]) {
    const pickup = getContactPerson(StopType.PICKUP, state);
    itemSets[0].product_payments = [
      {
        amount: productPaymentAmount,
        seller_phone: pickup?.details.phone || '',
        seller_email: pickup?.details.email || '',
      } as ProductPaymentCreationParams,
    ];
  }

  return {
    source_flow: SourceFlow.GENERAL,
    item_sets: itemSets,
    price: {
      amount: getTransportPrice(state),
      currency: 'EUR',
    },
    customer: getCustomerDetails(state) as CustomerCreateParams,
    session_ids: getSessionIds(),
    frontend_url: window.location.href,
    internal_attributes: getTransportRequestInternalAttributes(state, { with_contact: true }),
    pricing_attributes: getTransportRequestPricingAttributes(state),
    pickups: [
      {
        address: getContactAddress(StopType.PICKUP, state) as AddressCreateParams,
        contact: getContactPerson(StopType.PICKUP, state).details,
        available_datetime_periods: pickupDTPs,
        details: pickupDetails,
      },
    ],
    deliveries: [
      {
        address: getContactAddress(StopType.DELIVERY, state) as AddressCreateParams,
        contact: getContactPerson(StopType.DELIVERY, state).details,
        available_datetime_periods: deliveryDTPs,
        details: deliveryDetails,
      },
    ],
    customer_user: getLoggedInUser(state)?.userData?.['@id'],
    utm: getUtmDetails(state),
  };
};

export const getUtmDetails = (state: RootState): null | Record<string, string> => {
  const utmTtl = state.generalTransport.utm?.ttl;
  // Only expose UTMs that are not passed their TTL
  if (utmTtl && isBefore(new Date(), new Date(utmTtl))) {
    return state.generalTransport.utm?.values || null;
  } else {
    return null;
  }
};

export const getIsDirectlyPayable = (state: RootState): boolean => {
  /**
   * OPP
   * Assumption, all OPP orders should be payed directly
   */
  if (getProductPaymentOptIn(state)) {
    return true;
  }
  const quote = getQuote(state);
  return Boolean(quote?.guaranteed.directly_payable);
};

export const getPickupHelp = (state: RootState): CarryingHelp => getStopHelp(state);
export const getDeliveryHelp = (state: RootState): CarryingHelp => getStopHelp(state);

export const getStopHelp = (state: RootState): CarryingHelp => {
  // The default fallback is just to not reset persist upon releasing this, could be removed after while.
  return state.generalTransport.choices?.carrying_help || 'not_needed';
};

export const transportRequestHasHelp = (state): boolean => {
  const noHelpOptions: CarryingHelp[] = ['not_needed', 'needed'];
  return !noHelpOptions.includes(getStopHelp(state));
};

export const getPickupSituation = (state: RootState, withDefault = true): StopSituation | null =>
  getStopSituation(state, StopType.PICKUP, withDefault);

export const getStopSituation = (state: RootState, stopType: StopType, withDefault = true): StopSituation | null => {
  // Assume home for delivery, since we don't ask for explicitly
  if (stopType === StopType.DELIVERY) {
    return 'home';
  }
  const stopSituation = state.generalTransport.choices?.situation;
  const defaultValue = withDefault ? 'home' : null;
  return stopSituation || defaultValue;
};

export const getFloorOptionFees = (state: RootState): FloorOptionsWithFees => {
  const feePerFloor = getFeeFloor(state) || 0;
  const hasHelp = transportRequestHasHelp(state);
  const floorsWithFees = {
    with_elevator: hasHelp ? 0 : feePerFloor,
  };
  for (let i = 1; i <= 10; i++) {
    // Without help the customer pays for every floor
    let fee = i * feePerFloor;
    if (hasHelp) {
      // With help, the first three floors are free
      fee = i < 4 ? 0 : (i - 3) * feePerFloor;
    }
    floorsWithFees[i] = fee;
  }
  return floorsWithFees as FloorOptionsWithFees;
};

export const getBasementOptionFees = (state: RootState): BasementOptionsWithFees => {
  const feePerBasement = getFeeBasement(state) || 0;
  const hasHelp = transportRequestHasHelp(state);
  const basementWithFees = {
    with_elevator: hasHelp ? 0 : feePerBasement,
  };

  let fee = feePerBasement;
  if (hasHelp) {
    fee = 0;
  }

  basementWithFees[-1] = fee;

  return basementWithFees as BasementOptionsWithFees;
};

export const isSubmitDisabled = (state: RootState): boolean => {
  const transport = state.generalTransport;
  const location = getLocation(state);
  // Hard block for customers
  if (location.pathname === '/transport_request/terms' || location.pathname.includes('date')) {
    return (
      getBlockedTransportRequestReasons(state).length > 0 ||
      Boolean((state.user?.userData?.account as Account | undefined)?.disable_submitting_trs)
    );
  }

  const isPriceFailed = transport.price.failedAttempts;
  const isPriceLoading = transport.price.isLoading;
  const isVatNumberLoading = transport.vat_number.loading;
  const isDisabled = isPriceFailed || isPriceLoading || transport.isSubmitting || isVatNumberLoading;

  return transport.layout.step === 1 ? false : isDisabled;
};

export const getStopDetails = (state: RootState, stopType: StopType): StopDetails => {
  const floorData = getFloorData(state, stopType);
  return {
    situation: getStopSituation(state, stopType) as StopSituation,
    floor_level: floorData.floor,
    elevator: floorData.hasElevator,
    carrying_help: getStopHelp(state),
    extras: [],
    instructions: state.generalTransport.contact[stopType]?.instructions,
  };
};

export const getPickupDetails = (state: RootState): StopDetails => getStopDetails(state, StopType.PICKUP);
export const getDeliveryDetails = (state: RootState): StopDetails => getStopDetails(state, StopType.DELIVERY);

export const getStopAvailableDate = (state: RootState, stopType: StopType): string[] => {
  /**
   * TB AUCTIONS - pickup
   */
  const tbAuctionDates = getTbAuctionCollectionDays(state);
  if (tbAuctionDates?.length === 1 && stopType === StopType.PICKUP) {
    return [formatDate(new Date(tbAuctionDates[0].start), 'api-date')];
  }

  // When it is not a different delivery date, then also take the selected pickup date
  const lookAtPickupDateTime = !getDifferentDeliveryDate(state) && tbAuctionDates?.length !== 1;
  const fieldName = lookAtPickupDateTime
    ? FieldNamesDate.PICKUP
    : stopType === StopType.PICKUP
      ? FieldNamesDate.PICKUP
      : FieldNamesDate.DELIVERY;

  const date = getDateFormValues(state)?.[fieldName] || null;

  if (getIsDateSelectionNotAvailable(state)) {
    return [];
  }

  if (date === null) {
    return [];
  }
  if (isRequestAboveMaxDistance(state, true)) {
    const situation = getPickupSituation(state);
    const isAuctionPickup = stopType === StopType.PICKUP && situation === 'auction';
    /**
     * The idea here is that the auction is is always on the first picked day, as will be instructed by text.
     * So an auction pickup will only have one day when above max distance.
     */
    if (!isAuctionPickup) {
      const addDayToDate = addDays(new Date(date), 1);
      return [date, formatDate(addDayToDate, 'api-date')];
    }
  }
  return [date];
};

export const getPickupAvailableDate = (state: RootState): string[] => getStopAvailableDate(state, StopType.PICKUP);
export const getDeliveryAvailableDate = (state: RootState): string[] => getStopAvailableDate(state, StopType.DELIVERY);

export const getStopTimeOptions = (
  state: RootState,
  stopType: StopType,
  timeOptionType: TimeOptionType
): QuoteTimeOption[] => {
  const dtps = getStopDateOptions(state, stopType);
  const date = getStopAvailableDate(state, stopType)[0] || null;

  if (date === null || dtps.length === 0) {
    return [];
  }
  const selectedDateOption = dtps.find(dateOptions => dateOptions.dates.includes(date));
  if (!selectedDateOption) {
    return [];
  }
  return selectedDateOption.time_options.filter(timeOption => timeOption.type === timeOptionType);
};

export const getStopSelectedTime = (state, stopType: StopType): DateTimePeriodParams | null => {
  if (stopType === StopType.PICKUP) {
    const pickupDatetimePeriod = getTbAuctionDateTime(state);
    if (pickupDatetimePeriod) {
      return pickupDatetimePeriod;
    }
  }

  const timeValues = getTimeFormValues(state);
  const selectedValue = timeValues?.[stopType];
  /**
   * If it is not same day delivery, then we just return the selected value
   */
  if (selectedValue?.start && selectedValue?.end) {
    // Check if we have a value selected
    return selectedValue as DateTimePeriodParams;
  }
  return null;
};

export const getHistoricalAddresses = (state: RootState): Address[] => {
  return state.generalTransport.historicalAddresses;
};

export const getHistoricalAddressesForStop = (state: RootState, stopType: StopType): Address[] => {
  const addresses = getHistoricalAddresses(state);
  const destination = getStopDestinationDetails(state, stopType);
  return addresses.filter(a => a.locality === destination?.locality);
};

// return historical addresses grouped by stop type (pickup/delivery)
export const getGroupHistoricalAddresses = (state: RootState): GroupAddress => {
  const addresses = getHistoricalAddresses(state);
  // only show historical addresses with same locality as the destination
  const filteredAddresses = addresses.filter(address => {
    return address.locality === getStopDestinationDetails(state, StopType.DELIVERY)?.locality;
  });

  return {
    [StopType.PICKUP]: {
      label: String(translate('request_flow.contact.pickup_address')),
      options: filteredAddresses.filter(address => address.stop_type === StopType.PICKUP),
    },
    [StopType.DELIVERY]: {
      label: String(translate('request_flow.contact.delivery_address')),
      options: filteredAddresses.filter(address => address.stop_type === StopType.DELIVERY),
    },
  };
};

export const hasHistoricalAddressOptionsForCurrentTr = (state: RootState): boolean => {
  const pickup = getHistoricalAddressesForStop(state, StopType.PICKUP);
  const delivery = getHistoricalAddressesForStop(state, StopType.DELIVERY);
  return !!(pickup.length || delivery.length);
};

export const getSelectedDateTime = (state, stopType: StopType, withDefaultTimes = false): DateTimePeriodParams[] => {
  /**
   * Double check if the selected date is still available
   */
  const dates = getStopAvailableDate(state, stopType);
  if (getIsDateSelectionNotAvailable(state) || dates.length === 0) {
    return [];
  }
  const tbAuctionDates = getTbAuctionDateTime(state);
  if (stopType === StopType.PICKUP && tbAuctionDates) {
    return [tbAuctionDates];
  }
  let defaultOption: DateTimePeriodParams | null = null;
  const noTimeSelectionAvailable = getSkipTimeOptions(state, stopType);
  /**
   * When we are dealing with a TR above max distance or we need to include default times
   * We select the first full timeslot option from pricing
   */
  if (noTimeSelectionAvailable || withDefaultTimes) {
    let firstOption = getStopTimeOptions(state, stopType, 'full')[0];
    /**
     * In the case first full option is not available
     * AND we are delivery
     * AND it is same day delivery
     * it is save to fallback to the first full time pickup timeslot
     */
    if (!firstOption && stopType === StopType.DELIVERY) {
      firstOption = getStopTimeOptions(state, StopType.PICKUP, 'full')[0];
    }
    if (firstOption) {
      defaultOption = { start: firstOption.start as string, end: firstOption.end as string };
    }
  }
  /**
   * Is there already a time available?
   * - When above max distance, always return the default option
   * - else try to return chosen timeslot
   * - if that fails as well return defaultoption (which could be undefined)
   */
  const time = noTimeSelectionAvailable ? defaultOption : getStopSelectedTime(state, stopType) || defaultOption;

  if (!time?.start && !time?.end) {
    return [];
  }
  /**
   * Map the selected times to the dates
   * Most of the time we are dealing with just one date, but with long distances we have two dates
   */
  const dateStartTime = new Date(time.start);
  const dateEndTime = new Date(time.end);
  return dates.map(date => {
    return {
      start: set(new Date(date), {
        hours: dateStartTime.getHours(),
        minutes: dateStartTime.getMinutes(),
      }).toISOString(),
      end: set(new Date(date), { hours: dateEndTime.getHours(), minutes: dateEndTime.getMinutes() }).toISOString(),
    };
  });
};

export const isSameDayDelivery = (state): boolean => {
  const pickupDateTime = getStopAvailableDate(state, StopType.PICKUP);
  const deliveryDateTime = getStopAvailableDate(state, StopType.DELIVERY);
  // Most common situation
  if (pickupDateTime.length === 1 && deliveryDateTime.length === 1) {
    return isSameDay(new Date(pickupDateTime[0]), new Date(deliveryDateTime[0]));
  }
  // When there are more than 1 DTPs it is up to driver.
  if (pickupDateTime.length > 1 || deliveryDateTime.length > 1) {
    return false;
  }
  // we default to true (in 95% of the cases it is)
  return true;
};

export const getTransportOrigin = (state: RootState): TransportOriginType | undefined => {
  const pickupSituation = getStopSituation(state, StopType.PICKUP, false);
  if (pickupSituation === 'auction') {
    return 'auction';
  }
  const isMarketplaceUser = getIsMarketplaceUtmSource(state);
  if (isMarketplaceUser) {
    return 'marketplace';
  }
  const homeSituation = state.generalTransport.choices?.home_situation;
  if (['2dehands', 'facebook_marketplace', 'marktplaats'].includes(homeSituation || '')) {
    return 'marketplace';
  }
  if (homeSituation === 'acquaintances') {
    return 'private';
  }
};

export const getPriceRequestParams = (state: RootState, args?: { isDraft: boolean }): QuoteCreationParams | null => {
  /**
   * BAIL EARLY CHECKS
   */
  const itemSets = getValidItemSets(state);
  if (!args?.isDraft && (!itemSets.length || !itemSets[0].items.length)) {
    return null;
  }
  const pickupAddress = getPickupDestinationDetails(state);
  const deliveryAddress = getDeliveryDestinationDetails(state);
  if (!args?.isDraft && (!pickupAddress || !deliveryAddress)) {
    return null;
  }

  /**
   * Setting up PICKUP
   */
  const pickupDetails = getPickupDetails(state);
  const pickup = {
    address: {
      administrative_area: pickupAddress?.administrative_area || '',
      country_code: pickupAddress?.country_code || 'NL',
      locality: pickupAddress?.locality || '',
      lat: pickupAddress?.latitude || 0,
      lng: pickupAddress?.longitude || 0,
    },
    details: pickupDetails,
  };
  /**
   * Send the available pickup DTP when:
   * - we have a valid pickup DTP
   * - And if we are not sending this call just to get more date options
   */
  const pickupDateTime = getSelectedDateTime(state, StopType.PICKUP, true);
  const pickupDTPisValid = Boolean(pickupDateTime[0]?.start);
  //TODO enable eslint
  // eslint-disable-next-line no-prototype-builtins
  if (pickupDTPisValid) {
    // Pricing API can only handle one date time period, so we pick the first
    pickup['available_datetime_period'] = pickupDateTime[0];
  }

  /**
   * Setting up DELIVERY
   */
  const deliveryDetails = getDeliveryDetails(state);
  const delivery: QuoteStop = {
    address: {
      administrative_area: deliveryAddress?.administrative_area || '',
      country_code: deliveryAddress?.country_code || 'NL',
      locality: deliveryAddress?.locality || '',
      lat: deliveryAddress?.latitude || 0,
      lng: deliveryAddress?.longitude || 0,
    },
    details: deliveryDetails,
  };

  /**
   * Send the available delivery DTP when:
   * - we have a valid PICKUP DTP
   * - we have a valid delivery DTP
   * - And if we are not sending this call just to get more date options
   */
  const deliveryDateTime = getSelectedDateTime(state, StopType.DELIVERY, true);
  const deliveryDTPisValid = Boolean(deliveryDateTime[0]?.start);
  if (pickupDTPisValid && deliveryDTPisValid) {
    // Pricing can only handle one date time period, so we pick the first
    delivery['available_datetime_period'] = deliveryDateTime[0];
  }

  /**
   * Pickup dates should start from:
   * - We could be fetching more dates, so the dateOption.pickup is set and we should use that
   * - or we have a selected date, so we should use that
   * - if both are undefined, it falls back to today.
   */
  const selectedPickupDate = getPickupAvailableDate(state);
  // when selected pickup exists, it shouldn't be formatted again.
  const pickupDatesStartFrom = selectedPickupDate[0] || formatDate(new Date(), 'api-date');

  /**
   * Delivery dates should start from:
   * - We need a pickup date first
   * - We could be fetching more dates, so the dateOption.delivery is set and we should use that
   * - or we have a selected date, so we should use that
   */
  const selectedDeliveryDate = getDeliveryAvailableDate(state);
  const deliveryDatesStartFrom = selectedDeliveryDate[0] || selectedPickupDate[0] || undefined;

  return {
    item_sets: itemSets,
    pickup,
    delivery,
    last_visit_times: state.generalTransport.lastVisitTimes || [],
    pickup_dates_start_from: pickupDatesStartFrom,
    delivery_dates_start_from: deliveryDatesStartFrom,
    session_ids: getSessionIds(),
    frontend_url: window.location.href,
    dolphins: getDolphins(state),
    transport_origin: getTransportOrigin(state),
    internal_attributes: getTransportRequestInternalAttributes(state, { with_contact: false }),
    utm: getUtmDetails(state),
  };
};

const getDolphins = (state: RootState): string[] => {
  return (state.generalTransport.ab || []).map(ab => `wp_${ab.id}-${ab.v}`);
};

// return true when when we need to wait for something in the flow
export const isLoading = (state: RootState): boolean => {
  return state.generalTransport.price.isLoading || state.generalTransport.isSubmitting;
};

export const getValidItemSets = (state: RootState): ItemSetCreationParams[] => {
  const items = state.generalTransport?.product_selection.items?.map(item => {
    return {
      ...item,
      title: item.ps_temp.fullTitle,
      ps_temp: undefined,
      lot_temp: undefined,
    };
  });
  // Prevent itemset with no items
  if (!items?.length) {
    return [];
  }
  // Transform the items to an itemSet, generate title and add book_a_van when needed
  return correctItemSetProductGroups([
    {
      title: items.map(item => item.title).join(', '),
      items,
      description: '',
    },
  ]);
};

export const getTooManyItemForFloorLevel = (state: RootState): boolean => {
  const itemSets = getValidItemSets(state);
  const totalItems = itemSets.flatMap(itemSet => itemSet.items.flatMap(item => item.count)).reduce((a, i) => a + i, 0);
  return totalItems > MAX_ITEM_COUNT_FOR_FLOOR_LEVEL_SERVICE;
};

export const isFloorServiceAvailable = (state: RootState): boolean => {
  /**
   * Ideal situation: Pricing indicates this
   */
  if (state.generalTransport.quote?.service_advisory?.floor_service === 'not_available') {
    return false;
  }
  /**
   * Unfortunately we had more checks in frontend, should be lifted to Pricings advisory
   */
  const help = getStopHelp(state);
  if (EQUIPMENT_CHOICES.includes(help)) {
    return false;
  }
  const tooManyItems = getTooManyItemForFloorLevel(state);
  return !tooManyItems;
};

export const isItemValid = (item: ItemCreationParams): boolean => {
  const width = _get(item, 'width');
  const height = _get(item, 'height');
  const len = _get(item, 'length');
  const count = _get(item, 'count');
  return (
    typeof width === 'number' &&
    !isNaN(width) &&
    typeof height === 'number' &&
    !isNaN(height) &&
    typeof len === 'number' &&
    !isNaN(len) &&
    typeof count === 'number' &&
    !isNaN(count)
  );
};

export const getReferrer = (state: RootState): string => {
  return state.generalTransport.referrer || 'https://www.brenger.nl';
};

export const getIsMarketplaceUtmSource = (state: RootState): boolean => {
  const utmSource = state.generalTransport.utm?.values?.utm_source;
  if (!utmSource) {
    return false;
  }
  return ['marktplaats', '2dehands'].some(mp => utmSource.includes(mp.toLowerCase()));
};

export const getShowProductPayment = (state: RootState): boolean => {
  const homeSituation = state.generalTransport.choices?.home_situation;
  const quote = getQuote(state);
  const isMarketplaceUser = getIsMarketplaceUtmSource(state);
  const pickupSituation = getPickupSituation(state);
  return Boolean(
    quote?.guaranteed.directly_payable &&
      pickupSituation !== 'auction' &&
      (isMarketplaceUser || ['marktplaats', 'facebook_marketplace', '2dehands'].includes(homeSituation || ''))
  );
};

export const getProductPaymentOptIn = (state: RootState): boolean => {
  return Boolean(getProductPaymentFormValues(state)?.opt_in);
};

export const getProductPaymentAmount = (state: RootState): null | number => {
  const formValues = getProductPaymentFormValues(state);
  if (getProductPaymentOptIn(state) === false || !formValues?.amount) {
    return null;
  }
  /* Because we live in comma orientated country, this is unfortunately nescessary */
  const amount = parseFloat(formValues.amount.replace(',', '.'));
  // if we somehow get over the max, return null to be save
  if (amount > PRODUCT_PAYMENT_MAX_AMOUNT) {
    return null;
  }
  // Convert to cents, we talk to the API in cents
  return Math.round(amount * 100);
};

export const getPickupAdditions = (state, includeHelp): Addition[] =>
  getStopAdditions(state, StopType.PICKUP, includeHelp);
export const getDeliveryAdditions = (state, includeHelp): Addition[] =>
  getStopAdditions(state, StopType.DELIVERY, includeHelp);
export const getStopAdditions = (state, stopType: StopType, includeHelp = false): Addition[] => {
  const additions: Addition[] = [];

  // Situation
  const stopSituation = getStopSituation(state, stopType, false);
  if (stopType === 'pickup' && stopSituation !== null && stopSituation !== 'auction') {
    additions.push({
      transKey: 'request_flow.summary.situation.' + stopSituation,
      price: 0,
    });
  }
  // Help
  if (includeHelp) {
    const stopHelp = getStopHelp(state);
    if (stopHelp !== 'not_needed') {
      const amount = stopHelp === 'needed' ? 0 : getCarryHelpExtraCharge(state);
      additions.push({
        transKey: 'request_flow.summary.carrying_help.' + stopHelp,
        price: amount,
      });
    }
  }

  // Other additions
  let priceAdditions = _get(getPriceStructure(state), stopType, null);
  if (priceAdditions !== null) {
    priceAdditions = priceAdditions.filter(
      item => (_get(item, 'kind', '') as CarryingHelpChoices) !== CarryingHelpChoices.NOT_NEEDED
    );
    priceAdditions.map(addition => {
      let transKey = `request_flow.summary.${addition.charge}`;
      if (addition.charge === 'floor' && addition.level === 0) {
        transKey = 'request_flow.summary.ground_level';
      }
      if (addition.charge === 'basement') {
        transKey = 'request_flow.situation.x_basement_v2';
      }
      if (Object.prototype.hasOwnProperty.call(addition, 'kind')) {
        transKey += '.' + addition.kind;
      }
      additions.push({
        beforeText: `${
          _get(addition, 'level', null) && !['elevator', 'basement'].includes(addition.charge)
            ? floorAsString(addition.level)
            : ''
        }`,
        transKey,
        price: addition.amount,
      });
    });
  }

  // Date
  const dates = getStopAvailableDate(state, stopType);
  const noTimeSelectionAvailable = getSkipTimeOptions(state, stopType);
  /**
   * Time selection:
   * - When it is aboveMaxDistance we should display default times, coming from selectedDateTime
   * - When it's not, we should specific at selected time, which tells us if the user interacted
   */
  const time = noTimeSelectionAvailable
    ? getSelectedDateTime(state, stopType)[0]
    : getStopSelectedTime(state, stopType);
  if (dates.length > 0) {
    additions.push({
      text: `${printDatesAsString(dates)} ${
        time?.start
          ? formatDate(time.start, 'hour-minute') + '-' + (time.end ? formatDate(time.end, 'hour-minute') : '')
          : ''
      }`,
      price: null,
    });
  }

  // If flex dates
  const isFlexDates = getIsFlexDates(state);
  const step = getProgressStep(state);
  if (isFlexDates && step > 3) {
    const firstAndLast = getFirstAndLastFlexDates(state);
    if (firstAndLast) {
      additions.push({
        text: translate('request_flow.flex_dates.display_range', {
          first_date: formatDate(firstAndLast.first, 'day-month-short'),
          last_date: formatDate(firstAndLast.last, 'day-month-short'),
        }),
        price: null,
      });
    }
  }
  return additions;
};

export const getGlobalAdditions = (state): Addition[] => {
  let priceAdditions = _get(getPriceStructure(state), 'global', null);
  if (priceAdditions === null) {
    return [];
  }

  // check if we need to print help
  const pickupHelp = getPickupHelp(state);
  const deliveryHelp = getDeliveryHelp(state);
  if (pickupHelp === CarryingHelpChoices.NOT_NEEDED && deliveryHelp === CarryingHelpChoices.NOT_NEEDED) {
    // remove help not_needed, since there is no answer of user yet
    priceAdditions = priceAdditions.filter(
      item => (_get(item, 'kind', '') as CarryingHelpChoices) !== CarryingHelpChoices.NOT_NEEDED
    );
  }

  // Other price additions
  priceAdditions = priceAdditions.filter(item => _get(item, 'kind', '') !== 'needed');
  // filter out 'needed' help situation
  priceAdditions = priceAdditions.filter(
    item => (_get(item, 'kind', '') as CarryingHelpChoices) !== CarryingHelpChoices.NOT_NEEDED
  );
  return priceAdditions.map(addition => {
    let transKey = 'request_flow.summary.' + addition.charge;
    if (Object.prototype.hasOwnProperty.call(addition, 'kind')) {
      transKey += '.' + addition.kind;
    }
    return {
      transKey,
      price: addition.amount,
    };
  });
};

// return the current pickup step in the flow based on route path
export const getPickupStep = (state: RootState): number => {
  const pathname = state.router.location.pathname;
  if (matchPath(pathname, { path: r.generalFlow.pickup.invoice(), exact: true, strict: false })) {
    return 7;
  } else if (matchPath(pathname, { path: r.generalFlow.pickup.floor(), exact: true, strict: false })) {
    return 5;
  } else {
    return 2;
  }
};

// return the current delivery step in the flow based on route path
export const getDeliveryStep = (state: RootState): number => {
  const pathname = state.router.location.pathname;
  if (matchPath(pathname, { path: r.generalFlow.delivery.floor(), exact: true, strict: false })) {
    return 5;
  } else {
    return 3;
  }
};

export const getInitialDatePath = (state: RootState): string => {
  const collectionDays = getTbAuctionCollectionDays(state);
  if (collectionDays?.length === 1) {
    return r.generalFlow.date.delivery();
  }
  return r.generalFlow.date.index();
};

export const getStopInstructionSuspicion = (state: RootState, stopType: StopType): InstructionSuspicion[] => {
  return state.generalTransport.instructionSuspicion?.[stopType] || [];
};

export const getFloorLevelSuspicion = (
  state: RootState,
  stopType: StopType,
  houseNumber?: string
): {
  floor: number;
  floorFee: number;
  elevatorFee: number;
} | null => {
  // We don't check when the user already indicated that it has an elevator or floor level
  const floorData = getFloorData(state, stopType);
  if (floorData.hasElevator || (floorData?.floor && floorData?.floor !== 0)) {
    return null;
  }
  // Get any indication from the housenumber and prefix
  const suffixIndicatesFloorLevel = getFloorLevelFromHouseNumberSuffix(houseNumber || '');
  // If we don't suspect anything, return null
  if (!suffixIndicatesFloorLevel) {
    return null;
  }
  // When we do suspect there is a floor level
  const floorFees = getFloorOptionFees(state);
  const floorFee = floorFees[String(suffixIndicatesFloorLevel) as FloorOption];
  return {
    floor: suffixIndicatesFloorLevel,
    floorFee,
    elevatorFee: floorFees.with_elevator,
  };
};

export const getIsFlexDates = (state: RootState): boolean => {
  return !!state.generalTransport.quote?.flexible_dates?.result;
};

export const getIsFlexDatesModalActive = (state: RootState): boolean => {
  return !!state.generalTransport.layout.modal_flex_dates;
};

export const getFlexDatesRange = (state: RootState): DateTimePeriodParams[] => {
  const dtps: DateTimePeriodParams[] = [];
  state.generalTransport.quote?.pickup_datetime_period_options?.options?.forEach(dtpOption => {
    return dtpOption?.time_options?.forEach(({ start, end, type }) => {
      if (start && end && type === 'full') {
        dtps.push({ start, end });
      }
    });
  });
  return dtps;
};

export const getFirstAndLastFlexDates = (state: RootState): null | { first: string; last: string } => {
  if (!getIsFlexDates(state)) {
    return null;
  }

  const dtps = getFlexDatesRange(state);
  if (dtps.length === 0) {
    return null;
  }
  const first = dtps[0].start;
  const last = dtps[dtps.length - 1].start;
  if (!first || !last) {
    return null;
  }
  return { first, last };
};

// reducers
export const generalTransport = (state: GeneralTransport = defaultState, action): GeneralTransport => {
  switch (action.type) {
    case GeneralFlowActionTypes.PUSH_NOTIFICATION:
      return {
        ...state,
        error: action.payload,
      };
    case GeneralFlowActionTypes.SET_SUBMITTING_STATUS:
      return {
        ...state,
        isSubmitting: action.payload,
      };
    case GeneralFlowActionTypes.SET_TRANSPORT_UUID:
      return {
        ...state,
        request: {
          ...state.request,
          uuid: action.payload,
        },
      };
    case GeneralFlowActionTypes.CREATE_TRANSPORT_REQUEST_START:
      return {
        ...state,
        price: {
          ...state.price,
          isLoading: true,
        },
      };
    case GeneralFlowActionTypes.RESET_RETRY_ATTEMPTS:
      return {
        ...state,
        price: {
          ...state.price,
          retryAttempt: 0,
        },
      };
    case GeneralFlowActionTypes.PRICE_API_RETRY_ATTEMPT_INCREMENT: {
      let retryAttempts = state.price.retryAttempt;
      if (retryAttempts > 3) {
        retryAttempts = 0;
      }
      retryAttempts++;
      return {
        ...state,
        price: {
          ...state.price,
          retryAttempt: retryAttempts,
          failedAttempts: true,
        },
      };
    }
    case GeneralFlowActionTypes.CREATE_TRANSPORT_REQUEST_FAILURE:
      return {
        ...state,
        price: {
          ...state.price,
          isLoading: false,
        },
        error: action.payload,
      };
    case GeneralFlowActionTypes.SET_PRICE:
      return {
        ...state,
        price: {
          ...state.price,
          amount: action.payload,
          isLoading: false,
          rejected: null,
        },
      };
    case GeneralFlowActionTypes.SET_PRICE_STRUCTURE:
      return {
        ...state,
        priceStructure: action.payload,
      };
    case GeneralFlowActionTypes.SET_PRICE_LOADING:
      return {
        ...state,
        price: {
          ...state.price,
          isLoading: action.payload,
        },
      };
    case GeneralFlowActionTypes.SET_PROGRESS_STEP:
      return {
        ...state,
        layout: {
          ...state.layout,
          step: action.payload,
        },
      };
    case GeneralFlowActionTypes.SET_SHOW_TIME_MODAL:
      return {
        ...state,
        layout: {
          ...state.layout,
          modal_time: action.payload,
        },
      };
    case GeneralFlowActionTypes.SET_SHOW_FLEX_DATES_MODAL:
      return {
        ...state,
        layout: {
          ...state.layout,
          modal_flex_dates: action.payload,
        },
      };
    case GeneralFlowActionTypes.ITEMS_SHOW_MODAL:
      return {
        ...state,
        layout: {
          ...state.layout,
          modal_items: action.payload,
        },
      };
    case GeneralFlowActionTypes.POP_FOOTER_NOTIFICATION:
      return {
        ...state,
        notifications: {
          type: action.payload.type,
          message: action.payload.message,
          visible: true,
        },
      };
    case GeneralFlowActionTypes.HIDE_FOOTER_NOTIFICATION:
      return {
        ...state,
        notifications: {
          type: '',
          message: '',
          visible: false,
        },
      };
    case GeneralFlowActionTypes.TOGGLE_SHEET:
      return {
        ...state,
        layout: {
          ...state.layout,
          sheet: !state.layout.sheet,
        },
      };
    case GeneralFlowActionTypes.SET_STEP_LOADING:
      return {
        ...state,
        layout: {
          ...state.layout,
          step_loading: action.payload,
        },
      };
    case GeneralFlowActionTypes.SET_HISTORICAL_ADDRESSES: {
      const addresses = action.payload.map(address => {
        address['value'] = address['@id'];
        address['label'] = getAddressLabel(address);
        return address;
      });
      return {
        ...state,
        historicalAddresses: addresses,
      };
    }
    case GeneralFlowActionTypes.SET_REFERRER:
      return {
        ...state,
        referrer: action.payload,
      };
    case GeneralFlowActionTypes.SET_QUOTE: {
      const quote: Quote = action.payload.quote;
      /* Set price to zero, when we don't want to show a price yet.
       *  Main reason is when we fire off pricing, with a dummy item set.
       */
      if (action.payload.removePrice) {
        const price = {
          amount: 0,
          currency: 'EUR' as Currency,
        };
        quote.price = {
          incl_vat: price,
          excl_vat: price,
          vat: price,
        };
      }
      return {
        ...state,
        price: {
          ...state.price,
          failedAttempts: false,
        },
        quote,
      };
    }
    case GeneralFlowActionTypes.SET_PRICE_LIST:
      return {
        ...state,
        priceList: action.payload,
      };
    case GeneralFlowActionTypes.SET_DESTINATION_DETAILS:
      return {
        ...state,
        destination: {
          ...state.destination,
          [action.stopType]: {
            ...action.payload,
            latitude: anonymizeCoord(action.payload.latitude),
            longitude: anonymizeCoord(action.payload.longitude),
          },
        },
      };
    case GeneralFlowActionTypes.SET_DESTINATION_META: {
      const metaKey = action.stopType === 'pickup' ? 'pickup_meta' : 'delivery_meta';
      return {
        ...state,
        destination: {
          ...state.destination,
          [metaKey]: action.payload,
        },
      };
    }
    case GeneralFlowActionTypes.RESET_PRICE:
      return {
        ...state,
        price: defaultState.price,
        priceList: defaultState.priceList,
        priceStructure: defaultState.priceStructure,
        quote: defaultState.quote,
      };

    case GeneralFlowActionTypes.RESET_DESTINATION_DETAILS:
      return {
        ...state,
        destination: defaultState.destination,
      };
    case GeneralFlowActionTypes.ADD_LAST_VISIT_TIME: {
      const now = new Date();
      let lastVisitTimes = state.lastVisitTimes || [];
      /*
       * Save the last visit time, when:
       * - there are no visit times yet
       * - 'now' is at least one hour later then the last visit time
       */
      if (!lastVisitTimes.length) {
        lastVisitTimes = [now.toISOString()];
      }
      if (lastVisitTimes.length && differenceInHours(new Date(lastVisitTimes[0]), now) > 1) {
        /* Add entry and limit it to 10 in total */
        lastVisitTimes = [now.toISOString(), ...lastVisitTimes].filter((d, i) => i < 10);
      }
      return {
        ...state,
        lastVisitTimes,
      };
    }
    case GeneralFlowActionTypes.LOCALITY_CHANGE:
      return {
        ...state,
        localityChange: action.payload,
      };
    case GeneralFlowActionTypes.SET_INSTRUCTION_SUSPICION:
      return {
        ...state,
        instructionSuspicion: {
          ...state.instructionSuspicion,
          [action.payload.stopType]: action.payload.suspicions,
        },
      };
    case GeneralFlowActionTypes.SET_VAT_NUMBER:
      return {
        ...state,
        vat_number: {
          ...state.vat_number,
          value: action.payload,
        },
      };
    case GeneralFlowActionTypes.SET_VAT_NUMBER_LOADING:
      return {
        ...state,
        vat_number: {
          ...state.vat_number,
          loading: action.payload,
        },
      };
    case GeneralFlowActionTypes.SET_REJECTED_BY_QUOTES:
      return {
        ...state,
        price: {
          ...state.price,
          rejected: action.payload,
        },
      };
    case GeneralFlowActionTypes.SET_TR_VALIDATION_ERROR:
      return {
        ...state,
        tr_validation_error: action.payload,
      };
    case GeneralFlowActionTypes.RESET_FLOW: {
      // There is no reason to reset the step value,
      // Routes are taking care of this and this leads to race condition errors
      const layout = {
        ...defaultState.layout,
        step: state.layout.step,
      };
      if (action.payload.resetDestination) {
        return { ...defaultState, layout, utm: state.utm };
      }
      return {
        ...defaultState,
        destination: state.destination,
        layout,
        utm: state.utm,
      };
    }
    case GeneralFlowActionTypes.SET_QUERY_PS: {
      return {
        ...state,
        product_selection: {
          ...state.product_selection,
          query: action.payload,
        },
      };
    }
    case GeneralFlowActionTypes.SET_SEARCH_RESULT_PS: {
      return {
        ...state,
        product_selection: {
          ...state.product_selection,
          searchResult: action.payload,
        },
      };
    }
    case GeneralFlowActionTypes.ADD_ITEM_PS:
      return {
        ...state,
        product_selection: {
          ...state.product_selection,
          // We add an uuid to every item, so that an single item is easy to ref.
          // Makes deleting and updating easy.
          items: [
            ...state.product_selection.items,
            {
              ...action.payload,
              ps_temp: {
                ...action.payload.ps_temp,
                uuid: uuidv4(),
              },
            },
          ],
        },
      };
    case GeneralFlowActionTypes.REMOVE_ITEM_PS:
      if (action.payload.all) {
        // clear them all
        return {
          ...state,
          product_selection: {
            ...state.product_selection,
            items: [],
          },
        };
      }
      if (action.payload.uuid) {
        // simple filter to remove the item of payload's uuid
        return {
          ...state,
          product_selection: {
            ...state.product_selection,

            items: state.product_selection.items.filter(item => item.ps_temp.uuid !== action.payload.uuid),
          },
        };
      }
      // Either one of the payload keys should be there, but being a good boy: some state should always be returned
      return state;
    case GeneralFlowActionTypes.UPDATE_ITEM_PS:
      return {
        ...state,
        product_selection: {
          ...state.product_selection,
          // Out with the old, in with the new, based on index
          items: state.product_selection.items.map(item => {
            if (item.ps_temp.uuid === action.payload.uuid) {
              return action.payload.item;
            }
            return item;
          }),
        },
      };
    case GeneralFlowActionTypes.REVIEWED_ITEM_PS:
      return {
        ...state,
        product_selection: {
          ...state.product_selection,
          // Out with the old, in with the new, based on index
          items: state.product_selection.items.map(item => {
            if (item.ps_temp.uuid === action.payload.uuid) {
              return {
                ...item,
                ps_temp: {
                  ...item.ps_temp,
                  reviewed: action.payload.reviewed,
                },
              };
            }
            return item;
          }),
        },
      };
    case GeneralFlowActionTypes.SET_PREFILL_PS:
      return {
        ...state,
        product_selection: {
          ...state.product_selection,
          prefill: action.payload,
        },
      };
    case GeneralFlowActionTypes.SET_CONTACT_DETAILS: {
      const type = action.payload.contact_type;
      return {
        ...state,
        contact: {
          ...state.contact,
          [type]: {
            ...(state.contact?.[type] || {}),
            contact_details: action.payload.details,
          },
        },
      };
    }
    case GeneralFlowActionTypes.SET_COMPANY_DETAILS: {
      return {
        ...state,
        contact: {
          ...state.contact,
          customer: {
            ...((state.contact?.customer as ContactCustomer) || {}),
            company_details: action.payload,
          },
        },
      };
    }
    case GeneralFlowActionTypes.SET_STOP_ADDRESS: {
      const stopType = action.payload.stop_type;
      return {
        ...state,
        contact: {
          ...state.contact,
          [stopType]: {
            ...state.contact[stopType],
            address: action.payload.address,
            instructions: action.payload.instructions,
            address_is_prefilled: !!action.payload.address_is_prefilled,
          },
        },
      };
    }
    case GeneralFlowActionTypes.SET_AB: {
      return {
        ...state,
        ab: action.payload,
      };
    }
    case GeneralFlowActionTypes.RESET_AB: {
      return {
        ...state,
        ab: undefined,
      };
    }
    case GeneralFlowActionTypes.SET_CHOICE: {
      return {
        ...state,
        choices: {
          ...(state.choices || {}),
          ...action.payload,
        },
      };
    }
    case GeneralFlowActionTypes.RESET_CHOICE: {
      let newChoices = state.choices || {};
      if (action.payload === 'ALL') {
        newChoices = {};
      } else {
        action.payload.forEach((key: GfChoicesKey) => {
          delete newChoices[key];
        });
      }
      return {
        ...state,
        choices: newChoices,
      };
    }
    case GeneralFlowActionTypes.SET_UTM_DETAILS: {
      return {
        ...state,
        utm: {
          // We stick to a standard of 30 days (like the usual ad-service cookies)
          ttl: addDays(new Date(), 30).toUTCString(),
          values: action.payload,
        },
      };
    }
    default:
      return state;
  }
};

export default generalTransport;

import * as React from 'react';
import './icons.scss';

export const PlusWithCircle = (): JSX.Element => {
  return (
    <svg viewBox="0 0 29.97 29.97">
      <g id="Laag_2" data-name="Laag 2">
        <g id="Laag_2-2" data-name="Laag 2">
          <path
            className="cls-1"
            d="M15,0A15,15,0,1,0,30,15,15,15,0,0,0,15,0Zm0,27.65A12.67,12.67,0,1,1,27.65,15,12.68,12.68,0,0,1,15,27.65Z"
          />
          <path
            className="cls-1"
            d="M20.79,13.72H16.15V9.08a1.16,1.16,0,1,0-2.32,0v4.64H9.18a1.16,1.16,0,0,0,0,2.32h4.65v4.65a1.16,1.16,0,0,0,2.32,0V16h4.64a1.16,1.16,0,0,0,0-2.32Z"
          />
        </g>
      </g>
    </svg>
  );
};

import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { localizeReducer } from 'react-localize-redux-dep-updated';
import { combineReducers, Reducer } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { apiSettingsReducer } from '../../modules/ApiSettings/ducks';
import { businessReducer } from '../../modules/BusinessFlow/ducks';
import { generalTransport } from '../../modules/GeneralFlow/ducks';
import userReducer from '../../modules/User/ducks';
import { base } from './baseReducer';
import { notificationsReducer } from './notifications';

export default (history: History): Reducer =>
  combineReducers({
    router: connectRouter(history),
    localize: localizeReducer,
    form: formReducer,
    generalTransport,
    user: userReducer,
    base,
    business: businessReducer,
    notifications: notificationsReducer,
    apiSettings: apiSettingsReducer,
  });

import React from 'react';
import { actions as flowActions, getProductPaymentAmount } from '../ducks';

import { Translate } from 'react-localize-redux-dep-updated';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IconCross, IconPhone, PriceDisplay } from '../../../brenger-shared-ui';
import { RootState } from '../../../typings';
import { getActiveLanguageSettings } from '../../../utils/localization';
import { getValidDiscount, priceAsString } from '../../../utils/price';
import TransportRequestSummary from './TransportRequestSummary';

type ReduxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const SidebarLayout: React.FC<ReduxProps> = props => {
  const exclVat = Boolean(props.vatNumber);
  // Do we have a discount?
  const discount = getValidDiscount(props.quoteNotes || []);
  const hasSpecialPrice = discount?.type === 'percentage';

  let headerTrans = 'request_flow.price.total';
  if (hasSpecialPrice) {
    headerTrans = 'request_flow.price.including_discount_percentage';
  }
  const langSettings = getActiveLanguageSettings();
  return (
    <div>
      <div className={'sheet-layout--sidebar-shade-toggle'} onClick={props.toggleSheet} />
      <div className={'sheet-layout--sidebar-background'}>
        {/* AB TEST: change when people see price */}
        <div className={'summary'}>
          <div className={'summary--close'} onClick={props.toggleSheet}>
            <IconCross />
          </div>
          <div className="summary--body">
            <div className={'summary-heading'}>
              <Translate id={'request_flow.headers.transport_request_title'} />
            </div>
            <TransportRequestSummary />
          </div>
          <div className={'summary--price hide--md-down'}>
            <PriceDisplay
              failedAttempts={props.transport.price.failedAttempts}
              loading={props.transport.price.isLoading}
              header={
                <>
                  <Translate id={headerTrans} data={{ percentage: discount?.data }} />
                  {exclVat && (
                    <small style={{ margin: '0 0 0 .3em', display: 'inline-block' }}>
                      <Translate id={'request_flow.price.ex_vat'} />
                    </small>
                  )}
                </>
              }
              exclVat={exclVat}
              quotePrice={props.quotePrice}
              quoteNotes={props.quoteNotes}
            />
          </div>
          {props.productPaymentAmount && (
            <div className={'pt-1'}>
              <div className={'text--bold'}>
                <Translate id={'request_flow.summary.excluded'} />
              </div>
              <div className={'summary-line'}>
                <div>
                  <Translate id={'request_flow.summary.product_payment'} />
                </div>
                <div className={'adds-price'}>
                  {priceAsString({ price: { amount: props.productPaymentAmount }, addPlusMinusIndicator: false })}
                </div>
              </div>
            </div>
          )}
        </div>
        {['de-DE'].includes(langSettings.labels.full) && (
          <div className="sheet-layout--sidebar-contact">
            <Translate
              id={'request_flow.contact_us_by_phone'}
              data={{
                phone: (
                  <div style={{ display: 'inline-block' }}>
                    <IconPhone />
                    {langSettings.contact.phone.customer}
                  </div>
                ),
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState) => ({
  transport: state.generalTransport,
  quotePrice: state.generalTransport.quote?.price,
  quoteNotes: state.generalTransport.quote?.notes,
  productPaymentAmount: getProductPaymentAmount(state),
  vatNumber: state.generalTransport.contact.customer?.company_details?.vat_number,
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: Dispatch) => ({
  toggleSheet: () => dispatch(flowActions.toggleSheet()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarLayout);

import React from 'react';

export const IconMoney: React.FC<React.SVGAttributes<SVGElement>> = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={512} height={512} viewBox="0 0 512 512" {...props}>
      <path
        className="cls-1"
        d="M256,386c-5.52,0-10,4.48-10,10s4.48,10,10,10c5.52,0,10-4.48,10-10S261.52,386,256,386z"
      />
      <path
        className="cls-1"
        d="M469.087,61.37C444.479,51.459,412.485,46,379,46s-65.479,5.459-90.087,15.37C274.371,67.227,263.268,74.385,256,82.417
	c-7.268-8.032-18.371-15.189-32.913-21.046C198.479,51.459,166.485,46,133,46s-65.479,5.459-90.087,15.37
	C15.24,72.516,0,88.366,0,106v240c0,17.634,15.24,33.484,42.913,44.63C65.8,399.848,95.078,405.208,126,405.914V406
	c0,34.206,55.888,60,130,60s130-25.794,130-60v-0.086c30.922-0.706,60.2-6.066,83.087-15.284C496.76,379.484,512,363.634,512,346
	V106C512,88.366,496.76,72.516,469.087,61.37z M492,286c0,8.537-11.643,18.529-30.385,26.078
	c-20.285,8.17-47.519,13.113-75.615,13.823V286c0-0.029-0.002-0.058-0.002-0.086c30.923-0.705,60.201-6.065,83.09-15.284
	c8.983-3.618,16.646-7.734,22.913-12.244L492,286L492,286z M246,166c0,8.537-11.643,18.529-30.385,26.078
	C193.648,200.926,163.536,206,133,206s-60.648-5.074-82.615-13.922C31.643,184.529,20,174.537,20,166v-27.614
	c6.267,4.51,13.93,8.626,22.913,12.244C67.521,160.541,99.515,166,133,166s65.479-5.459,90.087-15.37
	c8.983-3.618,16.646-7.734,22.913-12.244V166z M42.913,210.63C67.521,220.541,99.515,226,133,226s65.479-5.459,90.087-15.37
	c8.983-3.618,16.646-7.734,22.913-12.244v27.791c-25.87,0.876-50.68,5.122-70.747,12.189c-20.127,7.089-34.61,16.542-42.486,27.631
	c-30.456-0.026-60.471-5.094-82.382-13.919C31.643,244.529,20,234.537,20,226v-27.614C26.267,202.896,33.93,207.012,42.913,210.63z
	 M20,258.386c6.267,4.51,13.93,8.626,22.913,12.244c22.888,9.218,52.167,14.578,83.09,15.284c0,0.029-0.002,0.058-0.002,0.086
	v39.901c-28.097-0.71-55.33-5.653-75.615-13.823C31.643,304.529,20,294.537,20,286V258.386z M147.058,281.087
	C154.469,263.84,197.244,246,256,246s101.531,17.84,108.934,35.067c0.718,1.681,1.066,3.294,1.066,4.933
	c0,8.792-10.839,18.558-28.995,26.123C315.529,321.072,286.761,326,256,326s-59.529-4.928-81.005-13.877
	C156.839,304.558,146,294.792,146,286C146,284.361,146.349,282.748,147.058,281.087z M492,226c0,8.537-11.643,18.529-30.385,26.078
	c-21.91,8.825-51.924,13.893-82.379,13.919c-7.87-11.083-22.355-20.54-42.489-27.631c-20.067-7.068-44.877-11.313-70.747-12.189
	v-27.791c6.267,4.51,13.93,8.626,22.913,12.244C313.521,220.541,345.515,226,379,226s65.479-5.459,90.087-15.37
	c8.983-3.618,16.646-7.734,22.913-12.244V226z M492,166c0,8.537-11.643,18.529-30.385,26.078C439.648,200.926,409.536,206,379,206
	s-60.648-5.074-82.615-13.922C277.643,184.529,266,174.537,266,166v-27.614c6.267,4.51,13.93,8.626,22.913,12.244
	C313.521,160.541,345.515,166,379,166s65.479-5.459,90.087-15.37c8.983-3.618,16.646-7.734,22.913-12.244V166z M296.385,79.922
	C318.352,71.074,348.464,66,379,66s60.648,5.074,82.615,13.922C480.357,87.471,492,97.463,492,106s-11.643,18.529-30.385,26.078
	C439.648,140.926,409.536,146,379,146s-60.648-5.074-82.615-13.922C277.643,124.529,266,114.537,266,106
	S277.643,87.471,296.385,79.922z M50.385,79.922C72.352,71.074,102.464,66,133,66s60.648,5.074,82.615,13.922
	C234.357,87.471,246,97.463,246,106s-11.643,18.529-30.385,26.078C193.648,140.926,163.536,146,133,146s-60.648-5.074-82.615-13.922
	C31.643,124.529,20,114.537,20,106S31.643,87.471,50.385,79.922z M50.385,372.078C31.643,364.529,20,354.537,20,346v-27.614
	c6.267,4.51,13.93,8.626,22.913,12.244C65.8,339.848,95.078,345.208,126,345.914v39.988
	C97.903,385.191,70.67,380.249,50.385,372.078z M337.005,432.123C315.529,441.072,286.761,446,256,446s-59.529-4.928-81.005-13.877
	C156.839,424.558,146,414.792,146,406v-27.031c2.265,1.639,4.768,3.268,7.544,4.874c15.529,8.986,36.658,15.633,61.104,19.221
	c0.493,0.072,0.981,0.107,1.465,0.107c4.88,0,9.151-3.577,9.881-8.549c0.802-5.464-2.978-10.544-8.441-11.346
	C171.078,376.453,146,358.979,146,346v-27.12C168.634,335.442,208.587,346,256,346s87.366-10.558,110-27.12V346
	c0,12.979-25.078,30.453-71.553,37.276c-5.464,0.802-9.244,5.882-8.441,11.346c0.73,4.973,5.001,8.549,9.881,8.549
	c0.483,0,0.973-0.035,1.465-0.107c24.445-3.588,45.574-10.234,61.104-19.221c2.775-1.606,5.279-3.235,7.544-4.874V406
	C366,414.792,355.161,424.558,337.005,432.123z M461.615,372.078c-20.285,8.17-47.519,13.113-75.615,13.823v-39.988
	c30.922-0.706,60.2-6.066,83.087-15.284c8.983-3.618,16.646-7.734,22.913-12.244V346C492,354.537,480.357,364.529,461.615,372.078z"
      />
    </svg>
  );
};

import { Locale } from '@brenger/api-client';
import { useForm } from '@brenger/react';
import cn from 'classnames';
import * as React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { useDispatch } from 'react-redux';
import { Button, Col, InputText, Row } from '../../../../brenger-shared-ui';
import { InputPhone } from '../../../../brenger-shared-ui/components/form/InputPhone';
import { useTypedSelector } from '../../../../hooks';
import { getActiveLanguageSettings, translate } from '../../../../utils/localization';
import { validateEmail, validatePhone, validateRequired } from '../../../../utils/validation';
import { actions } from '../../ducks';
import { ContactType } from '../../typings';

interface Props {
  forceShowErrors?: boolean;
  type: ContactType;
  submitCallBack?(): void;
  withDefault?: boolean;
  withButton?: boolean;
  withColumns?: boolean;
}

export const ContactFields: React.FC<Props> = ({
  forceShowErrors,
  type,
  submitCallBack,
  withDefault = true,
  withButton,
  withColumns = true,
}) => {
  const dispatch = useDispatch();
  const savedDetails = useTypedSelector(state => state.generalTransport.contact?.[type]);
  const prefLocale = getActiveLanguageSettings().labels.full.replace('-', '_') as Locale;
  const form = useForm({
    persist: {
      key: `gf_contact_${type}`,
      type: 'session',
    },
    initialState: {
      firstName: withDefault ? savedDetails?.contact_details?.first_name || '' : '',
      lastName: withDefault ? savedDetails?.contact_details?.last_name || '' : '',
      email: withDefault ? savedDetails?.contact_details?.email || '' : '',
      phone: withDefault ? savedDetails?.contact_details?.phone || '' : '',
    },
    validators: {
      firstName: val => validateRequired(translate('form.fields.user.first_name.label'), val),
      lastName: val => validateRequired(translate('form.fields.user.last_name.label'), val),
      email: val => validateEmail(val),
      phone: val => validatePhone(val),
    },
  });

  React.useEffect(() => {
    const email = form.data.email.value;
    if (type === 'customer' && email) {
      sessionStorage.setItem('GF_CUSTOMER_EMAIL', email);
    }
  }, [form.data.email.value]);

  const save = (): void => {
    dispatch(
      actions.setContactDetails({
        contact_type: type,
        details: form.hasErrors
          ? null
          : {
              first_name: form.data.firstName.value,
              last_name: form.data.lastName.value,
              email: form.data.email.value,
              phone: form.data.phone.value,
              preferred_locale: prefLocale,
            },
      })
    );
    if (submitCallBack) submitCallBack();
  };

  return (
    <>
      <Row>
        <Col xs={12} sm={withColumns ? 6 : undefined} extraClasses="pb-1">
          <InputText
            autoComplete="given-name"
            name="first_name"
            placeholder={translate('form.fields.user.first_name.label')}
            value={form.data.firstName.value}
            onChange={firstName => form.set({ firstName })}
            onBlur={!withButton ? save : undefined}
            meta={{ error: form.getError('firstName'), showError: forceShowErrors || form.data.firstName.isDirty }}
          />
        </Col>
        <Col xs={12} sm={withColumns ? 6 : undefined} extraClasses="pb-1">
          <InputText
            autoComplete="family-name"
            name="last_name"
            placeholder={translate('form.fields.user.last_name.label')}
            value={form.data.lastName.value}
            onChange={lastName => form.set({ lastName })}
            onBlur={!withButton ? save : undefined}
            meta={{ error: form.getError('lastName'), showError: forceShowErrors || form.data.lastName.isDirty }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={withColumns ? 6 : undefined} extraClasses={cn('pb-1', { 'md-pb-0': withColumns })}>
          <InputText
            autoComplete="email"
            name="email"
            placeholder={translate('form.fields.user.email.label')}
            value={form.data.email.value}
            onChange={email => form.set({ email })}
            onBlur={!withButton ? save : undefined}
            meta={{ error: form.getError('email'), showError: forceShowErrors || form.data.email.isDirty }}
          />
        </Col>
        <Col xs={12} sm={withColumns ? 6 : undefined} extraClasses={cn({ 'pb-1': withButton })}>
          <InputPhone
            autoComplete="tel"
            name="phone"
            defaultCountryCode={getActiveLanguageSettings().labels.short}
            placeholder={translate('form.fields.user.phone.label')}
            value={form.data.phone.value}
            onChange={phone => form.set({ phone })}
            onBlur={!withButton ? save : undefined}
            meta={{ error: form.getError('phone'), showError: forceShowErrors || form.data.phone.isDirty }}
          />
        </Col>
      </Row>
      {withButton && (
        <Row>
          <Col xs={12}>
            <Button disabled={form.hasErrors} buttonStyle="primary" onClick={save}>
              <Translate id="form_edit_contact.edit_button" />
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
};

import * as React from 'react';
import './icons.scss';

export const IconSituation2dehands = (): JSX.Element => {
  return (
    <svg className={'Icon2dehands'} width="96" height="96" viewBox="0 0 911.02 901.06">
      <defs>
        <linearGradient
          id="aalinear-gradient"
          x1="38.48"
          y1="496.81"
          x2="949.5"
          y2="496.81"
          gradientTransform="rotate(.05 -3251.155 7438.412)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".11" stopColor="#fb0" stopOpacity="0" />
          <stop offset=".79" stopColor="#fcb912" />
        </linearGradient>
      </defs>
      <path
        className="aacls-1"
        d="M623.09 749.15L384 748.92A52.85 52.85 0 0 1 331.21 696v-30.43c.06-69.45 61-107.07 119.93-143.45C500 492 566.84 450.74 566.88 411.11c0-17.15 0-52.84-69.52-52.91-36.08 0-48.64 7.41-76.15 23.73q-4.79 2.85-9.86 5.82a52.86 52.86 0 1 1-53.25-91.33l9.18-5.41c32-19 65.05-38.59 130.18-38.53 108.13.11 175.24 60.93 175.14 158.73-.09 98.54-95.92 157.7-165.9 200.88-12.45 7.69-32.32 20-47.67 31.19l164.16.15a52.86 52.86 0 0 1-.1 105.72z"
        transform="translate(-44.49 -49.47)"
      />
      <path
        d="M499.57 950.53c-116.58-.1-233.08-44-321.74-131.81-86.1-85.23-133.45-198.53-133.34-319s47.69-233.7 134-318.76C356.09 5.74 644.86 6 822.17 181.55c86.1 85.23 133.45 198.52 133.34 319s-47.69 233.7-134 318.76c-88.76 87.6-205.39 131.32-321.94 131.22zm.76-795.23c-89.65-.09-179.34 33.54-247.66 100.91-66 65.06-102.37 151.57-102.46 243.59s36.13 178.59 102 243.78c136.38 135 358.49 135.22 495.13.47 66-65.06 102.37-151.57 102.46-243.58s-36.13-178.59-102-243.78C679.61 189.18 590 155.38 500.33 155.3z"
        transform="translate(-44.49 -49.47)"
        fill="url(#aalinear-gradient)"
      />
      <path
        className="aacls-1"
        d="M215 834a52.74 52.74 0 0 1-37.13-15.29c-86.1-85.23-133.45-198.53-133.34-319 .2-248.51 204.73-450.48 455.9-450.24s455.32 202.6 455.08 451.1a52.86 52.86 0 0 1-105.72-.1c.21-190.21-156.58-345.09-349.46-345.28S150.39 309.59 150.21 499.8c-.09 92 36.13 178.59 102 243.78A52.86 52.86 0 0 1 215 834z"
        transform="translate(-44.49 -49.47)"
      />
    </svg>
  );
};

/**
 * Input is any referrer url, as in: the url were the user was before entering the flow
 * We use this only in case of Marktplaats referrer, where we have specific utms that indicate product groups
 * returns null if nothing found
 */

import { PSPrefillProductAction, PsPrefillOtherAction } from '../modules/GeneralFlow/typings';

export const getProductFromMarktplaatsRef = (url?: string | null): null | string => {
  if (!url) {
    return null;
  }
  try {
    const urlObj = new URL(url);
    const isOriginMP = urlObj.searchParams.get('utm_source') === 'marktplaats'; // This is the marktplaats add, 'Marktplaats' with capital M is our ads utm;
    if (!isOriginMP) {
      return null;
    }
    const mpProduct = urlObj.searchParams.get('utm_campaign')?.toLowerCase();
    const utmContent = urlObj.searchParams.get('utm_content')?.toLowerCase();
    if (utmContent === 'scooters') {
      return utmContent;
    }
    // This is the Martkplaats add default, means "Category not specified"
    if (!mpProduct || mpProduct === 'item') {
      return null;
    }
    // filter out MP prefix of the category
    const productStripped = mpProduct.replace('app_category_', '');
    // Witgoed is further specified in utmContent (example "wasdroogcombinatie")
    if (productStripped === 'witgoed' && utmContent) {
      return utmContent;
    }
    return productStripped;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
  return null;
};

export const getProductActionFromMarktplaatsRef = (
  url?: null | string
): PsPrefillOtherAction | PSPrefillProductAction | null => {
  const product = getProductFromMarktplaatsRef(url);
  // bail early
  if (!product) {
    return null;
  }
  const normalizedProd = product.replace(/[-_ ]/g, '').toLowerCase();
  switch (normalizedProd) {
    case 'zetels':
    case 'fauteuils':
      return {
        type: 'PREFILL',
        value: 'ProductGroup#Chairs|Armchair',
        shouldWarn: true,
      };
    case 'chair':
    case 'stoelen':
      return {
        type: 'PREFILL',
        value: 'ProductGroup#Chairs|Chair',
        shouldWarn: true,
      };
    case 'dressoirs':
      return {
        type: 'PREFILL',
        value: 'ProductGroup#Closets|Dresser',
        shouldWarn: true,
      };
    case 'betten':
    case 'beds':
    case 'bedden':
      return {
        type: 'CONFIG',
        value: 'ProductGroup#Beds|Bed',
      };
    case 'bureaus':
      return {
        type: 'PREFILL',
        value: 'ProductGroup#Tables|Desk',
        shouldWarn: true,
      };
    case 'fietsen':
      return {
        type: 'PREFILL',
        value: 'ProductGroup#Bikes|Bike',
        shouldWarn: true,
      };
    case 'scooters':
      return {
        type: 'PREFILL',
        value: 'ProductGroup#Mopeds|Scooter',
        shouldWarn: true,
      };
    case 'salontafels':
      return {
        type: 'CONFIG',
        value: 'ProductGroup#Tables|Coffee table',
      };
    case 'boekenkasten':
      return {
        type: 'PREFILL',
        value: 'ProductGroup#Closets|Bookcase',
        shouldWarn: true,
      };
    case 'buffetkasten':
      return {
        type: 'PREFILL',
        value: 'ProductGroup#Closets|Buffet cabinet',
        shouldWarn: true,
      };
    case 'ladenkasten':
    case 'ladekasten':
      return {
        type: 'PREFILL',
        value: 'ProductGroup#Closets|Closet parts|Sideboard',
        shouldWarn: true,
      };
    case 'secretaires':
      return {
        type: 'PREFILL',
        value: 'ProductGroup#Tables|Secretary',
        shouldWarn: true,
      };
    case 'tvmeubels':
      return {
        type: 'PREFILL',
        value: 'ProductGroup#Closets|TV stand',
        shouldWarn: true,
      };
    case 'schraenke':
    case 'wardrobes':
    case 'kledingkasten':
      return {
        type: 'CONFIG',
        value: 'ProductGroup#Closets|Wardrobe',
      };
    case 'lockerkasten':
      return {
        type: 'PREFILL',
        value: 'ProductGroup#Locker cabinet|Locker cabinet',
        shouldWarn: true,
      };
    case 'computermeubels':
      return {
        type: 'PREFILL',
        value: 'ProductGroup#Computer furniture|Computer cabinet',
        shouldWarn: true,
      };
    case 'wasmachines':
      return {
        type: 'PREFILL',
        value: 'ProductGroup#Heavy Household Appliances|Washing machine',
        shouldWarn: true,
      };
    case 'wasdrogers':
      return {
        type: 'PREFILL',
        value: 'ProductGroup#Household Appliances|Dryer',
        shouldWarn: true,
      };
    case 'wasdroogcombinaties':
      return {
        type: 'PREFILL',
        value: 'ProductGroup#Heavy Household Appliances|Washer dryer combination',
        shouldWarn: true,
      };
    case 'fornuizen':
      return {
        type: 'PREFILL',
        value: 'ProductGroup#Heavy Household Appliances|Stove',
        shouldWarn: true,
      };
    case 'koelkastenijskasten':
    case 'koelkastenenijskasten':
      return {
        type: 'PREFILL',
        value: 'ProductGroup#Household Appliances|Refrigerator',
        shouldWarn: true,
      };
    case 'vaatwasmachines':
      return {
        type: 'PREFILL',
        value: 'ProductGroup#Heavy Household Appliances|Dishwasher',
        shouldWarn: true,
      };
    case 'vriezersdiepvrieskisten':
    case 'vriezersendiepvrieskisten':
      return {
        type: 'PREFILL',
        value: 'ProductGroup#Household Appliances|Freezer',
        shouldWarn: true,
      };
    case 'sitzgruppen':
    case 'sofas':
    case 'bankstellen':
      return {
        type: 'CONFIG',
        value: 'ProductGroup#Couches|Sofa',
      };
    case 'tische':
    case 'tafel':
    case 'eettafels':
      return {
        type: 'CONFIG',
        value: 'ProductGroup#Tables|Dining table',
      };
    case 'kasten':
    case 'overigekasten':
      return {
        type: 'SEARCH',
        value: 'kast',
      };
    case 'wandmeubels':
      return {
        type: 'SEARCH',
        value: 'wand',
      };
    case 'furniture':
      return {
        type: 'SEARCH',
        value: 'furniture',
      };
    case 'meubels':
      return {
        type: 'SEARCH',
        value: 'meubel',
      };
    case 'moebel':
      return {
        type: 'SEARCH',
        value: 'möbel',
      };
    case 'tuinmeubelen':
      return {
        type: 'SEARCH',
        value: 'tuin',
      };
    case 'gardenfurniture':
      return {
        type: 'SEARCH',
        value: 'garden',
      };
    case 'witgoed':
      return {
        type: 'SEARCH',
        value: 'witgoed',
      };
    case 'whitegoods':
      return {
        type: 'SEARCH',
        value: 'whitegoods',
      };
  }
  return null;
};

import { Account } from '@brenger/api-client';
import { AxiosResponse } from 'axios';
import { getLocation, LOCATION_CHANGE, push } from 'connected-react-router';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import { matchPath } from 'react-router';
import { actionTypes as formActionTypes, change, reset } from 'redux-form';
import { translate } from '../../utils/localization';
import { all, call, delay, fork, put, select, take, takeLatest } from 'typed-redux-saga';
import { Config } from '../../config';
import { logError, pushToDataLayer, urlParser } from '../../utils/basics';
import { ROLES } from '../../utils/global';
import { coreClient, getIsProduction, http, routePlannerClient } from '../../utils/request';
import { apiSettingsActions } from '../ApiSettings/ducks';
import { ApiSettingsActionTypes } from '../ApiSettings/typings';
import { actions as userActions, getLoggedInUser, getResetToken, isRegistered, isSocialRegistration } from './ducks';
import { UserActionTypes, UserErrorTitles, UserForms } from './typings';
import { trackEvent } from '../../utils/eventTracking';
import { r } from '../../routes';

function startSocialAuth(action): void {
  const URL = urlParser();
  const uuid = URL.get('transport_request');
  const referer = uuid && uuid !== '' ? `&transport_request=${uuid}` : '';
  window.location.assign(`${Config.API_URL}/user/login?social=${action.service}${referer}`);
}

function* verifyLoggedInUser(action): Generator {
  if (matchPath(action.payload.location.pathname, { path: r.user.loginCheck() })) {
    try {
      const registered = yield* select(isRegistered);
      const redirectUrl = registered ? r.user.dashboard() : r.user.register();
      const user = yield* call(coreClient.users.retrieveCurrentUser);
      yield* all([put(userActions.socialAuthSuccess(user)), put(push(redirectUrl))]);
    } catch (e) {
      logError(e);
      yield* put(userActions.socialAuthFailed('Social auth failed'));
    }
  }
}

function* failedSocialAuth(action): Generator {
  if (matchPath(action.payload.location.pathname, { path: r.user.loginError(), exact: true })) {
    yield* delay(5000);
    yield* put(push(r.user.register()));
  }
}

function completeRegistration(details, id): Promise<AxiosResponse> {
  details['username'] = details.email;
  details['roles'] = [ROLES.role_customer, ROLES.role_user];
  return http()
    .put(`${id}/complete_social_registration`, details, { withCredentials: true })
    .then(resp => resp);
}

function createIndividualAccount(details): Promise<AxiosResponse> {
  const URL = urlParser();
  const uuid = URL.get('transport_request');
  const url = uuid && uuid !== '' ? `users?transport_request=${uuid}` : `users`;
  details.account = {
    account_type: 'individual',
    name: details.first_name + ' ' + details.last_name,
    phone: details.phone,
  };
  details['username'] = details.email;
  details['roles'] = [ROLES.role_customer, ROLES.role_user];
  return http()
    .post(url, details, { withCredentials: true })
    .then(resp => resp);
}

function createBusinessAccount(details): Promise<AxiosResponse> {
  const URL = urlParser();
  const uuid = URL.get('transport_request');
  const url = uuid && uuid !== '' ? `users?transport_request=${uuid}` : `users`;
  details.account.account_type = 'business';
  details.account.phone = details.phone;
  details['username'] = details.email;
  details['roles'] = [ROLES.role_customer, ROLES.role_user];
  return http()
    .post(url, details, { withCredentials: true })
    .then(resp => resp);
}

function* registerUser(action): Generator {
  let formPayload = action.payload.user;
  if (_get(action.payload.user, 'account', false)) {
    formPayload = _omit(action.payload.user, 'account');
  }
  try {
    yield* put(userActions.registerErrorReset());
    const isExistUsername = yield* call(coreClient.users.retrieveUserExists, action.payload.user.email);
    if (isExistUsername === UserErrorTitles.USERNAME_EXIST) {
      yield* put(userActions.registerFailed(translate('authentication.register.error.username_taken')));
      return;
    }
  } catch (e) {
    // If the username doesn't exist it returns 404
    logError(e);
  }

  try {
    const isSocialReg = yield* select(isSocialRegistration);
    if (isSocialReg) {
      const user = yield* select(getLoggedInUser);
      // social logged in user registration scenario
      if (user.isBusiness) {
        const account = action.payload.user.account;
        account.phone = action.payload.user.phone;
        yield* all([
          call(updateAccount, user.details?.account['@id'], account),
          call(createAddress, account),
          call(completeRegistration, formPayload, user.id),
        ]);
        yield* put(userActions.registerSuccess(action.payload.user));
      } else {
        yield* call(completeRegistration, formPayload, user.id);
        yield* put(userActions.registerSuccess(action.payload.user));
      }
      trackEvent({
        eventCategory: 'user_registered',
        eventAction: 'via_social',
        eventLabel: user.isBusiness ? 'business' : 'private',
      });
    } else {
      // without social account registration scenario
      const isBusiness = action.payload.user.type === 'business';
      if (isBusiness) {
        yield* call(createBusinessAccount, action.payload.user);
        yield* put(userActions.registerSuccess(action.payload.user));
      } else {
        // test failure
        yield* call(createIndividualAccount, action.payload.user);
        yield* put(userActions.registerSuccess(action.payload.user));
      }
      trackEvent({
        eventCategory: 'user_registered',
        eventAction: 'not_via_social',
        eventLabel: isBusiness ? 'business' : 'private',
      });
    }
  } catch (err) {
    logError(err);
    yield* put(userActions.registerFailed('User registration failed'));
  }
}

function* prefillLoggedInUserDetails(action): Generator {
  if (matchPath(action.payload.location.pathname, { path: r.user.register(), exact: true })) {
    if (urlParser().get('business')) {
      yield* put(change(UserForms.REGISTER, 'user.type', 'business'));
    }
    const user = yield* select(getLoggedInUser);
    if (user.id) {
      yield* all([
        put(change(UserForms.REGISTER, 'user.first_name', user.details?.first_name)),
        put(change(UserForms.REGISTER, 'user.last_name', user.details?.last_name)),
        put(change(UserForms.REGISTER, 'user.email', user.details?.email)),
        put(change(UserForms.REGISTER, 'user.phone', user.details?.phone)),
      ]);
    }
  }
}

function* loginUser(action): Generator {
  const URL = urlParser();
  const uuid = URL.get('transport_request');
  const url = uuid && uuid !== '' ? `/login?transport_request=${uuid}` : `/login`;
  try {
    const request = (user): Promise<AxiosResponse> =>
      http().post(url, { email: user.email, password: user.password }, { withCredentials: true });
    const response = yield* call(request, action.payload.user);
    if (response.status === 200) {
      // retrieve the currently logged in user!
      yield* put(userActions.retrieveCurrentUser());
      // successful login!
      yield* put(userActions.loginSuccess());
    }
  } catch (e) {
    logError(e);
    yield* put(userActions.loginFailed('Oops, wrong credentials!! please try again'));
  }
}

function* checkUserType(action): Generator {
  if (action.meta.field === 'user.type') {
    yield* put(userActions.setAccountType(action.payload));
  }
}

function createAddress(addr): Promise<AxiosResponse> {
  return http()
    .post('addresses', addr, { withCredentials: true })
    .then(resp => resp);
}

function updateAccount(id, account): Promise<AxiosResponse> {
  return http()
    .put(id, account, { withCredentials: true })
    .then(resp => resp);
}

function* init(action): Generator {
  if (
    matchPath(action.payload.location.pathname, { path: r.user.register(), exact: true }) ||
    matchPath(action.payload.location.pathname, { path: r.user.login(), exact: true })
  ) {
    yield* put(userActions.initAuthentication());
  }
}

function* checkWhatIsMyCountry(): Generator {
  try {
    const countryCode = yield* call(routePlannerClient.geo.whatIsMyCountry);
    yield* put(userActions.setWhatIsMyCountry(countryCode));
  } catch (err) {
    logError('cannot fetch the geo location based on IP');
  }
}

function* retrieveCurrentUser(): Generator {
  /**
   * Check if logged in
   */
  let isLoggedIn = false;
  try {
    // returns 403 if not, so fails, and ends up in catch
    yield* call(coreClient.users.isUserLoggedIn);
    isLoggedIn = true;
  } catch (e) {
    logError(e);
  }

  try {
    /**
     * We could be dealing with an expired sandbox session, so let's check that first
     */
    const isProduction = yield* select(getIsProduction);
    if (!isLoggedIn && !isProduction) {
      // We are not authenticated at the sandbox, let's kick off auth
      yield* put(apiSettingsActions.loginSandboxStart());

      // Wait for a result
      const loginResult = yield* take([
        ApiSettingsActionTypes.LOGIN_SANDBOX_FAILED,
        ApiSettingsActionTypes.LOGIN_SANDBOX_SUCCESS,
      ]);

      // Check if the login succeeded
      if (loginResult.type === ApiSettingsActionTypes.LOGIN_SANDBOX_FAILED) {
        throw new Error('Sandbox auth failed');
      }

      // if the sandbox didn't throw an error, we are logged in
      isLoggedIn = true;
    }

    // if we are not logged in at this moment throw an error
    if (!isLoggedIn) {
      throw new Error('We are not authenticated');
    }

    // We are fully authenticated, so we can fetch user details
    const loggedInUser = yield* call(coreClient.users.retrieveCurrentUser);
    yield* put(userActions.setUser(loggedInUser));
    /*
     * BRENGER_USER_UPDATED
     * Custom event that takes care updating user details across scripts
     * See event listener here: stand-alone/ts/menu.ts:23
     * */
    const event = new CustomEvent('BRENGER_USER_UPDATED', {
      detail: {
        result: loggedInUser,
      },
    });
    document.dispatchEvent(event);

    if ((loggedInUser.account as Account).has_business_flow) {
      // initializes chat with business preset
      document.dispatchEvent(
        new CustomEvent('BRENGER_OPEN_CHAT', {
          detail: {
            open: false,
            tags: ['logged_in_business_flow_customer'],
          },
        })
      );
    }
  } catch (e) {
    logError(e);
    yield* put(userActions.setUser(null));
  }
}

function* afterRegister(action): Generator {
  const URL = urlParser();
  const uuid = URL.get('transport_request');
  const url = uuid && uuid !== '' ? `/login?transport_request=${uuid}` : `/login`;
  yield* put(reset(UserForms.REGISTER));
  if (_get(action, 'payload.email', false) && _get(action, 'payload.new_password', false)) {
    try {
      const request = (email, password): Promise<AxiosResponse> =>
        http()
          .post(url, { email, password }, { withCredentials: true })
          .then(resp => resp);
      const response = yield* call(request, action.payload.email, action.payload.new_password);
      if (response.status === 200) {
        yield* put(userActions.loginSuccess());
      }
    } catch (e) {
      logError(e);
    }
  }
}

function* afterLogin(): Generator {
  const location = yield* select(getLocation);
  if (location.search.includes('contact=true')) {
    // to enforce state refresh, this is the easy way out
    window.location.assign(r.generalFlow.contact.index());
  } else {
    yield* put(push(r.user.dashboard()));
  }
}

function* sendPasswordReset(action): Generator {
  try {
    const passwordResetCall = (email): Promise<AxiosResponse> =>
      http()
        .post('/users/reset_password/request', { email })
        .then(resp => resp);
    const response = yield* call(passwordResetCall, action.payload.user.email);
    if (response.status === 200) {
      yield* put(userActions.resetPassSuccess());
    }
  } catch (e) {
    logError(e);
    yield* put(userActions.resetPassFailed('Request failed'));
  }
}

function* setPasswordResetToken(action): Generator {
  const url = action.payload.location.pathname;
  const match = matchPath(url, { path: r.user.passwordToken({}), exact: true, strict: false });
  if (match) {
    const token = _get(match, 'params.token', '');
    yield* put(userActions.setNewPassToken(token));
    return;
  }
  // redirect, should be deleted when mailer is updated
  // NOTE: OLD PATH, SO NOT INCLUDED IN CENTRALIZED ROUTES OBJECT
  const redirectMatch = matchPath(url, { path: `/password-reset/:token`, exact: true, strict: false });
  if (redirectMatch) {
    const token = _get(redirectMatch, 'params.token', '');
    yield* put(push(r.user.passwordToken({ token })));
  }
}

function* sendPasswordNew(action): Generator {
  const resetToken = yield* select(getResetToken);
  try {
    const newPasswordCall = (token, password): Promise<AxiosResponse> =>
      http()
        .post('/users/reset_password', { token, password })
        .then(resp => resp);
    const response = yield* call(newPasswordCall, resetToken, action.payload.user.password);
    if (response.status === 200) {
      yield* put(userActions.newPassSuccess());
    }
  } catch (e) {
    logError(e);
    yield* put(userActions.newPassFailed('Request failed'));
  }
}

function* trackUserId(): Generator {
  const user = yield* select(getLoggedInUser);
  const id = user.userData?.['@id'];
  if (!id) {
    return;
  }
  pushToDataLayer({ userId: id.replace('/users/', '') });
}

function* userAccountSaga(): Generator {
  yield* takeLatest(UserActionTypes.RETRIEVE_CURRENT_COUNTRY, checkWhatIsMyCountry);
  yield* takeLatest(UserActionTypes.LOGIN_USER_START, loginUser);
  yield* takeLatest(UserActionTypes.LOGIN_USER_SUCCESS, afterLogin);
  yield* takeLatest(UserActionTypes.RETRIEVE_CURRENT_USER, retrieveCurrentUser);
  yield* takeLatest(UserActionTypes.SET_USER_DETAILS, trackUserId);
  yield* takeLatest(formActionTypes.CHANGE, checkUserType);
}

function* socialAuthSaga(): Generator {
  yield* takeLatest(LOCATION_CHANGE, init);
  yield* takeLatest(LOCATION_CHANGE, verifyLoggedInUser);
  yield* takeLatest(LOCATION_CHANGE, failedSocialAuth);
  yield* takeLatest(LOCATION_CHANGE, prefillLoggedInUserDetails);
  yield* takeLatest(UserActionTypes.SOCIAL_AUTH_START, startSocialAuth);
  yield* takeLatest(UserActionTypes.REGISTER_USER_START, registerUser);
  yield* takeLatest(UserActionTypes.REGISTER_USER_SUCCESS, afterRegister);
}

function* passWordResetSaga(): Generator {
  yield* takeLatest(UserActionTypes.RESET_PASS_START, sendPasswordReset);
  yield* takeLatest(UserActionTypes.NEW_PASS_START, sendPasswordNew);
  yield* takeLatest(LOCATION_CHANGE, setPasswordResetToken);
}

export function* userSagas(): Generator {
  yield* fork(userAccountSaga);
  yield* fork(socialAuthSaga);
  yield* fork(passWordResetSaga);
}

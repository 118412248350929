import { DatetimePeriodOption, PopularItem, PriceList } from '@brenger/api-client';
import { PriceDataPart } from '../../typings';
import { DepotAddress } from './typings';

// Enums
export enum BusinessForms {
  PREVIEW = '@@BusinessFlow/Preview',
  DESTINATION = '@@BusinessFlow/Destination',
  ITEMS_AND_SERVICE = '@@BusinessFlow/ItemsAndServices',
}

export enum BusinessFields {
  RETURN_ORDER = 'return',
  CLIENT_REFERENCE = 'client_reference',
  INVOICE = 'invoice',
  DEPOT_SELECT = 'depot_select',
  DEPOT_INSTRUCTIONS = 'depot_instructions',
  DEPOT_CONTACT_FIRST_NAME = 'depot_first_name',
  DEPOT_CONTACT_LAST_NAME = 'depot_last_name',
  DEPOT_CONTACT_EMAIL = 'depot_email',
  DEPOT_CONTACT_PHONE = 'depot_phone',
  DISPLAY_NAME = 'display_name',
  DELIVERY_FIRST_NAME = 'delivery.contact.first_name',
  DELIVERY_LAST_NAME = 'delivery.contact.last_name',
  DELIVERY_EMAIL = 'delivery.contact.email',
  DELIVERY_PHONE = 'delivery.contact.phone',
  DELIVERY_AUTO_ADDRESS = 'delivery_auto_address',
  DELIVERY_ADDRESS_IS_MANUAL = 'delivery_address_is_manual_address',
  DELIVERY_MANUAL_LINE_1 = 'delivery_manual_address.address.line1',
  DELIVERY_MANUAL_POSTAL_CODE = 'delivery_manual_address.address.postal_code',
  DELIVERY_MANUAL_LOCALITY = 'delivery_manual_address.address.locality',
  DELIVERY_MANUAL_LATITUDE = 'delivery_manual_address.address.lat',
  DELIVERY_MANUAL_LONGITUDE = 'delivery_manual_address.address.lng',
  DELIVERY_MANUAL_PRECISION = 'delivery_manual_address.precision',
  DELIVERY_MANUAL_SECONDARY_SUBDIVISION = 'delivery_manual_address.address.secondary_subdivision',
  DELIVERY_MANUAL_ADMINISTRATIVE_AREA = 'delivery_manual_address.address.administrative_area',
  DELIVERY_MANUAL_COUNTRY_CODE = 'delivery_manual_address.address.country_code',
  DELIVERY_INSTRUCTIONS = 'delivery.details.instructions',
  DELIVERY_FLOOR_LEVEL = 'delivery.details.floor_level',
  ITEM_SETS = 'itemSets',
  WEIGHT = 'itemSets[0].items[0].weight', // We safe it at first item of item set, but it is a separate field
  DATE = 'date',
  EXTRA_DRIVER = 'extra_driver',
  ASSEMBLY = 'assembly',
}

export enum FloorLevelOptions {
  ELEVATOR = 'elevator',
  STAIRS = 'stairs',
}

export enum BusinessAttributes {
  // NOTE: this atribute key gets ignored in the driver dashboard
  // When the ORDER_TYPE value changes, please be aware to adjust that in the driver dashboard too.
  ORDER_TYPE = 'business_order_type',
}

export enum BusinessAttributesValues {
  ORDER_TYPE_NORMAL = 'normal',
  ORDER_TYPE_RETURN = 'return',
}

export enum QuoteRejectedReasonsBusiness {
  INTERNATIONAL = 'invalid_business_request.international',
  VOLUME = 'invalid_business_request.exceeding_max_volume',
  TOO_FAR = 'invalid_business_request.too_far',
  GEO_FAILED = 'invalid_business_request.geo_failed',
  ITEM_TOO_LARGE = 'item.too_large',
  ITEM_ONE_OVER_200KG = 'item.one_over_200kg',
  ITEM_TOGETHER_OVER_200KG = 'item.together_over_200kg',
  AREA_OUTSIDE_GUARANTEED = 'area.outside_guaranteed',
  PICKUP_TOO_MANY_ITEM = 'pickup.too_many_items_for_floor_level',
  DELIVERY_TOO_MANY_ITEM = 'delivery.too_many_items_for_floor_level',
}

export const BlockingNonGuaranteedReasons = [
  QuoteRejectedReasonsBusiness.ITEM_TOO_LARGE,
  QuoteRejectedReasonsBusiness.ITEM_ONE_OVER_200KG,
  QuoteRejectedReasonsBusiness.ITEM_TOGETHER_OVER_200KG,
  QuoteRejectedReasonsBusiness.AREA_OUTSIDE_GUARANTEED,
  QuoteRejectedReasonsBusiness.PICKUP_TOO_MANY_ITEM,
  QuoteRejectedReasonsBusiness.DELIVERY_TOO_MANY_ITEM,
];

// interfaces
export interface BusinessState {
  business_name: null;
  has_business_flow: boolean;
  depots: {
    loading: boolean;
    options: DepotAddress[];
  };
  popular_items: PopularItem[];
  price_list: null | PriceList;
  transport_request: {
    uuid: string;
    price: {
      rejected_reason: QuoteRejectedReasonsBusiness | null;
      loading: boolean;
      vat: PriceDataPart;
      incl_vat: PriceDataPart;
      excl_vat: PriceDataPart;
    };
    date_time_periods: {
      options: DatetimePeriodOption[];
      startDate: null | string;
    };
    layout: {
      sheet: boolean;
      step: number;
    };
  };
}

export interface DateSelectOption {
  value: string;
  title: string;
}

export interface SetDateTimePeriodStartFromAction {
  type: string;
  payload: {
    date: string;
    extendDateOptions: boolean;
  };
}

export interface GetNextDateTimePeriodOptionsAction {
  type: string;
}

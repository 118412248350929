import { CoordsPrecisionLevel, CountryCode } from '@brenger/api-client';
import classNames from 'classnames';
import React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { connect } from 'react-redux';
import { Field, getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import {
  Button,
  Col,
  FlashMessage,
  Heading,
  IconMarker,
  IconSmile,
  RfInput,
  RfInputFileUploads,
  RfInputHidden,
  RfInputTextToggle,
  Row,
  RfSelect,
  SheetLayoutContent,
  SheetLayoutContentWrapper,
  SheetLayoutHeader,
  RfTextarea,
} from '../../../brenger-shared-ui';
import { RootState } from '../../../typings';
import { translate } from '../../../utils/localization';
import { User } from '../../GeneralFlow/interface';
import { PersonSchema } from '../../GeneralFlow/schema';
import { DeliveryAddressFields } from '../components/DeliveryAddressFields';
import { FloorElevatorFields } from '../components/FloorElevatorFields';
import { actions, getIsReturnTransport } from '../ducks';
import { BusinessFields, BusinessForms } from '../interface';
import FooterLayout from './FooterLayout';

export type BusinessDeliveryAddress = {
  address?: {
    administrative_area?: string;
    country_code?: CountryCode;
    country_name?: string;
    lat?: number | string; // it is a string when comming from manual fields unfortunately
    line1?: string;
    locality?: string;
    lng?: number | string; // it is a string when comming from manual fields unfortunately
    postal_code?: string;
    secondary_subdivision?: string;
  };
  // Only available on manual address completion
  precision?: CoordsPrecisionLevel;
};

export interface BusinessDestinationFormValues {
  [BusinessFields.RETURN_ORDER]: boolean;
  [BusinessFields.DEPOT_SELECT]: number;
  [BusinessFields.DEPOT_INSTRUCTIONS]: string;
  [BusinessFields.DISPLAY_NAME]: string;
  [BusinessFields.DEPOT_CONTACT_FIRST_NAME]?: string;
  [BusinessFields.DEPOT_CONTACT_LAST_NAME]?: string;
  [BusinessFields.DEPOT_CONTACT_EMAIL]?: string;
  [BusinessFields.DEPOT_CONTACT_PHONE]?: string;
  /**
   * Delivery address
   * - Is handled by autocomplete, but could be switched to manual
   * - Full Address (from geo) is available in happy flow, non happy flow means manual input
   * - When nothing is selected after a input change the value becomes null or string
   * - Manual select means every field is optional, but that will be enforced with validation
   */
  [BusinessFields.DELIVERY_ADDRESS_IS_MANUAL]: boolean;
  [BusinessFields.DELIVERY_AUTO_ADDRESS]?: BusinessDeliveryAddress | string | null;
  delivery_manual_address?: BusinessDeliveryAddress;
  delivery?: {
    contact?: {
      first_name?: string;
      last_name?: string;
      email?: string;
      phone?: string;
    };
    details?: {
      instructions?: string;
      floor_level?: number;
    };
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [BusinessFields.INVOICE]?: any; // TODO needs better interface
  [BusinessFields.CLIENT_REFERENCE]?: string;
}

export const getBusinessDestinationFormValues = (state: RootState): undefined | BusinessDestinationFormValues => {
  return getFormValues(BusinessForms.DESTINATION)(state) as undefined | BusinessDestinationFormValues;
};

type ReduxProps = ReturnType<typeof mapStateToProps>;

type Form = InjectedFormProps<BusinessDestinationFormValues, ReduxProps>;

type Props = ReduxProps & Form;

const Destination: React.FC<Props> = props => {
  const depots = props.depots.options;
  const [editContact, setEditContact] = React.useState(false);
  return (
    <form onSubmit={props.handleSubmit}>
      <SheetLayoutContentWrapper>
        <SheetLayoutHeader>
          <Heading size={2}>
            <Translate id={'request_flow.business.step_1.title'} />
          </Heading>
        </SheetLayoutHeader>

        <SheetLayoutContent>
          <Row>
            <Col xs={12}>
              <Heading size={5} extraClasses={'no-margin pb-0-25'}>
                <Translate id={'request_flow.business.order_header' + (!props.isReturnOrder ? '_normal' : '_return')} />
              </Heading>
              <Field
                component={RfInputTextToggle}
                label={String(
                  translate('request_flow.business.toggle_order_type' + (!props.isReturnOrder ? '_normal' : '_return'))
                )}
                name={BusinessFields.RETURN_ORDER}
              />
            </Col>
          </Row>
        </SheetLayoutContent>
        <SheetLayoutContent
          className={classNames('order-wrapper', { 'order-wrapper--is-return': props.isReturnOrder })}
          style={{ zIndex: 6 }}
        >
          <Row>
            <Col xs={12} extraClasses={'pt-0-5 pb-0-5'}>
              <b>
                <Translate id={`request_flow.contact.${!props.isReturnOrder ? 'pickup' : 'delivery'}_address`} />
              </b>
            </Col>
            <Col xs={12}>
              {props.depots.loading ? (
                <IconSmile spinning={true} />
              ) : (
                <>
                  {depots.length === 0 && (
                    <FlashMessage type={'error'} message={translate('request_flow.business.errors.address')} />
                  )}
                  {depots.length === 1 && (
                    <div className="pb-1">
                      <FlashMessage type={'info-light'}>
                        <IconMarker /> <span style={{ paddingLeft: '10px' }} />
                        {depots[0].address.line1}, {depots[0].address.postal_code}, {depots[0].address.locality}
                      </FlashMessage>
                    </div>
                  )}
                  {depots.length > 1 && (
                    <Field
                      name={BusinessFields.DEPOT_SELECT}
                      component={RfSelect}
                      emptyFirstOption={false}
                      options={depots.map((depot, index) => {
                        return {
                          value: index,
                          label: `${depot.address.line1}, ${depot.address.postal_code}, ${depot.address.locality}`,
                        };
                      })}
                      label={translate('request_flow.business.choose_from_different_depots')}
                    />
                  )}
                </>
              )}
              <Field
                component={RfTextarea}
                type="text"
                name={BusinessFields.DEPOT_INSTRUCTIONS}
                label={translate('request_flow.business.fields.extra_information.depot_description')}
                autoCompleteOff={true}
              />
            </Col>
            <Col xs={12} extraClasses={'pb-1 text--right'}>
              <Button type={'button'} size={'sm'} onClick={() => setEditContact(!editContact)}>
                <Translate id={'request_flow.business.edit_depot_contact' + (editContact ? '_hide' : '')} />
              </Button>
            </Col>
            <Col xs={12} sm={6}>
              <Field
                validate={editContact ? PersonSchema[User.FIRST_NAME] : undefined}
                name={BusinessFields.DEPOT_CONTACT_FIRST_NAME}
                type="text"
                component={editContact ? RfInput : RfInputHidden}
                label={translate('form.fields.user.first_name.label')}
              />
            </Col>
            <Col xs={12} sm={6}>
              <Field
                validate={editContact ? PersonSchema[User.LAST_NAME] : undefined}
                name={BusinessFields.DEPOT_CONTACT_LAST_NAME}
                type="text"
                component={editContact ? RfInput : RfInputHidden}
                label={translate('form.fields.user.last_name.label')}
              />
            </Col>
            <Col xs={12} sm={6}>
              <Field
                validate={editContact ? PersonSchema[User.EMAIL] : undefined}
                name={BusinessFields.DEPOT_CONTACT_EMAIL}
                type="email"
                component={editContact ? RfInput : RfInputHidden}
                label={translate('form.fields.user.email.label')}
              />
            </Col>
            <Col xs={12} sm={6}>
              <Field
                validate={editContact ? PersonSchema[User.PHONE] : undefined}
                name={BusinessFields.DEPOT_CONTACT_PHONE}
                type="tel"
                component={editContact ? RfInput : RfInputHidden}
                label={translate('form.fields.user.phone.label')}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} extraClasses={'pt-0-5 pb-0-5'}>
              <b>
                <Translate id={`request_flow.contact.${!props.isReturnOrder ? 'delivery' : 'pickup'}_address`} />
              </b>
            </Col>
            <Col xs={12} sm={6}>
              <Field
                validate={PersonSchema[User.FIRST_NAME]}
                name={BusinessFields.DELIVERY_FIRST_NAME}
                type="text"
                component={RfInput}
                label={translate('form.fields.user.first_name.label')}
              />
            </Col>
            <Col xs={12} sm={6}>
              <Field
                validate={PersonSchema[User.LAST_NAME]}
                name={BusinessFields.DELIVERY_LAST_NAME}
                type="text"
                component={RfInput}
                label={translate('form.fields.user.last_name.label')}
              />
            </Col>
            <Col xs={12} sm={6}>
              <Field
                validate={PersonSchema[User.EMAIL]}
                name={BusinessFields.DELIVERY_EMAIL}
                type="email"
                component={RfInput}
                label={translate('form.fields.user.email.label')}
              />
            </Col>
            <Col xs={12} sm={6}>
              <Field
                validate={PersonSchema[User.PHONE]}
                name={BusinessFields.DELIVERY_PHONE}
                type="tel"
                component={RfInput}
                label={translate('form.fields.user.phone.label')}
              />
            </Col>
            <DeliveryAddressFields
              isManual={Boolean(props.formValues?.delivery_address_is_manual_address)}
              change={props.change}
            />
            <FloorElevatorFields />
          </Row>
        </SheetLayoutContent>
        <SheetLayoutContent>
          <Row>
            <Col xs={12} extraClasses={'pb-0-5'}>
              <b>
                <Translate id={`request_flow.business.optional_heading`} />
              </b>
            </Col>

            <Col xs={6}>
              <Field
                component={RfInput}
                type="text"
                name={BusinessFields.CLIENT_REFERENCE}
                label={translate('request_flow.business.fields.client_reference.label')}
              />
            </Col>
            <Col xs={6}>
              <Field
                name={BusinessFields.DISPLAY_NAME}
                type="text"
                component={RfInput}
                label={translate('form.fields.company.display_name.label')}
              />
            </Col>
            <Col xs={6}>
              <RfInputFileUploads
                fieldName={BusinessFields.INVOICE}
                formName={BusinessForms.DESTINATION}
                accept={'application/pdf,image/*'}
                texts={{
                  button: {
                    default: String(translate('request_flow.pickup.invoice.button.default')),
                    add: String(translate('request_flow.pickup.invoice.button.add')),
                    delete: String(translate('request_flow.pickup.invoice.button.delete')),
                    change: String(translate('request_flow.pickup.invoice.button.change')),
                  },
                  header: String(translate('request_flow.business.invoice_header')),
                }}
                headerShowAfterUpload={true}
              />
            </Col>
          </Row>
        </SheetLayoutContent>
        <FooterLayout submitForm={props.handleSubmit} />
      </SheetLayoutContentWrapper>
    </form>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState) => ({
  isReturnOrder: getIsReturnTransport(state),
  depots: state.business.depots,
  formValues: getBusinessDestinationFormValues(state),
});

export default reduxForm<BusinessDestinationFormValues, Props>({
  form: BusinessForms.DESTINATION,
  destroyOnUnmount: false,
  initialValues: {
    [BusinessFields.DEPOT_SELECT]: 0,
    [BusinessFields.DELIVERY_ADDRESS_IS_MANUAL]: false,
    delivery_manual_address: {
      address: {
        country_code: 'NL',
      },
    },
  },
  onSubmit: (details, dispatch) => {
    dispatch(actions.submitDestination(details));
  },
})(connect(mapStateToProps)(Destination));

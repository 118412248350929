/* eslint-disable @typescript-eslint/naming-convention */
import { ProductSelectionResult } from '@brenger/api-client';
import { IconSearch, useDebounce } from '@brenger/react';
import axios, { CancelTokenSource } from 'axios';
import cn from 'classnames';
import React from 'react';

import { Translate } from 'react-localize-redux-dep-updated';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import '../../../../assets/product-selection/search.scss';
import { IconSmile, Label } from '../../../../brenger-shared-ui';
import { useTypedSelector } from '../../../../hooks';
import { CacheKey } from '../../../../typings';
import { logError } from '../../../../utils/basics';
import { getActiveLanguageSettings } from '../../../../utils/localization';
import { priceClient } from '../../../../utils/request';
import { getWhatIsMyCountry } from '../../../User/ducks';
import { actions } from '../../ducks';
import { MANUAL_IRI } from './ItemConfig';
import { SearchListItemWrapper } from './SearchListItemWrapper';
import { trackEvent } from '../../../../utils/eventTracking';
import { r } from '../../../../routes';

export const SearchList: React.FC = () => {
  const capTotalElements = 7; // Number of elements we can see in the list for the first time without loading more elements
  const history = useHistory();
  const dispatch = useDispatch();
  const [showAll, setShowAll] = React.useState(false);
  const queryVal = useTypedSelector(state => state.generalTransport.product_selection.query);
  const dbQueryVal = useDebounce(queryVal || '', 400);

  /**
   * To keep track of search attempts, we store attempts and the query in a ref
   */
  const attemptsRef = React.useRef(1);
  const prevDbQueryValRef = React.useRef(dbQueryVal);
  React.useEffect(() => {
    const prevDbQueryVal = prevDbQueryValRef.current;
    const attempts = attemptsRef.current;
    let isAttempt = false;

    if (dbQueryVal.indexOf(prevDbQueryVal) === -1) {
      // User started over
      isAttempt = true;
    } else if (prevDbQueryVal.length > dbQueryVal.length + 2) {
      // User backspaced more then 3 characters
      isAttempt = true;
    }
    if (isAttempt) {
      attemptsRef.current = attempts + 1;
    }
    prevDbQueryValRef.current = dbQueryVal;
  }, [dbQueryVal]);

  const queryValMin = dbQueryVal.length > 2;
  let locale: string = getActiveLanguageSettings().labels.full.replace('-', '_');
  const countryByIp = useTypedSelector(getWhatIsMyCountry);
  // At pricing we support flemish as lang
  // Search results are really important and will match better with for some items
  if (locale === 'nl_NL' && countryByIp === 'BE') {
    locale = 'nl_BE';
  }

  const { data: results, isLoading } = useQuery([CacheKey.RETRIEVE_PS_SEARCH, dbQueryVal], () => search(dbQueryVal));

  const hasResults = Boolean(results?.length);

  React.useEffect(() => {
    if (queryValMin && !hasResults) {
      trackEvent({
        event: 'productSelection',
        action: 'zero-results',
        query: dbQueryVal,
        id: '',
        listLength: 0,
        listPosition: 0,
        attempts: attemptsRef.current,
      });
    }
  }, [queryValMin, hasResults]);

  const search = async (query: string): Promise<(ProductSelectionResult & { label: string })[]> => {
    setShowAll(false);
    if (!dbQueryVal || !queryValMin) {
      return [];
    }
    try {
      /**
       * GET RESULTS
       */
      const groups = await productSelectionSearch(query);
      // Enrich results with an html label
      const groupsWithLabels = groups.map(group => {
        // Start of with the group name
        let label = group.name[locale];
        if (label.toLowerCase() === query.toLowerCase()) {
          label = `<span class="text--bold">${label}</span>`;
        } else {
          const matches: { replace: string; match: string }[] = [];
          // We split the query on spaces, and will try to find and replace every part separately
          dbQueryVal.split(' ').forEach(queryPart => {
            // setup regex
            const re = new RegExp(queryPart, 'i');
            const match = label.match(re)?.[0];
            if (match) {
              // find and replace the partial, build the label
              matches.push(match);
            }
          });
          matches.forEach(m => {
            label = label.replace(m, `<span class="text--bold">${m}</span>`);
          });
        }
        return {
          ...group,
          label,
        };
      });
      return groupsWithLabels;
    } catch (e) {
      logError(e);
      return [];
    }
  };

  if (isLoading) {
    return (
      <div className="ps-modal--section">
        <IconSmile spinning={true} />
      </div>
    );
  }

  const selectResult = (result: Partial<ProductSelectionResult>): void => {
    dispatch(actions.setSearchResultPs(result));
    history.push(r.generalFlow.items.configure());
  };

  const visibleResults = (showAll ? results : results?.slice(0, capTotalElements)) || [];
  return (
    <div className="ps-search">
      {queryValMin && hasResults && (
        <>
          {visibleResults.map((result, i) => {
            const eventData = {
              listPosition: i + 1,
              listLength: visibleResults.length,
              query: dbQueryVal,
              id: result.iri,
            };
            return (
              <SearchListItemWrapper key={i} {...eventData}>
                <div
                  className={cn('flex flex--vc ps-modal--section bp trigger', {
                    bordered: results?.length || 0 - 1 !== i,
                  })}
                  data-qa-id={`ps-search-result-${i}`}
                  key={i}
                  onClick={() => {
                    selectResult(result);
                    // TODO Let's do it like this for now but Paul will refactor it
                    trackEvent({
                      event: 'productSelection',
                      action: 'click',
                      attempts: attemptsRef.current,
                      ...eventData,
                    });
                  }}
                >
                  <div
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    dangerouslySetInnerHTML={{ __html: result.label }}
                  />
                  {result.detected_material?.map(material => {
                    return (
                      <Label type="black-outline" style={{ margin: '0 0 0 .5em' }} key={material.id}>
                        {material.label?.[locale]}
                      </Label>
                    );
                  })}
                </div>
              </SearchListItemWrapper>
            );
          })}
          {!showAll && (results || []).length > capTotalElements && (
            <div
              className="ps-search--load-more bp bordered ps-modal--section trigger text--primary"
              onClick={() => setShowAll(true)}
            >
              <Translate id={'request_flow.product_selection.load_more'} />
            </div>
          )}
          <div
            className={cn('ps-search--add-manual ps-modal--section bp trigger', {
              'border-top': showAll || results?.length || 0 < capTotalElements,
            })}
            onClick={() => {
              trackEvent({
                event: 'productSelection',
                action: 'manual',
                query: dbQueryVal,
                id: MANUAL_IRI,
                listLength: visibleResults.length,
                listPosition: 0,
                attempts: attemptsRef.current,
              });
              selectResult({ iri: MANUAL_IRI });
            }}
          >
            <Translate id={'request_flow.product_selection.search_manual_item'} />{' '}
            <IconSmile smileDirection={'right'} />
          </div>
        </>
      )}
      {queryValMin && !hasResults && (
        <>
          <div className="ps-modal--section bordered bp trigger">
            <div className="pb-0-5 text--bold">
              <Translate id={'request_flow.product_selection.search_zero_results'} data={{ searchQuery: queryVal }} />
            </div>
            <i>
              <Translate id={'request_flow.product_selection.search_hint'} />
            </i>
          </div>
          <div
            className="ps-search--add-manual ps-modal--section bordered bp trigger"
            onClick={() => {
              trackEvent({
                event: 'productSelection',
                action: 'manual-zero-results',
                query: dbQueryVal,
                id: MANUAL_IRI,
                listLength: 0,
                listPosition: 0,
                attempts: attemptsRef.current,
              });
              selectResult({ iri: MANUAL_IRI });
            }}
          >
            <Translate id={'request_flow.product_selection.search_zero_results_manual'} />{' '}
            <IconSmile smileDirection={'right'} />
          </div>
        </>
      )}
      {!queryValMin && (
        <>
          <div className="ps-modal--section text--bold" style={{ paddingBottom: 0 }}>
            <Translate
              id={'request_flow.product_selection.popular_searches_count'}
              data={{ count: popularItems.length }}
            />
          </div>
          {popularItems.map((item, i) => {
            return (
              <div
                className="ps-modal--section bordered bp trigger"
                key={item.iri}
                onClick={() => {
                  trackEvent({
                    event: 'productSelection',
                    action: 'popular',
                    query: '',
                    id: item.iri,
                    listLength: popularItems.length,
                    listPosition: i + 1,
                    attempts: attemptsRef.current,
                  });
                  selectResult(item);
                }}
              >
                <IconSearch
                  style={{
                    color: '#7C90A8',
                    marginRight: '.5em',
                    marginBottom: '-0.1em',
                  }}
                />
                {item.name[locale]}
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

let source: CancelTokenSource | undefined = undefined;

const productSelectionSearch = (query: string): Promise<ProductSelectionResult[]> => {
  if (source !== undefined) source.cancel();
  source = axios.CancelToken.source();
  return priceClient.productSelection.search({ query, cancelToken: source.token });
};

// Below translations are copied from pricing, not ideal, but ok for now
const popularItems: ProductSelectionResult[] = [
  {
    id: 'Sofa|3-seater sofa',
    iri: 'ProductGroup#Couches|Sofa|3-seater sofa',
    name: {
      de_DE: 'Dreisitzer-Sofa',
      en_NL: '3-seater sofa',
      nl_BE: '3-zits zetel',
      nl_NL: '3-zits bank',
    },
    detected_material: [],
    property_identifier: null,
  },
  {
    detected_material: [],
    id: 'Bikes|Bike',
    iri: 'ProductGroup#Bikes|Bike',
    name: {
      de_DE: 'Fahrrad',
      en_NL: 'Bike',
      nl_BE: 'Fiets',
      nl_NL: 'Fiets',
    },
    property_identifier: 'Bike',
  },
  {
    detected_material: [],
    id: 'Closets|Dresser',
    iri: 'ProductGroup#Closets|Dresser',
    name: {
      de_DE: 'Kommode',
      en_NL: 'Dresser',
      nl_BE: 'Dressoir',
      nl_NL: 'Dressoir',
    },
    property_identifier: 'Low dresser',
  },
  {
    detected_material: [],
    id: 'Closets|Wardrobe|Double wardrobe',
    iri: 'ProductGroup#Closets|Wardrobe|Double wardrobe',
    name: {
      de_DE: 'Doppelter Kleiderschrank',
      en_NL: 'Double wardrobe',
      nl_BE: 'Kledingkast (2 deuren)',
      nl_NL: 'Kledingkast (2 deuren)',
    },
    property_identifier: 'Double wardrobe',
  },
  {
    detected_material: [],
    id: 'Heavy Household Appliances|Washing machine',
    iri: 'ProductGroup#Heavy Household Appliances|Washing machine',
    name: {
      de_DE: 'Waschmaschine',
      en_NL: 'Washing machine',
      nl_BE: 'Wasmachine',
      nl_NL: 'Wasmachine',
    },
    property_identifier: 'Washing machine',
  },
  {
    detected_material: [],
    id: 'Tables|Dining table|4 person dining table',
    iri: 'ProductGroup#Tables|Dining table|4 person dining table',
    name: {
      de_DE: 'Esstisch 4 Sitzer',
      en_NL: '4 person dining table',
      nl_BE: '4 persoons eettafel',
      nl_NL: '4 persoons eettafel',
    },
    property_identifier: '4 person dining table',
  },
  {
    detected_material: [],
    id: 'Closets|Buffet cabinet',
    iri: 'ProductGroup#Closets|Buffet cabinet',
    name: {
      de_DE: 'Buffet-Schrank',
      en_NL: 'Buffet cabinet',
      nl_BE: 'Buffetkast',
      nl_NL: 'Buffetkast',
    },
    property_identifier: 'Buffet cabinet',
  },
  {
    detected_material: [],
    id: 'Chairs|Armchair',
    iri: 'ProductGroup#Chairs|Armchair',
    name: {
      de_DE: 'Fauteuil',
      en_NL: 'Armchair',
      nl_BE: 'Fauteuil',
      nl_NL: 'Fauteuil',
    },
    property_identifier: 'Armchair',
  },
];

import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';
import { Dispatch } from 'redux';
import { getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import { SheetLayoutContentWrapper } from '../../../brenger-shared-ui';
import { RootState, StopType } from '../../../typings';
import { SubStepTime } from '../components/SubStepTime';
import { actions as flowActions } from '../ducks';
import { GeneralFlowForms } from '../interface';
import FooterLayout from './FooterLayout';
import { r } from '../../../routes';

export interface TimeFormValues {
  [StopType.PICKUP]?: {
    start?: string;
    end?: string;
  };
  [StopType.DELIVERY]?: {
    start?: string;
    end?: string;
  };
}

export const getTimeFormValues = (state: RootState): TimeFormValues | undefined => {
  return getFormValues(GeneralFlowForms.TIME)(state);
};

type ReduxProps = ReturnType<typeof mapDispatchToProps>;

type Form = InjectedFormProps<TimeFormValues, ReduxProps>;

type Props = ReduxProps & Form;

const TimeContainer: React.FC<Props> = props => {
  React.useEffect(() => {
    props.setProgress(4);
  }, []);

  return (
    <form onSubmit={props.handleSubmit}>
      <SheetLayoutContentWrapper>
        <Route exact={true} path={r.generalFlow.time.index()} component={SubStepTime} />
        <Route exact={true} path={r.generalFlow.time.delivery()} component={SubStepTime} />
        <FooterLayout />
      </SheetLayoutContentWrapper>
    </form>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: Dispatch) => ({
  setProgress: (step: number) => dispatch(flowActions.setProgress(step)),
});

export default reduxForm<TimeFormValues, Props>({
  form: GeneralFlowForms.TIME,
  destroyOnUnmount: false,
  onSubmit: (formResults: TimeFormValues, dispatch: Dispatch) => {
    dispatch(flowActions.submitForm(GeneralFlowForms.TIME, formResults));
  },
})(connect(null, mapDispatchToProps)(TimeContainer));

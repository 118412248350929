import React from 'react';
import mpBanner from './mp-banner-@1.5x.png';
import './banner.scss';
import { Translate } from 'react-localize-redux-dep-updated';
import { translate } from '../../../../utils/localization';

export const MarktPlaatsBanner: React.FC = () => {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={translate('request_flow.banner.marktplaats.url')}
      className="flex bg--primary text--white delay-index-1 input-el--tile--animation mp-banner"
    >
      <div>
        <img src={mpBanner} />
      </div>
      <div className="mp-banner--content">
        <h5>
          <Translate id="request_flow.banner.marktplaats.text" />
        </h5>
        <label className="trigger">
          <Translate id="request_flow.banner.marktplaats.button" />
        </label>
      </div>
    </a>
  );
};

import { Account } from '@brenger/api-client';
import { getLocation, replace } from 'connected-react-router';
import React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { connect, useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { Field, InjectedFormProps, getFormValues, reduxForm } from 'redux-form';
import {
  Col,
  FlashMessage,
  IconInfo,
  RfInput,
  Row,
  SheetLayoutContent,
  SheetLayoutContentWrapper,
} from '../../../brenger-shared-ui';
import { useTypedSelector } from '../../../hooks';
import { RootState, StopType } from '../../../typings';
import { getActiveLanguageSettings, helpTrans, translate } from '../../../utils/localization';
import { priceAsString } from '../../../utils/price';
import { required } from '../../../utils/validation';
import { BlockedNotGuaranteedReasonList } from '../components/BlockedNotGuaranteedReasonList';
import {
  actions,
  actions as flowActions,
  getAddressTextLabel,
  getBlockedTransportRequestReasons,
  getPriceAmount,
  getProductPaymentAmount,
  getStopHelp,
  isGuaranteed,
  validateSameAddress,
} from '../ducks';
import { GeneralFlowForms } from '../interface';
import FooterLayout from './FooterLayout';
type TermsFormValues = {
  conditions?: {
    newsletter_conditions?: boolean;
    general_conditions?: boolean;
    privacy_statement?: boolean;
  };
};

type ReduxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

type Form = InjectedFormProps<TermsFormValues, ReduxProps>;

type Props = ReduxProps & Form;

export const getTermsFormValues = (state: RootState): null | TermsFormValues => {
  return getFormValues(GeneralFlowForms.TERMS)(state);
};

export const Schema = {
  conditions: [value => required(value, translate('request_flow.payment.agree_terms_required'))],
  equipment: [value => required(value, translate('form.fields.default.required_simple'))],
};

const Terms: React.FC<Props> = props => {
  const dispatch = useDispatch();
  const isSameAddress = useTypedSelector(validateSameAddress);
  const location = useTypedSelector(getLocation);
  const [showCancelled, setShowCancelled] = React.useState(false);
  React.useEffect(() => {
    props.setProgress(7);
    if (location.search.includes('payment_status=canceled')) {
      setShowCancelled(true);
      dispatch(replace(location.pathname));
    }
  }, []);

  React.useEffect(() => {
    dispatch(actions.setTrValidationError(isSameAddress ? 'same_address_for_pickup_delivery' : null));
  }, [isSameAddress]);

  const isBlocked = props.blockedTransportReasons.length > 0;
  return (
    <SheetLayoutContentWrapper>
      <form onSubmit={props.handleSubmit}>
        <SheetLayoutContent>
          {showCancelled && (
            <div className={'pt-1'}>
              <FlashMessage type={'error'}>
                {helpTrans.asComponent({ id: 'request_flow.payment_canceled.flow_message' })}
              </FlashMessage>
            </div>
          )}
          {!props.isGuaranteed && !isBlocked && (
            <div className={'pb-1 pt-1'}>
              <Translate id={'request_flow.not_guaranteed.general_message'} />
            </div>
          )}
          {isBlocked && <BlockedNotGuaranteedReasonList reasons={props.blockedTransportReasons} />}
          {props.disabledReason && (
            <div className="pb-0-5">
              <FlashMessage type={'error'}>{helpTrans.asComponent({ id: props.disabledReason })}</FlashMessage>
            </div>
          )}
          <div className={'pt-1 hide--lg-up'}>
            <div style={{ maxWidth: '300px' }}>
              <table className={'price-table'}>
                <tbody>
                  <tr>
                    <td>
                      <Translate id={'request_flow.price_table.transport'} />{' '}
                      <IconInfo color={'blue'} onClick={() => props.toggleSheet()} />
                    </td>
                    <td>
                      {priceAsString({
                        price: { amount: props.transportPrice },
                        addPlusMinusIndicator: false,
                        withDoubleZeroes: true,
                      })}
                    </td>
                  </tr>
                  {props.productPaymentAmount && (
                    <tr>
                      <td>
                        <Translate id={'request_flow.price_table.product'} />
                      </td>
                      <td>
                        {priceAsString({
                          price: { amount: props.productPaymentAmount },
                          addPlusMinusIndicator: false,
                          withDoubleZeroes: true,
                        })}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>
                      <Translate id={'request_flow.price_table.total'} />
                    </td>
                    <td>
                      {priceAsString({
                        price: { amount: props.transportPrice + (props.productPaymentAmount || 0) },
                        addPlusMinusIndicator: false,
                        withDoubleZeroes: true,
                      })}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <Row extraClasses={'order--agree-terms'}>
            <Col xs={12} extraClasses={'pt-1'}>
              <Field
                name={'conditions.newsletter_conditions'}
                type={'checkbox'}
                label={<Translate id={'request_flow.contact.newsletter'} />}
                component={RfInput}
              />
              <Field
                name={'conditions.general_conditions'}
                type={'checkbox'}
                validate={Schema['conditions']}
                label={translate('request_flow.delivery.agree_general_terms', {
                  condition_link: (
                    <a
                      href={`https://www.brenger.nl/${getActiveLanguageSettings().labels.contentSite}/terms-customer`}
                      target={'_blank'}
                      rel="noreferrer"
                    >
                      <Translate id={'request_flow.delivery.general_terms'} />
                    </a>
                  ),
                })}
                component={RfInput}
              />
              <Field
                name={'conditions.privacy_statement'}
                type={'checkbox'}
                validate={Schema['conditions']}
                label={
                  <>
                    <Translate
                      id={'request_flow.delivery.agree_privacy_statement'}
                      data={{
                        privacy_link: (
                          <a
                            href={`https://www.brenger.nl/${
                              getActiveLanguageSettings().labels.contentSite
                            }/terms-privacy`}
                            target={'_blank'}
                            rel="noreferrer"
                          >
                            <Translate id={'request_flow.delivery.privacy_statement'} />
                          </a>
                        ),
                      }}
                    />
                    <br />
                    <small style={{ paddingTop: '1em' }}>
                      <Translate
                        id={'request_flow.delivery.agree_transport_condition'}
                        data={{
                          condition_link: (
                            <a
                              href={`https://www.brenger.nl/${
                                getActiveLanguageSettings().labels.contentSite
                              }/terms-transport-agreement`}
                              target={'_blank'}
                              rel="noreferrer"
                            >
                              <Translate id={'request_flow.delivery.transport_condition'} />
                            </a>
                          ),
                        }}
                      />
                    </small>
                  </>
                }
                component={RfInput}
              />
              {props.isEquipmentProvided && (
                <Field
                  name={'conditions.equipment'}
                  type={'checkbox'}
                  validate={Schema.equipment}
                  label={translate(`request_flow.terms.customer_equipment.label_both`)}
                  component={RfInput}
                />
              )}
            </Col>
          </Row>
          <div className={'general-errors-wrapper'}>
            {props.generalErrors && props.generalErrors !== '' && (
              <FlashMessage type={'error'} message={props.generalErrors} />
            )}
          </div>
        </SheetLayoutContent>
        <FooterLayout />
      </form>
    </SheetLayoutContentWrapper>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState) => ({
  transport: state.generalTransport,
  pickupAsText: getAddressTextLabel(state, StopType.PICKUP),
  generalErrors: state.generalTransport.error,
  isGuaranteed: isGuaranteed(state),
  blockedTransportReasons: getBlockedTransportRequestReasons(state),
  productPaymentAmount: getProductPaymentAmount(state),
  transportPrice: getPriceAmount(state),
  isEquipmentProvided: getStopHelp(state) === 'equipment_provided_by_customer',
  disabledReason: (state.user?.userData?.account as Account | undefined)?.disable_submitting_trs_reason,
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: Dispatch) => ({
  setProgress: step => dispatch(flowActions.setProgress(step)),
  toggleSheet: () => dispatch(flowActions.toggleSheet()),
});

export default reduxForm<TermsFormValues, Props>({
  form: GeneralFlowForms.TERMS,
  destroyOnUnmount: false,
  onSubmit: (details, dispatch) => {
    dispatch(flowActions.submitForm(GeneralFlowForms.TERMS));
  },
})(connect(mapStateToProps, mapDispatchToProps)(Terms));

import { getLocation } from 'connected-react-router';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ThankYouDataParams, useThankYouData, useTypedSelector } from '../../../hooks';
import { OppFailed } from '../OppFailedPage';
import { FailedRedirect } from './components/FailedRedirect';
import { Loading } from './components/Loading';
import { Success } from './components/Success';

type Props = RouteComponentProps<ThankYouDataParams>;

export const ThankYouPage: React.FC<Props> = props => {
  const { isLoading, error, trId, ...rest } = useThankYouData(props.match.params);
  const location = useTypedSelector(getLocation);
  const paymentStatus = new URLSearchParams(location.search).get('payment_status') as 'canceled' | 'expired' | null;

  if (isLoading) {
    return <Loading />;
  }
  // Opp has retry option for failed payments
  if (rest.type === 'opp' && (error || paymentStatus === 'expired')) {
    return <OppFailed trId={trId} />;
  }
  // In all other error cases we need a redirect
  if (paymentStatus === 'canceled' || paymentStatus === 'expired') {
    return <FailedRedirect id={trId} type={rest.type} />;
  }
  // else great success!
  return <Success trId={trId} {...rest} />;
};

import React from 'react';
import { parseInteger } from '../../../modules/GeneralFlow/schema';
import { InputMeta, InputWrapper } from '.';

type ModifiedInput = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>;

interface InputNumberProps extends ModifiedInput {
  onChange: (value: number | undefined) => void;
  meta?: InputMeta;
}

export const InputNumber: React.FC<InputNumberProps> = ({ onChange, meta, ...unused }) => {
  return (
    <InputWrapper {...meta}>
      <input
        type="number"
        onChange={(event: React.FormEvent<HTMLInputElement>): void => {
          const value = parseInteger(event.currentTarget.value);
          onChange(value ? value : undefined);
        }}
        {...unused}
      />
    </InputWrapper>
  );
};

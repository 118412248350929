import * as Sentry from '@sentry/react';
import { Integration } from '@sentry/types';
import { ExtraErrorData as ExtraErrorDataIntegration } from '@sentry/integrations';
import { Config } from '../config';
import { beforeSendFilter, ignoredErrors } from '../../stand-alone/ts/sentry';

export const initSentry = (): void => {
  if (!['production', 'staging'].includes(Config.NODE_ENV || '')) {
    return;
  }
  Sentry.init({
    environment: Config.NODE_ENV,
    dsn: Config.SENTRY_DSN,
    release: Config.COMMIT_SHA,
    allowUrls: [/(brenger)(-transport)?\.(nl|be|com)/, 'netlify.app'], // Only track exceptions from files that are served from this domain
    integrations: [
      new ExtraErrorDataIntegration({
        // limit of how deep the object serializer should go. Anything deeper than limit will
        // be replaced with standard Node.js REPL notation of [Object], [Array], [Function] or
        // a primitive value. Defaults to 3.
        depth: 4,
      }) as Integration,
    ],
    tracesSampleRate: 1.0, // lower this to consume a percentage of transactions
    normalizeDepth: 7,
    maxValueLength: 5000,
    ignoreErrors: [
      ignoredErrors,
      'hj.hq is not a function',
      'Could not load "onion".',
      'jQuery is not defined',
      // Attempt to ignore freshchat
      'with keys: data, status, success',
      // Error occurs with tag-manager, probably by a client script,
      // Or this is the reason but no solution yet: https://github.com/getsentry/sentry-javascript/issues/9110
      "undefined is not an object (evaluating 'a.L')",
      'Ongeldig argument.', // Error Occurs When Microsoft users Try to Import a Solution into Dynamics 365
      "/Cannot read property '_avast_submit' of undefined/g",
      'parentRef.postMessage is not a function',
      /hj.hq/,
      /fbq/,
      'QuotaExceededError: QuotaExceededError',
      "Argument 1 ('element') to Window.getComputedStyle must be an instance of Element",
      'QuotaExceededError: The quota has been exceeded.',
      /initFreshChat/,
      'geannuleerd',
      'Abgebrochen',
      'cancelled',
      'SecurityError: Blocked a frame with origin "https://www.brenger.nl" from accessing a cross-origin frame',
    ],
    denyUrls: [
      /script\.hotjar\.com/,
      /ct\.pinterest\.com/,
      /wchat\.freshchat\.com/,
      /maps\.googleapis\.com/,
      /\/subscribe_newsletter/,
    ],
    beforeSend: beforeSendFilter,
  });
};

import { GeoAutocompleteAddress } from '@brenger/api-client';
import { push } from 'connected-react-router';
import * as React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { useDispatch } from 'react-redux';
import { store } from '../../../..';
import { Heading, SectionWrapper, SheetLayoutContent, SheetLayoutHeader } from '../../../../brenger-shared-ui';
import { useTypedSelector } from '../../../../hooks';
import { RootState, StopType } from '../../../../typings';
import { logError, logException } from '../../../../utils/basics';
import { retrieveCachedLocationDetails } from '../../../../utils/geo';
import { priceAsString } from '../../../../utils/price';
import FooterLayout from '../../containers/FooterLayout';
import {
  actions,
  getFlowTitleByStepAndSituation,
  getIsDateSelectionNotAvailable,
  getPickupSituation,
  getProductPaymentAmount,
  getProductPaymentOptIn,
  getQuote,
  getStopDestinationDetails,
  isRequestAboveMaxDistance,
  showDutchAddresLayout,
} from '../../ducks';
import { getProceedAction } from '../../proceed';
import { ContactPageError } from './AddressError';
import { AddressFields } from './AddressFields';
import { AddressFieldsDutch } from './AddressFieldsDutch';
import { ContactFields } from './ContactFields';
import { ContactFieldsWithCard } from './ContactFieldsWithContactCard';
import { ContactSuspicionModal } from './ContactSuspicionModal';
import { FloorLevelSuspicionModal } from './FloorLevelSuspicionModal';

interface Props {
  type: StopType;
}

export interface ContactPageAddress {
  line1?: string;
  line2?: string;
  postal_code?: string;
  instructions?: string;
}

export const ContactStop: React.FC<Props> = ({ type }) => {
  const dispatch = useDispatch();
  const titleTransTag = useTypedSelector(state =>
    getFlowTitleByStepAndSituation(state, type === StopType.PICKUP ? 'contactPickup' : 'contactDelivery')
  );
  const [forceShowErrors, setForceShowErrors] = React.useState(false);
  const [contactPageAddress, setContactPageAddress] = React.useState<ContactPageAddress | null>(null);
  const [isAddressLoading, setIsAddressLoading] = React.useState(false);
  const [contactPageError, setContactPageError] = React.useState<ContactPageError>('none');

  const isDutchLayout = useTypedSelector(showDutchAddresLayout);
  const pickupSituation = useTypedSelector(getPickupSituation);
  const showContact = type === StopType.DELIVERY || pickupSituation !== 'auction';
  const widgetAddress = useTypedSelector(state => getStopDestinationDetails(state, type));
  const nextPath = useTypedSelector(getProceedAction);
  /**
   * When Opp we required contact details for the pickup
   */
  const isProductPayment = useTypedSelector(getProductPaymentOptIn);
  const productPaymentAmount = useTypedSelector(getProductPaymentAmount);
  const isOppContact = type === StopType.PICKUP && isProductPayment;
  const contactDetails = useTypedSelector(state => state.generalTransport.contact?.[type]?.contact_details);
  /**
   * House number checks
   */
  const [showFloorSuspicion, setShowFloorSuspicion] = React.useState(false);
  const submit = async (): Promise<void> => {
    // When trying to submit, but it isn't possible yet: force show errors
    setForceShowErrors(true);
    if (contactPageAddress === null || !widgetAddress || (isOppContact && !contactDetails)) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { line1, line2, postal_code, instructions } = contactPageAddress;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { locality, country_code } = widgetAddress;
    // Be sure everything is filled
    if (showFloorSuspicion || !line1 || !postal_code || (isDutchLayout && !line2)) {
      return;
    }
    // Show a loader
    setIsAddressLoading(true);
    // Amend the address
    // We use this function for fetching the "rest" of the address.
    // Rest = lat, lng, municipality & administrative_area
    const address = await ammendAddress([line1, line2].filter(Boolean).join(' '), postal_code, locality, country_code);
    // Remove loader
    setIsAddressLoading(false);
    // We could have failed
    if (!address) {
      // Let Sentry know
      logException('GF Contact Page - Address not found [take 2]', {
        contactPageAddress,
        widgetAddress,
        isDutchLayout,
      });
      // Let the user know
      setContactPageError('address');
      return;
    }
    // SUCCESS: Save all details and proceed
    dispatch(
      actions.setStopAddress({
        stop_type: type,
        address: {
          ...address,
          line1,
          line2,
          postal_code,
          locality,
          country_code,
        },
        instructions,
      })
    );
    // proceed to next path
    if (nextPath?.path) dispatch(push(nextPath.path));
  };

  /**
   * Needed to calculate locality change
   */
  const localityChange = (newAdress: GeoAutocompleteAddress): void => {
    if (!widgetAddress?.locality) {
      return;
    }
    /**
     * We need all this state as a one-off-thing
     * No need for the ui to reflect changes, so no need for a hook
     */
    const state = store.getState() as RootState;
    const quote = getQuote(state);
    const isAboveMaxDistance = isRequestAboveMaxDistance(state);
    const isAboveMaxDateDistance = isRequestAboveMaxDistance(state, true);
    const dateSelectionNotAvailable = getIsDateSelectionNotAvailable(state);
    // Set the locality change
    dispatch(
      actions.setLocalityChange({
        stopType: type,
        locality_old: widgetAddress.locality,
        locality_new: newAdress.locality,
        quote_old: quote,
        above_max_distance_old: isAboveMaxDistance,
        above_max_date_distance_old: isAboveMaxDateDistance,
        date_selection_not_available_old: dateSelectionNotAvailable,
      })
    );
    // Update the widget details
    dispatch(actions.setDestinationDetails(newAdress, type));
  };

  return (
    <>
      <SheetLayoutHeader>
        <Heading size={2}>
          <Translate id={titleTransTag} />
        </Heading>
      </SheetLayoutHeader>
      <SectionWrapper centerContent={false} sectionStyle={'primary-light'} dashed={true}>
        <SheetLayoutContent>
          {isDutchLayout && (
            <AddressFieldsDutch
              forceShowErrors={forceShowErrors}
              type={type}
              widgetAddress={widgetAddress}
              localityChange={localityChange}
              setIsAddressLoading={setIsAddressLoading}
              setContactPageAddress={setContactPageAddress}
              contactPageError={contactPageError}
              setContactPageError={setContactPageError}
            />
          )}
          {!isDutchLayout && (
            <AddressFields
              forceShowErrors={forceShowErrors}
              type={type}
              widgetAddress={widgetAddress}
              setIsAddressLoading={setIsAddressLoading}
              setContactPageAddress={setContactPageAddress}
              contactPageError={contactPageError}
              setContactPageError={setContactPageError}
            />
          )}
          {isOppContact && (
            <>
              <h4 className="no-margin pb-0-25">
                <Translate id={'request_flow.product_payment.bank_details'} />
              </h4>
              <div className="pb-1">
                <Translate
                  id={'request_flow.product_payment.bank_details_explain_with_opp'}
                  data={{
                    amount: priceAsString({
                      price: { amount: productPaymentAmount || undefined },
                      addPlusMinusIndicator: false,
                    }),
                  }}
                />
              </div>
              <ContactFields forceShowErrors={forceShowErrors} withButton={false} withColumns={true} type={type} />
            </>
          )}
          {!isOppContact && showContact && <ContactFieldsWithCard type={type} />}
        </SheetLayoutContent>
      </SectionWrapper>
      <ContactSuspicionModal stopType={type} />
      <FloorLevelSuspicionModal
        stopType={type}
        setShowFloorSuspicion={setShowFloorSuspicion}
        address={contactPageAddress}
      />
      <FooterLayout customSubmitFunction={submit} customIsLoading={isAddressLoading} />
    </>
  );
};

const ammendAddress = async (
  line1: string,
  postal_code: string,
  locality: string,
  country_code: string
): Promise<{ lat: number; lng: number; municipality: string; administrative_area: string } | null> => {
  try {
    if (!line1 || !postal_code || !locality || !country_code) {
      return null;
    }
    const query = `${line1}, ${postal_code} ${locality}`;
    const result = await retrieveCachedLocationDetails(query, line1);
    if (!result) {
      return null;
    }
    const a = result.address;
    return {
      municipality: a.municipality || '',
      administrative_area: a.administrative_area || a.secondary_subdivision,
      lat: a.latitude,
      lng: a.longitude,
    };
  } catch (e) {
    // Lawd have mercy
    logError(e);
    return null;
  }
};

import { ApolloProvider } from '@apollo/client';
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';
import { createBrowserHistory } from 'history';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { LocalizeProvider } from 'react-localize-redux-dep-updated';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import { ErrorBoundary } from './brenger-shared-ui';
import { Config } from './config';
import { configureStore } from './state/store';
import { BASE_PATH } from './utils/global';
import { getRedirectUrl } from './utils/router';
import { initSentry } from './utils/sentry';
import { tbAuctionsClient } from './utils/tbAuctionsQueries';

const history = createBrowserHistory({ basename: BASE_PATH });
export const { store, persistor } = configureStore(history);

initSentry();

if (Config.NODE_ENV === 'development') {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

// We might move this to api.ts - depending on further config
const queryClient = new QueryClient();

const rootContainer = document.getElementById('root-customer');
export const RootDOM = (): JSX.Element => (
  // NOTE: This is a temporary fix to allow the use of the connect function from react-redux
  // Just so I can update dependencies in steps
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  <ReduxProvider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <LocalizeProvider store={store}>
        <ApolloProvider client={tbAuctionsClient}>
          <QueryClientProvider client={queryClient}>
            <ErrorBoundary>
              <App history={history} />
            </ErrorBoundary>
          </QueryClientProvider>
        </ApolloProvider>
      </LocalizeProvider>
    </PersistGate>
  </ReduxProvider>
);
const container =
  rootContainer ||
  document.getElementById('general-request-flow') ||
  document.getElementById('block-createrequestblock-2');
if (container !== null) {
  const root = createRoot(container);
  root.render(<RootDOM />);
}

const redirectUrl = getRedirectUrl(window.location.href);
if (redirectUrl) {
  window.location.href = redirectUrl;
}

// serviceWorker();

import { ProductSelectionGroup, PsLangs } from '@brenger/api-client';
import { useForm } from '@brenger/react';
import cn from 'classnames';
import React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import '../../../../assets/product-selection/itemConfig.scss';
import { Button, Heading, IconCross, InputChips, InputNumber, InputText } from '../../../../brenger-shared-ui';
import { clarity } from '../../../../configs/clarity';
import { useTypedSelector } from '../../../../hooks';
import { SessionStorageKeys } from '../../../../typings';
import { logError } from '../../../../utils/basics';
import { trackEvent } from '../../../../utils/eventTracking';
import { getActiveLanguageSettings, translate } from '../../../../utils/localization';
import { priceClient } from '../../../../utils/request';
import { actions } from '../../ducks';
import { ItemWithPSAndLot } from '../../interface';
import { MANUAL_IRI } from './ItemConfig';
import { ItemCount } from './ItemCount';
import { ItemImage } from './ItemImage';
import { Modal } from './Modal';
import { r } from '../../../../routes';

interface Props {
  productGroup?: ProductSelectionGroup | null;
  /**
   * Only present when used for updating existing item
   */
  existingItem?: ItemWithPSAndLot;
}

export interface PSFormData {
  title: string;
  length: number;
  width: number;
  height: number;
  count: number;
  job_image: string | undefined;
  image_ref: string | undefined;
  weight: number;
  ps_temp: {
    fullTitle: string;
    properties: {
      size_variant: ProductSelectionGroup[];
      size_variant_choice: ProductSelectionGroup | undefined;
      material: ProductSelectionGroup[];
      material_choice: ProductSelectionGroup[];
    };
  };
}

export const ItemForm: React.FC<Props> = ({ productGroup, existingItem }) => {
  const langCode = getActiveLanguageSettings().labels.full.replace('-', '_');
  const history = useHistory();
  const dispatch = useDispatch();
  const searchResult = useTypedSelector(state => state.generalTransport.product_selection.searchResult);

  const isExistingItem = !!existingItem;
  const isManualItem = productGroup?.iri === MANUAL_IRI || existingItem?.ps_temp.isManual;
  const [sizeVariants, setSizeVariants] = React.useState<PSFormData['ps_temp']['properties']['size_variant']>([]);
  const [material, setMaterial] = React.useState<PSFormData['ps_temp']['properties']['material']>([]);
  const [showDimensionCheck, setShowDimensionCheck] = React.useState(false);
  const [isChecked, setChecked] = React.useState(false);
  const [showErrors, setShowErrors] = React.useState(false);

  React.useEffect(() => {
    if (material.length) {
      clarity.setTag('PS_includes_material');
    }
  }, [material]);

  const form = useForm({
    initialState: {
      title: '',
      length: 0,
      width: 0,
      height: 0,
      count: 1,
      weight: 0,
      image: null as string | null,
      image_ref: null as string | null,
      size_variant_choice: undefined as undefined | ProductSelectionGroup,
      material_choice: [] as ProductSelectionGroup[],
    },
    validators: {
      title: val => !val,
      length: val => !val,
      width: val => !val,
      height: val => !val,
      count: val => val <= 0,
      image: val => productGroup?.picture_required === true && !val,
    },
  });

  const dimsDirty = form.isFieldDirty('height') && form.isFieldDirty('width') && form.isFieldDirty('length');
  /**
   * We show a warning to prevent users to submit items that are really small. Treshold is 1000 cm3
   */
  const dimsSizeWarn =
    (form.data.height.value || 20) * (form.data.width.value || 20) * (form.data.length.value || 20) <= 1000;

  React.useEffect(() => {
    if (existingItem) {
      trackEvent({
        eventCategory: 'PS - Edit item',
        eventAction: 'New',
      });
    } else {
      trackEvent({
        eventCategory: 'PS - Add item',
        eventAction: 'New',
      });
    }
  }, []);

  const getFormItemData = (): PSFormData => {
    return {
      title: form.data.title.value as string,
      length: form.data.length.value as number,
      width: form.data.width.value as number,
      height: form.data.height.value as number,
      count: form.data.count.value,
      job_image: form.data.image.value ? form.data.image.value : undefined,
      image_ref: form.data.image_ref.value ? form.data.image_ref.value : undefined,
      weight: form.data.weight.value,
      ps_temp: {
        fullTitle: getFullItemTitle({
          title: form.data.title.value as string,
          lang: langCode as PsLangs,
          materialChoice: form.data.material_choice.value,
        }),
        properties: {
          size_variant: sizeVariants,
          size_variant_choice: form.data.size_variant_choice.value,
          material: material,
          material_choice: form.data.material_choice.value,
        },
      },
    };
  };

  const formHasError = form.hasErrors || !!(sizeVariants.length && !form.data.size_variant_choice.value);

  /**
   * Submitting the form means either updating or adding an item
   */
  const submitForm = async (e): Promise<void> => {
    // Nope, we don't want a page refresh
    e.preventDefault();
    setShowErrors(formHasError);
    if (formHasError) {
      return;
    }

    if (
      !isChecked &&
      (productGroup?.dimension_check_required || form.data.size_variant_choice.value?.dimension_check_required)
    ) {
      setShowDimensionCheck(true);
      return;
    }
    submitItem();
  };

  const submitItem = async (): Promise<void> => {
    const item = getFormItemData();
    const { weight, fragile, iriList } = isManualItem
      ? await getManualWeightFragileandIri(item, searchResult?.auctionLot?.weight || undefined)
      : getWeightFragileAndIri(
          item,
          existingItem?.ps_temp.group || productGroup || null,
          searchResult?.auctionLot?.weight || undefined
        );
    if (existingItem) {
      /**
       * Dipatch an update action where old values will be overwritten
       */
      dispatch(
        actions.updateItemPs({
          item: {
            // existing item holds previous selected details
            ...existingItem,
            ...item,
            weight,
            // important one to keep in sync is product_group for tracking
            product_groups: iriList,
            fragile,
            ps_temp: {
              ...existingItem.ps_temp,
              group: productGroup || null,
              ...item.ps_temp,
            },
            lot_temp: existingItem.lot_temp,
          },
          // Once we are dealing with an existing item, uuid is always present
          uuid: existingItem?.ps_temp.uuid as string,
        })
      );
    } else {
      /**
       * Dispatch an add item action
       */
      dispatch(
        actions.addItemPs({
          ...item,
          weight,
          product_groups: iriList,
          fragile,
          ps_temp: {
            group: productGroup || null,
            isManual: !!isManualItem,
            ...item.ps_temp,
          },
          lot_temp: searchResult?.auctionLot,
        })
      );
    }
    dispatch(actions.getPrice());
    dispatch(actions.setQueryPs(null));
    history.replace(r.generalFlow.items.index());
  };

  /**
   * \\ START: KEEP THIS CODE IN THIS ORDER - Else the form will always be empty at start
   * On mount effect that handles what data should be loaded. Can either be:
   * - Rehydration of the form
   * - Load the searchresult details
   */
  React.useEffect(() => {
    if (!productGroup && !existingItem && !isManualItem) {
      // Values need to be set first, then we decide what to do
      return;
    }
    // First we check for rehydration, any changes done should be retrieved
    const rawSessionData = sessionStorage.getItem(SessionStorageKeys.PRODUCT_SELECTION_FORM);
    if (rawSessionData) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { ps_temp, ...rest } = JSON.parse(rawSessionData) as PSFormData;
      form.set({
        ...rest,
        size_variant_choice: ps_temp.properties.size_variant_choice,
        material_choice: ps_temp.properties.material_choice,
      });
      // Update available choices
      setSizeVariants(ps_temp.properties.size_variant);
      setMaterial(ps_temp.properties.material);
    } else {
      /**
       * PROPERTIES
       * This is where size variants and material come from
       * Lookup follows this priority:
       * 1) Existing item
       * 2) Parent PSGroup - so current result is a child
       * 3) Current PSGroup - so current is a Parent
       * 4) In some very rare cases, we are dealing with a level in between (parent > CHILD > grandchild) PSgroup
       */
      // Dealing with case 1, 2 and 3
      const properties =
        existingItem?.ps_temp.properties ||
        (productGroup?.parent !== null && typeof productGroup?.parent !== 'string'
          ? productGroup?.parent.properties
          : productGroup.properties);
      // Size variants
      let sizeVars = properties?.size_variant || [];
      // Dealing with case 4
      if (!sizeVars.length) {
        sizeVars = productGroup?.properties?.size_variant || [];
      }
      // Material options are read from the first size variant.
      // ASSUMPTION: all size variants have the same material option
      // EXCEPTION: when there are no size variants, we might have options for this single size group at the group itself (so not at the parent)
      const materialVars = (sizeVars[0] ? sizeVars[0].properties : productGroup?.properties)?.material || [];
      // Detected material comes from the selected search result - to preselect we need a list of `property_identifier` values
      const detectedMaterial = isManualItem
        ? []
        : searchResult?.detected_material?.map(dMat => dMat.property_identifier) || [];
      let title = existingItem?.title || searchResult?.auctionLot?.title;
      if (!isManualItem && !title) {
        title = productGroup?.items?.[0]?.name[langCode] || productGroup?.name[langCode];
      }
      const isCurrentProductGroupAVariant = !!sizeVars.find(variant => variant.iri === productGroup?.iri);
      form.set({
        title,
        length:
          existingItem?.length ||
          searchResult?.auctionLot?.length ||
          productGroup?.items?.[0]?.length_suggested_cm ||
          0,
        width:
          existingItem?.width || searchResult?.auctionLot?.width || productGroup?.items?.[0]?.width_suggested_cm || 0,
        height:
          existingItem?.height ||
          searchResult?.auctionLot?.height ||
          productGroup?.items?.[0]?.height_suggested_cm ||
          0,
        weight: existingItem?.weight || productGroup?.items?.[0]?.weight_suggested_kg || 0,
        image: existingItem?.job_image || searchResult?.auctionLot?.image || null,
        count: existingItem?.count || 1,
        size_variant_choice:
          existingItem?.ps_temp?.properties.size_variant_choice ||
          (isCurrentProductGroupAVariant ? productGroup : undefined) ||
          undefined,
        material_choice:
          existingItem?.ps_temp?.properties.material_choice ||
          materialVars.filter(mvar => detectedMaterial?.includes(mvar.property_identifier)),
      });
      // Set the options, so the will end up in the UI
      setSizeVariants(sizeVars);
      setMaterial(materialVars);
    }
    return () => {
      // remove the storage on unmount
      sessionStorage.removeItem(SessionStorageKeys.PRODUCT_SELECTION_FORM);
    };
  }, [productGroup, existingItem]);
  /**
   * Storing the data in local storage so that we can retrieve it after a refresh.
   * Important note: this needs to stay below the hydration code
   */
  React.useEffect(() => {
    if (!productGroup && !existingItem && !isManualItem) {
      // Values need to be set first, then we decide what to do
      return;
    }
    sessionStorage.setItem(SessionStorageKeys.PRODUCT_SELECTION_FORM, JSON.stringify(getFormItemData()));
  }, [form.data]);
  /**
   * * // END: KEEP THIS CODE IN THIS ORDER
   */

  const prevTitleRef = React.useRef<null | string>();
  const onTitleInputChange = (title: string): void => {
    // This logic should only be applied to when the customer is editing an non-manual product
    if (!isExistingItem || isManualItem) {
      return;
    }
    let redirectToAddItem = false;
    // when user deletes all
    if (!title) {
      redirectToAddItem = true;
    } else {
      const prevTitle = prevTitleRef.current;
      // or when user copy pastes
      const sameTitle = prevTitle && (title.includes(prevTitle) || prevTitle.includes(title));
      if (prevTitle && !sameTitle) {
        redirectToAddItem = true;
      }
    }
    prevTitleRef.current = title;
    if (redirectToAddItem) {
      /**
       * This means that the user changed or deleted the name
       * which implies he/she wants to start over
       *  */
      // Deleting current item
      dispatch(actions.removeItemPs({ uuid: existingItem.ps_temp.uuid as string }));
      if (title) {
        // if we have a title (example: the user copy pasted something new/different)
        // let's help him a little and update the query
        dispatch(actions.setQueryPs(title));
      }
      // Send user back into search
      history.replace(r.generalFlow.items.add());
    }
  };
  const sizeVarUpdate = (choices: ProductSelectionGroup[]): void => {
    const variant = sizeVariants.find(v => v.iri === choices[0].iri);
    if (variant) {
      // Update the form
      form.set({
        title: variant.items?.[0]?.name[langCode] || variant.name[langCode],
        length: variant.items?.[0]?.length_suggested_cm || 0,
        width: variant.items?.[0]?.width_suggested_cm || 0,
        height: variant.items?.[0]?.height_suggested_cm || 0,
        size_variant_choice: choices[0],
      });
    }
  };

  return (
    <>
      <div
        className={cn('ps-config', {
          'ps-config--new': !isExistingItem,
        })}
        // When exisitng item, we only want the back button to trigger
        onClick={!isExistingItem ? history.goBack : undefined}
      >
        {isManualItem && !isExistingItem && (
          <div className="ps-config--manual-head">
            <div className="ps-config--manual-head--content text--bold">
              <Translate id={'request_flow.product_selection.head_manual_item'} />
            </div>
          </div>
        )}
        {isExistingItem && (
          <div className="ps-modal--section ps-config--update-head flex flex--vc">
            <div className="ps-config--update-head--content">
              <Translate id={'request_flow.product_selection.head_update_item'} />
            </div>
            <Button
              type="button"
              buttonStyle="transparant"
              onClick={() => history.replace(r.generalFlow.items.index())}
              extraClasses="ps-modal--back text--primary"
              style={{ margin: 0 }}
            >
              <IconCross height={'.9em'} width={'.9em'} />
            </Button>
          </div>
        )}
        <div className="ps-config--bg" onClick={e => e.stopPropagation()}>
          <form onSubmit={submitForm}>
            <div className="ps-config--content">
              {isManualItem && !isExistingItem && (
                <div className="ps-modal--section">
                  <Heading size={3} extraClasses={'no-margin'}>
                    <Translate id={'request_flow.product_selection.extra_head_manual_item'} />
                  </Heading>
                </div>
              )}
              {(isManualItem || isExistingItem) && (
                <div className="ps-modal--section">
                  <div className="pb-0-25">
                    <Translate id={'request_flow.product_selection.input_title'} />
                  </div>
                  <InputText
                    className="w-full"
                    value={form.data.title.value || ''}
                    onChange={title => {
                      onTitleInputChange(title);
                      form.set({
                        title,
                      });
                    }}
                  />
                  {form.isFieldDirty('title') && !form.data.title.value && (
                    <div className="text--red py-1">
                      <Translate id={'request_flow.product_selection.error_title'} />
                    </div>
                  )}
                </div>
              )}
              {!isManualItem && !isExistingItem && (
                <div className="ps-modal--section">
                  <Heading size={3} extraClasses={'no-margin'}>
                    &quot;
                    {form.data.title.value}
                    &quot;
                  </Heading>
                </div>
              )}
              {!!sizeVariants.length && (
                <div className="ps-modal--section">
                  <InputChips
                    id="size_variant"
                    title={translate('request_flow.product_selection.questions.what_size')}
                    value={[form.data.size_variant_choice.value?.iri || '']}
                    multiple={false}
                    onChange={choices => sizeVarUpdate(sizeVariants.filter(s => choices.includes(s.iri)))}
                    options={sizeVariants.map(s => ({
                      value: s.iri,
                      label: s.label?.[langCode] as string,
                    }))}
                  />
                  {form.isFieldDirty('size_variant_choice') && !form.data.size_variant_choice.value && (
                    <div className="text--red py-1">
                      <Translate id={'request_flow.product_selection.error_make_choice'} />
                    </div>
                  )}
                </div>
              )}
              <div className="ps-modal--section">
                <div className="ps-config--dimensions-header">
                  <Translate id={'request_flow.product_selection.check_dimensions'} />
                </div>
                <div className="ps-config--dimensions">
                  <div className="relative">
                    <InputNumber
                      placeholder={translate('request_flow.product_selection.placeholder_length')}
                      className="ps-config--dimensions-input"
                      value={form.data.length.value || ''}
                      onChange={length => {
                        form.set({ length });
                        setChecked(true);
                      }}
                    />
                    <span className="ps-config--dimensions-input-suffix">cm</span>
                  </div>
                  <div className="relative">
                    <InputNumber
                      placeholder={translate('request_flow.product_selection.placeholder_width')}
                      className="ps-config--dimensions-input"
                      value={form.data.width.value || ''}
                      onChange={width => {
                        form.set({ width });
                        setChecked(true);
                      }}
                    />
                    <span className="ps-config--dimensions-input-suffix">cm</span>
                  </div>
                  <div className="relative">
                    <InputNumber
                      placeholder={translate('request_flow.product_selection.placeholder_height')}
                      className="ps-config--dimensions-input"
                      value={form.data.height.value || ''}
                      onChange={height => {
                        form.set({ height });
                        setChecked(true);
                      }}
                    />
                    <span className="ps-config--dimensions-input-suffix">cm</span>
                  </div>
                </div>
                {dimsDirty && (!form.data.height.value || !form.data.width.value || !form.data.length.value) && (
                  <div className="text--red py-1" style={{ marginTop: '.5rem' }}>
                    <Translate id={'request_flow.product_selection.error_dims'} />
                  </div>
                )}
                {dimsDirty && dimsSizeWarn && (
                  <div className="text--grey py-1" style={{ marginTop: '.5rem' }}>
                    <Translate id={'request_flow.product_selection.warn_small_dims'} />
                  </div>
                )}
              </div>
              {!!material.length && (
                <div className="ps-modal--section">
                  <InputChips
                    id="material"
                    title={translate('request_flow.product_selection.questions.materials')}
                    value={form.data.material_choice.value.map(m => m.property_identifier as string)}
                    multiple={true}
                    onChange={choices =>
                      form.set({
                        material_choice: material.filter(m => choices.includes(m.property_identifier as string)),
                      })
                    }
                    options={material.map(m => ({
                      value: m.property_identifier as string,
                      label: m.label?.[langCode] as string,
                    }))}
                  />
                </div>
              )}
              <div className="ps-modal--section">
                <ItemImage
                  image={form.data.image.value}
                  required={productGroup?.picture_required}
                  showError={productGroup?.picture_required && showErrors}
                  updateImage={image =>
                    form.set({
                      image: image?.['@id'],
                      image_ref: image?.content_url,
                    })
                  }
                />
              </div>
              <div className="ps-modal--section">
                <ItemCount
                  count={form.data.count.value}
                  updateCount={count =>
                    form.set({
                      count,
                    })
                  }
                />
              </div>
              <div className="ps-modal--section">
                <Button type="submit" dataQaId="ps-submit-item">
                  <Translate id={`request_flow.product_selection.submit_${existingItem ? 'update' : 'add'}`} />
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Modal
        isActive={showDimensionCheck}
        closeHandler={() => setShowDimensionCheck(false)}
        closeByBackdrop={false}
        isPrompt={true}
      >
        <div className="ps-modal--section">
          <b>
            <Translate id={'request_flow.what.example_dimensions.title'} />
          </b>
        </div>
        <div className="ps-modal--section">
          <Translate id={'request_flow.what.example_dimensions.description'} />
        </div>
        <div className="ps-modal--section">
          <div className="flex flex--sb">
            <Button
              style={{ width: '160px', marginRight: '1rem' }}
              buttonStyle="primary"
              type="button"
              onClick={() => {
                setShowDimensionCheck(false);
                setChecked(true);
              }}
            >
              <Translate id={'request_flow.what.example_dimensions.cancel'} />
            </Button>
            <Button onClick={submitItem} style={{ width: '160px' }} buttonStyle="secondary" type="button">
              <Translate id={'request_flow.what.example_dimensions.submit'} />
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

const getWeightFragileAndIri = (
  fd: PSFormData,
  startGroup: ProductSelectionGroup | null,
  auctionWeight?: number
): { iriList: string[]; weight: number; fragile: boolean } => {
  // Chosen material
  const material = fd.ps_temp.properties.material_choice;
  // Chosen size
  const size = fd.ps_temp.properties.size_variant_choice;
  // Fallback Product Group, which is either the size variant or the search result
  const fallbackGroup = size || startGroup;

  let weight = auctionWeight || fallbackGroup?.items?.[0]?.weight_suggested_kg || 0;
  let fragile = !!fallbackGroup?.items?.[0]?.fragile;
  // if selected material we will try to overwrite above values
  if (material.length) {
    try {
      // If there is a size variant, then we should look up the material from that particular size
      // The presented material choices are not always the same as the values in the sizevariant
      if (size) {
        size?.properties?.material
          // to find the heaviest one
          // we loop trough the material options of the size variant (filtered first by selected).
          .filter(m =>
            Boolean(material.find(({ property_identifier }) => m.property_identifier === property_identifier))
          )
          // then update the variable if we have a heavier one
          .forEach(variant => {
            // If there is auction weight, stick to it
            if (!auctionWeight && weight < (variant?.items?.[0]?.weight_suggested_kg || 0)) {
              weight = variant?.items?.[0]?.weight_suggested_kg || 0;
            }
            if (variant?.items?.[0]?.fragile) {
              fragile = true;
            }
          });
      } else {
        // No size variant, so the operation is straight forward
        const fragilePS = material.find(m => m.items?.[0]?.fragile);
        if (fragilePS) {
          fragile = true;
        }
      }
    } catch (e) {
      logError(e);
    }
  }
  /**
   * Simple list of strings, starting with the main group, the selected size and optional material choices
   */
  const iriList = [startGroup?.iri, size?.iri, ...material.map(chosen => chosen.iri)].filter(Boolean) as string[];
  return {
    weight,
    fragile,
    iriList,
  };
};

const getManualWeightFragileandIri = async (
  fd: PSFormData,
  auctionWeight?: number
): Promise<{ weight: number; fragile: boolean; iriList: string[] }> => {
  const {
    height,
    width,
    length,
    // Weight could be present when it comes from an auction
    ps_temp: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      properties: { material_choice },
    },
  } = fd;
  /**
   * Simple list of strings, starting with the main group and optional material choices
   */
  const iriList = [MANUAL_IRI, ...material_choice.map(choice => choice.iri)];
  const fragile = material_choice.some(m => m.items?.[0]?.fragile);
  if (!auctionWeight) {
    try {
      const group = await priceClient.productSelection.retrieveWeight({
        id: encodeURIComponent(MANUAL_IRI),
        height,
        width,
        length,
        material: material_choice.map(m => m.iri),
      });
      return { weight: Math.ceil(group?.items?.[0]?.weight_suggested_kg || 0), fragile, iriList };
    } catch (e) {
      logError(e);
      return { weight: 0, fragile, iriList };
    }
  } else {
    return { weight: auctionWeight, fragile, iriList };
  }
};

/**
 * For new product groups we have specs to we want to reflect in the titem title,
 * but need to be translated
 */
interface GetFullItemTitle {
  title: string;
  lang: PsLangs;
  materialChoice?: ProductSelectionGroup[];
}
const getFullItemTitle = ({ title, lang, materialChoice }: GetFullItemTitle): string => {
  const material = (materialChoice || [])
    .map(option => {
      return option.label?.[lang] || '';
    })
    .filter(Boolean);
  if (material.length) {
    title = `${title} (${material.join(', ')})`;
  }
  return title;
};

import { IconAddCircle, IconSearch } from '@brenger/react';
import React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { useHistory } from 'react-router-dom';
import { Button, Heading } from '../../../../brenger-shared-ui';
import { r } from '../../../../routes';
import { getTbAuctionPlatform } from '../../ducks';
import { useTypedSelector } from '../../../../hooks';

interface Props {
  hasItems: boolean;
}

export const Header: React.FC<Props> = ({ hasItems }) => {
  const history = useHistory();
  const openAdd = (): void => history.push(r.generalFlow.items.add());
  const tbAuctionPlatform = useTypedSelector(getTbAuctionPlatform);

  if (!hasItems) {
    return (
      <>
        <Heading size={2}>
          <Translate id={'request_flow.product_selection.add_items'} />
        </Heading>
        {!tbAuctionPlatform && (
          <Button
            dataQaId="add-first-item"
            type="button"
            fullWidth={true}
            buttonStyle="transparant"
            onClick={openAdd}
            extraClasses={'ps-start-search-button flex flex--vc'}
          >
            <IconSearch style={{ marginLeft: 0 }} />
            <Translate id={'request_flow.product_selection.search_item'} />
          </Button>
        )}
      </>
    );
  }
  return (
    <div className="ps--main-head">
      <Heading size={2}>
        <div>
          <Translate id={'request_flow.product_selection.your_items'} />
        </div>
      </Heading>
      {!tbAuctionPlatform && (
        <Button dataQaId="add-another-item" iconPos="left" type="button" onClick={openAdd}>
          <IconAddCircle /> <Translate id={'request_flow.product_selection.item'} />
        </Button>
      )}
    </div>
  );
};

import React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import classNames from 'classnames';

import { useTypedSelector } from '../../../../hooks';
import { useNotification } from '../../../../hooks/useNotification';
import { IconCo2, IconMoney, IconTimeSaving, Toast } from '../../../../brenger-shared-ui';
import { getQuote } from '../../ducks';
import { getCO2SavingsForKilometers } from '@brenger/utils';

export const TransportSavingsNotification: React.FC = () => {
  const notification = useNotification('transport-request-savings');
  const progressStep = useTypedSelector(state => state.generalTransport.layout.step);
  const quote = useTypedSelector(getQuote);
  // get the numbers, we fall back to a 5km default
  const quoteDistance = quote?.distance || 0;
  const distance = quoteDistance > 5 ? quoteDistance : 5;
  // returns saved co2 based on distance
  const { inKg } = getCO2SavingsForKilometers(distance);
  // based on 100 km an hour, return trip.
  // The plus 1.9h makes it round up to 2h in total as lowest amount of time. This is including cycling to / from rent a van and driving to pickup and return van from delivery
  const savedTimeInHoursRaw = (distance * 2) / 100 + 1.9;
  // total minutes saved
  const savedTimeInMinutesInTotal = (savedTimeInHoursRaw > 1 ? savedTimeInHoursRaw : 1) * 60;
  // hours
  const savedTimeHours = Math.floor(savedTimeInMinutesInTotal / 60);
  // minutes left
  const savedTimeMinutes = savedTimeInMinutesInTotal - savedTimeHours * 60;
  const savedQuarterOfHour = Math.floor(savedTimeMinutes / 30) * 5;

  React.useEffect(() => {
    // AB test - so only show when body has specific class
    if (progressStep === 3 && quote !== null && document.body.classList.contains('show-savings')) {
      notification.add();
    } else {
      notification.remove();
    }
  }, [progressStep, quote]);

  return (
    <div className={classNames('info-message-container', { 'info-message--visible': notification.isVisible })}>
      <Toast handleClose={notification.removeForOneSession}>
        <div style={{ textAlign: 'center' }}>
          <b>
            <Translate id="request_flow.notification.transport_request_savings.title" />
          </b>
          <div>
            <Translate id="request_flow.notification.transport_request_savings.sub_title" />
          </div>
        </div>
        <div className="pt-0-5 container-fluid" style={{ maxWidth: 450, lineHeight: 1 }}>
          <div className={'row'}>
            <div className="col text--center">
              <IconMoney style={{ display: 'inline-block' }} width={40} height={40} />
              <div>
                <b>&euro; 40</b>
              </div>
            </div>
            <div className="col text--center">
              <IconCo2 style={{ display: 'inline-block' }} width={40} height={40} />
              <div>
                <b>{inKg} kg</b>
              </div>
            </div>
            <div className="col text--center">
              <IconTimeSaving style={{ display: 'inline-block' }} width={40} height={40} />
              <div>
                <b>
                  {savedTimeHours}
                  {savedQuarterOfHour !== 0 && ',' + savedQuarterOfHour} <Translate id={'calendar.hour'} />
                </b>
              </div>
            </div>
          </div>
        </div>
      </Toast>
    </div>
  );
};

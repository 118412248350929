import * as React from 'react';
import '../input/input.scss';
import './input-password.scss';
import InputWrapper from '../input/InputWrapper';
import InputFeedback from '../input/InputFeedback';
import { InputProps } from '../input/Input';
import { IconEye } from '../../basics/icons';

type InputPasswordProps = InputProps;

interface InputPasswordState {
  showPassword: boolean;
}

export class InputPassword extends React.Component<InputPasswordProps, InputPasswordState> {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
    };
  }

  public togglePasswordVisibility(): void {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }

  public render(): JSX.Element {
    const input = this.props.input;
    const meta = this.props.meta;
    const label = this.props.label;
    const required = this.props.required;
    return (
      <InputWrapper
        input={input}
        meta={meta}
        type={'password' + (this.state.showPassword ? ' input-el--password--visible' : '')}
      >
        <input id={input.name} type={this.state.showPassword ? 'text' : 'password'} {...input} />
        {label && (
          <label className={'input-el--label' + (required ? ' input-el--label--required' : '')} htmlFor={input.name}>
            {label}
          </label>
        )}
        <div className={'input-el--password--trigger'} onClick={() => this.togglePasswordVisibility()}>
          <IconEye />
        </div>
        <InputFeedback input={input} meta={meta} error={meta.error} successMessage={this.props.successMessage} />
      </InputWrapper>
    );
  }
}

export default InputPassword;

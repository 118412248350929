import * as React from 'react';
import './sheetlayout.scss';
import cn from 'classnames';

export const SheetLayoutHeader: React.FC<React.HTMLAttributes<HTMLElement>> = ({ className, ...props }) => {
  return <div className={cn('sheet-layout--main-header', className)} {...props} />;
};

export const SheetLayoutContentWrapper: React.FC<React.HTMLAttributes<HTMLElement>> = ({ className, ...props }) => {
  return <div className={cn('sheet-layout--content-wrapper', className)} {...props} />;
};

export const SheetLayoutContent: React.FC<React.HTMLAttributes<HTMLElement>> = ({ className, ...props }) => {
  return <div className={cn('sheet-layout--main-content', className)} {...props} />;
};

export const SheetLayoutSidebarContent: React.FC<React.HTMLAttributes<HTMLElement>> = ({ className, ...props }) => {
  return <div className={cn('sheet-layout--sidebar-content', className)} {...props} />;
};

export const SheetLayoutFooter: React.FC<React.HTMLAttributes<HTMLElement>> = ({ className, ...props }) => {
  return <div className={cn('sheet-layout--footer', className)} {...props} />;
};

export const SheetLayoutNotifications: React.FC<React.HTMLAttributes<HTMLElement>> = ({ className, ...props }) => {
  return <div className={cn('sheet-layout--notifications', className)} {...props} />;
};

import React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import {
  FlashMessage,
  Heading,
  RfInputFileUploads,
  SheetLayoutContent,
  SheetLayoutHeader,
} from '../../../brenger-shared-ui';
import { pickupDeliverySchema } from '../schema';
import { GeneralFlowForms, SubStepFieldNamesPickup } from '../interface';
import { translate } from '../../../utils/localization';
import { StopSituation } from '@brenger/api-client';

interface Props {
  error: string | null;
  situation: StopSituation | null;
}

export const SubStepInvoice: React.FC<Props> = ({ situation, error }) => {
  const required = situation === 'auction';
  return (
    <>
      <SheetLayoutHeader>
        <Heading size={2}>
          <Translate id={`request_flow.pickup.${situation}.invoice_question`} />
        </Heading>
      </SheetLayoutHeader>
      <SheetLayoutContent>
        <div className={'question-wrapper'}>
          <div>
            <div className={'text--bold pb-0-5'}>
              <Translate id={`request_flow.pickup.${situation}.invoice_label`} />
            </div>
            <div className={'pb-1'}>
              <Translate id={`request_flow.pickup.${situation}.invoice_description`} />
            </div>
            <RfInputFileUploads
              fieldName={SubStepFieldNamesPickup.INVOICE}
              formName={GeneralFlowForms.PICKUP}
              accept={'application/pdf'}
              validate={required ? pickupDeliverySchema.invoice : undefined}
              texts={{
                button: {
                  default: String(translate('request_flow.pickup.invoice.button.default')),
                  add: String(translate('request_flow.pickup.invoice.button.add')),
                  delete: String(translate('request_flow.pickup.invoice.button.delete')),
                  change: String(translate('request_flow.pickup.invoice.button.change')),
                },
                header: String(translate('request_flow.pickup.invoice.header')),
              }}
            />
          </div>
        </div>
        <div className={'general-errors-wrapper'}>{error && <FlashMessage type={'error'} message={error} />}</div>
      </SheetLayoutContent>
    </>
  );
};

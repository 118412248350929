import { GeoAutocompleteAddress } from '@brenger/api-client';
import { useForm } from '@brenger/react';
import * as React from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { Col, InputText, InputTextarea, Row } from '../../../../brenger-shared-ui';
import { useTypedSelector } from '../../../../hooks';
import { CacheKey, StopType } from '../../../../typings';
import { translate } from '../../../../utils/localization';
import { normalizeDutchPostalCode } from '../../../../utils/normalisation';
import { routePlannerClient } from '../../../../utils/request';
import { removeAccents } from '../../../../utils/string';
import { getSuspicions } from '../../../../utils/textScanning';
import { validateDutchPostalCode, validateRequired, validateStreet, warnLine1 } from '../../../../utils/validation';
import { actions } from '../../ducks';
import { AddressError, ContactPageError } from './AddressError';
import { ContactPageAddress } from './ContactStop';

interface Props {
  forceShowErrors: boolean;
  widgetAddress: GeoAutocompleteAddress | null;
  type: StopType;
  setContactPageAddress(addr: ContactPageAddress | null): void;
  contactPageError: ContactPageError;
  setContactPageError(error: ContactPageError): void;
  localityChange(a: GeoAutocompleteAddress): void;
  setIsAddressLoading(isLoading: boolean): void;
}

export const AddressFieldsDutch: React.FC<Props> = ({
  forceShowErrors,
  type,
  widgetAddress,
  setContactPageAddress,
  contactPageError,
  setContactPageError,
  localityChange,
  setIsAddressLoading,
}) => {
  const rehydrate = useTypedSelector(state => state.generalTransport.contact[type]);

  const dispatch = useDispatch();

  const form = useForm({
    persist: {
      key: `gf_contact_address_${type}`,
      type: 'session',
    },
    initialState: {
      postal_code: rehydrate?.address?.postal_code || widgetAddress?.postal_code || '',
      street_number: rehydrate?.address?.line2 || widgetAddress?.line2 || '',
      street: rehydrate?.address?.line1 || widgetAddress?.line1 || '',
      locality: rehydrate?.address?.locality || widgetAddress?.locality || '',
      instructions: rehydrate?.instructions || '',
    },
    validators: {
      postal_code: validateDutchPostalCode,
      street_number: val => validateRequired(translate('form.fields.address.house_number.label'), val),
      street: validateStreet,
    },
  });

  const pc = form.data.postal_code.value;
  const sn = form.data.street_number.value;
  const street = form.data.street.value;
  const instructions = form.data.instructions.value;

  // We use this endpoint only for pre-filling line1, so no other details of the address will be copied or used
  const line1Lookup = useQuery(
    [CacheKey.RETRIEVE_LINE_1, pc, sn],
    () => routePlannerClient.geo.retrieveStructuredPostalCode({ country: 'NL', postal_code: pc, street_number: sn }),
    { enabled: pc.length > 4 && !!sn }
  );

  React.useEffect(() => {
    setIsAddressLoading(line1Lookup.isLoading);
  }, [line1Lookup.isLoading]);

  React.useEffect(() => {
    const a = line1Lookup.data?.[0]?.address;
    if (a && a.locality && widgetAddress?.locality) {
      form.set({ street: a.line1 });
      if (normGeoStr(a.locality) !== normGeoStr(widgetAddress.locality)) {
        localityChange(a);
        form.set({ locality: a.locality });
      }
    }
  }, [line1Lookup.data]);

  React.useEffect(() => {
    if (pc && sn && street) {
      setContactPageAddress({
        line1: street,
        line2: sn,
        postal_code: pc,
        instructions: instructions,
      });
    } else {
      setContactPageAddress(null);
    }
  }, [pc, sn, street, instructions]);

  const onBlurInstructions = (): void => {
    const suspicions = getSuspicions(instructions);
    dispatch(actions.setInstructionSuspicion({ stopType: type, suspicions }));
  };

  return (
    <Row>
      <Col xs={8} extraClasses="pb-1">
        <InputText
          value={form.data.postal_code.value}
          onChange={postal_code => form.set({ postal_code: normalizeDutchPostalCode(postal_code) })}
          id={`${type}_postal_code`}
          placeholder={translate('form.fields.address.postal_code.label')}
          meta={{
            error: form.getError('postal_code'),
            showError: forceShowErrors || form.data.postal_code.isDirty,
          }}
        />
      </Col>
      <Col xs={4} extraClasses="pb-1">
        <InputText
          value={form.data.street_number.value}
          onChange={street_number => form.set({ street_number })}
          id={`${type}_house_number`}
          placeholder={translate('form.fields.address.house_number.label')}
          meta={{
            error: form.getError('street_number'),
            showError: forceShowErrors || form.data.street_number.isDirty,
          }}
        />
      </Col>
      <Col xs={12} extraClasses="pb-1">
        <InputText
          value={form.data.street.value}
          onChange={s => form.set({ street: s })}
          id={`${type}_street`}
          placeholder={translate('form.fields.address.street.label')}
          meta={{
            error: form.getError('street'),
            warning: warnLine1(form.data.street.value),
            showError: forceShowErrors || form.data.street.isDirty,
          }}
        />
      </Col>
      <Col xs={12} extraClasses="pb-1">
        <InputText
          value={widgetAddress?.locality}
          id={`${type}_locality`}
          disabled={true}
          placeholder={translate('form.fields.address.locality.label')}
        />
      </Col>
      <Col xs={12} extraClasses="pb-1">
        <InputTextarea
          value={form.data.instructions.value}
          onChange={i => form.set({ instructions: i })}
          id={`${type}_instructions`}
          placeholder={translate('form.fields.address.instructions.label')}
          autoCompleteDisabled={true}
          onBlur={onBlurInstructions}
        />
      </Col>
      {contactPageError !== 'none' && (
        <Col xs={12} extraClasses="pb-1">
          <AddressError type={contactPageError} setMessage={setContactPageError} />
        </Col>
      )}
    </Row>
  );
};

const normGeoStr = (val: string): string => {
  return removeAccents(val.toLowerCase());
};

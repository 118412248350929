import { getIdFromIri } from '@brenger/utils';
import React from 'react';
import { useQuery } from 'react-query';
import { AppLoader, Card, FlashMessage } from '../../../brenger-shared-ui';
import { CacheKey } from '../../../typings';
import { coreClient } from '../../../utils/request';
import { InputWebHook } from './InputWebhook';

export const Overview: React.FC = () => {
  const apiKeys = useQuery([CacheKey.API_KEY_LIST], coreClient.apiKeys.list);
  const keys = apiKeys.data?.['hydra:member'] || [];
  if (apiKeys.isLoading) {
    return <AppLoader />;
  }
  /* Talking to devs here - so no need to get lost in translations */
  if (apiKeys.isError) {
    return <FlashMessage type="error">{(apiKeys.error as Error).message}</FlashMessage>;
  }
  if (!keys.length) {
    return <FlashMessage type="info">No api keys found</FlashMessage>;
  }
  return (
    <>
      {keys.map((apiKey, index) => {
        return (
          <Card key={index}>
            <div className={'pb-0-25'}>
              <span className={'text--bold'}>{apiKey.test ? 'Test' : 'Production'} key</span>
            </div>
            <div className={'pb-0-25 text--primary'}>{apiKey.token}</div>
            <InputWebHook
              id={getIdFromIri(apiKey['@id']) || ''}
              webhook={apiKey.webhook_url}
              onSuccess={apiKeys.refetch}
            />
          </Card>
        );
      })}
    </>
  );
};

import React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { Field } from 'redux-form';
import {
  Button,
  FlashMessage,
  Heading,
  IconSmile,
  RfInput,
  RfInputTiles,
  SheetLayoutContent,
  SheetLayoutHeader,
} from '../../../brenger-shared-ui';
import { useTypedSelector } from '../../../hooks';
import { useDateOptions } from '../../../hooks/useDateOptions';
import { RootState, StopType } from '../../../typings';
import { getBlockedTransportRequestReasons, getTbAuctionPickupDateAsString, getPickupSituation } from '../ducks';
import { FieldNamesDate } from '../interface';
import { pickupDeliverySchema } from '../schema';
import { BlockedNotGuaranteedReasonList } from './BlockedNotGuaranteedReasonList';
import { proceed } from '../proceed';

export const SubStepDate: React.FC = () => {
  const dates = useDateOptions();
  const transportBlockedReasons = useTypedSelector(getBlockedTransportRequestReasons);
  const auctionPickupDate = useTypedSelector(getTbAuctionPickupDateAsString);
  const error = useTypedSelector((state: RootState) => state.generalTransport.error);
  const isAuction = useTypedSelector(getPickupSituation) === 'auction';
  return (
    <>
      {dates.meta.hasSingleCollectionDay && dates.meta.stopType === StopType.DELIVERY && (
        <SheetLayoutHeader>
          <Heading size={2}>
            <Translate id={'request_flow.BVA.integration.pickup_date_auction'} />
          </Heading>
          <p>{auctionPickupDate}</p>
        </SheetLayoutHeader>
      )}
      <SheetLayoutHeader>
        <Heading size={2}>
          <Translate id={dates.meta.title} />
        </Heading>
        {dates.meta.aboveMaxDistance && (
          <p>
            <Translate id={'request_flow.date.sub_title_long_distance' + (isAuction ? '_auction' : '')} />
          </p>
        )}
      </SheetLayoutHeader>
      <SheetLayoutContent>
        {transportBlockedReasons.length > 0 && <BlockedNotGuaranteedReasonList reasons={transportBlockedReasons} />}
        {transportBlockedReasons.length === 0 && (
          <div className={'question-wrapper'}>
            <div>
              {dates.loading && !dates.options.length && <IconSmile spinning={true} />}
              {dates.options.length !== 0 && (
                <>
                  <Field
                    validate={pickupDeliverySchema.date}
                    name={dates.meta.stopType === StopType.PICKUP ? FieldNamesDate.PICKUP : FieldNamesDate.DELIVERY}
                    options={dates.options}
                    compact={true}
                    maxDelayIndex={7}
                    component={RfInputTiles}
                    onChangeCallback={() => proceed({ updatePriceFirst: true })}
                  />
                  {dates.getMore && (
                    <Button
                      dataQaId="show_more_dates"
                      fullWidth={true}
                      type="button"
                      buttonStyle="outline"
                      iconPos="left"
                      onClick={dates.getMore}
                    >
                      <span className="icon-wrapper">
                        <IconSmile smileDirection={'down'} spinning={dates.loading} />
                      </span>
                      <Translate id={'request_flow.date.show_more_dates'} />
                    </Button>
                  )}
                  {dates.showDifferentDeliveryDate && (
                    <>
                      <div className="pb-1" />
                      <Field
                        name={FieldNamesDate.DIFFERENT_DELIVERY_DATE}
                        type={'checkbox'}
                        label={<Translate id={'request_flow.date.select_different_delivery_date'} />}
                        component={RfInput}
                      />
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        )}
        {error && (
          <div className={'general-errors-wrapper'}>
            <FlashMessage type={'error'} message={error} />
          </div>
        )}
      </SheetLayoutContent>
    </>
  );
};

import { isAfter, subHours } from 'date-fns';
import iban from 'iban';
import _get from 'lodash/get';
import { CarryingHelpChoices, GenericExtraInputFileFields } from '../../typings';
import { PRODUCT_PAYMENT_MAX_AMOUNT, TIME_SLOT_LENGTH } from '../../utils/global';
import { translate } from '../../utils/localization';
import { priceAsString } from '../../utils/price';
import {
  email,
  isBoolean,
  maxLength,
  minLength,
  numeric,
  numericMin,
  required,
  validateMaxAmount,
  validateMinAmount,
} from '../../utils/validation';
import { TimeFormValues } from './containers/Time';

export const PersonSchema = {
  first_name: value =>
    required(
      value,
      translate('form.fields.default.required', { field: translate('form.fields.user.first_name.label') })
    ),
  last_name: value =>
    required(
      value,
      translate('form.fields.default.required', { field: translate('form.fields.user.last_name.label') })
    ),
  phone: [
    value =>
      required(value, translate('form.fields.default.required', { field: translate('form.fields.user.phone.label') })),
    value => minLength(value, 10, translate('form.fields.user.phone.valid')),
    value => maxLength(value, 14, translate('form.fields.user.phone.valid')),
    value => numeric(value, translate('form.fields.user.phone.valid')),
  ],
  email: [
    value =>
      required(value, translate('form.fields.default.required', { field: translate('form.fields.user.email.label') })),
    value => email(value, translate('form.fields.user.email.valid')),
  ],
};

export const homeSituationSchema = {
  type: value =>
    required(
      value,
      translate('form.fields.default.required', {
        field: translate('request_flow.home_situation.fields.type.label'),
      })
    ),
};

export const productPaymentSchema = {
  opt_in: value => required(value, translate('request_flow.actions.make_choice')),
  amount: [
    value =>
      required(
        value,
        translate('form.fields.default.required', {
          field: translate('request_flow.product_payment.fields.amount.label'),
        })
      ),
    value =>
      validateMaxAmount(
        value,
        PRODUCT_PAYMENT_MAX_AMOUNT / 100, // amount is in cents, but users thinks in euro's
        translate('request_flow.product_payment.fields.amount.valid', {
          amount: priceAsString({ price: { amount: PRODUCT_PAYMENT_MAX_AMOUNT }, addPlusMinusIndicator: false }),
        })
      ),
    value =>
      validateMinAmount(
        value,
        1, // amount is 1 euro
        translate('request_flow.product_payment.fields.amount.valid_min', {
          amount: priceAsString({ price: { amount: 100 }, addPlusMinusIndicator: false }),
        })
      ),
  ],
  iban: [
    value =>
      required(
        value,
        translate('form.fields.default.required', {
          field: translate('request_flow.product_payment.fields.iban.label'),
        })
      ),
    value => {
      return iban.isValid(value) ? undefined : translate('request_flow.product_payment.fields.iban.valid');
    },
  ],
};

export const itemSchema = {
  title: value =>
    required(
      value,
      translate('form.fields.default.required', { field: translate('request_flow.what.fields.title.label') })
    ),
  width: value =>
    numericMin(
      value,
      1,
      translate('form.fields.default.required', { field: translate('request_flow.what.fields.width.label') })
    ),
  height: value =>
    numericMin(
      value,
      1,
      translate('form.fields.default.required', { field: translate('request_flow.what.fields.height.label') })
    ),
  length: value =>
    numericMin(
      value,
      1,
      translate('form.fields.default.required', { field: translate('request_flow.what.fields.length.label') })
    ),
  weigth: value =>
    numericMin(
      value,
      0,
      translate('form.fields.default.required', { field: translate('request_flow.what.fields.weight.label') })
    ),
  count: value =>
    numericMin(
      value,
      1,
      translate('form.fields.default.required', { field: translate('request_flow.what.fields.count.label') })
    ),
  extraCare: value => required(value, translate('request_flow.fields.extra_care.valid')),
  productSelectionGroup: value => required(value, translate('request_flow.actions.make_choice')),
  productSelectionQuestion: value => isBoolean(value, translate('request_flow.actions.make_choice')),
};

export const pickupDeliverySchema = {
  time: value => required(value, ' '),
  elevator: value => (value === false || value === true ? undefined : ' '),
  auction_type: value => required(value, ' '),
  situation: value => required(value, ' '),
  situation_home: value => required(value, ' '),
  help: value => {
    if (window.location.href.indexOf('help') > -1) {
      if (
        [
          CarryingHelpChoices.NOT_NEEDED,
          CarryingHelpChoices.NEEDED,
          CarryingHelpChoices.EXTRA_DRIVER,
          CarryingHelpChoices.EQUIPMENT_TAILGATE,
          CarryingHelpChoices.EQUIPMENT_TAILGATE_PALLET_JACK,
        ].includes(value)
      ) {
        return undefined;
      } else {
        return ' ';
      }
    }
    return undefined;
  },
  equipment: value => required(value, ' '),
  invoice: (value, allValues, props, name) => {
    const collectionKey = `${name}_${GenericExtraInputFileFields.COLLECTION}`;
    if (_get(allValues, collectionKey, []).length) {
      return undefined;
    }
    return translate('request_flow.errors.upload_required');
  },
  other: value => required(value, translate('request_flow.fields.other_note.valid')),
  floor: value => numeric(value, ' '),
  date: value => {
    if (window.location.href.indexOf('date') > -1) {
      return required(value, ' ');
    }
    return undefined;
  },
  pickup_time_start: (value: string) => {
    return required(
      value,
      translate('form.fields.default.required', {
        field: translate('request_flow.time.fields.time_start.label'),
      })
    );
  },
  pickup_time_end: (value?: string, allValues?: TimeFormValues) => {
    const pickupStart = allValues?.pickup?.start;
    if (!pickupStart) {
      return translate('request_flow.time.fields.select_time_start_first');
    }
    if (value) {
      const pickupStartDate = new Date(pickupStart);
      const pickupEndDate = subHours(new Date(value), TIME_SLOT_LENGTH);
      if (isAfter(pickupStartDate, pickupEndDate)) {
        return translate('request_flow.time.fields.time_slot', {
          hours: TIME_SLOT_LENGTH,
        });
      }
    }
    return required(
      value,
      translate('form.fields.default.required', {
        field: translate('request_flow.time.fields.time_end.label'),
      })
    );
  },
};

export const getCountryCode = (allValues): string => {
  const addresPrefix = window.location.href.indexOf('delivery') > 0 ? 'delivery.address.' : 'pickup.address.';
  return _get(allValues, addresPrefix + 'country_code', '');
};

// Normalizer functions
export const parseInteger = (input: string): number | '' => {
  if (input === '0') {
    return '';
  }
  input = input.toString().replace('.', '');
  return parseInt(input.replace(/[^0-9.]/g, ''), 10);
};

export const numbersOnly = (input: string): string | undefined => {
  const stripped = input.replace(/[^\d.-.,]/g, '').trim();
  return stripped || undefined;
};

import * as React from 'react';
import './card.scss';
import { Heading } from '../../basics/heading';
import { IconSmile } from '../../basics/icons';
import cn from 'classnames';

interface Props {
  extraClasses?: string;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  onClick?: React.MouseEventHandler;
  disabled?: boolean;
  withBrengerSmile?: boolean;
  withBorder?: boolean;
  title?: string;
  children: React.ReactNode;
}

export const Card = ({
  extraClasses = '',
  header,
  footer,
  onClick,
  children,
  disabled = false,
  withBrengerSmile = false,
  withBorder = true,
  title,
}: Props): JSX.Element => {
  return (
    <div
      className={cn('card col-12', extraClasses, {
        trigger: onClick,
        'card--disabled': disabled,
        'card--bordered': withBorder,
      })}
      onClick={onClick}
      title={title}
    >
      {withBrengerSmile && <IconSmile />}
      {header && (
        <div className="card-header">
          <Heading size={4}>{header}</Heading>
        </div>
      )}
      <div className="card-body">{children}</div>
      {footer && <div className="card-footer">{footer}</div>}
    </div>
  );
};
export default Card;

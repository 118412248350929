import * as React from 'react';
import './icons.scss';

const BrengerLogo = (): JSX.Element => {
  return (
    <svg className={'brenger-logo'} width="98px" height="27px" viewBox="0 0 98 27">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-20.000000, -17.000000)">
          <g>
            <g transform="translate(20.000000, 17.000000)">
              <g>
                <path
                  className={'cls-1'}
                  d="M97.7530851,6.7429875 C97.8458284,6.5678625 97.7594682,6.3514875 97.5717287,6.2881125 L96.7805946,6.0214875 L96.7783418,6.0244875 C96.0356444,5.8028625 95.285813,5.7087375 94.6076981,5.7087375 C91.9515602,5.7087375 88.1543418,7.0201125 88.1543418,12.3736125 L88.1543418,20.5081125 C88.1543418,20.6888625 88.301154,20.8358625 88.4828858,20.8358625 L91.3376521,20.8358625 C91.5190084,20.8358625 91.6665716,20.6888625 91.6665716,20.5077375 L91.6665716,12.8476125 C91.6665716,10.3238625 92.5804874,8.9937375 94.6076981,8.9937375 C95.0334912,8.9937375 95.4040889,9.0597375 95.7303801,9.1789875 C95.7307556,9.1789875 95.7307556,9.1793625 95.7307556,9.1793625 L96.097223,9.2978625 C96.2459126,9.3462375 96.4077441,9.2809875 96.4809625,9.1426125 L97.7530851,6.7429875 Z"
                />
                <path
                  className={'cls-1'}
                  d="M76.1040215,11.7494625 C76.2639755,9.7038375 77.60819,8.6497125 79.4634314,8.6497125 C81.5435847,8.6497125 82.4394774,10.1827125 82.4394774,11.7494625 L76.1040215,11.7494625 Z M79.4634314,5.7089625 C75.1116307,5.7089625 72.5516153,8.4573375 72.5516153,13.3477125 C72.5516153,18.6835875 75.3035004,21.0809625 79.7517992,21.0809625 C82.2919142,21.0809625 83.906849,20.1322125 84.6386575,19.5509625 C84.7918529,19.4290875 84.8072475,19.2104625 84.6807111,19.0612125 L83.1153395,17.2207125 C83.0079525,17.0939625 82.8078222,17.0662125 82.6662667,17.1524625 C81.9352092,17.5987125 81.0092782,17.9808375 79.7517992,17.9808375 C77.6397303,17.9808375 76.0078989,16.9267125 76.0078989,14.6253375 L83.9113548,14.6253375 C85.8634697,14.6253375 85.8634697,13.2509625 85.8634697,12.0689625 C85.8634697,9.8643375 84.8718299,5.7089625 79.4634314,5.7089625 Z"
                />
                <path
                  className={'cls-1'}
                  d="M67.0492345,16.6775625 C67.0492345,16.6775625 65.8537096,18.2383125 64.0153648,18.2383125 C61.9318322,18.2383125 60.7366828,16.0835625 60.7366828,13.0539375 C60.7366828,10.0546875 61.9014184,8.5239375 64.0153648,8.5239375 C65.7925065,8.5239375 67.0492345,9.7175625 67.0492345,11.8299375 L67.0492345,16.6775625 Z M64.0153648,5.7088125 C59.8787134,5.7088125 57.4576253,8.0653125 57.4576253,13.0539375 C57.4576253,18.0421875 59.8171349,21.0541875 63.6778092,21.0541875 C65.1489356,21.0541875 66.5919011,20.2843125 67.2046828,19.6723125 L67.2046828,20.8359375 C67.2046828,22.7015625 65.9152881,23.9919375 63.7397632,23.9919375 C62.5956789,23.9919375 61.5492192,23.4624375 60.9773648,23.1088125 L60.9769893,23.1088125 L60.7633418,22.9816875 C60.6086444,22.8890625 60.4081387,22.9453125 60.3236559,23.1043125 L59.1945908,25.2339375 C59.1161157,25.3820625 59.1675563,25.5654375 59.3117402,25.6513125 L59.8299011,25.9603125 L59.8389126,25.9505625 C60.8557096,26.4954375 62.1657556,26.8070625 63.7397632,26.8070625 C68.2751732,26.8070625 70.3583303,24.4201875 70.3583303,20.1973125 L70.3583303,11.8299375 C70.3583303,7.7593125 67.7232192,5.7088125 64.0153648,5.7088125 Z"
                />
                <path
                  className={'cls-1'}
                  d="M48.5121778,5.7088125 C45.8564153,5.7088125 42.0588215,7.0201875 42.0588215,12.3733125 L42.0588215,20.5078125 C42.0588215,20.6889375 42.2060092,20.8359375 42.3873655,20.8359375 L45.2425073,20.8359375 C45.4238636,20.8359375 45.5710513,20.6889375 45.5710513,20.5078125 L45.5710513,20.2438125 L45.5710513,12.8480625 C45.5710513,10.3246875 46.4853425,8.9938125 48.5121778,8.9938125 C50.539764,8.9938125 51.4536797,10.3246875 51.4536797,12.8480625 L51.4510513,19.4023125 L51.4510513,20.5078125 C51.4510513,20.6889375 51.5986146,20.8359375 51.7795954,20.8359375 L54.63699,20.8359375 C54.8187218,20.8359375 54.9655341,20.6889375 54.9655341,20.5078125 L54.9655341,12.3733125 C54.9655341,7.0201875 51.1683157,5.7088125 48.5121778,5.7088125"
                />
                <path
                  className={'cls-1'}
                  d="M30.0290023,11.7494625 C30.1893318,9.7038375 31.5331709,8.6497125 33.3887877,8.6497125 C35.468941,8.6497125 36.3648337,10.1827125 36.3648337,11.7494625 L30.0290023,11.7494625 Z M33.3887877,5.7089625 C29.0366115,5.7089625 26.4769716,8.4573375 26.4769716,13.3477125 C26.4769716,18.6835875 29.2288567,21.0809625 33.6771556,21.0809625 C36.2172705,21.0809625 37.8322054,20.1322125 38.5640138,19.5509625 C38.7172092,19.4290875 38.7326038,19.2104625 38.6060674,19.0612125 L37.0406958,17.2207125 C36.9333088,17.0939625 36.7328031,17.0662125 36.591623,17.1524625 C35.8605655,17.5987125 34.9346345,17.9808375 33.6771556,17.9808375 C31.5650866,17.9808375 29.9332552,16.9267125 29.9332552,14.6253375 L37.8367111,14.6253375 C39.7884506,14.6253375 39.7884506,13.2509625 39.7884506,12.0689625 C39.7884506,9.8643375 38.7971862,5.7089625 33.3887877,5.7089625 Z"
                />
                <path
                  className={'cls-1'}
                  d="M26.2998958,6.7429875 C26.3926391,6.5678625 26.3066544,6.3514875 26.1185395,6.2881125 L25.3274054,6.0214875 L25.3251525,6.0244875 C24.5824552,5.8028625 23.8326238,5.7087375 23.1545088,5.7087375 C20.4983709,5.7087375 16.7011525,7.0201125 16.7011525,12.3736125 L16.7011525,20.5081125 C16.7011525,20.6888625 16.8479648,20.8358625 17.0296966,20.8358625 L19.8848383,20.8358625 C20.0661946,20.8358625 20.2133824,20.6888625 20.2133824,20.5077375 L20.2133824,12.8476125 C20.2133824,10.3238625 21.1272981,8.9937375 23.1545088,8.9937375 C23.5803019,8.9937375 23.9508996,9.0597375 24.2775663,9.1789875 L24.2775663,9.1793625 L24.6440337,9.2978625 C24.7927234,9.3462375 24.9549303,9.2809875 25.0277732,9.1426125 L26.2998958,6.7429875 Z"
                />
                <path
                  className={'cls-1'}
                  d="M13.4712828,14.741025 C13.6684092,14.741025 13.8287387,14.910525 13.8065854,15.10665 C13.4190912,18.4644 10.5575663,21.080775 7.09377318,21.080775 C3.6296046,21.080775 0.767704215,18.4644 0.38096092,15.10665 C0.358056705,14.910525 0.518386207,14.741025 0.715888123,14.741025 L3.35550498,14.741025 C3.51170421,14.741025 3.65250881,14.84715 3.68254713,15.00015 C3.99644751,16.596525 5.40524444,17.80515 7.09377318,17.80515 C8.78267739,17.80515 10.1914743,16.596525 10.5053747,15.00015 C10.535413,14.84715 10.6765931,14.741025 10.8327923,14.741025 L13.4712828,14.741025 Z M7.42622222,5.7088125 C12.1647663,5.7088125 13.711364,9.3249375 13.8442835,11.6694375 C13.8544215,11.8535625 13.702728,12.0009375 13.5183678,12.0009375 L10.887387,12.0009375 C10.7157931,12.0009375 10.5731111,11.8756875 10.5550881,11.7054375 C10.4672261,10.8654375 9.96445977,8.5254375 7.09392337,8.5254375 C4.19034483,8.5254375 3.70935632,10.9111875 3.62975479,11.7298125 C3.61511111,11.8824375 3.48970115,12.0009375 3.33575479,12.0009375 L0.612030651,12.0009375 C0.446819923,12.0009375 0.313149425,11.8674375 0.313149425,11.7020625 L0.313149425,10.4229375 L0.313149425,0.9324375 C0.313149425,0.5473125 0.625547893,0.2349375 1.01078927,0.2349375 L3.13862835,0.2341875 C3.52386973,0.2341875 3.8362682,0.5461875 3.8362682,0.9309375 L3.83664368,0.9309375 L3.83664368,6.0875625 L3.83664368,7.0119375 C4.48960153,6.3598125 5.85972414,5.7088125 7.42622222,5.7088125 Z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default BrengerLogo;

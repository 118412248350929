import { goBack, push } from 'connected-react-router';
import React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { Dispatch } from 'redux';
import { ProgressHeader, SheetLayout, SheetLayoutContentWrapper, StepIndicator } from '../../brenger-shared-ui';
import { r } from '../../routes';
import { RootState } from '../../typings';
import { translate } from '../../utils/localization';
import { RejectedByQuotesModal } from './components/RejectedByQuotesModal';
import { SidebarLayout } from './components/SidebarLayout';
import Destination from './containers/Destination';
import ItemsAndServices from './containers/ItemsAndServices';
import TransportPreview from './containers/TransportPreview';
import { actions, getProgressStep, getSheetState } from './ducks';
import '../../assets/business/business.scss';
type ReduxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

type Props = ReduxProps;

const BusinessFlow: React.FC<Props> = props => {
  React.useEffect(() => {
    props.fetchAddresses();
    props.fetchPopularItems();
    /* Take care of chat visibillity */
    document.body.classList.add('chat--is-visible');
    return () => {
      /* Remove class on unmount */
      document.body.classList.remove('chat--is-visible');
    };
  }, []);

  return (
    <>
      <ProgressHeader
        goBack={props.goBack}
        goBackText={String(translate('nav.previous'))}
        goBackAlwaysAvailable={true}
        currentStep={props.step}
        totalSteps={4}
        logoOnClick={event => {
          event.preventDefault();
          props.goToPath(r.user.dashboard());
        }}
      />
      <SheetLayout sheetOpen={props.isSheetOpen} sidebar={<SidebarLayout />}>
        <SheetLayoutContentWrapper className={'sheet-layout--content-wrapper--top-header'}>
          <div className={'sheet-layout--container'}>
            <StepIndicator currentStep={props.step}>
              <Translate id={'request_flow.business.step_' + props.step + '.title_small'} />
            </StepIndicator>
          </div>
        </SheetLayoutContentWrapper>
        <Switch>
          <Route path={r.businessFlow.destination()} exact={true} component={Destination} />
          <Route path={r.businessFlow.items()} exact={true} component={ItemsAndServices} />
          <Route path={r.businessFlow.preview()} exact={true} component={TransportPreview} />
          <Redirect to={r.businessFlow.destination()} />
        </Switch>
        <RejectedByQuotesModal />
      </SheetLayout>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState) => ({
  isSheetOpen: getSheetState(state),
  step: getProgressStep(state),
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: Dispatch) => ({
  goBack: () => dispatch(goBack()),
  goToPath: path => dispatch(push(path)),
  fetchAddresses: () => dispatch(actions.fetchAddressesStart()),
  fetchPopularItems: () => dispatch(actions.startPopularItems()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BusinessFlow);

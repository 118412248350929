import React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { RootState } from 'src/typings';
import '../../../assets/user/user.scss';
import { Button, Heading, RfInput, SectionWrapper } from '../../../brenger-shared-ui';
import { FlashMessage } from '../../../brenger-shared-ui/';
import { Col, Row } from '../../../brenger-shared-ui/components/layout/grid';
import { translate } from '../../../utils/localization';
import { actions } from '../ducks';
import { PersonSchema } from '../schema';
import { UserForms, UserProperties } from '../typings';

// FIX ME: Inject a proper form value interface
type FormValues = Record<string, unknown>;

type ReduxProps = ReturnType<typeof mapStateToProps>;

type Form = InjectedFormProps<FormValues, ReduxProps>;

type Props = ReduxProps & Form;

const PasswordReset: React.FC<Props> = props => {
  return (
    <SectionWrapper extraClasses={'login-wrapper'} sectionStyle={'white'} centerContent={true}>
      <form onSubmit={props.handleSubmit}>
        <Heading size={2}>
          <Translate id={'authentication.reset.headline'} />
        </Heading>
        <Row>
          <Col xs={12} extraClasses={'pt-1'}>
            <Field
              validate={PersonSchema[UserProperties.EMAIL]}
              name={'user.email'}
              type="text"
              component={RfInput}
              label={translate('form.fields.user.email.label')}
            />
          </Col>
          <Col xs={12} sm={12} extraClasses={'login-errors'}>
            {typeof props.error === 'string' && props.error !== '' && (
              <FlashMessage type={'error'} message={props.error} />
            )}
            {props.success && (
              <FlashMessage type={'success'} message={String(translate('authentication.reset.success'))} />
            )}
          </Col>
          <Col xs={12} sm={12}>
            <Button
              fullWidth={true}
              loading={props.submitting}
              disabled={props.submitting || props.pristine || !props.valid}
              buttonStyle={'secondary'}
            >
              <Translate id={'authentication.reset.button'} />
            </Button>
          </Col>
        </Row>
      </form>
    </SectionWrapper>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState) => ({
  submitting: state.user.password_reset.loading,
  success: state.user.password_reset.success,
  error: state.user.password_reset.error,
});

export default reduxForm<FormValues, Props>({
  form: UserForms.PASSWORD_RESET,
  destroyOnUnmount: false,
  onSubmit: (details, dispatch) => dispatch(actions.resetPass(details)),
})(connect(mapStateToProps)(PasswordReset));

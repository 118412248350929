import React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import classNames from 'classnames';

import { useTypedSelector } from '../../../../hooks';
import { CirclePerson2, Toast } from '../../../../brenger-shared-ui';
import { useNotification } from '../../../../hooks/useNotification';
import { getLocation } from 'connected-react-router';

export const OutsideOfServiceAreaNotification: React.FC = () => {
  const notification = useNotification('quotes-rejected-area');
  const rejected = useTypedSelector(state => state.generalTransport.price.rejected);
  const { pathname } = useTypedSelector(getLocation);

  React.useEffect(() => {
    const isNotificationActive = rejected === 'area.no_service_for_at_least_one_stop' && pathname.includes('items');
    if (isNotificationActive) {
      notification.add();
    } else {
      notification.remove();
    }
  }, [rejected, pathname]);

  return (
    <div className={classNames('info-message-container', { 'info-message--visible': notification.isVisible })}>
      <Toast prefix={<CirclePerson2 />} handleClose={notification.remove}>
        <div style={{ paddingRight: '15px' }}>
          <b>
            <Translate id={'request_flow.notification.outside_of_service_area.title'} />
          </b>
        </div>
        <Translate id={'request_flow.notification.outside_of_service_area.text'} />
      </Toast>
    </div>
  );
};

// init state
import { Address, CountryCode, UserMe, UserRole } from '@brenger/api-client';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { RootState } from '../../typings/interfaces';
import { PERSIST_VERSION } from '../../utils/global';
import { User, UserActionTypes } from './typings';

export const defaultState: User = {
  id: null,
  fetching: false,
  loggedIn: false,
  loggedInMethod: null,
  userData: null,
  errors: '',
  details: null,
  loading: false,
  registered: false,
  isBusiness: false,
  what_is_my_country: 'NL',
  billingAddress: null,
  password_reset: {
    loading: false,
    error: '',
    success: false,
  },
  password_new: {
    token: '',
    loading: false,
    error: '',
    success: false,
  },
};

// actions
export const actions = {
  setWhatIsMyCountry: (country: CountryCode) => ({ type: UserActionTypes.SET_WHAT_IS_MY_COUNTRY, payload: country }),
  socialAuthStart: (service, registered) => ({ type: UserActionTypes.SOCIAL_AUTH_START, service, registered }),
  register: user => ({ type: UserActionTypes.REGISTER_USER_START, payload: user }),
  registerFailed: (message: string) => ({ type: UserActionTypes.REGISTER_USER_FAILED, payload: message }),
  registerSuccess: user => ({ type: UserActionTypes.REGISTER_USER_SUCCESS, payload: user }),
  registerErrorReset: () => ({ type: UserActionTypes.REGISTER_USER_ERROR_RESET }),
  socialAuthSuccess: user => ({ type: UserActionTypes.SOCIAL_AUTH_SUCCESS, payload: user }),
  socialAuthFailed: user => ({ type: UserActionTypes.SOCIAL_AUTH_FAILED, payload: user }),
  initAuthentication: () => ({ type: UserActionTypes.CREATE_ACCOUNT_INIT }),
  login: user => ({ type: UserActionTypes.LOGIN_USER_START, payload: user }),
  loginSuccess: () => ({ type: UserActionTypes.LOGIN_USER_SUCCESS }),
  loginFailed: err => ({ type: UserActionTypes.LOGIN_USER_FAILED, payload: err }),
  resetPass: details => ({ type: UserActionTypes.RESET_PASS_START, payload: details }),
  resetPassSuccess: () => ({ type: UserActionTypes.RESET_PASS_SUCCESS }),
  resetPassFailed: message => ({ type: UserActionTypes.RESET_PASS_FAILED, payload: message }),
  newPass: details => ({ type: UserActionTypes.NEW_PASS_START, payload: details }),
  newPassSuccess: () => ({ type: UserActionTypes.NEW_PASS_SUCCESS }),
  newPassFailed: message => ({ type: UserActionTypes.NEW_PASS_FAILED, payload: message }),
  setNewPassToken: token => ({ type: UserActionTypes.SET_NEW_PASS_TOKEN, payload: token }),
  setUser: (userDetails: UserMe | null) => ({ type: UserActionTypes.SET_USER_DETAILS, payload: userDetails }),
  setAccountType: isBusiness => ({ type: UserActionTypes.SET_ACCOUNT_TYPE, payload: isBusiness }),
  retrieveCurrentUser: () => ({ type: UserActionTypes.RETRIEVE_CURRENT_USER }),
  retrieveCurrentCountry: () => ({ type: UserActionTypes.RETRIEVE_CURRENT_COUNTRY }),
  setBillingAddress: (address: Address) => ({ type: UserActionTypes.SET_BILLING_ADDRESS, payload: address }),
};

// selectors
export const getLoggedInUser = (state: RootState): User => {
  return state.user;
};

export const getUserRoles = (state: RootState): UserRole[] => {
  return state.user.userData?.roles || [];
};

export const getWhatIsMyCountry = (state: RootState): CountryCode => {
  return state.user.what_is_my_country;
};

export const isUserLoggedIn = (state: RootState): boolean => {
  return state.user.loggedIn;
};

export const isRegistered = (state: RootState): boolean => {
  return state.user.registered;
};

export const isSocialRegistration = (state: RootState): boolean => {
  return getUserRoles(state).includes('ROLE_NEEDS_SOCIAL_REGISTRATION');
};

export const getResetToken = (state: RootState): string => {
  return state.user.password_new.token;
};

// reducers
export const userReducer = (state = defaultState, action): User => {
  switch (action.type) {
    case UserActionTypes.CREATE_ACCOUNT_INIT:
      return {
        ...state,
        errors: '',
        loading: false,
        fetching: false,
        registered: false,
        password_reset: {
          ...state.password_reset,
          loading: false,
          error: '',
          success: false,
        },
        password_new: {
          ...state.password_new,
          loading: false,
          error: '',
          success: false,
        },
      };
    case UserActionTypes.SET_ACCOUNT_TYPE:
      return {
        ...state,
        isBusiness: action.payload === 'business',
      };
    case UserActionTypes.SET_WHAT_IS_MY_COUNTRY:
      return {
        ...state,
        what_is_my_country: action.payload,
      };
    case UserActionTypes.SET_USER_DETAILS:
      return {
        ...state,
        userData: action.payload,
        loading: false,
        loggedIn: Boolean(action.payload),
        errors: '',
      };
    case UserActionTypes.LOGIN_USER_START:
      return {
        ...state,
        loading: true,
        errors: '',
      };
    case UserActionTypes.LOGIN_USER_FAILED:
      return {
        ...state,
        errors: action.payload,
        loading: false,
        loggedIn: false,
        fetching: false,
        loggedInMethod: null,
        userData: null,
        details: null,
      };
    case UserActionTypes.LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        loggedIn: true,
        loggedInMethod: 'manual',
        errors: '',
      };
    case UserActionTypes.REGISTER_USER_START:
      return {
        ...state,
        loading: true,
        errors: '',
      };
    case UserActionTypes.REGISTER_USER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UserActionTypes.REGISTER_USER_FAILED:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case UserActionTypes.REGISTER_USER_ERROR_RESET:
      return {
        ...state,
        errors: '',
      };
    case UserActionTypes.SOCIAL_AUTH_START:
      return {
        ...state,
        fetching: true,
        errors: '',
        loggedInMethod: action.service,
        registered: action.registered,
      };
    case UserActionTypes.SOCIAL_AUTH_FAILED:
      return {
        ...state,
        fetching: false,
        errors: action.payload,
      };
    case UserActionTypes.SOCIAL_AUTH_SUCCESS:
      return {
        ...state,
        id: action.payload['@id'],
        fetching: false,
        errors: '',
        loggedIn: true,
        details: action.payload,
      };
    case UserActionTypes.RESET_PASS_START:
      return {
        ...state,
        password_reset: {
          ...state.password_reset,
          success: false,
          loading: true,
          error: '',
        },
      };
    case UserActionTypes.RESET_PASS_SUCCESS:
      return {
        ...state,
        password_reset: {
          ...state.password_reset,
          success: true,
          loading: false,
          error: '',
        },
      };
    case UserActionTypes.RESET_PASS_FAILED:
      return {
        ...state,
        password_reset: {
          ...state.password_reset,
          success: false,
          loading: false,
          error: action.payload,
        },
      };
    case UserActionTypes.NEW_PASS_START:
      return {
        ...state,
        password_new: {
          ...state.password_new,
          success: false,
          loading: true,
          error: '',
        },
      };
    case UserActionTypes.NEW_PASS_SUCCESS:
      return {
        ...state,
        password_new: {
          ...state.password_new,
          success: true,
          loading: false,
          error: '',
        },
      };
    case UserActionTypes.NEW_PASS_FAILED:
      return {
        ...state,
        password_new: {
          ...state.password_new,
          success: false,
          loading: false,
          error: action.payload,
        },
      };
    case UserActionTypes.SET_NEW_PASS_TOKEN:
      return {
        ...state,
        password_new: {
          ...state.password_new,
          token: action.payload,
        },
      };
    case UserActionTypes.SET_BILLING_ADDRESS:
      return {
        ...state,
        billingAddress: action.payload,
      };
    default:
      return state;
  }
};

const persistConfig = {
  key: 'user',
  blacklist: ['password_new', 'password_reset'],
  version: PERSIST_VERSION,
  storage,
};

export default persistReducer(persistConfig, userReducer);

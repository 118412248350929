import React from 'react';
import './icons.scss';

const IconQuestionBubble = (): JSX.Element => {
  return (
    <svg className={'IconQuestionBubble'} width="33" height="40" viewBox="0 0 33 40">
      <path
        className={'bubble'}
        d="M25.782 39.472c-.021 0-.043 0-.064-.002a.832.832 0 0 1-.723-.574l-2.127-6.437a15.969 15.969 0 0 1-6.455 1.347C9.218 33.806 2.752 28.9.69 21.879a.85.85 0 0 1 .558-1.054.828.828 0 0 1 1.034.568c1.853 6.311 7.664 10.719 14.13 10.719 2.307 0 4.52-.53 6.576-1.576a.817.817 0 0 1 .689-.026c.221.094.393.28.47.513l1.773 5.369c1.348-2.96 3.876-9.038 4.847-14.897a.837.837 0 0 1 .958-.695.846.846 0 0 1 .68.977c-1.39 8.385-5.704 16.883-5.887 17.24a.83.83 0 0 1-.736.455zM15.483 20.236a2.135 2.135 0 0 1-.66-1.545c0-2.291 3.46-2.436 3.46-4.364 0-.89-.66-1.4-1.8-1.4-1 0-1.678.51-1.678 1.528a1 1 0 0 1-.98 1 1 1 0 0 1-.982-1c0-2.073 1.356-3.31 3.64-3.31 2.354 0 3.727 1.146 3.727 3.182 0 2.928-3.407 3.146-3.407 4.237 0 .145.054.254.179.436.107.164.16.345.16.545a.977.977 0 0 1-.963.982.945.945 0 0 1-.696-.29zm.642 3.546c-.749 0-1.177-.382-1.177-1.182 0-.818.428-1.218 1.177-1.218.75 0 1.16.4 1.16 1.218 0 .8-.41 1.182-1.16 1.182zM.85 18.686a.838.838 0 0 1-.83-.807c-.012-.266-.02-.534-.02-.803C0 7.852 7.364.346 16.414.346s16.413 7.506 16.413 16.73c0 .262-.006.527-.016.793a.844.844 0 0 1-.859.817.84.84 0 0 1-.801-.876c.008-.246.014-.491.014-.734 0-8.29-6.617-15.035-14.751-15.035S1.663 8.785 1.663 17.076c0 .243.006.484.017.723a.84.84 0 0 1-.83.887z"
      />
    </svg>
  );
};

export default IconQuestionBubble;

import React from 'react';
import cn from 'classnames';

export const IconPhone: React.FC<React.SVGAttributes<SVGElement>> = ({ className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={'1em'}
      height={'1em'}
      viewBox="0 0 219.8 219.77"
      className={cn('icon-phone', className)}
      fill={'currentColor'}
      {...rest}
    >
      <path d="M170.36 219.77c-14.65-.3-26.76-4.11-38.57-9.07-21.4-9-40.35-21.86-57.87-36.94A259 259 0 0 1 29.06 124c-12-17.4-21.8-35.86-26.77-56.57C-.09 57.48-1.24 47.46 2 37.55a27.55 27.55 0 0 1 4.9-8.83C13.22 21.09 20 13.81 26.42 6.3 30.4 1.68 35-1 41.31.35c4 .86 7 3.29 9.85 6.08Q59.74 15 68.27 23.6c5.46 5.5 10.92 11 16.34 16.53 6 6.12 6.2 14.07.37 20.34-4.14 4.45-8.61 8.59-12.79 13a28 28 0 0 0-4.42 6 9.59 9.59 0 0 0 1.92 11q29.86 29.88 59.74 59.74c2.69 2.68 7.23 3.73 10.6 2a27.91 27.91 0 0 0 6.23-4.48c4.25-4 8.23-8.26 12.45-12.28 6.84-6.53 14.79-6.48 21.48.13q16.53 16.35 32.93 32.81c3.12 3.14 6 6.49 6.54 11.2.54 4.51-.47 8.5-3.89 11.57-7.29 6.58-15 12.79-22 19.65s-15.65 9.01-23.41 8.96Z" />
    </svg>
  );
};

import { AddressCreateParams, PopularItem, PriceList } from '@brenger/api-client';
import { QuoteRejectedReasonsBusiness } from './interface';

export enum BusinessActionTypes {
  SUBMIT_DESTINATION = `@@BusinessFlow/SUBMIT_DESTINATION`,
  SUBMIT_ITEMS = `@@BusinessFlow/SUBMIT_ITEMS`,
  SET_BUSINESS_DOMAIN_NAME = `@@BusinessFlow/SET_BUSINESS_DOMAIN_NAME`,
  SET_SHEET_STATE = `@@BusinessFlow/SET_SHEET_OPEN`,
  SET_PROGRESS_STEP = `@@BusinessFlow/SET_PROGRESS_STEP`,
  SET_TR_ID = `@@BusinessFlow/SET_TR_ID`,
  RESET_FLOW = `@@BusinessFlow/RESET_FLOW`,
  START_NEW_TR = `@@BusinessFlow/START_NEW_TR`,
  CREATE_TR = `@@BusinessFlow/CREATE_TR`,
  SET_HAS_BUSINESS_FLOW = `@@BusinessFlow/SET_HAS_BUSINESS_FLOW`,
  GET_PRICE = `@@BusinessFlow/GET_PRICE`,
  SET_PRICE = `@@BusinessFlow/SET_PRICE`,
  SET_PRICE_LOADING = `@@BusinessFlow/SET_PRICE_LOADING`,
  SET_DATE_TIME_PERIODS_OPTIONS = `@@BusinessFlow/SET_DATE_TIME_PERIODS_OPTIONS`,
  SET_DATE_TIME_START_FROM = `@@BusinessFlow/SET_DATE_TIME_START_FROM`,
  GET_NEXT_DATE_TIME_PERIOD_OPTIONS = `@@BusinessFlow/GET_NEXT_DATE_TIME_PERIODS_OPTIONS`,
  FETCH_ADDRESSES = `@@BusinessFlow/FETCH_BUSINESS_ADDRESS_START`,
  SET_BUSINESS_DEPOT_ADDRESSES = `@@BusinessFlow/FETCH_BUSINESS_ADDRESS_SUCCESS`,
  UPDATE_PRICE_LIST = `@@BusinessFlow/UPDATE_PRICE_LIST`,
  FETCH_GEO_DETAILS = `@@BusinessFlow/FETCH_GEO_DETAILS`,
  START_POPULAR_ITEMS = `@@BusinessFlow/START_POPULAR_ITEMS`,
  SET_POPULAR_ITEMS = `@@BusinessFlow/SET_POPULAR_ITEMS`,
  SET_REJECTED_BY_PRICING = `@@BusinessFlow/SET_REJECTED_BY_PRICING`,
}

export interface DepotAddress {
  address: AddressCreateParams;
  contact: {
    first_name: string;
    last_name: string;
    phone: string;
    email: string;
  };
  details: {
    instructions: string;
  };
}

export interface SimpleBusinessAction {
  type: BusinessActionTypes.START_POPULAR_ITEMS;
}
export interface SetDepotAddressesAction {
  type: BusinessActionTypes.SET_BUSINESS_DEPOT_ADDRESSES;
  payload: DepotAddress[];
}

export interface SetPopularItemsAction {
  type: BusinessActionTypes.SET_POPULAR_ITEMS;
  payload: PopularItem[];
}

export interface SetRejectedByPricing {
  type: BusinessActionTypes.SET_REJECTED_BY_PRICING;
  payload: QuoteRejectedReasonsBusiness | null;
}

export interface UpdateBusinessPriceList {
  type: BusinessActionTypes.UPDATE_PRICE_LIST;
  payload: PriceList | null;
}

import * as React from 'react';
import InputWrapper from '../input/InputWrapper';
import InputFeedback from '../input/InputFeedback';
// import { InputOptionsProps } from "../inputpaymentselect/InputPaymentSelect";
import './inputswitch.scss';
import { InputProps } from '../input/Input';

export interface SwitchOptionsProps extends InputProps {
  options: {
    name: string;
    value: string;
    label: JSX.Element;
  }[];
}

const InputSwitch = ({ input, meta, successMessage, options }: SwitchOptionsProps): JSX.Element => {
  return (
    <InputWrapper input={input} meta={meta} type={'switch'}>
      <div className={'input-el--switch-options'}>
        {options?.map((option, index) => {
          return (
            <div key={index}>
              <input
                id={input.name + '_' + index}
                type={'radio'}
                {...input}
                checked={input.value === option.value}
                value={option.value}
              />
              <label className={'input-el--switch--label'} htmlFor={input.name + '_' + index}>
                {option.label}
              </label>
            </div>
          );
        })}
      </div>
      <InputFeedback input={input} meta={meta} error={meta.error} successMessage={successMessage} />
    </InputWrapper>
  );
};

export default InputSwitch;

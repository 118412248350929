import { StopSituation } from '@brenger/api-client';
import React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { useDispatch } from 'react-redux';
import {
  FlashMessage,
  Heading,
  IconAuction,
  IconHome,
  IconShop,
  IconVan,
  InputTiles,
  SheetLayoutContent,
  SheetLayoutContentWrapper,
  SheetLayoutHeader,
} from '../../../brenger-shared-ui';
import { useTypedSelector } from '../../../hooks';
import { translate } from '../../../utils/localization';
import FooterLayout from '../containers/FooterLayout';
import { actions, getPickupDestinationDetails } from '../ducks';
import { proceed } from '../proceed';

interface SituationOption {
  value: StopSituation;
  title: string;
  desc: string;
  prefix: JSX.Element;
}

export const SubStepSituation: React.FC = () => {
  const dispatch = useDispatch();
  const situation = useTypedSelector(state => state.generalTransport.choices?.situation);
  const errors = useTypedSelector(state => state.generalTransport.error);
  const widgetDetails = useTypedSelector(getPickupDestinationDetails);
  const situationOptions: SituationOption[] = [
    {
      value: 'home',
      title: String(translate('request_flow.situation.house')),
      desc: String(translate('request_flow.situation.house_explain')),
      prefix: <IconHome />,
    },
    {
      value: 'store',
      title: String(translate('request_flow.situation.shop')),
      desc: String(translate('request_flow.situation.shop_explain')),
      prefix: <IconShop />,
    },
    {
      value: 'auction',
      title: String(translate('request_flow.situation.auction')),
      desc: String(translate('request_flow.situation.auction_explain')),
      prefix: <IconAuction />,
    },
    {
      value: 'book_a_van',
      title: String(translate('request_flow.situation.book_a_van_situation')),
      desc: String(translate('request_flow.situation.book_a_van_situation_explain')),
      prefix: <IconVan />,
    },
  ];

  React.useEffect(() => {
    dispatch(actions.resetChoice(['situation']));
  }, []);

  return (
    <SheetLayoutContentWrapper>
      <SheetLayoutHeader>
        <Heading size={2}>
          <Translate
            id={'request_flow.pickup.situation_question'}
            data={{ locality: <span className={'text--primary'}>{widgetDetails?.locality}</span> }}
          />
        </Heading>
      </SheetLayoutHeader>
      <SheetLayoutContent>
        <div className={'question-wrapper'}>
          <InputTiles
            value={situation}
            id={'situation'}
            name={'situation'}
            options={situationOptions}
            onChange={newSituation => {
              dispatch(actions.setChoice({ situation: newSituation as StopSituation }));
              proceed();
            }}
          />
        </div>
        <div className={'general-errors-wrapper'}>{errors && <FlashMessage type={'error'} message={errors} />}</div>
      </SheetLayoutContent>
      <FooterLayout customSubmitFunction={proceed} />
    </SheetLayoutContentWrapper>
  );
};

import { Translate } from 'react-localize-redux-dep-updated';
import { FlashMessage } from '../../../../brenger-shared-ui';
import * as React from 'react';
import { toggleChat } from '../HelpModal';
import { getActiveLanguageSettings } from '../../../../utils/localization';

export type ContactPageError = 'none' | 'address' | 'contact';

interface Props {
  type: ContactPageError;
  setMessage(type: ContactPageError): void;
}

export const AddressError: React.FC<Props> = ({ type, setMessage }) => {
  const langSettings = getActiveLanguageSettings();
  if (type === 'address') {
    return (
      <FlashMessage type="info">
        <Translate
          id="request_flow.address_error.cta_to_contact"
          data={{
            contact_us: (
              <span onClick={() => setMessage('contact')} className="trigger text--underline">
                <Translate id="request_flow.address_error.contact_cta" />
              </span>
            ),
          }}
        />
      </FlashMessage>
    );
  }
  if (type === 'contact') {
    return (
      <FlashMessage type="info">
        <Translate
          id="request_flow.address_error.contact_options"
          data={{
            phone: (
              <div style={{ display: 'inline-block' }}>
                <a href={`tel:${langSettings.contact.phone.customer}`}>{langSettings.contact.phone.customer}</a>
              </div>
            ),
            chat: (
              <span onClick={() => toggleChat()} className="trigger text--underline">
                chat
              </span>
            ),
          }}
        />
      </FlashMessage>
    );
  }
  return null;
};

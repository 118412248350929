import { CountryCode } from '@brenger/api-client';
import { translate } from '../../utils/localization';
import {
  atLeastOneNumberRegex,
  numericMax,
  numericMin,
  postalCodeRegexNL,
  required,
  testRegex,
} from '../../utils/validation';
import { BusinessDeliveryAddress, BusinessDestinationFormValues } from './containers/Destination';

export const BusinessSchema = {
  date: value =>
    required(value, translate('form.fields.default.required', { field: translate('form.fields.datetime.date.label') })),
  weight: [
    value =>
      numericMin(
        value,
        1,
        translate('form.fields.default.required', { field: translate('request_flow.business.fields.weight.label') })
      ),
    value => numericMax(value, 300),
  ],
  autocompleteFullAddress: (value: BusinessDeliveryAddress): string | undefined => {
    if (value === null || typeof value === 'string') {
      return translate('form.fields.address.autocomplete.select_option');
    }
    if (
      !value?.address?.line1 ||
      !value?.address?.postal_code ||
      !value?.address?.lat ||
      !value?.address?.lng ||
      !value?.address?.country_code
    ) {
      return translate('form.fields.address.autocomplete.valid_not_full_address');
    }
    const countryCodesWithNumber: CountryCode[] = ['NL', 'DE', 'LU'];
    if (countryCodesWithNumber.includes(value?.address?.country_code)) {
      return testRegex(
        value.address.line1 || '',
        atLeastOneNumberRegex,
        translate('form.fields.address.autocomplete.valid_with_number')
      );
    }
  },
  line1: (value: string, allValues: BusinessDestinationFormValues): string | undefined => {
    if (!value) {
      return translate('form.fields.default.required', { field: translate('form.fields.address.line1.label') });
    }
    const addressType = allValues?.delivery_address_is_manual_address
      ? allValues.delivery_manual_address
      : allValues?.delivery_auto_address;
    if (!addressType || typeof addressType === 'string') {
      return;
    }
    const countryCode = addressType.address?.country_code;
    // if dealing with NL addresses
    if (countryCode === 'NL') {
      return testRegex(value, atLeastOneNumberRegex, translate('form.fields.address.line1.valid_with_number'));
    }
  },
  postalCode: (value: string, allValues: BusinessDestinationFormValues): string | undefined => {
    const addressType = allValues?.delivery_address_is_manual_address
      ? allValues.delivery_manual_address
      : allValues?.delivery_auto_address;
    if (!addressType || typeof addressType === 'string') {
      return;
    }
    const countryCode = addressType.address?.country_code;
    if (countryCode === 'NL') {
      return testRegex(value, postalCodeRegexNL, translate('form.fields.address.postal_code.valid'));
    }
    return undefined;
  },
  locality: value =>
    required(
      value,
      translate('form.fields.default.required', { field: translate('form.fields.address.locality.label') })
    ),
  country_code: value =>
    required(
      value,
      translate('form.fields.default.required', { field: translate('form.fields.address.country_code.label') })
    ),
  administrative_area: value =>
    required(
      value,
      translate('form.fields.default.required', { field: translate('form.fields.address.administrative_area.label') })
    ),
  secondary_subdivision: value =>
    required(
      value,
      translate('form.fields.default.required', { field: translate('form.fields.address.secondary_subdivision.label') })
    ),
  latitude: value =>
    numericMin(
      value,
      0,
      translate('form.fields.default.required', { field: translate('form.fields.address.latitude.label') })
    ),
  longitude: value =>
    numericMin(
      value,
      0,
      translate('form.fields.default.required', { field: translate('form.fields.address.longitude.label') })
    ),
};

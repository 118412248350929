import React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { getLocation } from 'connected-react-router';
import classNames from 'classnames';
import { QuoteNotGuaranteedReason } from '@brenger/api-client';

import { useTypedSelector } from '../../../../hooks';
import { CirclePerson2, Toast } from '../../../../brenger-shared-ui';
import { useNotification } from '../../../../hooks/useNotification';

interface INotGuaranteedValue {
  activePathnames: string[];
  isBlocked: boolean;
}

/**
 * ATTENTION
 * This list of reasons is used for two occasions:
 * 1) Showing a notification
 * 2) Based on `isBlocked` we use this to block posting the TR. At the terms pages we show the reason why the TR is blocked.
 */
export const quoteReasonPathBlockedMap: { [key in QuoteNotGuaranteedReason]?: INotGuaranteedValue } = {
  'area.outside_guaranteed': {
    activePathnames: ['items'],
    isBlocked: false,
  },
  'area.directly_payable_not_guaranteed': {
    activePathnames: ['items'],
    isBlocked: false,
  },
  'area.max_distance_exceeded': {
    activePathnames: ['items'],
    isBlocked: false,
  },
  'item.one_over_200kg': {
    activePathnames: ['items'],
    isBlocked: true,
  },
  'item.togheter_over_200kg': {
    activePathnames: ['items'],
    isBlocked: false,
  },
  'item.too_many': {
    activePathnames: ['weight'],
    isBlocked: true,
  },
  'item.too_large': {
    activePathnames: ['items', 'weight'],
    isBlocked: false,
  },
  'extra_care.not_guaranteed': {
    activePathnames: ['items'],
    isBlocked: false,
  },
  'pickup.date.holiday': {
    activePathnames: ['contact', 'time', 'help'],
    isBlocked: false,
  },
  'product_selection.overwrite': {
    activePathnames: ['items'],
    isBlocked: false,
  },
  'product_group.non-guaranteed.overwrite': {
    activePathnames: ['items'],
    isBlocked: false,
  },
  // This notification is not visible
  // The reason you don't see it: we skip the floor step, so they aren't able to select it.
  // But if they somehow did "by hAcKInG", then we block the TR anyway.
  'pickup.too_many_items_for_floor_level': {
    activePathnames: ['pickup/home_and_floor'],
    isBlocked: true,
  },
  // This notification is not visible
  'delivery.too_many_items_for_floor_level': {
    activePathnames: ['delivery/home_and_floor'],
    isBlocked: true,
  },
};

/**
 * This notification responds directly to the non-guaranteed reasons returned by
 * the Pricing API.
 */
export const NotGuaranteedNotification: React.FC = () => {
  const notification = useNotification('not-guaranteed');
  const { pathname } = useTypedSelector(getLocation);
  const quote = useTypedSelector(state => state.generalTransport.quote);
  const notGuaranteedReason = quote?.guaranteed?.reason;
  const activePathnames = notGuaranteedReason && quoteReasonPathBlockedMap[notGuaranteedReason]?.activePathnames;
  const isBlocked = notGuaranteedReason && quoteReasonPathBlockedMap[notGuaranteedReason]?.isBlocked;

  React.useEffect(() => {
    const isNotificationActive = activePathnames?.some(p => pathname.includes(p));
    if (isNotificationActive) {
      notification.add();
    } else {
      notification.remove();
    }
  }, [notGuaranteedReason, pathname]);

  // Handle differnece in heading text depending on whether it's a fully blocked non-guaranteed job
  let headingTransID = 'request_flow.not_guaranteed.heading';
  if (isBlocked) {
    headingTransID = headingTransID.concat('_blocked');
  }

  return (
    <div className={classNames('info-message-container', { 'info-message--visible': notification.isVisible })}>
      <Toast prefix={<CirclePerson2 />} handleClose={notification.remove}>
        <div style={{ paddingRight: '15px' }}>
          <b>
            <Translate id={headingTransID} />
          </b>
        </div>
        {notGuaranteedReason && <Translate id={`request_flow.not_guaranteed.${notGuaranteedReason}`} />}
      </Toast>
    </div>
  );
};

import React from 'react';
import { RfInput, RfInputHidden, RfSelect } from '../../../brenger-shared-ui';
import { translate } from '../../../utils/localization';
import { CompanySchema } from '../schema';
import { Company } from '../typings';
import { Field } from 'redux-form';
import { normalizePostalCode } from '../../../utils/normalisation';
import { SelectOption } from '../../../brenger-shared-ui/components/reduxForm/select/Select';

export const CompanyDetails: React.FC = () => {
  const countries: SelectOption[] = [
    {
      label: 'NL',
      value: 'NL',
    },
    {
      label: 'BE',
      value: 'BE',
    },
  ];
  return (
    <>
      <Field
        required
        component={RfInput}
        type="text"
        name={'user.account.name'}
        label={translate('form.fields.account.account_name.label')}
        validate={CompanySchema[Company.NAME]}
      />
      <Field
        required
        validate={CompanySchema[Company.LINE1]}
        name={`user.account.address.line1`}
        type="text"
        component={RfInput}
        label={translate('form.fields.address.line1.label')}
      />
      <Field
        required
        validate={CompanySchema[Company.POSTAL_CODE]}
        name={`user.account.address.postal_code`}
        type="text"
        component={RfInput}
        normalize={normalizePostalCode}
        label={translate('form.fields.address.postal_code.label')}
      />
      <Field
        required
        validate={CompanySchema[Company.LOCALITY]}
        name={`user.account.address.locality`}
        type="text"
        component={RfInput}
        label={translate('form.fields.address.locality.label')}
      />
      <Field
        required
        validate={CompanySchema[Company.COUNTRY]}
        name={`user.account.address.country_code`}
        type="text"
        component={RfSelect}
        emptyFirstOption={false}
        options={countries}
        label={translate('form.fields.address.country.label')}
      />
      <Field name={`user.account.address.country_code`} type="text" component={RfInputHidden} />
    </>
  );
};

export default CompanyDetails;

import _get from 'lodash/get';
import { RootState } from 'src/typings';
import { getFormValues } from 'redux-form';
import { ProcessedFileData, Base } from 'src/typings/interfaces';
import {
  BaseActionTypes,
  GeneralBaseAction,
  GenericExtraInputFileFields,
  RemoveFileUploadPayload,
  RemoveUploadedFileAction,
  SelectProductDimensionAction,
} from '../../typings/enums';
import { FormNames } from 'src/typings/types';
import { PopularItem } from '@brenger/api-client';

const defaultState: Base = {
  loading: true,
};

// actions
export const actions = {
  cleanLocalStorage: (): GeneralBaseAction => ({ type: BaseActionTypes.CLEAN_LOCAL_STORAGE }),
  startLoading: (): GeneralBaseAction => ({ type: BaseActionTypes.START_APP_LOADING }),
  stopLoading: (): GeneralBaseAction => ({ type: BaseActionTypes.STOP_APP_LOADING }),
  selectProductDimensions: (
    dimension: PopularItem | null,
    meta: {
      form: FormNames;
      field: string;
      value: string;
    }
  ): SelectProductDimensionAction => ({
    type: BaseActionTypes.SELECT_PRODUCT_DIMENSION,
    payload: dimension,
    meta,
  }),
  removeUploadedFile: (payload: RemoveFileUploadPayload): RemoveUploadedFileAction => ({
    type: BaseActionTypes.REMOVE_UPLOADED_FILE,
    payload,
  }),
};

// generic uploads selectors
export const getUploadsByFormAndFieldName = (
  state: RootState,
  form: FormNames,
  fieldName: string,
  fromDuplicateCollection = false
): ProcessedFileData[] => {
  const formValues = getFormValues(form)(state);
  const collection = fromDuplicateCollection
    ? GenericExtraInputFileFields.COLLECTION_DUP
    : GenericExtraInputFileFields.COLLECTION;
  return _get(formValues, `${fieldName}_${collection}`, []);
};

export const getIsUploadingByFormAndFieldName = (state: RootState, form: FormNames, fieldName: string): boolean => {
  const formValues = getFormValues(form)(state);
  return _get(formValues, `${fieldName}_${GenericExtraInputFileFields.UPLOADING}`, false);
};

export const getUploadFeedbackByFormAndFieldName = (state: RootState, form: FormNames, fieldName: string): string => {
  const formValues = getFormValues(form)(state);
  return _get(formValues, `${fieldName}_${GenericExtraInputFileFields.UPLOAD_FEEDBACK}`, '');
};

export const getShouldThisFieldUploadTwice = (state: RootState, form: FormNames, fieldName: string): boolean => {
  const formState = state.form[form];
  return (
    typeof _get(formState, `registeredFields.${fieldName}_${GenericExtraInputFileFields.COLLECTION_DUP}`) !==
    'undefined'
  );
};

export const getUploadedFileDataByFormAndFieldName = (
  state: RootState,
  form: FormNames,
  fieldName: string,
  dataType: '@id' | 'original_name' | 'key',
  fromDuplicateCollection = false
): string[] => {
  const collection: ProcessedFileData[] = getUploadsByFormAndFieldName(state, form, fieldName, fromDuplicateCollection);
  return collection.map((file: ProcessedFileData) => {
    return file[dataType];
  });
};

// reducers
export const base = (state: Base = defaultState, action: GeneralBaseAction): Base => {
  switch (action.type) {
    case BaseActionTypes.STOP_APP_LOADING:
    case BaseActionTypes.START_APP_LOADING:
      return {
        ...state,
        loading: action.type === BaseActionTypes.START_APP_LOADING,
      };
    default:
      return state;
  }
};

import * as React from 'react';
import { Col, RfSelect } from '../../../brenger-shared-ui';
import { Field } from 'redux-form';
import { BusinessFields, FloorLevelOptions } from '../interface';
import { translate } from '../../../utils/localization';
import { required } from '../../../utils/validation';
import { floorAsString } from '../../../utils/floors';
import { priceAsString } from '../../../utils/price';

export const FloorElevatorFields: React.FC = () => {
  const charge = `(${priceAsString({ price: { amount: 1000 } })})`;
  const floorOptions = [
    {
      value: 0 + '|' + FloorLevelOptions.STAIRS,
      label: translate('request_flow.floor_select.option_0'),
    },
  ];
  let floor = 1;
  do {
    floorOptions.push({
      value: floor + '|' + FloorLevelOptions.STAIRS,
      label: `${floorAsString(floor) + ' ' + translate('request_flow.business.fields.floor.label')} ${translate(
        'request_flow.business.fields.floor.without_elevator'
      )} ${charge}`,
    });
    floor++;
  } while (floor <= 4);
  floor = 1;
  do {
    floorOptions.push({
      value: floor + '|' + FloorLevelOptions.ELEVATOR,
      label: `${
        floor === 5 ? '5+' : floorAsString(floor) + ' ' + translate('request_flow.business.fields.floor.label')
      } ${translate('request_flow.business.fields.floor.with_elevator')} ${charge}`,
    });
    floor++;
  } while (floor <= 5);
  return (
    <Col xs={12} sm={6}>
      <Field
        label={translate('request_flow.business.fields.floor.label')}
        name={BusinessFields.DELIVERY_FLOOR_LEVEL}
        validate={value => {
          return required(
            value,
            translate('form.fields.default.required', {
              field: translate('request_flow.business.fields.floor.label'),
            })
          );
        }}
        component={RfSelect}
        options={floorOptions}
      />
    </Col>
  );
};

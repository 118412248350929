import * as React from 'react';
import './toast.scss';
import { IconCross } from '../../basics/icons';

interface Props {
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  handleClose?: React.MouseEventHandler;
  children: React.ReactNode;
}

export const Toast = ({ prefix, suffix, handleClose, children }: Props): JSX.Element => {
  return (
    <div className={'info-message'}>
      <div className={'info-message--wrapper'}>
        {prefix && <div className={'info-message--prefix'}>{prefix}</div>}
        <div className={'info-message--content'}>{children}</div>
        {suffix && <div className={'info-message--suffix'}>{suffix}</div>}
        {handleClose && (
          <div className={'info-message--close trigger'} onClick={handleClose}>
            <IconCross />
          </div>
        )}
      </div>
    </div>
  );
};
export default Toast;

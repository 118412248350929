import React from 'react';
import { InputMeta, InputWrapper, ModifiedInput } from '.';
import cn from 'classnames';
import './inputTiles.scss';
import { ANIMATION_CHOICE_DELAY } from '../../../utils/global';

export interface TileOption {
  title: string;
  value: string;
  desc?: React.ReactNode;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  after?: React.ReactNode;
}

interface InputTileProps extends ModifiedInput {
  /**
   * On change sets a value obviously.
   * Make sure to pass a onClick as well, so you still have a action when the users clicks on the already selected tile
   */
  onChange: (value: string) => void;
  wrapperClass?: string;
  meta?: InputMeta;
  options: TileOption[];
  maxDelayIndex?: number;
}

export const InputTiles: React.FC<InputTileProps> = ({
  wrapperClass,
  meta,
  options,
  onChange,
  onClick,
  id,
  name,
  value,
  maxDelayIndex = -1,
}) => {
  return (
    <InputWrapper {...meta} className={wrapperClass}>
      {options.map((option, i) => {
        const selected = value === option.value;
        const delayClass = maxDelayIndex === -1 ? i : i % maxDelayIndex;
        const identifiers = {
          'data-qa-id': `${name}_${option.value}_${i}`,
          'data-qa-input-name': name,
          'data-qa-input-value': option.value,
          'data-qa-nth-of-kind': i,
        };
        return (
          <div key={`${id}_${i}`}>
            <input
              id={`${id}_${i}`}
              type={'radio'}
              name={name}
              onChange={() => onChange(option.value)}
              checked={selected}
              value={option.value}
              className="sr-only"
            />
            <label
              htmlFor={`${id}_${i}`}
              /**
               * NOTE About clicky:
               * A click event is usefull for when you end up on a step were an existing value is prefilled,
               * if you click on the selected value a change event won't be triggered.
               *
               * Why a tiny delay?
               * - Let the animation finish so the user has a "selected" feedback
               * - The click event does not propagate the select event
               * - Delay is only needed if the value isn't selected
               */
              onClick={onClick ? () => setTimeout(onClick, selected ? undefined : ANIMATION_CHOICE_DELAY) : undefined}
              {...identifiers}
              className={cn('trigger', 'input-el--tile--option', `delay-index-${delayClass}`, {
                'is-selected': selected,
              })}
            >
              {option.prefix && <div className={'input-el--tile--prefix'}>{option.prefix}</div>}
              <div className={'input-el--tile--copy'}>
                {/* eslint-disable-next-line @typescript-eslint/naming-convention */}
                <div className={'input-el--tile--title'} dangerouslySetInnerHTML={{ __html: option.title }} />
                {option.desc && <div className={'input-el--tile--desc'}>{option.desc}</div>}
              </div>
              {option.suffix && typeof option.suffix !== 'string' && (
                <div className={'input-el--tile--suffix'}>{option.suffix}</div>
              )}
              {option.suffix && typeof option.suffix === 'string' && (
                // eslint-disable-next-line @typescript-eslint/naming-convention
                <div className={'input-el--tile--suffix'} dangerouslySetInnerHTML={{ __html: option.suffix }} />
              )}
            </label>
            {option.after && option.after}
          </div>
        );
      })}
    </InputWrapper>
  );
};

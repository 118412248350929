/*
/* BRENGER VALIDATION UTILS
/* ================================================== */
import { translate } from './localization';

export const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,24}$/;
export const atLeastOneNumberRegex = /^(.*[0-9].*)$/;
export const atLeastOneLetter = /[A-Z]/i;
export const postalCodeRegexNL = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i;

export const required = (value: unknown, msg = 'required'): string | undefined =>
  value ? undefined : msg || 'Required';

export const isBoolean = (value: unknown, msg = 'required'): string | undefined => {
  if (typeof value !== 'boolean') {
    return msg || 'Required';
  }
};

export const exactLength = (value: number | string, charLength: number, msg?: string): string | undefined =>
  value && String(value).length !== charLength
    ? msg || translate('request_flow.fields.exact_length.valid', { charLength })
    : undefined;

export const maxLength = (value: number | string, max: number, msg: string): string | undefined =>
  value && String(value).length > max ? msg || `Must be ${max} characters or less` : undefined;

export const betweenLength = (value: number | string, min: number, max: number, msg: string): string | undefined => {
  const valLength = (value && String(value).length) || 0;
  if (valLength < min || valLength > max) {
    return undefined;
  }
  return msg || `Must be between ${min} and ${max} characters`;
};

export const numeric = (value: number, msg: string): string | undefined =>
  !isNaN(Number(value)) || Number(value) <= 0 ? undefined : msg || 'Must be a number';

export const numericMin = (value: string, minimum: number, msg: string): string | undefined => {
  return parseFloat(value) >= minimum ? undefined : msg || 'Must be a number';
};

export const numericMax = (value: string, maximum: number, msg?: string): string | undefined => {
  return parseFloat(value) <= maximum
    ? undefined
    : msg || translate('request_flow.fields.number.max_valid', { number: maximum });
};

export const validateMaxAmount = (value: string, maximum: number, msg: string): string | undefined => {
  const number = (value || '').replace(',', '.'); // just to be sure the comma is read as decimal separator
  return parseFloat(number) <= maximum ? undefined : msg || 'Must be a number';
};

export const validateMinAmount = (value: string, minimum: number, msg: string): string | undefined => {
  const number = (value || '').replace(',', '.'); // just to be sure the comma is read as decimal separator
  return parseFloat(number) >= minimum ? undefined : msg || 'Must be a number';
};

export const minLength = (value: number | string, min: number, msg: string): string | undefined =>
  value && String(value).length < min ? msg || `Must be at least ${min}` : undefined;

export const email = (value: string, msg: string): string | undefined =>
  value && !emailRegex.test(value.toLowerCase()) ? msg || 'Invalid email address' : undefined;

export const testRegex = (value: string, regexString: RegExp, msg: string): string | undefined =>
  value && !regexString.test(value) ? msg : undefined;

/**
 * Validators for useForm, that require to return "false" to not contain the error
 */
export const validateRequired = (field: string, val?: string): string | boolean => {
  if (!val) {
    return translate('form.fields.default.required', { field });
  }
  return false;
};

export const validateEmail = (val?: string): string | boolean => {
  if (!val) {
    return translate('form.fields.default.required', { field: translate('form.fields.user.email.label') });
  }
  if (!emailRegex.test(val.toLowerCase())) {
    return translate('form.fields.user.email.valid');
  }
  return false;
};

export const validatePhone = (val?: string): string | boolean => {
  if (!val) {
    return translate('form.fields.default.required', { field: translate('form.fields.user.phone.label') });
  }
  if (val.length < 10 || val.length > 16) {
    return translate('form.fields.user.phone.valid');
  }
  return false;
};

export const validateStreet = (val?: string): string | boolean => {
  if (!val) {
    return translate('form.fields.default.required', { field: translate('form.fields.address.street.label') });
  }
  if (!/^([^0-9]*)$/.test(val)) {
    return translate('form.fields.address.street.no_numbers');
  }
  return false;
};

export const validateLine1 = (val?: string): string | boolean => {
  if (!val) {
    return translate('form.fields.default.required', { field: translate('form.fields.address.line1.label') });
  }
  if (!/.*[0-9].*/.test(val)) {
    return translate('form.fields.address.line1.valid_with_number');
  }
  if ((val?.length || 0) > 64) {
    return translate('form.fields.address.line1.valid_too_long');
  }
  return false;
};

export const validateDutchPostalCode = (val?: string): string | boolean => {
  if (!val) {
    return translate('form.fields.default.required', { field: translate('form.fields.address.postal_code.label') });
  }
  if (!postalCodeRegexNL.test(val)) {
    return translate('form.fields.address.postal_code.valid');
  }
  return false;
};

export const warnLine1 = (val?: string): string | boolean => {
  if ((val?.length || 0) < 39) {
    return false;
  }
  return translate('form.fields.address.line1.warning_length');
};

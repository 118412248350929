import { renderToStaticMarkup } from 'react-dom/server';
import { logError } from '../utils/basics';
import { Config } from '../config';
import { ACTIVE_LANG_SETTINGS } from '../utils/localization';

/**
 * report any missing translation as error to sentry
 * @param translationId
 * @param languageCode
 * @returns {string}
 */
export const onMissingTranslation = ({ translationId, languageCode }): string => {
  // We would like to show missing translations when:
  // 1) envs = dev | staging
  // 2) language = es or de
  if (['development', 'staging'].includes(Config.NODE_ENV || '') || ['de-de'].includes(languageCode)) {
    return `key: ${translationId}`;
  } else {
    return '';
  }
};

export const localization = {
  configure: provider => {
    // initialize the redux localize
    provider.initialize({
      languages: Config.SUPPORTED_LANGUAGES.map(lang => {
        return { name: lang, code: lang };
      }),
      options: {
        renderToStaticMarkup,
        onMissingTranslation,
      },
    });
    // 1. set active language in redux-localize
    provider.setActiveLanguage(ACTIVE_LANG_SETTINGS.labels.contentSite);
    // 2. fetch all translation from server
    localization.getTranslations(ACTIVE_LANG_SETTINGS.labels.contentSite, provider.addTranslationForLanguage);
  },

  /**
   * fetch and set all translation keys to state
   * @param lang: string
   * @param callback: function
   * @returns {void}
   */
  getTranslations: (lang, callback) => {
    let coreLang = lang;
    if (lang.includes('-')) {
      coreLang = lang.split('-')[0];
    }
    fetch(`${Config.TRANSLATIONS_URL}/i18n/translations/${coreLang}?target=js`)
      .then(resp => resp.json())
      .then(resp => {
        if (typeof resp.common !== 'undefined' && typeof resp.common.client !== 'undefined') {
          callback(resp.common.client, lang);
        }
        if (typeof resp.customer !== 'undefined') {
          callback(resp.customer, lang);
        }
        if (typeof resp.driver !== 'undefined') {
          callback(resp.driver, lang);
        }
      })
      .catch(err => logError(err));
  },
};

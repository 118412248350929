import React from 'react';
import { CheckMarkList, PriceDisplay, SheetLayoutSidebarContent } from '../../../brenger-shared-ui';
import { translate } from '../../../utils/localization';
import { INSURANCE_AMOUNT } from '../../../utils/global';
import { Translate } from 'react-localize-redux-dep-updated';
import { useTypedSelector } from '../../../hooks';
import { RootState } from '../../../typings';
import { priceAsString } from '../../../utils/price';

export const SidebarLayout: React.FC = () => {
  const price = useTypedSelector((state: RootState) => state.business.transport_request.price);
  return (
    <div className={'sheet-layout--sidebar-background'}>
      <div className="summary">
        <div className="summary--body">
          <div className={'summary-heading'}>
            <Translate id={'request_flow.headers.transport_request_title'} />
          </div>
        </div>
      </div>

      <SheetLayoutSidebarContent>
        <div className={'business--price-wrapper'}>
          <PriceDisplay
            header={
              <>
                <Translate id={'request_flow.price.total_ex_vat'} />
              </>
            }
            quotePrice={price}
            exclVat={true}
          />
        </div>
        <CheckMarkList
          header={String(translate('request_flow.business.usp.title'))}
          checkMarkStyle={'primary'}
          list={[
            translate('request_flow.business.usp.one', {
              insurance_amount: priceAsString({ price: { amount: INSURANCE_AMOUNT }, addPlusMinusIndicator: false }),
            }),
            translate('request_flow.business.usp.two', {
              insurance_amount: priceAsString({ price: { amount: INSURANCE_AMOUNT }, addPlusMinusIndicator: false }),
            }),
            translate('request_flow.business.usp.three', {
              insurance_amount: priceAsString({ price: { amount: INSURANCE_AMOUNT }, addPlusMinusIndicator: false }),
            }),
            translate('request_flow.business.usp.four', {
              insurance_amount: priceAsString({ price: { amount: INSURANCE_AMOUNT }, addPlusMinusIndicator: false }),
            }),
          ]}
        />
      </SheetLayoutSidebarContent>
    </div>
  );
};

import { PopularItem } from '@brenger/api-client';
import cn from 'classnames';
import * as React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { useDispatch } from 'react-redux';
import { WrappedFieldProps } from 'redux-form';
import { clarity } from '../../../../configs/clarity';
import { useTypedSelector } from '../../../../hooks';
import { actions as baseActions } from '../../../../state/ducks/baseReducer';
import { FormNames, RootState } from '../../../../typings';
import { trackEvent } from '../../../../utils/eventTracking';
import { removeAccents } from '../../../../utils/string';
import { IconArrow, PlusWithCircle } from '../../basics/icons';
import { Dialog } from '../../notice/dialog/Dialog';
import InputWrapper from '../input/InputWrapper';
import './input-examples.scss';

interface Custom {
  label?: string;
  setActive(b: boolean): void;
  overlaySpecs: OverlaySpecs | null;
}

type InputProps = WrappedFieldProps & Custom;

export interface OverlaySpecs {
  left: number;
  width: number;
  top: number;
}

interface GetExampleMatches {
  options: PopularItem[];
  value: string;
}

export const InputWithProductExamplesOverlay: React.FC<InputProps> = ({
  input,
  meta,
  label,
  setActive,
  overlaySpecs,
}) => {
  const dispatch = useDispatch();
  // Array with bunch of dimensions
  const options = useTypedSelector((state: RootState) => state.business.popular_items);

  // Used in combination with keyboard arrows further down
  const [highlighted, setHighlighted] = React.useState(-1);
  const [selected, setSelected] = React.useState<PopularItem | null | undefined>();
  const [examples, setExamples] = React.useState<PopularItem[]>([]);

  // make it comparable
  const value = input.value.toLowerCase().trim();

  // Effect on value change
  React.useEffect(() => {
    // Temps holds matched examples
    const exampleMatches = getExampleMatches({ options, value });
    setExamples(exampleMatches);
  }, [value]);

  React.useEffect(() => {
    if (examples.length) {
      trackEvent({
        eventCategory: 'PS - Impression',
        eventAction: 'Legacy',
      });
    }
  }, [examples]);

  // Effect on selected change
  React.useEffect(() => {
    if (typeof selected === 'undefined') {
      return;
    }
    // dispatch selected one
    dispatch(
      baseActions.selectProductDimensions(selected, {
        form: meta.form as FormNames,
        field: input.name,
        value: input.value,
      })
    );
    setActive(false);
  }, [selected]);

  // Selecting an option
  const selectOption = (option: PopularItem): void => {
    clarity.setTag('product_example_selected');
    trackEvent({
      eventCategory: 'PS - Add item',
      eventAction: 'Legacy',
    });
    setSelected(option);
  };
  // Selecting manual, aka opt out on product select, happens when last option is selected, or no option is selected and clicked out side
  const selectManual = (): void => {
    clarity.setTag('product_example_manual');
    setSelected(null);
  };

  return (
    <Dialog isActive={true}>
      <div style={{ position: 'fixed', inset: 0 }}>
        <div className="input-el--examples--backdrop" onClick={() => setActive(false)} />
        <div
          className={'input-el--examples--overlay'}
          style={
            // Passing the coords to CSS, so we can use them optionally in media queries
            {
              '--input-el--examples--top': String(overlaySpecs?.top) + 'px',
              '--input-el--examples--left': String(overlaySpecs?.left) + 'px',
              '--input-el--examples--width': String(overlaySpecs?.width) + 'px',
            } as React.CSSProperties // Hush TS, it only accepts official css properties
          }
        >
          <InputWrapper label={label} input={input} meta={meta} type={'text'}>
            <div className={cn('input-el--inner', { 'input-el--examples-with-list': examples.length > 0 })}>
              <div className="input-el--tag--wrapper">
                <IconArrow arrowStyle="black" left={true} onClick={() => setActive(false)} />
                <div className={'input-el--tag'}>
                  <input
                    autoFocus={true}
                    id={input.name + '_overlay'}
                    data-clarity-unmask="true"
                    type={'text'}
                    autoComplete={'off'}
                    {...input}
                    onKeyDown={e => {
                      switch (e.key) {
                        case 'Enter':
                          e.preventDefault();
                          // If highlighted is -1, then nothing is selected, so we don't
                          if (highlighted !== -1) {
                            selectOption(examples[highlighted]);
                          }
                          break;
                        case 'ArrowUp': {
                          if (!examples.length) {
                            return;
                          }
                          const prev = highlighted < 0 ? examples.length - 1 : highlighted - 1;
                          setHighlighted(prev);
                          break;
                        }
                        case 'ArrowDown': {
                          if (!examples.length) {
                            return;
                          }
                          const next = highlighted >= examples.length - 1 ? -1 : highlighted + 1;
                          setHighlighted(next);
                          break;
                        }
                        case 'Tab': {
                          setActive(false);
                          /**
                           * Because we are in a modal, normal tab doesn't work
                           * So we need to help a little and try to focus on the next dimension input
                           */
                          e.preventDefault();
                          const lengthInput = document.getElementById(
                            input.name.replace('title', 'length')
                          ) as HTMLInputElement | null;
                          if (lengthInput) {
                            lengthInput.focus();
                          }
                          break;
                        }
                      }
                    }}
                  />
                  {label && (
                    <label className={'input-el--label'} htmlFor={input.name}>
                      <span className={'input-el--label-text'}>{label}</span>
                    </label>
                  )}
                </div>
              </div>
              <div
                className={cn('input-el--examples-list', {
                  'input-el--examples-list--visible': examples.length && meta.active,
                })}
              >
                {examples.length > 0 && (
                  <div key={'title'} className={'input-el--examples-list--title'}>
                    <Translate id="request_flow.product_suggestions.title" />
                  </div>
                )}
                <div className="input-el--examples-list--predefined">
                  {examples.map((example, index) => (
                    <div
                      key={index}
                      className={cn('input-el--examples-list--item', {
                        'input-el--examples-list--item--highlight': highlighted === index,
                      })}
                      onClick={() => selectOption(example)}
                    >
                      {example.title}{' '}
                      <span>
                        {example.length}x{example.width}x{example.height}cm
                      </span>
                      <PlusWithCircle />
                    </div>
                  ))}
                </div>
                {examples.length > 0 && (
                  <div className="input-el--examples-list--manual" key={'manual'}>
                    <div className={'input-el--examples-list--title'}>
                      <Translate id="request_flow.product_suggestions.manual" />
                    </div>
                    <div className={'input-el--examples-list--item'} onClick={() => selectManual()}>
                      {input.value}
                      <PlusWithCircle />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </InputWrapper>
        </div>
      </div>
    </Dialog>
  );
};

const getExampleMatches = ({ options, value }: GetExampleMatches): PopularItem[] => {
  const exampleMatches: PopularItem[] = [];
  // Only try to match if we have a value longer than 3 characters
  if (value?.length > 2 && options.length) {
    let i = 0;
    // loop
    do {
      // current option
      const option = options[i];
      // make the label comparable
      const label = option.title?.toLowerCase().trim();
      const searchString = removeAccents(label);
      const searchItem = removeAccents(value);
      if (searchItem.indexOf(label) > -1 || searchString.indexOf(searchItem) > -1) {
        exampleMatches.push(option);
      }
      i++;
      // Keep looping trough options, until we have 5 matches or looped trough all options
    } while (i < options.length);
  }
  return exampleMatches.slice(0, 4);
};

import React from 'react';
import { trackEvent } from '../../../../utils/eventTracking';
import { translate } from '../../../../utils/localization';
import './sharebuttons.scss';

interface Props {
  message: string;
  url?: string;
}

type Medium = 'Facebook' | 'LinkedIn' | 'WhatsApp' | 'Twitter' | 'Pinterest' | 'Email';

export const ShareButtons: React.FC<Props> = ({ url, message }) => {
  const shareUrl = encodeURI(url || 'https://www.brenger.nl');
  const shareMessage = encodeURI(message);
  /* Simple function to open window */
  const openWindow = (medium: Medium, link: string): void => {
    const isThankYou = window.location.href.includes('thank_you');
    window.open(link, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');
    trackEvent({
      eventCategory: `Social media share`,
      eventAction: `From ${isThankYou ? 'thank-you' : 'page'} to  ${medium}`,
      eventLabel: shareMessage,
    });
  };
  const shareOptions: { medium: Medium; mediumShareUrl: string }[] = [
    {
      medium: 'Facebook',
      mediumShareUrl: `http://www.facebook.com/sharer/sharer.php?u=${shareUrl}&quote=${shareMessage}`,
    },
    {
      medium: 'LinkedIn',
      mediumShareUrl: `https://www.linkedin.com/shareArticle?mini=true&url=${shareUrl}&title=${shareMessage}`,
    },
    {
      medium: 'WhatsApp',
      mediumShareUrl: `whatsapp://send?text=${shareMessage}+${shareUrl}`,
    },
    {
      medium: 'Twitter',
      mediumShareUrl: `https://twitter.com/intent/tweet?text=${shareMessage}+${shareUrl}`,
    },
    {
      medium: 'Pinterest',
      mediumShareUrl: `http://pinterest.com/pin/create/button/?url=${shareUrl}&media=${encodeURI(
        'https://www.brenger.nl/img/banner-handbook.jpg'
      )}&description=${shareMessage}+`,
    },
    {
      medium: 'Email',
      mediumShareUrl: `mailto:?subject=${shareMessage}&body=${shareUrl}`,
    },
  ];
  return (
    <div className={'share-button--wrapper'}>
      <div className={'share-button--header pb-1'}>
        <b>{translate('request_flow.share-button.header')}</b>
      </div>
      {shareOptions.map((option, index) => {
        return (
          <button
            className={`share-button share-button--${option.medium.toLowerCase()}`}
            key={index}
            type={'button'}
            title={translate(`request_flow.share-button.share_on`, { medium: option.medium, message })}
            onClick={() => openWindow(option.medium, option.mediumShareUrl)}
          />
        );
      })}
    </div>
  );
};

import cn from 'classnames';
import React from 'react';
import { InputMeta } from '.';
import './input.scss';

export const InputWrapper: React.FC<React.PropsWithChildren & InputMeta & { className?: string }> = ({
  error,
  warning,
  showError,
  className,
  children,
}) => {
  return (
    <div className={cn('input--wrapper w-full', className)}>
      {children}
      {showError && error && <div className="input--error">{error}</div>}
      {!error && warning && <div className="input--warning">{warning}</div>}
    </div>
  );
};

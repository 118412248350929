import { addDays, isAfter, startOfDay } from 'date-fns';
import { CategoryAttribute, LotDetails } from '../generated/graphql';
import { DateTimePeriodParams } from '@brenger/api-client';
import { uploadRemoteImageByUrl } from './request';
import { logError } from './basics';

export interface LotDtps extends DateTimePeriodParams {
  isByAppointment: boolean;
}
export interface TransformedLot {
  title: string;
  lotId: string;
  auctionId: string;
  collectionDays: {
    address: {
      description: string | null;
      line1: string;
      locality: string;
      country_code: string;
      postal_code: string;
    };
    days: LotDtps[];
  };
  width: number | null;
  height: number | null;
  length: number | null;
  weight: number | null;
  image: string | null;
}

export type TbAuctionLotError =
  | 'different_auction'
  | 'no_collection_days'
  | 'expired_auction'
  | 'transport_included'
  | 'outside_service_area';

export const parseTbAuctionQuery = (query: string): { lotId: string | null; error: 'invalid_query' | null } => {
  const queryUpper = query.toUpperCase();
  const lotIdPattern = new RegExp(/[A-Z]{1}[0-9]{1}-[0-9]+-[0-9]+/);
  const matchLotId = queryUpper.match(lotIdPattern);
  if (matchLotId) {
    return {
      lotId: matchLotId[0],
      error: null,
    };
  }
  return {
    lotId: null,
    error: 'invalid_query',
  };
};

export const validateTbAuctionLot = (
  activeAuctionId: string | null,
  lotDetails: LotDetails
): { lotDetails: LotDetails | null; error: TbAuctionLotError | null } => {
  try {
    if (lotDetails.auction.isNeedsDelivery) {
      throw new Error('transport_included');
    }
    if (activeAuctionId !== null && lotDetails.auction.id !== activeAuctionId) {
      throw new Error('different_auction');
    }
    if (!lotDetails.collectionDays?.days || lotDetails.collectionDays?.days.length === 0) {
      throw new Error('no_collection_days');
    }
    // Assumption service area
    if (!['nl', 'be', 'de', 'lu'].includes(lotDetails.collectionDays.countryCode.toLowerCase())) {
      throw new Error('outside_service_area');
    }
    // Copied validation from BVA, we only want to handle auctions with pickupdays on tomorrow or later
    const tomorrow = startOfDay(addDays(new Date(), 1));
    const pickupDaysAfterTommorow = lotDetails.collectionDays.days.filter(day =>
      isAfter(new Date(day.startDate * 1000), tomorrow)
    );
    if (pickupDaysAfterTommorow.length === 0) {
      throw new Error('expired_auction');
    }
    return {
      lotDetails,
      error: null,
    };
  } catch (error) {
    return {
      lotDetails: null,
      error: error.message,
    };
  }
};

export const transformTbAuctionLot = async (
  rawLotDetails: LotDetails,
  locale: 'nl' | 'en' | 'de'
): Promise<TransformedLot | null> => {
  let length = getTbAuctionAttributeInCm(rawLotDetails, 'L', locale);
  let width = getTbAuctionAttributeInCm(rawLotDetails, 'W', locale);
  const height = getTbAuctionAttributeInCm(rawLotDetails, 'H', locale);
  const deep = getTbAuctionAttributeInCm(rawLotDetails, 'D', locale);
  if (!width && length) {
    width = deep;
  } else if (!length && width) {
    length = deep;
  } else if (!length && !width) {
    width = deep;
  }

  try {
    const image = await uploadRemoteImageByUrl(rawLotDetails.lot.images[0].url);
    return {
      title: rawLotDetails.lot.title,
      lotId: rawLotDetails.lot.displayId,
      auctionId: rawLotDetails.auction.id,
      collectionDays: {
        address: {
          line1: rawLotDetails.collectionDays?.address1 as string,
          description: rawLotDetails.collectionDays?.address2 || null,
          locality: rawLotDetails.collectionDays?.city as string,
          country_code: (rawLotDetails.collectionDays?.countryCode as string).toUpperCase(),
          postal_code: rawLotDetails.collectionDays?.postalCode as string,
        },
        days: rawLotDetails.collectionDays?.days.map(day => {
          return {
            isByAppointment: day.isByAppointment,
            start: new Date(day.startDate * 1000).toISOString(),
            end: new Date(day.endDate * 1000).toISOString(),
          };
        }) as LotDtps[],
      },
      width,
      height,
      length,
      weight: getTbAuctionAttributeInKg(rawLotDetails, locale),
      image,
    };
  } catch (e) {
    logError(e);
    return null;
  }
};

export const getTbAuctionAttributeInCm = (
  rawLotDetails: LotDetails,
  dim: 'W' | 'H' | 'L' | 'D',
  locale: 'nl' | 'en' | 'de'
): number | null => {
  const attrNamesToMatch = {
    W: {
      nl: ['breed', 'breedte'],
      en: ['width', 'wide'],
      de: ['breite', 'breit'],
    },
    H: {
      nl: ['hoog', 'hoogte'],
      en: ['height', 'high'],
      de: ['höhe', 'hoch'],
    },
    L: {
      nl: ['lengte', 'lang'],
      en: ['length', 'long'],
      de: ['lang', 'läng'],
    },
    D: {
      nl: ['diep', 'diepte'],
      en: ['deep', 'depth'],
      de: ['tief', 'tiefe'],
    },
  }[dim][locale];
  const filteredAttr = rawLotDetails.lot.attributes.filter(attribute => {
    return attribute.unit && ['mm', 'cm', 'm'].includes(attribute.unit.toLowerCase());
  });
  const foundAttr = getTbAuctionBestMatchedAttr(filteredAttr, attrNamesToMatch);

  const value = normalizeTbAuctionNumber(foundAttr?.value);
  const unit = foundAttr?.unit?.toLowerCase() || null;
  if (!value || !unit) {
    return null;
  }
  if (unit === 'mm') {
    return Math.round(value / 10);
  }
  if (unit === 'cm') {
    return Math.round(value);
  }
  if (unit === 'm') {
    return Math.round(value * 10);
  }
  return null;
};

export const getTbAuctionAttributeInKg = (rawLotDetails: LotDetails, locale: 'nl' | 'en' | 'de'): number | null => {
  const attrNamesToMatch = {
    nl: ['gewicht'],
    en: ['weight'],
    de: ['gewicht'],
  }[locale];
  const filteredAttr = rawLotDetails.lot.attributes.filter(attribute => {
    return attribute.unit && ['k', 'kg', 'g', 'gr'].includes(attribute.unit.toLowerCase());
  });
  const foundAttr = getTbAuctionBestMatchedAttr(filteredAttr, attrNamesToMatch);

  const value = normalizeTbAuctionNumber(foundAttr?.value);
  const unit = foundAttr?.unit?.toLowerCase() || null;
  if (!value || !unit) {
    return null;
  }
  if (unit === 'k' || unit === 'kg') {
    return Math.round(value);
  }
  if (unit === 'g' || unit === 'gr') {
    return Math.round(value / 1000);
  }

  return null;
};

export const normalizeTbAuctionNumber = (value?: string): number => {
  // The retured values are in Dutch number format, meaning: comma's as decimal separator
  return (
    Number(
      value
        ?.split(',')
        .map(part => part.replace('.', ''))
        .join('.')
    ) || 0
  );
};

export const getTbAuctionBestMatchedAttr = (
  attributes: LotDetails['lot']['attributes'],
  attrNamesToMatch: string[]
): undefined | CategoryAttribute => {
  return (
    attributes.find(attribute => {
      const attributeName = attribute.name.toLowerCase();
      // First attempt searches for exact match
      return attrNamesToMatch.some(name => attributeName === name);
    }) ||
    attributes.find(attribute => {
      const attributeName = attribute.name.toLowerCase();
      // Second attempt searches for substring
      return attrNamesToMatch.some(name => attributeName.includes(name));
    })
  );
};

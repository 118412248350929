import * as React from 'react';
import _isEqual from 'lodash/isEqual';
import classNames from 'classnames';

import InputWrapper from '../input/InputWrapper';
import InputFeedback from '../input/InputFeedback';
import { InputProps } from '../input/Input';

import '../input/input.scss';
import './input-tiles.scss';

export interface InputOption<V = string> {
  title: string;
  value: V;
  desc?: string;
  prefix?: string | React.ReactNode;
  suffix?: string | React.ReactNode;
  selected?: boolean;
}
interface Custom {
  compact?: boolean;
  options: InputOption[];
  maxDelayIndex: number;
  onChangeCallback?(): void;
}

export type InputOptionsProps = Custom & InputProps;

const InputTiles: React.FC<InputOptionsProps> = ({
  input,
  meta,
  successMessage,
  compact,
  options,
  maxDelayIndex = -1,
  onChangeCallback,
}) => {
  return (
    <InputWrapper
      input={input}
      meta={meta}
      type={'tile'}
      extraClasses={classNames({ 'input-el--tile-compact': compact })}
      // override any input tile container bottom margin styling
      style={{ marginBottom: 0 }}
    >
      <div>
        <input {...input} type={'hidden'} />
        {options.map((option: InputOption, index: number) => {
          let selected = option.selected || input.value === option.value;
          if (typeof option === 'object') {
            selected = _isEqual(input.value, option.value);
          }
          const delayClass = maxDelayIndex === -1 ? index : index % maxDelayIndex;
          const identifiers = {
            'data-qa-id': `${input.name}_${option.value}_${index}`,
            'data-qa-input-name': input.name,
            'data-qa-input-value': option.value,
            'data-qa-nth-of-kind': index,
          };
          return (
            <div
              className={classNames('trigger', 'input-el--tile--option', `delay-index-${delayClass}`, {
                'is-selected': selected,
              })}
              key={index}
              onClick={() => {
                input.onChange(option.value);
                if (onChangeCallback) onChangeCallback();
              }}
              {...identifiers}
            >
              {option.prefix && <div className={'input-el--tile--prefix'}>{option.prefix}</div>}
              <div className={'input-el--tile--copy'}>
                {/* eslint-disable-next-line @typescript-eslint/naming-convention */}
                <div className={'input-el--tile--title'} dangerouslySetInnerHTML={{ __html: option.title }} />
                {option.desc && <div className={'input-el--tile--desc'}>{option.desc}</div>}
              </div>
              {option.suffix && typeof option.suffix !== 'string' && (
                <div className={'input-el--tile--suffix'}>{option.suffix}</div>
              )}
              {option.suffix && typeof option.suffix === 'string' && (
                // eslint-disable-next-line @typescript-eslint/naming-convention
                <div className={'input-el--tile--suffix'} dangerouslySetInnerHTML={{ __html: option.suffix }} />
              )}
            </div>
          );
        })}
      </div>
      <InputFeedback input={input} meta={meta} error={meta.error} successMessage={successMessage} />
    </InputWrapper>
  );
};

export default InputTiles;

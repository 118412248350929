import * as React from 'react';
import '../input/input.scss';
import './textarea.scss';
import InputWrapper from '../input/InputWrapper';
import InputFeedback from '../input/InputFeedback';
import { InputProps } from '../input/Input';

const Textarea = ({ required, input, meta, label, successMessage, autocompleteOff }: InputProps): JSX.Element => (
  <InputWrapper input={input} meta={meta} type={'textarea'}>
    <textarea
      id={input.name}
      defaultValue={input.value}
      onBlur={input.onBlur}
      onChange={input.onChange}
      onFocus={input.onFocus}
      /**
       * HACK ALERT: This seems to be best practice to prevent auto fill per field, according to MDN
       * https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion#Preventing_autofilling_with_autocompletenew-password
       */
      autoComplete={autocompleteOff ? 'new-password' : undefined}
    />
    <label
      className={
        'input-el--label' + (typeof required !== 'undefined' && required === true ? ' input-el--label--required' : '')
      }
      htmlFor={input.name}
    >
      {label}
    </label>
    <InputFeedback input={input} meta={meta} error={meta.error} successMessage={successMessage} />
  </InputWrapper>
);

export default Textarea;

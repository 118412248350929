import {
  Account,
  Address,
  AddressCreateParams,
  ContactCreateParams,
  CustomerCreateParams,
  DateTimePeriod,
  DatetimePeriodOption,
  DateTimePeriodParams,
  DeliveryCreateParams,
  ItemCreationParams,
  ItemSetCreationParams,
  PickupCreateParams,
  PopularItem,
  PriceList,
  QuoteCreationParams,
  QuotePrice,
  QuoteStop,
  SourceFlow,
  StopDetails,
  TransportRequestCreationParams,
} from '@brenger/api-client';
import { parse } from 'date-fns';
import _get from 'lodash/get';
import { getFormValues } from 'redux-form';
import { r } from '../../routes';
import { getUploadedFileDataByFormAndFieldName } from '../../state/ducks/baseReducer';
import { CarryingHelpChoices, Currencies, GenericExtraInputFileFields, Nullable, StopType } from '../../typings';
import { RootState } from '../../typings/interfaces';
import { formatDate, formatDateUTCToISO } from '../../utils/datetime';
import { getSessionIds } from '../../utils/eventTracking';
import { dummyItemSet, GENERAL_DEFAULT_TIME_END, GENERAL_DEFAULT_TIME_START } from '../../utils/global';
import { isItemValid } from '../GeneralFlow/ducks';
import { getLoggedInUser } from '../User/ducks';
import { BusinessDestinationFormValues, getBusinessDestinationFormValues } from './containers/Destination';
import { getItemsAndServicesFormValues, ItemsAndServiceFormValues } from './containers/ItemsAndServices';
import {
  BusinessAttributes,
  BusinessAttributesValues,
  BusinessFields,
  BusinessForms,
  BusinessState,
  DateSelectOption,
  FloorLevelOptions,
  GetNextDateTimePeriodOptionsAction,
  QuoteRejectedReasonsBusiness,
  SetDateTimePeriodStartFromAction,
} from './interface';
import {
  BusinessActionTypes,
  DepotAddress,
  SetDepotAddressesAction,
  SetPopularItemsAction,
  SetRejectedByPricing,
  SimpleBusinessAction,
  UpdateBusinessPriceList,
} from './typings';

export const defaultState: BusinessState = {
  business_name: null,
  has_business_flow: false,
  depots: {
    loading: false,
    options: [],
  },
  price_list: null,
  popular_items: [],
  transport_request: {
    uuid: '',
    price: {
      loading: false,
      rejected_reason: null,
      vat: {
        amount: 0,
        currency: Currencies.EUR,
      },
      incl_vat: {
        amount: 0,
        currency: Currencies.EUR,
      },
      excl_vat: {
        amount: 0,
        currency: Currencies.EUR,
      },
    },
    date_time_periods: {
      options: [],
      startDate: null,
    },
    layout: {
      sheet: false,
      step: 1,
    },
  },
};

// actions
export const actions = {
  setBusinessDomainName: (name: string | null) => ({ type: BusinessActionTypes.SET_BUSINESS_DOMAIN_NAME, name }),
  submitDestination: (details: BusinessDestinationFormValues) => ({
    type: BusinessActionTypes.SUBMIT_DESTINATION,
    details,
  }),
  submitItemsServices: (details: BusinessDestinationFormValues | ItemsAndServiceFormValues | string) => ({
    type: BusinessActionTypes.SUBMIT_ITEMS,
    details,
  }),
  setSheetState: (openBoolean: boolean) => ({ type: BusinessActionTypes.SET_SHEET_STATE, openBoolean }),
  setProgressStep: (step: number) => ({ type: BusinessActionTypes.SET_PROGRESS_STEP, step }),
  setTransportRequestId: (id: string) => ({ type: BusinessActionTypes.SET_TR_ID, id }),
  resetFlow: () => ({ type: BusinessActionTypes.RESET_FLOW }),
  setHasBusinessFlow: (hasFlow: boolean) => ({ type: BusinessActionTypes.SET_HAS_BUSINESS_FLOW, hasFlow }),
  getPrice: () => ({ type: BusinessActionTypes.GET_PRICE }),
  setPrice: (priceData: QuotePrice) => ({ type: BusinessActionTypes.SET_PRICE, priceData }),
  setPriceLoading: (loading: boolean) => ({ type: BusinessActionTypes.SET_PRICE_LOADING, loading }),
  setDateTimePeriodOptions: (options: DatetimePeriodOption[]) => ({
    type: BusinessActionTypes.SET_DATE_TIME_PERIODS_OPTIONS,
    options,
  }),
  setDateTimePeriodStartFrom: (date: string, extendDateOptions = false): SetDateTimePeriodStartFromAction => ({
    type: BusinessActionTypes.SET_DATE_TIME_START_FROM,
    payload: { date, extendDateOptions },
  }),
  getNextDateTimePeriodOptions: (): GetNextDateTimePeriodOptionsAction => ({
    type: BusinessActionTypes.GET_NEXT_DATE_TIME_PERIOD_OPTIONS,
  }),
  fetchAddressesStart: () => ({ type: BusinessActionTypes.FETCH_ADDRESSES }),
  setDepotAddresses: (addresses: DepotAddress[]): SetDepotAddressesAction => ({
    type: BusinessActionTypes.SET_BUSINESS_DEPOT_ADDRESSES,
    payload: addresses,
  }),
  updateBusinessPriceList: (payload: PriceList | null): UpdateBusinessPriceList => ({
    type: BusinessActionTypes.UPDATE_PRICE_LIST,
    payload,
  }),
  createNewTr: () => ({ type: BusinessActionTypes.CREATE_TR }),
  fetchGeoDetails: () => ({ type: BusinessActionTypes.FETCH_GEO_DETAILS }),
  startPopularItems: (): SimpleBusinessAction => ({ type: BusinessActionTypes.START_POPULAR_ITEMS }),
  setPopularItems: (items: PopularItem[]): SetPopularItemsAction => ({
    type: BusinessActionTypes.SET_POPULAR_ITEMS,
    payload: items,
  }),
  setRejectedByPricing: (reason: QuoteRejectedReasonsBusiness | null): SetRejectedByPricing => ({
    type: BusinessActionTypes.SET_REJECTED_BY_PRICING,
    payload: reason,
  }),
};

// selectors
export const getSheetState = (state: RootState): boolean => {
  return state.business.transport_request.layout.sheet;
};

export const getProgressStep = (state: RootState): number => {
  return state.business.transport_request.layout.step;
};

export const getPrice = (state: RootState, inclVat = true): number => {
  try {
    return inclVat
      ? state.business.transport_request.price.incl_vat.amount
      : state.business.transport_request.price.excl_vat.amount;
  } catch (e) {
    return 0;
  }
};

export const getPriceLoading = (state: RootState): boolean => {
  return state.business.transport_request.price.loading;
};

const getDateTimePeriodOptions = (state: RootState): DatetimePeriodOption[] => {
  return state.business.transport_request.date_time_periods.options;
};

export const getDtpSelectableOptions = (state: RootState): DateSelectOption[] => {
  const options = getDateTimePeriodOptions(state);
  if (options.length === 0) {
    return [];
  }
  const dateOptions: DateSelectOption[] = [];
  const optionsWithPriceChange = options.filter(option => option.price_change?.excl_vat.amount !== 0).length;

  let i = 0;
  let iPriceChange = 0;
  do {
    if (options[i].dates[0]) {
      const option = options[i];
      let priceChange = ``;

      if (option.price_change?.excl_vat.amount !== 0) {
        /* Calculate percentage of price increase
         * - We can't calculate it based on TR price, because at first we don't have a price (because item sets are still missing)
         * - If we are dealing with the first increase (iPriceChange === 0) && we have 2 options then the first option => 100%
         * - If its only one option, or we are dealing with the second option => 50%
         */
        const percentage = iPriceChange === 0 && optionsWithPriceChange === 2 ? 100 : 50;
        priceChange = `<span class="text--${percentage === 100 ? 'red' : 'orange'}"> + ${percentage}%</span>`;
        iPriceChange++;
      }
      const date = parse(options[i].dates[0], 'yyyy-MM-dd', new Date());
      dateOptions.push({
        value: String(i),
        title: `<div>${formatDate(date, 'weekday')} ${priceChange}<div class="date-short">${formatDate(
          date,
          'digit-date'
        )}</div></div>`,
      });
    }
    i++;
  } while (i < options.length);
  return dateOptions;
};

export const getNewTransportRequestLink = (state: RootState): string => {
  if (state.business.has_business_flow) {
    return r.businessFlow.index();
  }
  return r.generalFlow.index();
};

export const getDeliveryAddressAsString = (state: RootState): Nullable<string> => {
  const destinationForm = getBusinessDestinationFormValues(state);
  if (!destinationForm) {
    return null;
  }
  const deliveryAddress = getDeliveryAddress(state);
  return `${destinationForm.delivery?.contact?.first_name || ''} ${
    destinationForm.delivery?.contact?.last_name || ''
  }. ${deliveryAddress?.line1} ${deliveryAddress?.postal_code}, ${deliveryAddress?.locality}`;
};

export const getValidItemSets = (state: RootState): ItemSetCreationParams[] => {
  const itemFormValues = getItemsAndServicesFormValues(state);
  if (!itemFormValues?.itemSets.length) {
    return [];
  }
  const itemSets = itemFormValues?.itemSets.filter(itemSet => {
    return itemSet.items.filter(item => isItemValid(item as ItemCreationParams)).length !== 0;
  });
  // check for client reference and external invoices
  const clientReference = getClientReference(state);
  const externalInvoices = getUploadedFileDataByFormAndFieldName(
    state,
    BusinessForms.DESTINATION,
    BusinessFields.INVOICE,
    '@id'
  );
  const hasAssembly = itemFormValues?.[BusinessFields.ASSEMBLY];
  // else map them into the itemSets and
  return (itemSets as ItemSetCreationParams[]).map(itemSet => {
    if (externalInvoices.length) {
      itemSet.external_invoices = externalInvoices;
    }
    if (clientReference) {
      itemSet.client_reference = clientReference;
    }
    if (hasAssembly) {
      itemSet.services = ['assembly'];
    }
    if (!hasAssembly && 'services' in itemSet) {
      delete itemSet.services;
    }

    return itemSet;
  });
};

export const getClientReference = (state: RootState): string | undefined => {
  const destinationForm = getBusinessDestinationFormValues(state);
  return destinationForm?.client_reference;
};

export const getContactPickup = (state: RootState): CustomerCreateParams => {
  const selectedDepot = getSelectedDepot(state);
  const destinationValues = getBusinessDestinationFormValues(state);
  const customer = {
    first_name: destinationValues?.depot_first_name || '',
    last_name: destinationValues?.depot_last_name || '',
    phone: destinationValues?.depot_phone || '',
    email: destinationValues?.depot_email || '',
  };
  const contact = getLoggedInUser(state);
  const account = contact.userData?.account as Account | undefined;
  const billingAddress = state.user.billingAddress || (selectedDepot?.address as Address | undefined);
  if (account?.account_type === 'business') {
    return {
      ...customer,
      company_details: {
        company_name: destinationValues?.display_name || account?.name || '',
        coc_number: account?.coc_number || '',
        vat_number: account?.vat_number || '',
        address: {
          line1: billingAddress?.line1 || '',
          line2: billingAddress?.line2,
          postal_code: billingAddress?.postal_code || '',
          locality: billingAddress?.locality || '',
          country_code: billingAddress?.country_code || 'NL',
          lat: billingAddress?.lat || 0,
          lng: billingAddress?.lng || 0,
        },
      },
    };
  } else {
    return customer;
  }
};

export const getContactDelivery = (state: RootState): ContactCreateParams => {
  const destinationValues = getBusinessDestinationFormValues(state);
  return {
    first_name: destinationValues?.delivery?.contact?.first_name || '',
    last_name: destinationValues?.delivery?.contact?.last_name || '',
    email: destinationValues?.delivery?.contact?.email || '',
    phone: destinationValues?.delivery?.contact?.phone || '',
  };
};

export const getDeliveryAddress = (state: RootState): Partial<Address> | null => {
  // to prevent conflicts and double work this needs that selector as well. There are quite some open MR's around the same code.
  const destinationFormValues = getBusinessDestinationFormValues(state);
  // do we go for manual or auto address values
  const addressType = destinationFormValues?.delivery_address_is_manual_address
    ? destinationFormValues.delivery_manual_address
    : destinationFormValues?.delivery_auto_address;
  // Do we correct have input?
  if (!addressType || typeof addressType === 'string') {
    return null;
  }
  const address = addressType.address;
  // Normalisation is needed, because somehow floats end up being a string when manually submitted
  const lat = typeof address?.lat === 'string' ? parseFloat(address?.lat) : address?.lat;
  const lng = typeof address?.lng === 'string' ? parseFloat(address?.lng) : address?.lng;
  return {
    administrative_area: address?.administrative_area,
    country_code: address?.country_code,
    line1: address?.line1,
    locality: address?.locality,
    postal_code: address?.postal_code,
    lat,
    lng,
  };
};

export const getSelectedDepot = (state: RootState): null | DepotAddress => {
  // to prevent conflicts and double work this needs that selector as well. There are quite some open MR's around the same code.
  const destinationFormValues = getBusinessDestinationFormValues(state);
  const depotAddresses = state.business.depots.options;
  if (!depotAddresses.length) {
    return null;
  }
  if (depotAddresses.length === 1) {
    return depotAddresses[0];
  }
  const index = destinationFormValues?.depot_select || 0;
  return depotAddresses[index];
};

export const getInstructions = (state: RootState, stopType: StopType): string => {
  const destinationFormValues = getBusinessDestinationFormValues(state);
  if (stopType === StopType.PICKUP) {
    return destinationFormValues?.depot_instructions || '';
  }
  if (stopType === StopType.DELIVERY) {
    return destinationFormValues?.delivery?.details?.instructions || '';
  }
  return '';
};

export const getAddressDetails = (
  state: RootState,
  stopType: StopType
): { address: AddressCreateParams; details: StopDetails } => {
  const isPickup = stopType === StopType.PICKUP;
  const destinationFormValues = getBusinessDestinationFormValues(state);
  const instructions = getInstructions(state, stopType);
  // Reading property name here
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [floor_level, elevator] = String(destinationFormValues?.delivery?.details?.floor_level || '').split('|');
  const serviceValues = getFormValues(BusinessForms.ITEMS_AND_SERVICE)(state);
  const carryingHelp =
    _get(serviceValues, BusinessFields.EXTRA_DRIVER, false) === true
      ? CarryingHelpChoices.EXTRA_DRIVER
      : CarryingHelpChoices.NOT_NEEDED;
  return {
    address: (!isPickup ? getDeliveryAddress(state) : getSelectedDepot(state)?.address) as AddressCreateParams,
    details: {
      carrying_help: carryingHelp,
      floor_level: isPickup ? 0 : Number(floor_level),
      elevator: isPickup ? false : elevator === FloorLevelOptions.ELEVATOR,
      extras: [],
      instructions,
      situation: isPickup ? 'store' : 'home',
    },
  };
};

export const getSelectedDateOption = (state: RootState): undefined | DatetimePeriodOption => {
  const formValues = getFormValues(BusinessForms.ITEMS_AND_SERVICE)(state);
  const dateIndex = _get(formValues, BusinessFields.DATE, false);
  const dateOptions = getDateTimePeriodOptions(state);
  // if no options or no selection made
  if (!dateOptions.length || !dateIndex) {
    return;
  }
  return dateOptions[parseInt(dateIndex, 10)];
};

export const getSelectedDatetimePeriod = (state: RootState, stopType: StopType): undefined | DateTimePeriodParams[] => {
  const selectedDateOption = getSelectedDateOption(state);
  if (!selectedDateOption) {
    return;
  }
  if (stopType === StopType.PICKUP) {
    const start = selectedDateOption.time_options[0].start || '';
    const end = selectedDateOption.time_options[0].end || '';
    return [
      {
        start,
        end,
      },
    ];
  }
  const date = new Date(selectedDateOption.dates[0]);

  return [
    {
      start: formatDateUTCToISO(date, GENERAL_DEFAULT_TIME_START),
      end: formatDateUTCToISO(date, GENERAL_DEFAULT_TIME_END),
    },
  ];
};

export const getPriceRequestParams = (
  state: RootState,
  withDummyItem: boolean,
  skipDates = false
): QuoteCreationParams | null => {
  let itemSets = normalizeItems(getValidItemSets(state));
  if (itemSets.length === 0 && !withDummyItem) {
    return null;
  }
  if (itemSets.length === 0 && withDummyItem) {
    /* Set a dummy itemset, so we can get succesfull call for dates */
    itemSets = [dummyItemSet];
  }
  const pickup = getAddressDetails(state, StopType.PICKUP) as QuoteStop;
  const pickupDateTimePeriod = getSelectedDatetimePeriod(state, StopType.PICKUP);
  if (pickupDateTimePeriod && !skipDates) {
    pickup['available_datetime_period'] = pickupDateTimePeriod[0];
  }
  const delivery = getAddressDetails(state, StopType.DELIVERY) as QuoteStop;
  const deliveryDateTimePeriod = getSelectedDatetimePeriod(state, StopType.DELIVERY);
  if (deliveryDateTimePeriod && !skipDates) {
    delivery['available_datetime_period'] = deliveryDateTimePeriod[0];
  }
  const pickupStartDate = state.business.transport_request.date_time_periods.startDate;
  return {
    account_id: _get(getLoggedInUser(state), 'userData.account.id', ''),
    item_sets: itemSets,
    pickup,
    delivery,
    pickup_dates_start_from: pickupStartDate ? pickupStartDate : formatDate(new Date(), 'api-date'),
  };
};

export const getIsReturnTransport = (state: RootState): boolean => {
  return Boolean(getBusinessDestinationFormValues(state)?.return);
};

export const getTransportRequestPayload = (state: RootState): TransportRequestCreationParams | null => {
  const items = normalizeItems(getValidItemSets(state));
  const price = getPrice(state);
  if (items.length === 0 || price === 0) {
    return null;
  }
  // Is it a return transport?
  const isReturnOrder = getIsReturnTransport(state);

  // Store the type of orderinstructions
  items[0]['attributes'] = {
    [BusinessAttributes.ORDER_TYPE]: !isReturnOrder
      ? BusinessAttributesValues.ORDER_TYPE_NORMAL
      : BusinessAttributesValues.ORDER_TYPE_RETURN,
  };

  // Prepare pickup data
  const pickupAddress = getAddressDetails(state, StopType.PICKUP);
  const pickups: PickupCreateParams[] = [
    {
      ...pickupAddress,
      contact: getContactPickup(state),
      available_datetime_periods: getSelectedDatetimePeriod(state, StopType.PICKUP) as DateTimePeriod[],
    },
  ];

  // Prepare delivery data
  const deliveryAddress = getAddressDetails(state, StopType.DELIVERY);
  const deliveries: DeliveryCreateParams[] = [
    {
      ...deliveryAddress,
      contact: getContactDelivery(state),
      available_datetime_periods: getSelectedDatetimePeriod(state, StopType.DELIVERY) as DateTimePeriod[],
    },
  ];

  const creationParams: TransportRequestCreationParams = {
    source_flow: SourceFlow.BUSINESS,
    item_sets: items,
    price: {
      amount: price,
      currency: Currencies.EUR,
    },
    customer: getContactPickup(state),
    pickups: !isReturnOrder ? pickups : deliveries,
    deliveries: !isReturnOrder ? deliveries : pickups,
    frontend_url: window.location.href,
    session_ids: getSessionIds(),
    customer_user: getLoggedInUser(state)?.userData?.['@id'],
  };
  // check if we are dealing with a manual address
  const destinationFormValues = getBusinessDestinationFormValues(state);
  const isManual = destinationFormValues?.delivery_address_is_manual_address;
  if (isManual) {
    creationParams.internal_attributes = {
      delivery_coords_precision: destinationFormValues?.delivery_manual_address?.precision,
    };
  }
  return creationParams;
};

/**
 * DEPRECATED
 * Itemsets should follow more strongly typed, less messy
 * Normalisation is something that should/could be done on component level
 * This only lives here because the BF is relying on it still
 */
const normalizeItems = (itemSets: ItemSetCreationParams[]): ItemSetCreationParams[] => {
  return itemSets.map(itemSet => {
    const normalizedItemSet = {
      title: itemSet.title,
      description: itemSet.description,
      services: itemSet.services,
      /**
       * Since item sets needs refactor I took a shortcut here
       * - We should start by refactoring uploads interface
       * - then we can do the itemSet interface
       */
      items: (itemSet.items as unknown as ItemCreationParams[]).map(item => {
        const nomalizedItem = {
          title: item.title || '',
          length: item.length || 0,
          height: item.height || 0,
          width: item.width || 0,
          weight: (item as ItemCreationParams).weight || 0,
          count: item.count || 0,
          heavy: item.heavy || false,
          product_groups: (item as ItemCreationParams).product_groups || [],
        };
        /*
         * Check if there is already a job image atached
         */
        if (!(item as ItemCreationParams).job_image) {
          // Check if upload components has some
          // Because the upload component creates more fields then we need, we need to take care of two things:
          // 1. get the job image
          // 2. return explicitly the fields that we need for an item, to filter out the unnescary stuff
          const jobImage = _get(item, `image_${GenericExtraInputFileFields.COLLECTION}[0]['@id']`);
          if (jobImage) {
            // job_image can only be a single IRI reference
            // So it can't be an empty string or array
            nomalizedItem['job_image'] = jobImage;
          }
        } else {
          nomalizedItem['job_image'] = (item as ItemCreationParams).job_image;
        }

        return nomalizedItem;
      }),
    };
    // Some optional keys should be checked on existence, and added to the normalized itemSet.
    const optionalKeys = ['external_party', 'external_invoices', 'attributes', 'client_reference'];
    optionalKeys.forEach(key => {
      if (itemSet[key]) {
        normalizedItemSet[key] = itemSet[key];
      }
    });

    return normalizedItemSet;
  });
};

// reducers
export const businessReducer = (state: BusinessState = defaultState, action): BusinessState => {
  switch (action.type) {
    case BusinessActionTypes.SET_BUSINESS_DOMAIN_NAME:
      return {
        ...state,
        business_name: action.name,
      };
    case BusinessActionTypes.SET_SHEET_STATE:
      return {
        ...state,
        transport_request: {
          ...state.transport_request,
          layout: {
            ...state.transport_request.layout,
            sheet: action.step,
          },
        },
      };
    case BusinessActionTypes.SET_PROGRESS_STEP:
      return {
        ...state,
        transport_request: {
          ...state.transport_request,
          layout: {
            ...state.transport_request.layout,
            step: action.step,
          },
        },
      };
    case BusinessActionTypes.SET_TR_ID:
      return {
        ...state,
        transport_request: {
          ...state.transport_request,
          uuid: action.id,
        },
      };
    case BusinessActionTypes.SET_HAS_BUSINESS_FLOW:
      return {
        ...state,
        has_business_flow: action.hasFlow,
      };
    case BusinessActionTypes.SET_PRICE:
      return {
        ...state,
        transport_request: {
          ...state.transport_request,
          price: {
            ...action.priceData,
            rejected_reason: null,
            loading: false,
          },
        },
      };
    case BusinessActionTypes.SET_PRICE_LOADING:
      return {
        ...state,
        transport_request: {
          ...state.transport_request,
          price: {
            ...state.transport_request.price,
            loading: action.loading,
          },
        },
      };
    case BusinessActionTypes.SET_DATE_TIME_PERIODS_OPTIONS:
      return {
        ...state,
        transport_request: {
          ...state.transport_request,
          date_time_periods: {
            ...state.transport_request.date_time_periods,
            options: action.options,
          },
        },
      };
    case BusinessActionTypes.SET_DATE_TIME_START_FROM:
      return {
        ...state,
        transport_request: {
          ...state.transport_request,
          date_time_periods: {
            ...state.transport_request.date_time_periods,
            startDate: action.payload.date,
          },
        },
      };
    case BusinessActionTypes.FETCH_ADDRESSES:
      return {
        ...state,
        depots: {
          ...state.depots,
          loading: true,
        },
      };
    case BusinessActionTypes.SET_BUSINESS_DEPOT_ADDRESSES:
      return {
        ...state,
        depots: {
          loading: false,
          options: action.payload,
        },
      };
    case BusinessActionTypes.UPDATE_PRICE_LIST:
      return {
        ...state,
        price_list: action.payload,
      };
    case BusinessActionTypes.SET_POPULAR_ITEMS:
      return {
        ...state,
        popular_items: action.payload,
      };
    case BusinessActionTypes.SET_REJECTED_BY_PRICING:
      return {
        ...state,
        transport_request: {
          ...state.transport_request,
          price: {
            ...state.transport_request.price,
            rejected_reason: action.payload,
          },
        },
      };
    default:
      return state;
  }
};

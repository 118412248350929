import React from 'react';
import { Field } from 'redux-form';
import { IconEcoFriendly, IconSmile, RfInputTiles } from '../../../brenger-shared-ui';
import { useTypedSelector } from '../../../hooks';
import { RootState, StopType } from '../../../typings';
import { formatDate } from '../../../utils/datetime';
import { priceAsString } from '../../../utils/price';
import { getStopSituation, getStopTimeOptions, isLoading } from '../ducks';
import { pickupDeliverySchema } from '../schema';
import { TimeOptionTile } from './SubStepTime';
import { proceed } from '../proceed';

interface Props {
  stopType: StopType;
}

export const TimeCustomOptionsList: React.FC<Props> = ({ stopType }) => {
  const optionsCustom = useTypedSelector((state: RootState) => getStopTimeOptions(state, stopType, 'custom'));
  const loading = useTypedSelector(isLoading);

  // situation
  const situation = useTypedSelector((state: RootState) => getStopSituation(state, stopType));
  const isPickupAndAuctionOrStore = stopType === 'pickup' && (situation === 'auction' || situation === 'store');

  const optionsCustomTiles: TimeOptionTile[] = optionsCustom.map(option => {
    let label = 'green';
    if (option.price_change?.incl_vat.amount) {
      label = option.price_change.incl_vat.amount > 1250 ? 'red' : 'orange';
    }
    return {
      value: { start: option.start, end: option.end },
      title: `${formatDate(option.start || '', 'hour-minute')} - ${formatDate(option.end || '', 'hour-minute')}`,
      suffix: (
        <span className={'text--' + label}>
          {option.price_change?.incl_vat.amount === 0 && <IconEcoFriendly />}
          {priceAsString({ price: { amount: option.price_change?.incl_vat.amount } })}
        </span>
      ),
    };
  });

  if (loading) {
    return <IconSmile spinning={true} />;
  }

  return (
    <div>
      <Field
        compact={true}
        maxDelayIndex={optionsCustomTiles.length}
        name={`${stopType}`}
        validate={isPickupAndAuctionOrStore ? undefined : pickupDeliverySchema.time}
        options={optionsCustomTiles}
        component={RfInputTiles}
        onChangeCallback={proceed}
      />
    </div>
  );
};

import React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { connect, useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { InjectedFormProps, getFormValues, reduxForm } from 'redux-form';
import '../../../assets/general-flow/items.scss';
import '../../../assets/user/user.scss';
import { Heading, SheetLayoutContent, SheetLayoutContentWrapper, SheetLayoutHeader } from '../../../brenger-shared-ui';
import { RootState } from '../../../typings';
import { formatDate } from '../../../utils/datetime';
import { ItemsAndServiceFields } from '../components/ItemsAndServiceFields';
import { actions, getDeliveryAddressAsString } from '../ducks';
import { BusinessFields, BusinessForms, DateSelectOption } from '../interface';
import FooterLayout from './FooterLayout';

/**
 * DEPRECATED
 * */
interface ItemFormValues {
  title?: string;
  width?: number;
  height?: number;
  length?: number;
  count: number;
  image?: string;
}

/**
 * DEPRECATED
 * */
interface ItemSetFormValues {
  itemSets: {
    items: ItemFormValues[];
  }[];
}

export interface ItemsAndServiceFormValues {
  // FIX ME: Add proper item_set values
  [BusinessFields.ITEM_SETS]: ItemSetFormValues['itemSets'];
  [BusinessFields.EXTRA_DRIVER]?: boolean;
  [BusinessFields.ASSEMBLY]?: boolean;
  [BusinessFields.WEIGHT]?: string; // Couldn't make redux form save it as number
  [BusinessFields.DATE]?: DateSelectOption;
}

type ReduxProps = ReturnType<typeof mapStateToProps>;

type Form = InjectedFormProps<ItemsAndServiceFormValues, ReduxProps>;

type Props = ReduxProps & Form;

export const getItemsAndServicesFormValues = (state: RootState): ItemsAndServiceFormValues | undefined => {
  return getFormValues(BusinessForms.ITEMS_AND_SERVICE)(state) as ItemsAndServiceFormValues | undefined;
};
const ItemsAndServices: React.FC<Props> = props => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(actions.setDateTimePeriodStartFrom(formatDate(new Date(), 'api-date')));
  }, []);

  return (
    <form onSubmit={props.handleSubmit}>
      <SheetLayoutContentWrapper>
        <SheetLayoutHeader>
          <Heading size={2}>
            <Translate id={'request_flow.business.step_2.title'} />
          </Heading>
        </SheetLayoutHeader>
        <SheetLayoutContent>
          {props.deliveryAddressString && (
            <>
              <Translate id={'request_flow.business.delivery_details_to'} /> {props.deliveryAddressString}
            </>
          )}
        </SheetLayoutContent>
        <ItemsAndServiceFields />
        <FooterLayout submitForm={props.handleSubmit} />
      </SheetLayoutContentWrapper>
    </form>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState) => ({
  deliveryAddressString: getDeliveryAddressAsString(state),
});

export default reduxForm<ItemsAndServiceFormValues, Props>({
  form: BusinessForms.ITEMS_AND_SERVICE,
  destroyOnUnmount: false,
  initialValues: {
    itemSets: [{ items: [{ count: 1 }] }],
  },
  onSubmit: (details, dispatch: Dispatch) => {
    dispatch(actions.submitItemsServices(details));
  },
})(connect(mapStateToProps, null)(ItemsAndServices));

import * as React from 'react';
import './icons.scss';

const IconCamera = (): JSX.Element => {
  return (
    <svg className={'IconCamera'} width="612px" height="612px" viewBox="0 0 612 612">
      <circle cx="472.909" cy="222.545" r="27.818" />
      <path
        d="M160.733,125.181c3.422,0,6.176-2.476,6.176-5.563v-16.691c0-3.06-2.754-5.563-6.176-5.563h-43.285
    c-3.421,0-6.175,2.476-6.175,5.563v16.691c0,3.06,2.754,5.563,6.175,5.563H160.733z"
      />
      <path
        d="M319.909,445.091c69.128,0,125.182-56.055,125.182-125.182c0-69.129-56.054-125.183-125.182-125.183
    c-69.128,0-125.182,56.054-125.182,125.183C194.727,389.036,250.781,445.091,319.909,445.091z M319.909,222.545
    c53.772,0,97.363,43.591,97.363,97.364c0,53.771-43.591,97.363-97.363,97.363s-97.364-43.592-97.364-97.363
    C222.545,266.136,266.137,222.545,319.909,222.545z"
      />
      <path
        d="M0,194.727v278.183c0,55.637,55.636,55.637,55.636,55.637h500.727c0,0,55.637,0,55.637-55.637V194.727
    c0-55.636-55.637-55.636-55.637-55.636H450.654l-29.543-49.21c-2.114-3.561-7.232-6.426-11.238-6.426H229.945
    c-4.089,0-9.18,2.977-11.239,6.426l-29.543,49.21H55.636C55.636,139.09,0,139.09,0,194.727z M207.913,166.909l30.989-55.637
    h159.343l35.245,55.637h122.567c0,0,28.124,0,28.124,28.319V472.38c0,28.348-28.124,28.348-28.124,28.348H55.942
    c0,0-28.124,0-28.124-28.348V195.228c0-28.319,28.124-28.319,28.124-28.319H207.913z"
      />
    </svg>
  );
};

export default IconCamera;

import * as React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { Field } from 'redux-form';
import { Col, RfInput, RfInputHidden, RfLocationAutocomplete, RfSelect, RfTextarea } from '../../../brenger-shared-ui';
import { translate } from '../../../utils/localization';
import { normalizeFirstLetterCaps, normalizePostalCode } from '../../../utils/normalisation';
import { BusinessFields } from '../interface';
import { BusinessSchema } from '../schema';

interface Props {
  isManual: boolean;
  change(fieldname: string, value: boolean): void;
}

export const DeliveryAddressFields: React.FC<Props> = props => {
  if (!props.isManual) {
    return (
      <>
        <Field name={BusinessFields.DELIVERY_ADDRESS_IS_MANUAL} component={RfInputHidden} />
        <Col xs={12}>
          <Field
            name={BusinessFields.DELIVERY_AUTO_ADDRESS}
            component={RfLocationAutocomplete}
            validate={BusinessSchema.autocompleteFullAddress}
            label={translate('form.fields.address.line1.label')}
          />
          <small
            style={{ margin: '-.8em 0 .8em 0' }}
            className="text--primary trigger"
            onClick={() => props.change(BusinessFields.DELIVERY_ADDRESS_IS_MANUAL, true)}
          >
            <Translate id="form.fields.address.autocomplete.switch_to_manual" />
          </small>
        </Col>
        <Col xs={12}>
          <Field
            component={RfTextarea}
            type="text"
            name={BusinessFields.DELIVERY_INSTRUCTIONS}
            label={translate('request_flow.business.fields.extra_information.description')}
          />
        </Col>
      </>
    );
  }
  return (
    <>
      <Field name={BusinessFields.DELIVERY_ADDRESS_IS_MANUAL} component={RfInputHidden} />
      <Field name={BusinessFields.DELIVERY_MANUAL_ADMINISTRATIVE_AREA} component={RfInputHidden} />
      <Field name={BusinessFields.DELIVERY_MANUAL_LATITUDE} component={RfInputHidden} />
      <Field name={BusinessFields.DELIVERY_MANUAL_LONGITUDE} component={RfInputHidden} />
      <Field name={BusinessFields.DELIVERY_MANUAL_PRECISION} component={RfInputHidden} />
      <Col xs={12} sm={8}>
        <Field
          validate={BusinessSchema.line1}
          name={BusinessFields.DELIVERY_MANUAL_LINE_1}
          type="text"
          component={RfInput}
          label={translate('form.fields.address.line1.label')}
        />
      </Col>
      <Col xs={12} sm={4}>
        <Field
          validate={BusinessSchema.postalCode}
          name={BusinessFields.DELIVERY_MANUAL_POSTAL_CODE}
          type="text"
          component={RfInput}
          normalize={normalizePostalCode}
          label={translate('form.fields.address.postal_code.label')}
        />
      </Col>
      <Col xs={12} sm={6}>
        <Field
          validate={BusinessSchema.locality}
          name={BusinessFields.DELIVERY_MANUAL_LOCALITY}
          type="text"
          component={RfInput}
          normalize={normalizeFirstLetterCaps}
          label={translate('form.fields.address.locality.label')}
        />
      </Col>
      <Col xs={12} sm={6}>
        <Field
          name={BusinessFields.DELIVERY_MANUAL_COUNTRY_CODE}
          type="text"
          validate={BusinessSchema.country_code}
          component={RfSelect}
          options={[
            {
              label: 'NL',
              value: 'NL',
            },
            {
              label: 'BE',
              value: 'BE',
            },
          ]}
          label={translate('form.fields.address.country_code.label')}
        />
      </Col>
      <Col xs={12}>
        <small
          style={{ margin: '-.8em 0 .8em 0' }}
          className="text--primary trigger"
          onClick={() => props.change(BusinessFields.DELIVERY_ADDRESS_IS_MANUAL, false)}
        >
          <Translate id="form.fields.address.autocomplete.switch_to_autocomplete" />
        </small>
      </Col>
      <Col xs={12}>
        <Field
          component={RfTextarea}
          type="text"
          name={BusinessFields.DELIVERY_INSTRUCTIONS}
          label={translate('request_flow.business.fields.extra_information.description')}
        />
      </Col>
    </>
  );
};

type NotificationType = 'ADD_ACTIVE_NOTIFICATION' | 'REMOVE_ACTIVE_NOTIFICATION' | 'SET_NOTIFICATION_AS_SEEN';

// All new notifications require a string literal type as a name.
export type Notification =
  | 'quotes-rejected-area'
  | 'marktplaats-situation'
  | 'default-special-price'
  | 'not-guaranteed'
  | 'offer-limited-until-midnight'
  | 'weight'
  | 'corona'
  | 'extra-care--scooter'
  | 'extra-care--glass-insurance'
  | 'require-equipment'
  | 'live-tracking-info'
  | 'transport-request-savings'
  | 'product-selection--floor-service'
  | 'product-selection--help-service'
  | 'auto-selected--extra-driver';

/* These notifications are always added to the top of the stack.
 * - 'extra-care--scooter' added because moving around these things require extra papers (the customer should be prepared)
 * - 'extra-care--glass-insurance' added because glass is not insured (important for terms and conditions)
 * - 'auto-selected--extra-driver' added because we force the user to pick extra help
 */
const HighPriorityNotifications = [
  'not-guaranteed',
  'weight',
  'extra-care--scooter',
  'extra-care--glass-insurance',
  'auto-selected--extra-driver',
] as Notification[];

interface NotificationAction {
  type: NotificationType;
  payload: Notification;
}

export const actions = {
  addActiveNotification: (notification: Notification): NotificationAction => ({
    type: 'ADD_ACTIVE_NOTIFICATION',
    payload: notification,
  }),
  removeActiveNotification: (notification: Notification): NotificationAction => ({
    type: 'REMOVE_ACTIVE_NOTIFICATION',
    payload: notification,
  }),
  setNotificationAsSeen: (notification: Notification): NotificationAction => ({
    type: 'SET_NOTIFICATION_AS_SEEN',
    payload: notification,
  }),
};

export interface NotificationState {
  active: Notification[];
  hasSeen: Notification[];
}

const defaultState: NotificationState = {
  active: [],
  hasSeen: [],
};

export const notificationsReducer = (state = defaultState, action: NotificationAction): NotificationState => {
  switch (action.type) {
    case 'ADD_ACTIVE_NOTIFICATION': {
      // High priority notifications are always added to the top of the stack, which
      // means it'll become the currently visible one.
      const isHighPriority = HighPriorityNotifications.includes(action.payload);
      return {
        ...state,
        active: isHighPriority ? [...state.active, action.payload] : [action.payload, ...state.active],
      };
    }
    case 'REMOVE_ACTIVE_NOTIFICATION':
      return {
        ...state,
        active: state.active.filter(n => n !== action.payload),
      };
    case 'SET_NOTIFICATION_AS_SEEN':
      return {
        ...state,
        active: state.active.filter(n => n !== action.payload),
        hasSeen: [...state.hasSeen, action.payload],
      };
    default:
      return state;
  }
};

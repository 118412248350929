import { SandboxToken } from '@brenger/api-client';

import {
  Environment,
  ApiSettings,
  ApiSettingsActionTypes,
  SetEnvironmentAction,
  LoginSandboxStartAction,
  SetSandboxTokenAction,
  LoginSandboxResultAction,
} from './typings';

// state
export const defaultState: ApiSettings = {
  environment: 'production',
  sandbox: {
    token: '',
    expire_at: '',
  },
};

// actions
export const apiSettingsActions = {
  setEnvironment: (environment: Environment): SetEnvironmentAction => ({
    type: ApiSettingsActionTypes.SET_ENVIRONMENT,
    payload: environment,
  }),
  loginSandboxStart: (): LoginSandboxStartAction => ({ type: ApiSettingsActionTypes.LOGIN_SANDBOX_START }),
  loginSandboxSuccess: (): LoginSandboxResultAction => ({ type: ApiSettingsActionTypes.LOGIN_SANDBOX_SUCCESS }),
  loginSandboxFailed: (): LoginSandboxResultAction => ({ type: ApiSettingsActionTypes.LOGIN_SANDBOX_FAILED }),
  setSandboxToken: (payload: SandboxToken): SetSandboxTokenAction => ({
    type: ApiSettingsActionTypes.SET_SANDBOX_TOKEN,
    payload,
  }),
};

// reducers
export const apiSettingsReducer = (
  state: ApiSettings = defaultState,
  action: SetEnvironmentAction | SetSandboxTokenAction
): ApiSettings => {
  switch (action.type) {
    case ApiSettingsActionTypes.SET_ENVIRONMENT:
      return {
        ...state,
        environment: action.payload,
      };
    case ApiSettingsActionTypes.SET_SANDBOX_TOKEN:
      return {
        ...state,
        sandbox: action.payload,
      };
    default:
      return state;
  }
};

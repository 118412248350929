import { call, delay, put, takeLatest } from 'typed-redux-saga';
import { coreClient } from '../../../utils/request';
import { apiSettingsActions } from '../ducks';
import { ApiSettingsActionTypes, SetEnvironmentAction, SetSandboxTokenAction } from '../typings';
import { differenceInMilliseconds } from 'date-fns';

export function* scheduleTokenRefresh(action: SetSandboxTokenAction): Generator {
  const expireAt = new Date(action.payload.expire_at);
  // one minute before expiring we would like to refresh
  const oneMinutebeforeExp = differenceInMilliseconds(expireAt, new Date()) - 6000;
  yield* delay(oneMinutebeforeExp);
  yield* put(apiSettingsActions.loginSandboxStart());
}

export function* loginSandboxStart(): Generator {
  try {
    // get a token
    const sandboxAuth = yield* call(coreClient.users.retrieveSandboxToken);
    // save it
    yield* put(apiSettingsActions.setSandboxToken(sandboxAuth));
    // auth at sandbox
    yield* call(coreClient.users.login, { token: sandboxAuth.token });
    // flag success
    yield* put(apiSettingsActions.loginSandboxSuccess());
  } catch (e) {
    // flag failure
    yield* put(apiSettingsActions.loginSandboxFailed());
  }
}

export function* triggerAuthSandbox(action: SetEnvironmentAction): Generator {
  if (action.payload === 'development') {
    // we call this function immediately, instead of firing the login event
    // This way the call gets canceled when the users switches back to production
    yield* call(loginSandboxStart);
  }
}

export function* sandboxSagas(): Generator {
  // triggered when switching envs
  yield* takeLatest(ApiSettingsActionTypes.SET_ENVIRONMENT, triggerAuthSandbox);
  // triggered when we aren't authenticated anymore, but where still in sandbox mode
  yield* takeLatest(ApiSettingsActionTypes.LOGIN_SANDBOX_START, loginSandboxStart);
  // triggered when successfully obtained a token from core prod
  yield* takeLatest(ApiSettingsActionTypes.SET_SANDBOX_TOKEN, scheduleTokenRefresh);
}

import React from 'react';
import {
  getAddressTextLabel,
  getBasePrice,
  getDeliveryAdditions,
  getGlobalAdditions,
  getItemSetTotalSizeAsString,
  getPickupAdditions,
  getRequestTitle,
} from '../ducks';

import { Translate } from 'react-localize-redux-dep-updated';
import { connect } from 'react-redux';
import { LocationDisplay } from '../../../brenger-shared-ui';
import { RootState, StopType } from '../../../typings';
import { translate } from '../../../utils/localization';
import { priceAsString } from '../../../utils/price';
import AdditionList from '../components/AdditionList';

type ReduxProps = ReturnType<typeof mapStateToProps>;

type Props = ReduxProps;

const TransportRequestSummary: React.FC<Props> = props => {
  return (
    <>
      <div className={'summary-heading'}>{props.title}</div>
      {props.itemsAsString !== '' && (
        <>
          {props.itemsAsString}
          <br />
        </>
      )}
      {props.basePrice > 0 && (
        <div className={'summary-line'}>
          <div>
            <Translate id={'request_flow.price.base'} />
          </div>
          <div className={'adds-price'}>
            {priceAsString({ price: { amount: props.basePrice }, addPlusMinusIndicator: false })}
          </div>
        </div>
      )}
      <AdditionList additions={props.globalAdditions} renderCheckMark={false} />
      <div className={'summary-route pt-0-5'}>
        {!props.pickupAsText && !props.deliveryAsText && (
          <i>
            <Translate id={'request_flow.location.no_location'} />
          </i>
        )}
        {(props.pickupAsText || props.deliveryAsText) && (
          <>
            <LocationDisplay
              isPickup={true}
              locationName={
                props.pickupAsText ? props.pickupAsText : String(translate('request_flow.location.unknown'))
              }
            >
              <AdditionList additions={props.pickupAdditions} renderCheckMark={false} />
            </LocationDisplay>
            <LocationDisplay
              locationName={
                props.deliveryAsText ? props.deliveryAsText : String(translate('request_flow.location.unknown'))
              }
            >
              <AdditionList additions={props.deliveryAdditions} renderCheckMark={false} />
            </LocationDisplay>
          </>
        )}
      </div>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState) => ({
  title: getRequestTitle(state, false),
  itemsAsString: getItemSetTotalSizeAsString(state),
  basePrice: getBasePrice(state),
  pickupAsText: getAddressTextLabel(state, StopType.PICKUP),
  deliveryAsText: getAddressTextLabel(state, StopType.DELIVERY),
  globalAdditions: getGlobalAdditions(state),
  pickupAdditions: getPickupAdditions(state, false),
  deliveryAdditions: getDeliveryAdditions(state, false),
});

export default connect(mapStateToProps)(TransportRequestSummary);

import { actionTypes as reduxFormActions } from 'redux-form';
import { call, debounce, takeLatest } from 'typed-redux-saga';
import { logError } from '../../../utils/basics';
import { GeneralFlowForms } from '../interface';
import { proceed } from '../proceed';
import { GeneralFlowActionTypes, SubmitFormAction } from '../typings';
import { createTr } from './createTr';

function* handleFormSubmits(action: SubmitFormAction): Generator {
  if (action.meta.form === GeneralFlowForms.TERMS) {
    yield* call(createTr);
    return;
  }
  yield* call(proceed);
}

function scrollToErrorAfterSubmitFail(action): void {
  try {
    if (action.error && (action.meta.form === GeneralFlowForms.ITEMS || action.meta.form === GeneralFlowForms.TERMS)) {
      const messageElement = document.querySelector('.input-el--feedback--error') as HTMLElement;
      if (messageElement) {
        messageElement.scrollIntoView();
      }
    }
  } catch (err) {
    logError(err);
  }
}

export function* formSubmitSaga(): Generator {
  yield* takeLatest(GeneralFlowActionTypes.SUBMIT_FORM, handleFormSubmits);
  yield* debounce(250, reduxFormActions.SET_SUBMIT_FAILED, scrollToErrorAfterSubmitFail);
}

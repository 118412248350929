import React from 'react';
import './locationdisplay.scss';

interface Props {
  locationName?: string;
  isPickup?: boolean;
  showArrow?: boolean;
  size?: string;
  color?: string;
  children?: React.ReactNode;
}

export const LocationDisplay = ({
  locationName,
  isPickup = false,
  showArrow = true,
  size = 'normal',
  color = 'blue',
  children,
}: Props): JSX.Element => {
  return (
    <div
      className={
        'location-display location-display--size-' +
        size +
        ' location-display--color-' +
        color +
        (isPickup ? ' location-display--a' : ' location-display--b') +
        (!showArrow ? ' location-display--hide-arrow' : '')
      }
    >
      <div className={'location-display--dot'} />
      {isPickup && <div className={'location-display--arrow'} />}
      {locationName && <div className={'location-display--header'}>{locationName}</div>}
      <div className={'location-display--information'}>{children}</div>
    </div>
  );
};

export default LocationDisplay;

export const getRedirectUrl = (url: string): string | null => {
  if (url.indexOf('www.brenger.nl/nl/driver') > -1) {
    return url.replace('www.brenger.nl/nl/driver', 'driver.brenger.nl/nl/driver');
  }
  // Redirect all ikea page traffic - except for the thank you page (to be on the safe side)
  if (url.indexOf('/customer/partner/ikea/transport_request') > -1 && url.indexOf('thank_you') === -1) {
    return 'https://www.brenger.nl/nl/ikea/?utm_source=ikea&utm_account_pickup=ikea&utm_medium=referral&utm_campaign=prefill_address&utm_content=account_pickup';
  }
  if (url.indexOf('brenger.be/nl-be/customer') > -1) {
    return url.replace('brenger.be/nl-be/customer', 'brenger.nl/nl/customer');
  }
  const { hostname } = window.location;
  if (hostname.includes('kamernet')) {
    return 'https://www.brenger.nl/nl';
  }
  return null;
};

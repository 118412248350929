import React from 'react';

export const IconTimeSaving: React.FC<React.SVGAttributes<SVGElement>> = props => {
  return (
    <svg height="444pt" viewBox="0 -19 444.8228 444" width="444pt" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        className="cls-1"
        d="m25.191406 400.648438c.761719 4.351562 4.90625 7.261718 9.257813 6.5l65.316406-11.425782c4.351563-.761718 7.261719-4.90625 6.5-9.257812l-1.273437-7.292969 15.234374-12.371094c5.96875-4.972656 13.640626-7.421875 21.382813-6.839843l106.515625 7.785156c.179688.015625.355469.019531.535156.023437h.65625c23.503906-.050781 46.386719-7.539062 65.367188-21.402343.304687-.222657.59375-.46875.867187-.734376l126.867188-124.222656c3.027343-2.960937 3.21875-7.765625.445312-10.960937-14.640625-16.898438-39.96875-19.3125-57.535156-5.488281l-58.273437 43.574218c-12.117188-14.746094-31.226563-21.835937-50.027344-18.5625l-59.566406 10.417969c-9.492188 1.648437-19.230469 1.140625-28.5-1.484375l-6.660157-1.902344c-34.230469-9.832031-71.109375-2.175781-98.601562 20.46875l-2.507813-14.34375c-.761718-4.351562-4.90625-7.261718-9.257812-6.5l-65.3125 11.425782c-4.351563.765624-7.261719 4.910156-6.5 9.261718zm152.734375-148.25 6.660157 1.898437c11.589843 3.285156 23.765624 3.917969 35.632812 1.859375l59.566406-10.417969c17.066406-2.964843 33.90625 6.300781 40.535156 22.304688l-89.171874 15.597656c-4.09375.710937-6.960938 4.433594-6.601563 8.570313.355469 4.136718 3.816406 7.3125 7.96875 7.3125.464844 0 .929687-.039063 1.390625-.121094l97.910156-17.125c4.351563-.765625 7.261719-4.910156 6.5-9.261719-.636718-3.644531-1.65625-7.214844-3.039062-10.648437l59.710937-44.648438c.054688-.042969.109375-.085938.164063-.128906 8.625-6.820313 20.617187-7.453125 29.910156-1.574219l-120.292969 117.816406c-16.148437 11.65625-35.542969 17.957031-55.457031 18.007813h-.300781l-106.242188-7.796875c-11.835937-.898438-23.558593 2.832031-32.695312 10.410156l-8.242188 6.683594-14.871093-85.023438c23.714843-23.921875 58.585937-33.011719 90.964843-23.714843zm-111.113281 1.371093 22.316406 127.570313-49.554687 8.671875-22.316407-127.570313zm0 0"
      />
      <path
        className="cls-1"
        d="m242.410156 217.675781c59.996094 0 108.632813-48.632812 108.632813-108.628906s-48.632813-108.632813-108.628907-108.636719c-59.996093 0-108.632812 48.636719-108.636718 108.632813.066406 59.96875 48.664062 108.566406 108.632812 108.632812zm0-201.265625c51.160156 0 92.632813 41.476563 92.632813 92.636719s-41.472657 92.632813-92.632813 92.628906c-51.160156 0-92.632812-41.472656-92.632812-92.632812.058594-51.136719 41.5-92.574219 92.632812-92.632813zm0 0"
      />
      <path
        className="cls-1"
        d="m236.8125 125.839844h44.757812c4.417969 0 8-3.582032 8-8 0-4.417969-3.582031-8-8-8h-37.160156v-51.144532c0-4.421874-3.582031-8-8-8-4.417968 0-8 3.578126-8 8v58.746094c.160156 4.570313 3.828125 8.242188 8.402344 8.398438zm0 0"
      />
    </svg>
  );
};

import * as React from 'react';
import './stepindicator.scss';

interface Props {
  currentStep: number;
  children: React.ReactNode;
}

export const StepIndicator = ({ currentStep, children }: Props): JSX.Element => {
  return (
    <div className={'step-indicator step-indicator--step-' + currentStep}>
      <div className={'step-indicator--circle'}>{currentStep}</div>
      <div className={'step-indicator--title'}>{children}</div>
    </div>
  );
};
export default StepIndicator;

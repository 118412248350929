import * as React from 'react';
import './icons.scss';

const IconElevator = (): JSX.Element => {
  return (
    <svg className={'IconElevator'} version="1.1" viewBox="0 0 31 31">
      <g>
        <path d="M6.58 24.76c.355 0 .646-.289.646-.642V13.286h15.548v10.832a.646.646 0 0 0 1.29 0V12.643A.646.646 0 0 0 23.42 12H6.581a.646.646 0 0 0-.646.643v11.475c0 .353.29.643.646.643zM14.536 5.296a.508.508 0 0 0-.734 0l-2.769 2.858V.536a.528.528 0 0 0-.52-.536c-.286 0-.518.24-.518.536v7.632L7.225 5.31a.519.519 0 0 0-.749-.013.56.56 0 0 0-.012.773l3.659 3.761h.02c.042.04.09.073.142.097h.028a.499.499 0 0 0 .173.035.499.499 0 0 0 .173-.035h.028a.515.515 0 0 0 .16-.072h.02l3.67-3.786a.544.544 0 0 0 0-.775zM23.722 4.668a.508.508 0 0 1-.734 0l-2.77-2.857v7.618a.528.528 0 0 1-.519.535.528.528 0 0 1-.519-.535V1.796l-2.77 2.858a.519.519 0 0 1-.749.012.56.56 0 0 1-.012-.773l3.66-3.76h.02a.518.518 0 0 1 .142-.097h.027A.499.499 0 0 1 19.672 0c.059.001.117.014.173.036h.027c.057.014.11.038.16.071h.02l3.67 3.786a.544.544 0 0 1 0 .775z" />
        <path d="M29.355 29.714h-5.258V19.332a.646.646 0 0 0-1.29 0v10.382H7.225V19.332a.646.646 0 0 0-1.29 0v10.382H.71a.646.646 0 0 0-.645.643c0 .354.29.643.645.643h28.645c.355 0 .645-.29.645-.643a.646.646 0 0 0-.645-.643z" />
      </g>
    </svg>
  );
};

export default IconElevator;

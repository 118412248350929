import { PaymentMethodId } from '@brenger/api-client';
import cn from 'classnames';
import React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import {
  Heading,
  IconSmile,
  SheetLayoutContent,
  SheetLayoutContentWrapper,
  SheetLayoutFooter,
  SheetLayoutHeader,
} from '../../../brenger-shared-ui';
import { CacheKey } from '../../../typings';
import { coreClient } from '../../../utils/request';
import { actions } from '../ducks';
import FooterLayout from './FooterLayout';

type Props = RouteComponentProps<{ id: string }>;

export const OppPayment: React.FC<Props> = props => {
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = React.useState(false);

  React.useEffect(() => {
    dispatch(actions.setProgress(7));
  }, []);

  const fetchMethods = useQuery(CacheKey.RETRIEVE_OPP_METHODS, () => coreClient.paymentMethods.oppList());
  const createPayment = useMutation(coreClient.payments.addMultiTransactionToTransportRequest, {
    onSuccess: payment => window.location.assign(payment.redirect_url as string),
    onError: () => setSubmitting(false),
  });
  const methods = fetchMethods.data?.['hydra:member'] || [];
  const startPayment = (method: PaymentMethodId): void => {
    setSubmitting(true);
    createPayment.mutate({
      id: props.match.params.id,
      data: {
        method,
      },
    });
  };

  const showMethods = !fetchMethods.isLoading && !submitting;
  return (
    <SheetLayoutContentWrapper>
      <SheetLayoutHeader>
        <Heading size={2}>
          <Translate id={'request_flow.headers.payment'} />
        </Heading>
      </SheetLayoutHeader>
      <SheetLayoutContent>
        {!showMethods && (
          <div className={'pb-1'}>
            <IconSmile spinning={true} centered={true} />
          </div>
        )}
        {showMethods && fetchMethods.data && (
          <div>
            {methods.map((method, index) => {
              const identifiers = {
                'data-qa-id': `${method.name}_${method.provider_id}_${index}`,
                'data-qa-input-name': method.name,
                'data-qa-input-value': method.provider_id,
                'data-qa-nth-of-kind': index,
              };
              return (
                <div
                  key={index}
                  {...identifiers}
                  className={cn(['trigger', 'input-el--button-select--option', `delay-index-${index}`])}
                  onClick={() => startPayment(method.provider_id)}
                >
                  {method.icon && (
                    <img className={'input-el--button-select--option-icon'} src={method.icon} alt={method.name} />
                  )}
                  <div className={'input-el--button-select--option-label'}>
                    {method.provider_id == 'creditcard' ? (
                      <Translate id={'request_flow.payment.method_options.credit_card'} />
                    ) : (
                      method.name
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {/* <div>{error && <FlashMessage type={'error'} message={error} />}</div> */}
      </SheetLayoutContent>
      <SheetLayoutFooter>
        <FooterLayout />
      </SheetLayoutFooter>
    </SheetLayoutContentWrapper>
  );
};

import React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { Button, Col, Heading, Row, SectionWrapper } from '../../../brenger-shared-ui';
import { getActiveLanguageSettings, helpTrans } from '../../../utils/localization';

export const CanceledPayment: React.FC = () => {
  const settings = getActiveLanguageSettings();
  return (
    <Col xs={12}>
      <SectionWrapper sectionStyle={'primary'} centerContent={true}>
        <Row>
          <Col xs={12} md={8} extraClasses={'offset-md-2'}>
            <Row>
              <Col xs={12} extraClasses={'pt-1 pb-1'}>
                <Heading size={4}>
                  <Translate id="request_flow.payment_canceled.title" />
                </Heading>
              </Col>
            </Row>
          </Col>
        </Row>
      </SectionWrapper>
      <SectionWrapper sectionStyle={'white'} centerContent={true}>
        <Row>
          <Col xs={12} md={8} extraClasses={'offset-md-2 pb-1'}>
            <Heading size={5}>
              <Translate id="request_flow.payment_canceled.sub_title" />
            </Heading>
            <div
              dangerouslySetInnerHTML={{
                // eslint-disable-next-line @typescript-eslint/naming-convention
                __html: helpTrans.asString({
                  id: `request_flow.payment_canceled.further_steps`,
                }),
              }}
            />
            <Button fullWidth={true} href={settings.home_page} buttonStyle={'primary'}>
              <Translate id={`request_flow.payment_canceled.cta`} />
            </Button>
          </Col>
        </Row>
      </SectionWrapper>
    </Col>
  );
};

import React from 'react';

export const IconCo2: React.FC<React.SVGAttributes<SVGElement>> = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={512} height={512} viewBox="0 0 512 512" {...props}>
      <g>
        <g>
          <path
            className="cls-1"
            d="M384.397,181.081c-6.544-27.372-21.433-52.232-42.789-71.072c-25.098-22.141-57.369-34.334-90.87-34.334
			c-75.774,0-137.421,61.647-137.421,137.421c0,1.176,0.015,2.352,0.045,3.53c-1.163-0.038-2.324-0.056-3.485-0.056
			C49.291,216.57,0,265.86,0,326.448c0,60.587,49.291,109.878,109.878,109.878h274.5c70.371,0,127.622-57.251,127.622-127.622
			C512,238.336,454.762,181.088,384.397,181.081z M384.378,406.325h-274.5C65.833,406.325,30,370.492,30,326.448
			s35.833-79.878,79.878-79.878c5.673,0,11.395,0.617,17.005,1.833l21.68,4.699l-3.715-21.87c-1.015-5.979-1.53-12.081-1.53-18.135
			c0-59.232,48.189-107.421,107.421-107.421c53.616,0,99.386,40.008,106.464,93.063l1.936,14.51l14.553-1.582
			c3.565-0.388,7.16-0.584,10.686-0.584c53.829,0,97.622,43.793,97.622,97.622C482,362.534,438.207,406.325,384.378,406.325z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            className="cls-1"
            d="M232.122,319.232c-1.189,2.92-2.754,5.379-4.699,7.377c-1.945,2.001-4.08,3.622-6.402,4.863
			c-2.325,1.243-4.756,2.163-7.294,2.755c-2.54,0.595-4.999,0.891-7.375,0.891c-4.647,0-8.833-0.944-12.561-2.836
			c-3.728-1.889-6.916-4.457-9.563-7.699c-2.649-3.242-4.675-6.969-6.078-11.184c-1.406-4.214-2.107-8.59-2.107-13.128
			c0-4.214,0.593-8.372,1.783-12.48c1.188-4.105,3.024-7.78,5.511-11.021c2.484-3.242,5.617-5.888,9.4-7.942
			c3.781-2.051,8.21-3.08,13.29-3.08c2.376,0,4.807,0.243,7.294,0.729c2.484,0.486,4.915,1.352,7.293,2.593
			c2.376,1.243,4.563,2.864,6.564,4.862c1.998,2,3.646,4.513,4.943,7.537l20.422-14.263c-3.89-7.456-9.806-13.506-17.747-18.153
			c-7.942-4.645-17.317-6.97-28.121-6.97c-9.076,0-17.18,1.677-24.312,5.025c-7.131,3.35-13.184,7.727-18.153,13.128
			c-4.971,5.404-8.752,11.536-11.346,18.396c-2.593,6.863-3.89,13.752-3.89,20.665c0,7.35,1.378,14.587,4.133,21.719
			c2.755,7.131,6.645,13.508,11.67,19.125c5.024,5.62,11.021,10.13,17.991,13.534c6.969,3.403,14.612,5.105,22.935,5.105
			c4.753,0,9.616-0.595,14.587-1.783c4.969-1.188,9.669-2.917,14.101-5.187c4.429-2.269,8.347-5.078,11.751-8.428
			c3.403-3.348,5.969-7.131,7.699-11.346L232.122,319.232z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            className="cls-1"
            d="M369.73,279.201c-2.703-7.024-6.54-13.291-11.508-18.802c-4.971-5.511-10.968-9.94-17.991-13.29
			c-7.025-3.348-14.805-5.025-23.34-5.025c-8.322,0-15.993,1.596-23.015,4.782c-7.025,3.188-13.104,7.455-18.234,12.804
			c-5.133,5.349-9.132,11.536-11.994,18.558c-2.864,7.025-4.295,14.319-4.295,21.881c0,7.456,1.378,14.696,4.133,21.719
			c2.755,7.025,6.617,13.265,11.589,18.72c4.969,5.458,10.965,9.862,17.991,13.21c7.022,3.351,14.803,5.025,23.339,5.025
			c8.319,0,15.99-1.621,23.016-4.863c7.023-3.241,13.073-7.537,18.153-12.885c5.078-5.349,9.049-11.533,11.913-18.558
			c2.862-7.023,4.295-14.316,4.295-21.881C373.782,293.358,372.43,286.226,369.73,279.201z M344.85,312.833
			c-1.244,4.161-3.107,7.889-5.592,11.183c-2.487,3.297-5.62,5.972-9.401,8.023c-3.783,2.054-8.213,3.08-13.29,3.08
			c-4.862,0-9.185-0.972-12.966-2.918c-3.784-1.945-6.944-4.538-9.482-7.78c-2.54-3.242-4.457-6.941-5.754-11.103
			c-1.297-4.158-1.945-8.453-1.945-12.885c0-4.32,0.621-8.563,1.864-12.723c1.241-4.159,3.133-7.861,5.673-11.103
			c2.538-3.242,5.673-5.86,9.4-7.861c3.728-1.999,8.129-2.999,13.21-2.999c4.863,0,9.158,0.947,12.886,2.836
			c3.728,1.892,6.888,4.432,9.482,7.618c2.593,3.188,4.539,6.863,5.835,11.021c1.297,4.161,1.945,8.456,1.945,12.885
			C346.714,304.432,346.091,308.674,344.85,312.833z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            className="cls-1"
            d="M391.176,367.916c0.456-0.505,1.127-1.075,2.015-1.71c0.886-0.633,1.85-1.267,2.889-1.901
			c1.039-0.633,2.091-1.254,3.155-1.863c1.064-0.608,1.977-1.14,2.737-1.597c1.114-0.658,2.205-1.38,3.269-2.167
			c1.065-0.785,2.027-1.698,2.889-2.737c0.861-1.038,1.559-2.217,2.091-3.535c0.532-1.317,0.798-2.813,0.798-4.486
			c0-4.054-1.457-7.272-4.372-9.656c-2.915-2.382-7.109-3.573-12.583-3.573c-2.231,0-4.371,0.203-6.424,0.608
			c-2.053,0.406-3.941,0.95-5.664,1.635c-1.724,0.684-3.27,1.457-4.638,2.319c-1.369,0.862-2.459,1.748-3.27,2.661l7.755,8.972
			c1.47-1.673,2.99-3.028,4.562-4.068c1.57-1.038,3.421-1.559,5.55-1.559c1.875,0,3.319,0.432,4.334,1.293
			c1.013,0.863,1.521,2.003,1.521,3.421c0,0.71-0.203,1.331-0.608,1.863c-0.406,0.532-1.077,1.128-2.015,1.787
			c-0.938,0.66-2.205,1.42-3.801,2.281c-1.597,0.863-3.586,1.952-5.968,3.27c-2.231,1.268-4.081,2.56-5.55,3.877
			c-1.471,1.318-2.636,2.75-3.498,4.295c-0.862,1.547-1.47,3.257-1.824,5.132c-0.355,1.875-0.532,4.004-0.532,6.386h37.862v-10.948
			H391.176z"
          />
        </g>
      </g>
    </svg>
  );
};

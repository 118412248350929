import React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import '../../../../assets/product-selection/itemConfig.scss';
import { Button, IconMinus, IconPlus } from '../../../../brenger-shared-ui';

interface Props {
  count: number;
  updateCount(count: number): void;
}

export const ItemCount: React.FC<Props> = ({ count, updateCount }) => {
  return (
    <div className="ps-config--count flex">
      <Translate id={'request_flow.product_selection.how_many'} />
      <div className="ps-config--count-value flex">
        <Button
          type={'button'}
          disabled={count === 1}
          buttonStyle={'transparant'}
          onClick={count - 1 === 0 ? undefined : () => updateCount(count - 1)}
          extraClasses="flex flex--vc ps-config--count-trigger ps-config--count--decrease"
        >
          <IconMinus />
        </Button>
        <div className="ps-config--count--number">{count}</div>
        <Button
          type={'button'}
          buttonStyle={'transparant'}
          onClick={() => updateCount(count + 1)}
          extraClasses="flex flex--vc ps-config--count-trigger ps-config--count--increase"
        >
          <IconPlus />
        </Button>
      </div>
    </div>
  );
};

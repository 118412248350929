import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';
import { Dispatch } from 'redux';
import { getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import { SheetLayoutContentWrapper } from '../../../brenger-shared-ui';
import { RootState } from '../../../typings';
import { SubStepDate } from '../components/SubStepDate';
import { TimeModal } from '../components/TimeModal';
import { actions, actions as flowActions } from '../ducks';
import { FieldNamesDate, GeneralFlowForms } from '../interface';
import FooterLayout from './FooterLayout';
import { r } from '../../../routes';

export interface DateFormValues {
  [FieldNamesDate.DIFFERENT_DELIVERY_DATE]: boolean;
  [FieldNamesDate.PICKUP]: string;
  [FieldNamesDate.DELIVERY]: string;
}

type ReduxProps = ReturnType<typeof mapDispatchToProps>;

type Form = InjectedFormProps<DateFormValues, ReduxProps>;

type Props = ReduxProps & Form;

export const getDateFormValues = (state: RootState): null | DateFormValues => {
  return getFormValues(GeneralFlowForms.DATE)(state) as null | DateFormValues;
};

const DateContainer: React.FC<Props> = props => {
  /**
   * Set progress and timestamp last visited
   */
  React.useEffect(() => {
    props.setProgress(4);
    props.addLastVisitTime();
  }, []);

  return (
    <form onSubmit={props.handleSubmit}>
      <SheetLayoutContentWrapper>
        <Route exact={true} path={r.generalFlow.date.index()} component={SubStepDate} />
        <Route exact={true} path={r.generalFlow.date.delivery()} component={SubStepDate} />
        <TimeModal />
        <FooterLayout />
      </SheetLayoutContentWrapper>
    </form>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: Dispatch) => ({
  setProgress: (step: number) => dispatch(flowActions.setProgress(step)),
  addLastVisitTime: () => dispatch(flowActions.addLastVisitTime()),
});

export default reduxForm<DateFormValues, Props>({
  form: GeneralFlowForms.DATE,
  destroyOnUnmount: false,
  initialValues: {
    [FieldNamesDate.DIFFERENT_DELIVERY_DATE]: false,
  },
  onSubmit: (values: DateFormValues, dispatch) => {
    dispatch(actions.submitForm(GeneralFlowForms.DATE, values));
  },
})(connect(null, mapDispatchToProps)(DateContainer));

import * as React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { useDispatch } from 'react-redux';
import {
  Heading,
  InputSwitch,
  SectionWrapper,
  SheetLayoutContent,
  SheetLayoutHeader,
} from '../../../../brenger-shared-ui';
import { useTypedSelector } from '../../../../hooks';
import { SessionStorageKeys } from '../../../../typings';
import { getCrossDomainSetup } from '../../../../utils/basics';
import { getBasePath } from '../../../../utils/global';
import { isUserLoggedIn } from '../../../User/ducks';
import FooterLayout from '../../containers/FooterLayout';
import { actions } from '../../ducks';
import { proceed } from '../../proceed';
import { BusinessFields } from './BusinessFields';
import { ContactFields } from './ContactFields';

type CustomerType = 'consumer' | 'business';

export const ContactCustomer: React.FC = () => {
  const isLoggedIn = useTypedSelector(isUserLoggedIn);
  const crossDomain = getCrossDomainSetup();
  const customerDetails = useTypedSelector(state => state.generalTransport.contact.customer?.contact_details);
  const companyDetails = useTypedSelector(state => state.generalTransport.contact.customer?.company_details);
  const dispatch = useDispatch();
  const defaultCustomerType = companyDetails
    ? 'business'
    : ((sessionStorage.getItem(SessionStorageKeys.CUSTOMER_TYPE) || 'consumer') as CustomerType);
  const [type, setType] = React.useState(defaultCustomerType);
  const [forceShowErrors, setForceShowErrors] = React.useState(false);

  React.useEffect(() => {
    sessionStorage.setItem(SessionStorageKeys.CUSTOMER_TYPE, type);
    setForceShowErrors(false);
    if (type === 'consumer') {
      dispatch(actions.setCompanyDetails(null));
    }
  }, [type]);

  const ConsumerTypeOptions: {
    value: CustomerType;
    label: React.ReactNode;
  }[] = [
    {
      value: 'consumer',
      label: <Translate id={'request_flow.contact.is_private'} />,
    },
    {
      value: 'business',
      label: <Translate id={'request_flow.contact.is_business'} />,
    },
  ];

  const submit = (): void => {
    // When trying to submit, but it isn't possible yet: force show errors
    setForceShowErrors(true);
    if (!customerDetails) {
      return;
    }
    if (type === 'business' && !companyDetails) return;
    proceed();
  };

  return (
    <>
      <SheetLayoutHeader>
        <Heading size={2}>
          <Translate id={'request_flow.headers.contact_main'} />
        </Heading>
        {/* Cross domain logins don't work, so we don't offer login here, this wouldl ead to a bad experience */}
        {!crossDomain && (
          <p>
            <Translate id={'request_flow.headers.contact_sub'} />
            {!isLoggedIn && (
              <>
                {' '}
                <Translate id={'authentication.login.brief'} />
                <a href={`${getBasePath()}/login?contact=true`}>
                  <Translate id={'authentication.login.button'} />
                </a>
              </>
            )}
          </p>
        )}
      </SheetLayoutHeader>
      <SheetLayoutContent>
        <InputSwitch
          id={'customer_type'}
          value={type}
          options={ConsumerTypeOptions}
          onChange={t => setType(t as CustomerType)}
        />
      </SheetLayoutContent>
      <SectionWrapper centerContent={false} sectionStyle={'primary-light'} dashed={true}>
        <SheetLayoutContent>
          <ContactFields type="customer" forceShowErrors={forceShowErrors} />
        </SheetLayoutContent>
      </SectionWrapper>
      <SheetLayoutContent>
        {type === 'business' && (
          <>
            <div className="pb-1" />
            <Heading size={4}>
              <Translate id={'request_flow.contact.business_details'} />
            </Heading>
            <BusinessFields forceShowErrors={forceShowErrors} />
          </>
        )}
      </SheetLayoutContent>
      <FooterLayout customSubmitFunction={submit} />
    </>
  );
};

import {
  AccountingClient,
  CarryingHelp,
  CoreClient,
  PaginationControls,
  PriceClient,
  RoutePlannerClient,
  UserDataClient,
} from '@brenger/api-client';
import axios, { AxiosError, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { store } from '../';
import { Config } from '../config';
import { ApiSettings } from '../modules/ApiSettings/typings';
import { actions, actions as generalActions } from '../modules/GeneralFlow/ducks';
import { GeneralFlowActionTypes } from '../modules/GeneralFlow/typings';
import { QuotesErrorTitles } from '../typings';
import { logError, logException } from './basics';
import { uuidv4 } from './eventTracking';
import { uuid } from '@brenger/utils';

export enum CONFIGS {
  ACCEPT_HEADER = 'application/ld+json',
  CONTENT_TYPE_HEADER = 'application/ld+json',
}

interface HttpType {
  version?: number;
  headers?: Record<string, string>;
}

// Note: over time, we should aim to stop using this axios instance in favor of the
// the api-clients (see below).
export const http = (options?: HttpType): AxiosInstance => {
  const headers = options?.headers;
  // apply interceptor on response
  const instance = axios.create({
    baseURL: Config.API_URL,
    headers: {
      Accept: headers ? headers.accept : CONFIGS.ACCEPT_HEADER,
      'Content-Type': headers ? headers.contentType : CONFIGS.CONTENT_TYPE_HEADER,
    },
  });
  instance.interceptors.request.use(coreRequestInterceptor);
  instance.interceptors.response.use(
    response => response,
    error => errorResponseInterceptor(error)
  );
  return instance;
};

// Check for specific error codes we care about and log them in Sentry.
const errorResponseInterceptor = (error: AxiosError): AxiosError => {
  const status = error?.response?.status;
  const isStatusOfInterest = status && status > 400 && status !== 404 && status !== 403 && status < 500;
  // Did core throw some interesting error at us?
  const shouldBeBeHandledHere = !['/transport_requests'].includes(error?.response?.config?.url || '');
  if (isStatusOfInterest && shouldBeBeHandledHere) {
    // Come at Sentry bro!
    logException(`${status} STATUS: ${error?.response?.config?.url}`, {
      config: error.response?.config,
      data: error.response?.data,
    });
  }
  // Always end up with throwing an error
  // Other functions depending on this call will end up in their catch.
  throw error;
};

// Check for specific error codes we care about and log them in Sentry.
const errorResponseInterceptorPrice = (error: AxiosError): AxiosError => {
  const status = error?.response?.status;
  const isStatusOfInterest = status && status >= 400 && status < 500;
  if (!isStatusOfInterest) {
    if (
      status == 503 &&
      (error?.response?.data as undefined | { errors: string })?.errors ===
        QuotesErrorTitles.ROUTE_CALCULATION_NOT_POSSIBLE
    ) {
      store.dispatch(actions.setRejectedByPricing('route.calculation_not_possible'));
    }
    throw error;
  }

  // Is it an outside of service error
  const isNoServiceError =
    (error.response?.data as undefined | { title: string })?.title === QuotesErrorTitles.OUTSIDE_SERVICE_AREA;
  if (isNoServiceError) {
    store.dispatch(generalActions.setRejectedByPricing('area.no_service_for_at_least_one_stop'));
  }

  // If not a specifc error, we would like to retry
  if (!isNoServiceError) {
    const state = store.getState();
    const retryAttempt = state.generalTransport.price.retryAttempt;
    if (retryAttempt <= 3) {
      store.dispatch({ type: GeneralFlowActionTypes.PRICE_API_RETRY_ATTEMPT_INCREMENT });
    } else {
      store.dispatch({ type: GeneralFlowActionTypes.RESET_RETRY_ATTEMPTS });
    }
  }

  // Always end up with throwing an error
  // Other functions depending on this call will end up in their catch.
  throw error;
};

// In certain environments, it is helpful to surface internal logs that may
// shed light on the decisions made behind certain quotes.
const priceResponseInterceptor = (response: AxiosResponse): AxiosResponse => {
  const logs = response?.data?.logs;
  if (logs && Config.LOG) {
    logError(logs);
  }
  return response;
};

// Add a request interceptor
const coreRequestInterceptor = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  const isProd = getIsProduction();
  /**
   * SOME PATHS ALWAYS TALK TO PROD
   * - Reading API keys
   * - Generating a auth token, because this is based on the core prod session
   */
  if (
    !isProd &&
    !['/users/me/generate_sandbox_token'].includes(config.url || '') &&
    !config.url?.includes('api_keys')
  ) {
    config.baseURL = getCoreSandBoxUrl();
  }
  /**
   * Add unique trace id to every call
   */
  config.headers['X-Brenger-Trace-Id'] = config.headers['X-Brenger-Trace-Id'] || uuid();

  return config;
};

// Add a request interceptor
const priceRequestInterceptor = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  const isProd = getIsProduction();
  if (!isProd) {
    config.baseURL = getPricingSandBoxUrl();
  }
  /**
   * Add unique trace id to every call
   */
  config.headers['X-Brenger-Trace-Id'] = config.headers['X-Brenger-Trace-Id'] || uuid();

  return config;
};

const priceAxiosInstance = axios.create({
  baseURL: Config.PRICE_API_URL,
});
priceAxiosInstance.interceptors.response.use(priceResponseInterceptor, errorResponseInterceptorPrice);
priceAxiosInstance.interceptors.request.use(priceRequestInterceptor);
export const priceClient = new PriceClient(priceAxiosInstance);

const coreAxiosInstance = axios.create({
  baseURL: Config.API_URL,
  withCredentials: true,
  headers: {
    Accept: 'application/ld+json',
    'Content-Type': 'application/ld+json',
  },
});
coreAxiosInstance.interceptors.response.use(undefined, errorResponseInterceptor);
coreAxiosInstance.interceptors.request.use(coreRequestInterceptor);
export const coreClient = new CoreClient(coreAxiosInstance);

const routePlannerAxiosInstance = axios.create({
  baseURL: Config.GEO_API_URL,
});
routePlannerAxiosInstance.interceptors.request.use(config => {
  config.headers['X-Brenger-Trace-Id'] = config.headers['X-Brenger-Trace-Id'] || uuid();
  return config;
});

export const routePlannerClient = new RoutePlannerClient(routePlannerAxiosInstance);

const accountingClientAxiosInstance = axios.create({
  baseURL: 'https://accounting.brenger.nl',
});
accountingClientAxiosInstance.interceptors.request.use(config => {
  config.headers['X-Brenger-Trace-Id'] = config.headers['X-Brenger-Trace-Id'] || uuid();
  return config;
});

export const accountingClient = new AccountingClient(accountingClientAxiosInstance);
export const paginationControls = new PaginationControls();

export const routePlannerClientToken = uuidv4();

const getCoreSandBoxUrl = (): string => {
  return 'https://sandbox.api.brenger.nl';
};

const getPricingSandBoxUrl = (): string => {
  /* FIXME: Where to get the url from? */
  return 'https://sandbox.pricing.brenger.nl';
};

export const getIsProduction = (): boolean => {
  /* the store / api settings should always be there
   * but to be on the safe side let's consider it either undefined or holding the right state
   */
  const apiSettings: undefined | ApiSettings = store?.getState()['apiSettings'];
  return apiSettings?.environment !== 'development';
};

export const EQUIPMENT_CHOICES: CarryingHelp[] = [
  'equipment_tailgate',
  'equipment_tailgate_pallet_jack',
  'equipment_tailgate_extra_driver',
  'equipment_provided_by_customer',
];

export const userDataClient = new UserDataClient(Config.USER_DATA_URL);

export const uploadRemoteImageByUrl = async (url?: string | null): Promise<string | null> => {
  if (!url) {
    return null;
  }
  try {
    const imageData = new FormData();
    imageData.append('source_file_url', url);
    const resource = await coreClient.images.createJobImage({ imageData });
    return resource['@id'];
  } catch (e) {
    logError(e);
    return null;
  }
};

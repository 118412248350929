import { GeoAutocompleteAddress } from '@brenger/api-client';
import React from 'react';
import { Platform } from '../generated/graphql';
import { retrieveCachedLocationDetails } from '../utils/geo';
import { getActiveLanguageSettings } from '../utils/localization';
import {
  TbAuctionLotError,
  TransformedLot,
  parseTbAuctionQuery,
  transformTbAuctionLot,
  validateTbAuctionLot,
} from '../utils/tbAuctionsMethods';
import { fetchTbAuctionLotById } from '../utils/tbAuctionsQueries';
import { useDebounce } from './useDebounce';
import { useTypedSelector } from './useTypedSelector';

interface LotValidationState {
  error: TbAuctionsError | null;
  lot: TransformedLot | null;
  address: GeoAutocompleteAddress | null;
  isLoading: boolean;
}
interface UseTbAuctions extends LotValidationState {
  query: string;
  setQuery(query: string): void;
}
export type TbAuctionsError =
  | TbAuctionLotError
  | 'invalid_query'
  | 'failed_to_fetch_lot'
  | 'lot_is_not_valid'
  | 'lot_address_not_found';

export const useTbAuctions = (platform: Platform): UseTbAuctions => {
  const [query, setQuery] = React.useState('');
  const dbQuery = useDebounce(query, 500);
  const [lotValidation, setLotValidation] = React.useState<LotValidationState>({
    lot: null,
    error: null,
    address: null,
    isLoading: true,
  });

  const activeLang = getActiveLanguageSettings();
  const activeAuctionId =
    (useTypedSelector(state => state.generalTransport.product_selection.items) || [])[0]?.lot_temp?.auctionId || null;

  const setError = (error: TbAuctionsError | null): void => {
    setLotValidation({ lot: null, error, address: null, isLoading: false });
  };

  const retrieveLot = async (): Promise<void> => {
    // Set loading state
    setLotValidation({ lot: null, error: null, address: null, isLoading: true });
    // Start retrieve / validate
    const { lotId } = parseTbAuctionQuery(dbQuery);
    if (!dbQuery) {
      return setError(null);
    }
    if (!lotId) {
      return setError('invalid_query');
    }
    const fetchLot = await fetchTbAuctionLotById(lotId, activeLang.labels.short, platform);
    if (fetchLot.error || !fetchLot.lotDetails) {
      return setError('failed_to_fetch_lot');
    }
    const validatedLot = validateTbAuctionLot(activeAuctionId, fetchLot.lotDetails);
    if (validatedLot.error || !validatedLot.lotDetails) {
      return setError(validatedLot.error || 'lot_is_not_valid');
    }
    const transformedLot = await transformTbAuctionLot(validatedLot.lotDetails, activeLang.labels.short);
    if (!transformedLot) {
      return setError('lot_is_not_valid');
    }
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { line1, postal_code, locality, country_code } = transformedLot.collectionDays.address;
    const geo = await retrieveCachedLocationDetails(
      [line1, postal_code, locality, country_code].filter(Boolean).join(','),
      postal_code
    );
    if (!geo) {
      return setError('lot_address_not_found');
    }
    // victory
    setLotValidation({ lot: transformedLot, error: null, address: geo.address, isLoading: false });
  };

  React.useEffect(() => {
    retrieveLot();
  }, [dbQuery]);

  return {
    ...lotValidation,
    query,
    setQuery,
  };
};

import * as React from 'react';
import { Config } from '../../../../config';

interface Props {
  src: string;
  width?: number;
  height?: number;
  alt?: string;
}

export const ImageCdn: React.FC<Props> = ({ src, width, height, alt }) => {
  return (
    <img src={`${Config.BRENGER_MAIN_DOMAIN}/wp-content/uploads/img/${src}`} height={height} width={width} alt={alt} />
  );
};

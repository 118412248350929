import {
  AccountUtmParams,
  DateTimePeriodParams,
  GeoAutocompleteAddress,
  QuoteCreationParams,
  StopSituation,
} from '@brenger/api-client';
import { LOCATION_CHANGE, LocationChangeAction, getLocation } from 'connected-react-router';
import { getHours } from 'date-fns';
import _get from 'lodash/get';
import { matchPath } from 'react-router';
import { actionTypes, change, clearFields, getFormValues, reset, updateSyncErrors } from 'redux-form';
import { all, call, debounce, delay, fork, put, select, takeEvery, takeLatest } from 'typed-redux-saga';
import { widgetAddressWhiteList } from '../../../../stand-alone/ts/global';
import { clarity } from '../../../configs/clarity';
import { r } from '../../../routes';
import {
  CarryingHelpChoices,
  ReduxFormClearFieldsAction,
  ReduxFormInterAction,
  RootState,
  SessionStorageKeys,
  StopType,
} from '../../../typings';
import * as appUtils from '../../../utils/basics';
import { GaClassicEvent, trackEvent } from '../../../utils/eventTracking';
import { GENERAL_DEFAULT_TIME_END, GENERAL_DEFAULT_TIME_START, dummyItemSet } from '../../../utils/global';
import { getItemSetsVolume } from '../../../utils/itemSet';
import { translate } from '../../../utils/localization';
import { getProductActionFromMarktplaatsRef } from '../../../utils/products';
import { priceClient } from '../../../utils/request';
import {
  ExpectedUtmParams,
  FieldNamesDate,
  GeneralFlowForms,
  SubStepFieldNamesDelivery,
  SubStepFieldNamesPickup,
} from '.././interface';
import { HomeSituationType } from '../components/SubStepHomeSituation';
import { getTimeFormValues } from '../containers/Time';
import {
  actions,
  getAuctionType,
  getDeliveryFloorData,
  getDeliveryHelp,
  getDistance,
  getIsMarketplaceUtmSource,
  getNotGuaranteedReason,
  getPickupFloorData,
  getPickupHelp,
  getPriceCallRetryAttempts,
  getPriceRequestParams,
  getProgressStep,
  getStopDestinationDetails,
  getStopSituation,
  getTransportPrice,
  getValidItemSets,
  isGuaranteed,
  isSameDayDelivery,
  isSheetOpen,
} from '../ducks';
import { FLOW_TYPE } from '../index';
import { proceed } from '../proceed';
import { DateStepParam, GeneralFlowActionTypes, PsPrefillActionType, ResetFlowAction } from '../typings';
import { draftQuotesSaga } from './draftQuote';
import { formSubmitSaga } from './formSubmits';

export function* retryPriceHandler(): Generator {
  // check retry attempt < 3 or not show error
  const retryAttempts = yield* select(getPriceCallRetryAttempts);
  if (retryAttempts <= 3) {
    yield* delay(retryAttempts * 1000);
    yield* put(actions.getPrice());
  } else {
    yield* put(actions.resetRetryAttempts());
    yield* put(actions.setPriceLoading(false));
    yield* put(
      actions.popNotification({
        type: 'error',
        message: translate('request_flow.price.failedAttempts'),
      })
    );
  }
}

export function* closeSummaryAfterNavigation(action): Generator {
  if (action.payload.isFirstRendering) {
    return;
  }
  const summaryOpen = yield* select(isSheetOpen);
  if (summaryOpen) {
    yield* put(actions.toggleSheet());
  }
}

export function* priceHandler(): Generator {
  const priceParams = yield* select(getPriceRequestParams);
  if (priceParams === null) {
    yield* put(actions.resetPrice());
    return;
  }
  try {
    yield* put(actions.setPriceLoading(true));
    yield* put(actions.hideNotification());

    const quote = yield* call(priceClient.quotes.create, priceParams);
    if (quote.price.incl_vat.amount) {
      const price = quote.price.incl_vat.amount;
      clarity.setTag('price_api_response');
      /**
       * Keep prev quote in memory to compare later
       */
      yield* all([
        put(actions.setPriceLoading(false)),
        put(actions.setPrice(price)),
        put(actions.setPriceStructure(quote.price_structure)),
        put(actions.setPriceList(quote.price_list)),
        put(actions.setQuote(quote)),
      ]);
      if (quote.flexible_dates?.result) {
        clarity.setTag('flex_dates', 'true');
      }
    }
  } catch (e) {
    const rejected = yield* select((state: RootState) => state.generalTransport.price.rejected);
    // Pricing fails on purpose
    // For example when one of the stops is out of our service area
    if (rejected) {
      yield* put(actions.setPriceLoading(false));
      return;
    }
    appUtils.logError(e);
    const formItems = yield* select(getFormValues(GeneralFlowForms.ITEMS));
    const numberOfAttempts = yield* select(getPriceCallRetryAttempts);
    if (numberOfAttempts <= 3) {
      // we handle retry process in axios error handling
      return;
    }
    appUtils.logException(`Failed to get a price after 4 attempts`, {
      error: e,
      numberOfAttempts,
      payload: priceParams,
      rawItemFormValues: formItems,
    });
    clarity.setTag('price_api_failure');
    yield* put(actions.setPriceLoading(false));
  }
}

export function* updateFormSyncErrors(action): Generator {
  if (Object.keys(action.payload.syncErrors).length === 0) {
    yield* put(actions.pushNotification(null));
  }
}

export function* handleSyncErrors(action): Generator {
  const step = yield* select(getProgressStep);
  const location = yield* select(getLocation);
  if (action.error) {
    if (matchPath(location.pathname, { path: r.generalFlow.pickup.invoice(), exact: true, strict: false })) {
      return;
    } else if (step >= 7) {
      yield* put(actions.pushNotification(null));
    } else {
      yield* put(actions.pushNotification(translate('request_flow.errors.select_required')));
    }
  }
}

export function afterPushNotification(): void {
  const messageElement = document.querySelector('.message') as HTMLElement;
  if (messageElement) {
    messageElement.scrollIntoView();
    // first wait for scroll to the view and wait for update window scroll Y then scroll a bit to the top
    setTimeout(() => window.scroll(0, window.scrollY - 50), 100);
  }
}

export function hideProgressElements(): void {
  const sidebar = document.querySelector('.sheet-layout--sidebar') as HTMLElement;
  const stepsIndicator = document.querySelector('.progress-steps') as HTMLElement;
  // hide unnecessary elements
  sidebar.style.display = 'none';
  stepsIndicator.style.display = 'none';
}

function* determineNextSubmitStep(action: { type: string; formResults?: Record<string, unknown> }): Generator {
  // Somehow validation doesn't kick in at first load,
  // since we want to get rid of redux-form lib I didn't bother to dive in deeper
  // So we do a simple check here
  const formResults = action.formResults;
  if (formResults && Object.keys(action.formResults || {}).length === 0) {
    return;
  }
  yield* call(proceed);
}

function* resetForms(action: ResetFlowAction): Generator {
  // When resetting we also clear the transaction event flag
  sessionStorage.removeItem(SessionStorageKeys.IS_TRANSACTION_EVENT_FIRED);
  // We also need to reset the landing page item executed, so we can anticipate again
  sessionStorage.removeItem(SessionStorageKeys.LANDING_PAGE_ITEM_ACTION);
  // Same for the product selection form
  sessionStorage.removeItem(SessionStorageKeys.PRODUCT_SELECTION_FORM);

  const resetActions = [
    put(reset(GeneralFlowForms.ITEMS)),
    put(reset(GeneralFlowForms.PICKUP)),
    put(reset(GeneralFlowForms.DELIVERY)),
    put(reset(GeneralFlowForms.DATE)),
    put(reset(GeneralFlowForms.TERMS)),
    put(reset(GeneralFlowForms.PRODUCT_PAYMENT)),
    put(reset(GeneralFlowForms.TIME)),
    put(actions.resetChoice('ALL')),
  ];
  /**
   * Most of the time we don't want to reset destination details
   * At this point we only reset destination after:
   * - a succesfull TR.
   * - when quotes rejects TR (and customers chooses to start over)
   */
  if (action.payload.resetDestination) {
    resetActions.push(put(actions.resetDestinationDetails()));
  }
  yield* all(resetActions);
}

function resetDestinationWidget(): void {
  /*
   * BRENGER_WIDGET_RESET
   * Custom event that takes care of widget reset
   * See event listener here: stand-alone/ts/widget.ts:22
   * */
  document.dispatchEvent(new CustomEvent('BRENGER_WIDGET_RESET'));
}

function* resetSyncErrors(action): Generator {
  yield* put(updateSyncErrors(action.meta.form, {}, undefined));
}

function* resetLoadingNotificationState(): Generator {
  try {
    const location = yield* select(getLocation);
    if (!location.pathname.includes(r.generalFlow.index())) {
      return;
    }
    // reset loadings from price
    yield* put(actions.setPriceLoading(false));
    // reset general errors
    yield* put(actions.pushNotification(null));
  } catch (error) {
    appUtils.logException('[GFS] Failed to reset loading and notification state', { extra: { error } });
  }
}

function* getQuoteOnDestinationChange(): Generator {
  const itemSets = yield* select(getValidItemSets);
  if (itemSets.length && itemSets[0].items.length) {
    yield* put(actions.getPrice());
    return;
  }
  const [pickup, delivery] = yield* all([
    select(getStopDestinationDetails, StopType.PICKUP),
    select(getStopDestinationDetails, StopType.DELIVERY),
  ]);

  if (!pickup?.latitude || !delivery?.latitude) {
    return;
  }

  const params: QuoteCreationParams = {
    pickup: {
      address: {
        country_code: pickup.country_code,
        administrative_area: pickup.administrative_area,
        locality: pickup.locality,
        lat: pickup.latitude,
        lng: pickup.longitude,
      },
    },
    delivery: {
      address: {
        country_code: delivery.country_code,
        administrative_area: delivery.administrative_area,
        locality: delivery.locality,
        lat: delivery.latitude,
        lng: delivery.longitude,
      },
    },
    item_sets: [dummyItemSet],
  };
  try {
    const quote = yield* call(priceClient.quotes.create, params);
    yield* put(actions.setQuote(quote, true));
  } catch (e) {
    appUtils.logError(e);
  }
}

export function* pathsThatShouldTriggerPriceCalls(action: LocationChangeAction): Generator {
  const triggerRouterAction = action.payload.isFirstRendering || action.payload.action === 'PUSH';
  const triggerPaths = [
    r.generalFlow.items.index(),
    r.generalFlow.pickup.index(),
    r.generalFlow.help.index(),
    r.generalFlow.contact.index(),
    r.generalFlow.terms.index(),
    r.generalFlow.time.index(),
    r.generalFlow.time.delivery(),
  ].includes(action.payload.location.pathname);
  if (!triggerPaths || !triggerRouterAction) {
    return;
  }
  yield* put(actions.getPrice());
}

export function* fieldsThatShouldTriggerPriceCalls(
  action: ReduxFormInterAction | ReduxFormClearFieldsAction
): Generator {
  const targetFields = [
    SubStepFieldNamesPickup.SITUATION_HOME_FLOORS_COMBINED,
    SubStepFieldNamesDelivery.SITUATION_HOME_FLOORS_COMBINED,
  ] as string[];
  if (action.type === '@@redux-form/CLEAR_FIELDS') {
    const isTargetFieldCleared = action.meta.fields.some(field => targetFields.includes(field));
    if (isTargetFieldCleared) {
      yield* put(actions.getPrice());
    }
    return;
  }
  if (targetFields.includes((action as ReduxFormInterAction).meta.field)) {
    yield* put(actions.getPrice());
  }
}

export function* resetFormValuesPerStep(action): Generator {
  try {
    const location = action.payload.location.pathname;
    yield* put(actions.toggleSubmitting(false));
    const allActions = [] as Generator[];
    switch (location) {
      case r.generalFlow.pickup.index():
        allActions.push(put(reset(GeneralFlowForms.PICKUP)));
        allActions.push(put(reset(GeneralFlowForms.DELIVERY)));
        allActions.push(put(clearFields(GeneralFlowForms.DATE, false, true, FieldNamesDate.PICKUP)));
        allActions.push(put(clearFields(GeneralFlowForms.DATE, false, true, FieldNamesDate.DELIVERY)));
        // these actions clear the book a van value potentionally, so update pricing
        allActions.push(put(actions.getPrice()));
        break;
      case r.generalFlow.pickup.floor():
        allActions.push(
          put(clearFields(GeneralFlowForms.PICKUP, false, true, SubStepFieldNamesPickup.SITUATION_HOME_FLOORS_COMBINED))
        );
        break;
      case r.generalFlow.date.index():
        /*
         * When the user gets send back to dates with param DONT_RESET,
         * And doesn't select anything, we want to keep the values.
         */
        if (!action.payload.location.search.includes(DateStepParam.DONT_RESET)) {
          allActions.push(put(reset(GeneralFlowForms.DATE)));
        }
        // update price
        allActions.push(put(actions.getPrice()));
        break;
      case r.generalFlow.date.delivery():
        allActions.push(put(clearFields(GeneralFlowForms.DATE, false, true, FieldNamesDate.DELIVERY)));
        // update price
        allActions.push(put(actions.getPrice()));
        break;
      case r.generalFlow.delivery.floor():
        allActions.push(
          put(
            clearFields(
              GeneralFlowForms.DELIVERY,
              false,
              true,
              SubStepFieldNamesDelivery.SITUATION_HOME_FLOORS_COMBINED
            )
          )
        );
        break;
      case r.generalFlow.time.index():
        allActions.push(put(clearFields(GeneralFlowForms.TIME, false, true, StopType.PICKUP)));
        allActions.push(put(clearFields(GeneralFlowForms.TIME, false, true, StopType.DELIVERY)));
        allActions.push(put(actions.getPrice()));
        break;
      case r.generalFlow.time.delivery():
        allActions.push(put(clearFields(GeneralFlowForms.TIME, false, true, StopType.DELIVERY)));
        allActions.push(put(actions.getPrice()));
        break;
      case r.generalFlow.items.index():
        allActions.push(put(reset(GeneralFlowForms.PRODUCT_PAYMENT)));
        allActions.push(put(actions.getPrice()));
        break;
    }
    yield* all(allActions);
  } catch (error) {
    appUtils.logError(error);
    appUtils.logException('[GFS] Failed reset/clear a value', { extra: { action, error } });
  }
}

export function* handleTimeSelection(action: ReduxFormInterAction): Generator {
  const isDeliveryChange = action.meta.form === GeneralFlowForms.TIME && action.meta.field === StopType.DELIVERY;
  const sameDayDelivery = yield* select(isSameDayDelivery);
  if (!isDeliveryChange || !sameDayDelivery) {
    return;
  }
  /**
   * It is a user change and we are same day delivery
   */
  try {
    const timeValues = yield* select(getTimeFormValues);
    /**
     * We must have pickup times
     */
    if (!timeValues?.[StopType.PICKUP]?.start || !timeValues?.[StopType.PICKUP]?.end) {
      return;
    }
    const pickupTime = timeValues[StopType.PICKUP] as DateTimePeriodParams;
    const pickupStartHour = getHours(new Date(pickupTime.start));
    const pickupEndHour = getHours(new Date(pickupTime.end));
    const pickupTimeIsFullTimeSlot =
      pickupStartHour === GENERAL_DEFAULT_TIME_START && pickupEndHour === GENERAL_DEFAULT_TIME_END;
    const deliveryTime = action.payload as DateTimePeriodParams;
    const deliveryStartHour = getHours(new Date(deliveryTime.start));
    const deliveryEndHour = getHours(new Date(deliveryTime.end));
    /**
     * DOUBLE CHECK ON SELECTED DELIVERY
     * - When pickup time is full time slot (so not a custom time)
     * - When the user has selected an early delivery, meaning start before 13.00, end before 17:00.
     * => then it means pickup isn't flexible as well and we update the pickup times with the delivery times
     */
    if (pickupTimeIsFullTimeSlot && deliveryStartHour < 13 && deliveryEndHour < 17) {
      yield* put(change(GeneralFlowForms.TIME, StopType.PICKUP, action.payload));
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
}

export function* resetRetryAttemptsErrors(): Generator {
  if (window.location.pathname.includes(r.generalFlow.index())) {
    yield* put(actions.resetRetryAttempts());
  }
}

function* readWidgetParameters(action): Generator {
  if (!action.payload.isFirstRendering || window.location.search.length === 0) {
    return;
  }
  // First check if reset is needed
  try {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get('reset_flow') === 'full') {
      /**
       * Coming from the widget we only want to reset the flow
       * but keep destination details around.
       */
      yield* put(actions.resetFlow(false));
    }
  } catch (error) {
    appUtils.logError(error);
    appUtils.logException('[GFS] Failed to execute reset forms', { extra: { action, error } });
  }
  // then read new details
  try {
    const searchParams = new URLSearchParams(window.location.search);
    const pickup = {};
    widgetAddressWhiteList.forEach(key => {
      const keyName = `pickup[${key}]`;
      if (!searchParams.has(keyName)) {
        return;
      }
      pickup[key] = searchParams.get(keyName);
    });
    const delivery = {};
    widgetAddressWhiteList.forEach(key => {
      const keyName = `delivery[${key}]`;
      if (!searchParams.has(keyName)) {
        return;
      }
      delivery[key] = searchParams.get(keyName);
    });
    /**
     * First set the UTM details
     */
    const utm: { [key in ExpectedUtmParams]?: string } = {};
    searchParams.forEach((value, key) => {
      // Every UTM parameter gets saved in the utm object
      if (key.includes('utm')) {
        utm[key] = value;
      }
    });
    // Only update if we have UTM parameters saved to the object,
    // Important, if none exist we don't want to clear the previous ones
    if (Object.keys(utm).length) {
      yield* put(actions.setUtm(utm));
    }
    /**
     * Then collect rest of read actions
     */
    const readActions: Generator[] = [];
    // Save AB test params
    try {
      const ab = searchParams.get('wpab');
      if (ab) {
        const tests = JSON.parse(decodeURIComponent(ab)).map(test => ({
          id: Number(test[0]),
          v: Number(test[1]),
        }));
        readActions.push(put(actions.setAb(tests)));
      } else {
        readActions.push(put(actions.resetAb()));
      }
    } catch (_) {
      readActions.push(put(actions.resetAb()));
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - quick fix to make the checks work
    if (pickup.latitude && pickup.longitude && pickup.locality) {
      readActions.push(put(actions.setDestinationDetails(pickup as GeoAutocompleteAddress, StopType.PICKUP)));
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - quick fix to make the checks work
    if (delivery.latitude && delivery.longitude && delivery.locality) {
      readActions.push(put(actions.setDestinationDetails(delivery as GeoAutocompleteAddress, StopType.DELIVERY)));
    }

    // Is there a Pickup UTM? else set to NULL
    const accountPickup = searchParams.get(AccountUtmParams.PICKUP);
    readActions.push(
      put(actions.setDestinationMeta(accountPickup ? { utm_account: accountPickup } : null, StopType.PICKUP))
    );
    // Is there a Delivery UTM? else set to NULL
    const accountDelivery = searchParams.get(AccountUtmParams.DELIVERY);
    readActions.push(
      put(actions.setDestinationMeta(accountDelivery ? { utm_account: accountDelivery } : null, StopType.DELIVERY))
    );
    const referrer = searchParams.get(`meta[referrer]`);
    let isMpUser = false;
    if (referrer !== null) {
      const decodedReferrer = decodeURI(referrer);
      yield* put(actions.setReferrer(decodedReferrer));
      // if MP user, we skip the first two steps
      isMpUser = yield* select(getIsMarketplaceUtmSource);
      let prefillAction = getProductActionFromMarktplaatsRef(window.location.href);
      if (!prefillAction) {
        // We have our own way of setting a prefill, let's check if we have any
        const psPrefillAction = searchParams.get('ps-action') as PsPrefillActionType | null;
        const psPrefillValue = decodeURIComponent(searchParams.get('ps-value') || '');
        if (psPrefillAction && psPrefillValue) {
          // We have a PREFILL action
          if (psPrefillAction === 'PREFILL') {
            prefillAction = {
              type: psPrefillAction,
              value: psPrefillValue,
              shouldWarn: true,
            };
          }
          // We have a CONFIG or SEARCH
          if (psPrefillAction === 'CONFIG' || psPrefillAction === 'SEARCH') {
            prefillAction = {
              type: psPrefillAction,
              value: psPrefillValue,
            };
          }
        }
      }
      // We dont care about the outcome:
      // We either have an action or we set to null (to prevent cache side effects)
      readActions.push(put(actions.setPrefillPs(prefillAction)));
    }
    yield* all(readActions);
    let pathnameOverwrite: string | null = null;
    if (isMpUser) {
      /**
       * Show a loader so we can navigate and flag routing affected
       */
      yield* put(actions.setStepLoading(true));
      yield* put(change(GeneralFlowForms.PICKUP, SubStepFieldNamesPickup.SITUATION, 'home'));
      yield* put(actions.setChoice({ home_situation: 'marktplaats' }));

      pathnameOverwrite = r.generalFlow.homeSituation.index();
    } else {
      const pickupType = searchParams.get('pickup-type') as StopSituation;
      const pickupTypeSpecific = searchParams.get('pickup-type-specific');
      if (pickupType) {
        /**
         * Show a loader so we can navigate and flag routing is affected
         */
        yield* put(actions.setStepLoading(true));
        yield* put(change(GeneralFlowForms.PICKUP, SubStepFieldNamesPickup.SITUATION, pickupType));
        pathnameOverwrite = r.generalFlow.pickup.index();
        if (pickupTypeSpecific) {
          // pickupTypeSpecific can either indicate auction type or home situation
          if (pickupType === 'auction') {
            yield* put(change(GeneralFlowForms.PICKUP, SubStepFieldNamesPickup.AUCTION_TYPE, pickupTypeSpecific));
            pathnameOverwrite = r.generalFlow.pickup.auction();
          } else {
            yield* put(actions.setChoice({ home_situation: pickupTypeSpecific as HomeSituationType }));
            pathnameOverwrite = r.generalFlow.homeSituation.index();
          }
        }
      }
    }
    /**
     * Above actions could lead to input changes, and the user could skip certain steps in the flow because of it.
     * Besides setting values it sets pathnameOverwrite, the pathname is the exact same as the page were you manually fill the form.
     * This way we can use the "getProceedAction", to determine what is the next path.
     */
    if (pathnameOverwrite) {
      yield* call(proceed, { pathnameOverwrite });
    }
    yield* put(actions.setStepLoading(false));
  } catch (e) {
    appUtils.logError(e);
    // if above statements crash for whatever reason, we should not be stuck in loading state
    yield* put(actions.setStepLoading(false));
  }
}

function progressTracking(action): void {
  const event = {
    event: 'Flow progress',
    flow_type: FLOW_TYPE,
  };
  let progressLabel = 'undefined';
  let progressValue = action.payload;
  switch (action.payload) {
    case 1:
      progressLabel = 'Step 1 - Destination';
      break;
    case 2:
      progressLabel = 'Step 2 - Type';
      break;
    case 3:
      progressLabel = 'Step 3 - Items';
      break;
    case 4:
      progressLabel = 'Step 4 - Date & Time';
      break;
    case 5:
      progressLabel = 'Step 5 - Services';
      break;
    case 6:
      progressLabel = 'Step 6 - Contact';
      break;
    case 7:
      progressLabel = 'Step 7 - Terms & Payment';
      break;
    /**
     * Let's see if this is still needed
     */
    case 'payment_not_guaranteed':
      progressLabel = 'Step 7 - Payment non guaranteed';
      progressValue = 7;
      break;
  }

  event['progress_label'] = progressLabel;
  event['progress_value'] = progressValue;
  appUtils.pushToDataLayer(event);
}

function* distanceTracking(): Generator {
  const distance = yield* select(getDistance);
  const ranges = [10, 30, 50, 75, 100, 150, 200, 250, 300, 400];
  let action = '> 400 km';
  for (let i = 0; i < ranges.length; i++) {
    if (distance < ranges[i]) {
      action = ranges[i] === 10 ? '< ' : '';
      action += `${ranges[i]} km`;
      break;
    }
  }
  trackEvent({
    eventCategory: 'General TR - Distance',
    eventAction: action,
    eventLabel: String(distance),
    eventValue: distance,
  });
}

function* metaDataTracking(): Generator {
  const guaranteed = yield* select(isGuaranteed);
  const notGuaranteedReason = yield* select(getNotGuaranteedReason);
  const items = yield* select(getValidItemSets);
  const pickupDetails = yield* select(getStopDestinationDetails, StopType.PICKUP);
  const deliveryDetails = yield* select(getStopDestinationDetails, StopType.DELIVERY);
  const pickupSituation = yield* select(getStopSituation, StopType.PICKUP, false);
  const pickupHelp = yield* select(getPickupHelp);
  const deliveryHelp = yield* select(getDeliveryHelp);
  const pickupFloorData = yield* select(getPickupFloorData);
  const deliveryFloorData = yield* select(getDeliveryFloorData);
  const transportPrice = yield* select(getTransportPrice);
  const auctionType = yield* select(getAuctionType);

  // collect events
  const events: GaClassicEvent[] = [];

  // Guaranteed - Check if the price === 0, if not we have a valid guaranteed/not guaranteed answer
  if (transportPrice !== 0) {
    events.push({
      eventCategory: 'General TR - Guaranteed',
      eventAction: String(guaranteed),
      eventLabel: notGuaranteedReason,
    });
  }

  // Auction type
  if (auctionType) {
    events.push({
      eventCategory: 'General TR - Auction type',
      eventAction: auctionType,
    });
  }

  // Items
  if (items.length > 0) {
    events.push({
      eventCategory: 'General TR - volume m3',
      eventAction: Number(getItemSetsVolume(items) / 1000000).toFixed(2),
    });
    events.push({
      eventCategory: 'General TR - number of items',
      eventAction: String(items.length),
    });
  }

  // Region & country
  const pickupCountry = _get(pickupDetails, 'country', 'none');
  const deliveryCountry = _get(deliveryDetails, 'country', 'none');
  const pickupAdministrative = _get(pickupDetails, 'administrative_area', 'none');
  const deliveryAdministrative = _get(deliveryDetails, 'administrative_area', 'none');
  if (pickupDetails !== null) {
    events.push({
      eventCategory: 'General TR - Pickup region',
      eventAction: pickupAdministrative,
    });
    events.push({
      eventCategory: 'General TR - Pickup country',
      eventAction: pickupCountry,
    });
  }

  if (deliveryDetails !== null) {
    events.push({
      eventCategory: 'General TR - Delivery region',
      eventAction: deliveryAdministrative,
    });
    events.push({
      eventCategory: 'General TR - Delivery country',
      eventAction: deliveryCountry,
    });
  }

  if (pickupDetails !== null && deliveryDetails !== null) {
    events.push({
      eventCategory: 'General TR - Pickup => Delivery | region',
      eventAction: `From ${pickupAdministrative} to ${deliveryAdministrative}`,
      eventLabel: pickupAdministrative === deliveryAdministrative ? 'same' : 'different',
    });
    events.push({
      eventCategory: 'General TR - Pickup => Delivery | country',
      eventAction: `From ${pickupCountry} to ${deliveryCountry}`,
      eventLabel: pickupCountry === deliveryCountry ? 'same' : 'different',
    });
  }

  // Situation / type
  if (pickupSituation) {
    events.push({
      eventCategory: 'General TR - Type of transport (pickup situation)',
      eventAction: pickupSituation,
    });
  }

  // Help
  if (pickupHelp !== CarryingHelpChoices.NOT_NEEDED) {
    events.push({
      eventCategory: 'General TR - Pickup help',
      eventAction: pickupHelp,
    });
  }

  if (deliveryHelp !== CarryingHelpChoices.NOT_NEEDED) {
    events.push({
      eventCategory: 'General TR - Delivery help',
      eventAction: deliveryHelp,
    });
  }

  // Floor / Elevator
  if (pickupFloorData?.floor && pickupFloorData?.floor !== null) {
    events.push({
      eventCategory: 'General TR - Pickup floor',
      eventAction: pickupFloorData?.hasElevator ? 'Elevator' : pickupFloorData?.floor?.toString(),
    });
  }

  if (deliveryFloorData?.floor && deliveryFloorData?.floor !== null) {
    events.push({
      eventCategory: 'General TR - Delivery floor',
      eventAction: deliveryFloorData?.hasElevator ? 'Elevator' : deliveryFloorData?.floor?.toString(),
    });
  }

  // Fire events
  events.map(event => trackEvent(event));
}

export function scrollToTop(action): void {
  if (action.payload.location.pathname.indexOf(r.generalFlow.index()) > -1) {
    window.scrollTo(0, 0);
  }
}

function* pickupSaga(): Generator {
  // Take every change, exceptional, but we do want to make sure we catch any change to delivery times.
  yield* takeEvery(actionTypes.CHANGE, handleTimeSelection);
}

function* destinationSaga(): Generator {
  yield* takeEvery(LOCATION_CHANGE, readWidgetParameters);
}

function* subStepsSaga(): Generator {
  yield* takeLatest(GeneralFlowActionTypes.SUBMIT_PICKUP, determineNextSubmitStep);
  yield* takeLatest(GeneralFlowActionTypes.SUBMIT_DELIVERY, determineNextSubmitStep);
}

function* generalSaga(): Generator {
  yield* debounce(100, LOCATION_CHANGE, scrollToTop);
  yield* takeLatest(LOCATION_CHANGE, resetLoadingNotificationState);
  yield* takeLatest(LOCATION_CHANGE, resetFormValuesPerStep);
  yield* takeLatest(LOCATION_CHANGE, resetRetryAttemptsErrors);
  yield* takeLatest(LOCATION_CHANGE, pathsThatShouldTriggerPriceCalls);
  yield* takeEvery([actionTypes.CHANGE, actionTypes.CLEAR_FIELDS], fieldsThatShouldTriggerPriceCalls);
  yield* takeLatest(LOCATION_CHANGE, closeSummaryAfterNavigation);

  yield* takeLatest(actionTypes.SET_SUBMIT_FAILED, handleSyncErrors);
  yield* takeLatest(actionTypes.UPDATE_SYNC_ERRORS, updateFormSyncErrors);
  yield* takeLatest(GeneralFlowActionTypes.RESET_FLOW, resetForms);
  yield* takeLatest(actionTypes.SET_SUBMIT_SUCCEEDED, resetSyncErrors);

  yield* takeLatest(GeneralFlowActionTypes.PRICE_API_RETRY_ATTEMPT_INCREMENT, retryPriceHandler);
  yield* debounce(500, GeneralFlowActionTypes.PUSH_NOTIFICATION, afterPushNotification);

  yield* debounce(250, GeneralFlowActionTypes.GET_PRICE, priceHandler);
  /* Debounce to prevent useless pricing calls */
  yield* debounce(100, GeneralFlowActionTypes.SET_DESTINATION_DETAILS, getQuoteOnDestinationChange);
  yield* takeLatest(GeneralFlowActionTypes.RESET_DESTINATION_DETAILS, resetDestinationWidget);
}

function* trackingSaga(): Generator {
  yield* takeLatest(GeneralFlowActionTypes.SET_PROGRESS_STEP, progressTracking);
  yield* takeLatest(GeneralFlowActionTypes.SET_PROGRESS_STEP, metaDataTracking);
  yield* takeLatest(GeneralFlowActionTypes.SET_PROGRESS_STEP, distanceTracking);
}

export function* generalTransportSagas(): Generator {
  yield* fork(generalSaga);
  yield* fork(pickupSaga);
  yield* fork(subStepsSaga);
  yield* fork(destinationSaga);
  yield* fork(trackingSaga);
  yield* fork(formSubmitSaga);
  yield* fork(draftQuotesSaga);
}

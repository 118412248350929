import React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { Button, Dialog, Modal } from '../../../brenger-shared-ui';
import { clarity } from '../../../configs/clarity';

interface Props {
  isOpen: boolean;
  onCancel(): void;
  onConfirm(): void;
}

export const ItemPrefilledModal: React.FC<Props> = ({ isOpen, onCancel, onConfirm }) => {
  return (
    <Dialog isActive={isOpen}>
      <Modal
        header={
          <b>
            <Translate id={'request_flow.what.example_dimensions.title'} />
          </b>
        }
        onlyToggleByButton={true}
        style={'white'}
        size={'small'}
        open={isOpen}
      >
        <div className="pt-1">
          <p>
            <Translate id={'request_flow.what.example_dimensions.description'} />
          </p>
          <div className="flex flex--sb">
            <Button
              style={{ margin: 0 }}
              fullWidth={true}
              type="button"
              onClick={() => {
                onCancel();
                clarity.setTag('product_example_modal_back_to_items');
              }}
              buttonStyle={'tertiary'}
            >
              <Translate id={'request_flow.what.example_dimensions.cancel'} />
            </Button>
            <Button
              style={{ margin: 0 }}
              fullWidth={true}
              onClick={() => {
                onConfirm();
                clarity.setTag('product_example_modal_submit_untouched');
              }}
              buttonStyle={'secondary'}
            >
              <Translate id={'request_flow.what.example_dimensions.submit'} />
            </Button>
          </div>
        </div>
      </Modal>
    </Dialog>
  );
};

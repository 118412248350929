import * as React from 'react';
import './icons.scss';

export const IconSituationMarktplaats = (): JSX.Element => {
  return (
    <svg className="IconMarktplaats" viewBox="0 0 1000 1000" width="96" height="96">
      <path
        className="aast0"
        d="M500.4 10c272 0 492.4 219.4 492.4 490.1 0 270.6-220.5 490.1-492.4 490.1S8 770.8 8 500.1C8 229.4 228.5 10 500.4 10"
      />
      <path
        className="aast1"
        d="M666.3 384.2L465.8 189.7c-37.1 36-37.1 94.5 0 130.5L599 449.5 398.5 644c37.1 36.1 97.4 36.1 134.5 0l133.2-129.3c37.2-36 37.2-94.4.1-130.5zM594.7 797.4c52.1 0 94.4-41.4 94.4-92.4H407.7c-52.1 0-94.4 41.4-94.4 92.4h281.4"
      />
    </svg>
  );
};

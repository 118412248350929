import { push } from 'connected-react-router';
import * as React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { useDispatch } from 'react-redux';
import { Button, Dialog, Modal } from '../../../brenger-shared-ui';
import van from '../../../brenger-shared-ui/assets/images/van@2x.png';
import { useTypedSelector } from '../../../hooks';
import { RootState } from '../../../typings';
import { printDatesAsString } from '../../../utils/datetime';
import { GENERAL_DEFAULT_TIME_END, GENERAL_DEFAULT_TIME_START } from '../../../utils/global';
import { actions, getDeliveryAvailableDate, getPickupAvailableDate, isRequestAboveMaxDistance } from '../ducks';
import { r } from '../../../routes';

export const TimeModal: React.FC = () => {
  const dispatch = useDispatch();
  const isAboveMaxDistance = useTypedSelector((state: RootState) => isRequestAboveMaxDistance(state, false));
  const isAboveDateDistance = useTypedSelector((state: RootState) => isRequestAboveMaxDistance(state, true));
  const isActive = useTypedSelector((state: RootState) => state.generalTransport.layout.modal_time);
  const pickupDates = useTypedSelector(getPickupAvailableDate);
  const deliveryDates = useTypedSelector(getDeliveryAvailableDate);
  const dates = pickupDates.concat(deliveryDates);

  return (
    <Dialog isActive={isActive}>
      <Modal onlyToggleByButton={true} open={isActive} style={'white'} size={'small'}>
        <div className={'time-pref-modal'}>
          <div className={'text--center pb-1'}>
            <div className={'text--primary text--bold'}>
              <Translate id={'request_flow.time.modal.title_top'} />
            </div>
            <img className={'van-assets-image'} src={van} />
          </div>
          <div className={'pb-1 text--bold'}>
            <Translate
              id={'request_flow.time.modal.we_let_you_know'}
              data={{
                date: printDatesAsString(dates, isAboveDateDistance ? 'default.and_or' : 'default.and'),
              }}
            />
          </div>
          <div className={'pb-1'}>
            <Translate
              id={'request_flow.time.modal.explain_timeslot'}
              data={{ timeStart: `${GENERAL_DEFAULT_TIME_START}:00`, timeEnd: `${GENERAL_DEFAULT_TIME_END}:00` }}
            />
          </div>
          {/* Extra explanation when above max distance */}
          {isAboveMaxDistance && (
            <div className={'pb-1'}>
              <Translate id={'request_flow.time.modal.above_max_distance'} />
            </div>
          )}
          <Button
            buttonStyle={'secondary'}
            fullWidth={true}
            onClick={() => {
              dispatch(push(r.generalFlow.help.index()));
              dispatch(actions.setShowTimeModal(false));
            }}
          >
            <Translate id={'request_flow.time.modal.ok'} />
          </Button>
        </div>
      </Modal>
    </Dialog>
  );
};

import cn from 'classnames';
import { replace } from 'connected-react-router';
import React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { useDispatch } from 'react-redux';
import { Button, CirclePerson2, Dialog, Modal } from '../../../brenger-shared-ui';
import { useTypedSelector } from '../../../hooks';
import { RootState, StopType } from '../../../typings';
import { actions, getInitialDatePath } from '../ducks';
import { r } from '../../../routes';

export const TransportRequestValidationModal: React.FC = () => {
  const dispatch = useDispatch();
  const trError = useTypedSelector((state: RootState) => state.generalTransport.tr_validation_error);
  const datePath = useTypedSelector(getInitialDatePath);
  const continueToDeliveryAction = (): void => {
    dispatch(actions.setTrValidationError(null));
    dispatch(
      actions.setContactDetails({
        contact_type: StopType.DELIVERY,
        details: null,
      })
    );
    dispatch(replace('/transport_request/contact/delivery'));
  };
  const continueToPickupAction = (): void => {
    dispatch(actions.setTrValidationError(null));
    dispatch(
      actions.setContactDetails({
        contact_type: StopType.PICKUP,
        details: null,
      })
    );
    dispatch(replace('/transport_request/contact/pickup'));
  };

  const continueToDateAction = (): void => {
    dispatch(actions.setTrValidationError(null));
    dispatch(replace(datePath));
  };
  const startOverAction = (): void => {
    dispatch(actions.setTrValidationError(null));
    dispatch(actions.resetFlow(false));
    dispatch(replace(r.generalFlow.items.index()));
  };

  if (!trError) {
    return null;
  }
  if (trError === 'failed_price_request') {
    return (
      <Dialog isActive={true}>
        <Modal
          open={true}
          header={<Translate id={'request_flow.tr_validation.modal.title'} />}
          onlyToggleByButton={true}
          onClick={startOverAction}
          style={'blue'}
          size={'small'}
        >
          <div className="text--center">
            <CirclePerson2 />
          </div>
          <div className="pt-1 px-4">
            <Translate id={'request_flow.tr_validation.modal.text_start_over'} />
          </div>
          <div className="text--center pt-1 px-4">
            <Button fullWidth={true} type={'button'} buttonStyle={'secondary'} onClick={startOverAction}>
              <Translate id={'request_flow.tr_validation.modal.cta_start_over'} />
            </Button>
          </div>
        </Modal>
      </Dialog>
    );
  }

  if (trError === 'same_address_for_pickup_delivery') {
    return (
      <Dialog isActive={true}>
        <Modal open={true} onlyToggleByButton={true} onClick={continueToPickupAction} style={'blue'} size={'small'}>
          <h3 className={cn('text--center')}>
            <Translate id={'request_flow.tr_validation.modal.same_address_title'} />
          </h3>
          <div className="pt-1 text--center">
            <CirclePerson2 />
          </div>
          <div className="pt-1 px-4">
            <Translate id={'request_flow.tr_validation.modal.same_address_text'} />
          </div>
          <div className="pt-1 px-4">
            <Button fullWidth={true} type={'button'} buttonStyle={'secondary'} onClick={continueToPickupAction}>
              <Translate id={'request_flow.tr_validation.modal.same_address_cta'} />
            </Button>
          </div>
        </Modal>
      </Dialog>
    );
  }

  if (trError === 'pickup_has_invalid_coordinates' || trError === 'delivery_has_invalid_coordinates') {
    const stopType = trError === 'pickup_has_invalid_coordinates' ? 'pickup' : 'delivery';
    const action = stopType === 'pickup' ? continueToPickupAction : continueToDeliveryAction;
    return (
      <Dialog isActive={true}>
        <Modal open={true} onlyToggleByButton={true} onClick={action} style={'blue'} size={'small'}>
          <h3 className={cn('text--center')}>
            <Translate id={`request_flow.tr_validation.modal.invalid_${stopType}_title`} />
          </h3>
          <div className="pt-1 text--center">
            <CirclePerson2 />
          </div>
          <div className="pt-1 px-4">
            <Translate id={'request_flow.tr_validation.modal.invalid_stop_address_text'} />
          </div>
          <div className="pt-1 px-4">
            <Button fullWidth={true} type={'button'} buttonStyle={'secondary'} onClick={action}>
              <Translate id={'request_flow.tr_validation.modal.invalid_stop_address_cta'} />
            </Button>
          </div>
        </Modal>
      </Dialog>
    );
  }

  return (
    <Dialog isActive={true}>
      <Modal open={true} onlyToggleByButton={true} onClick={continueToDateAction} style={'blue'} size={'small'}>
        <h5 className={cn('text--center')}>
          <Translate id={'request_flow.tr_validation.modal.title'} />
        </h5>
        <CirclePerson2 />
        <div className="pt-1 px-4">
          <Translate id={'request_flow.tr_validation.modal.text_dates'} />
        </div>
        <div className="pt-1 px-4">
          <Button fullWidth={true} type={'button'} buttonStyle={'secondary'} onClick={continueToDateAction}>
            <Translate id={'request_flow.tr_validation.modal.cta_dates'} />
          </Button>
        </div>
      </Modal>
    </Dialog>
  );
};

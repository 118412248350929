import cn from 'classnames';
import React from 'react';
import './input.scss';
import { InputMeta, InputWrapper } from './';

type ModifiedTextArea = Omit<React.InputHTMLAttributes<HTMLTextAreaElement>, 'onChange'>;

interface InputTextProps extends ModifiedTextArea {
  onChange?: (value: string) => void;
  autoCompleteDisabled?: boolean;
  meta?: InputMeta;
}

export const InputTextarea: React.FC<InputTextProps> = ({
  onChange,
  className,
  autoCompleteDisabled,
  autoComplete,
  meta,
  ...unused
}) => {
  return (
    <InputWrapper {...meta}>
      <textarea
        type="text"
        // Yes this is a bit weird, but works well
        // https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion#Preventing_autofilling_with_autocompletenew-password
        autoComplete={autoCompleteDisabled ? 'new-password' : autoComplete}
        className={cn('input-default', className)}
        onChange={(event: React.FormEvent<HTMLTextAreaElement>): void => {
          onChange?.(event.currentTarget.value);
        }}
        {...unused}
      />
    </InputWrapper>
  );
};

import React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { useDispatch } from 'react-redux';
import { Field, FieldArray, GenericFieldArray } from 'redux-form';
import { Button, IconSmile, RfInput, RfInputTiles, SheetLayoutContent } from '../../../brenger-shared-ui';
import { useTypedSelector } from '../../../hooks';
import { RootState } from '../../../typings';
import { translate } from '../../../utils/localization';
import { ItemsAndServiceFormValues } from '../containers/ItemsAndServices';
import { actions, getDtpSelectableOptions } from '../ducks';
import { BusinessFields, BusinessForms } from '../interface';
import { BusinessSchema } from '../schema';
import ItemSets, { Props as ItemSetsProps } from './ItemSets';

type ItemSetFormValues = Pick<ItemsAndServiceFormValues, BusinessFields.ITEM_SETS>;

// This is the way we can extend the FieldArray to be a custom component with optional custom props
// https://github.com/DefinitelyTyped/DefinitelyTyped/pull/35723
const FieldArrayCustom = FieldArray as new () => GenericFieldArray<ItemSetFormValues, ItemSetsProps>;

export const ItemsAndServiceFields: React.FC = () => {
  const dispatch = useDispatch();
  const dateOptions = useTypedSelector(getDtpSelectableOptions);
  const isAssemblyAvailable = useTypedSelector((state: RootState) => state.business.price_list?.['assembly.available']);

  return (
    <>
      <FieldArrayCustom
        name={BusinessFields.ITEM_SETS}
        title={<Translate id={'request_flow.business.items_sub_title'} />}
        businessFlow={true}
        showProductSuggestions={true}
        formName={BusinessForms.ITEMS_AND_SERVICE}
        component={ItemSets}
      />
      <SheetLayoutContent>
        {dateOptions.length > 0 && (
          <div className={'business--date-selector'}>
            <div className={'pb-1 pt-1'}>
              <b>
                <Translate id={'request_flow.business.headings.date'} />
              </b>
            </div>
            <Field
              validate={BusinessSchema.date}
              name={BusinessFields.DATE}
              options={dateOptions}
              component={RfInputTiles}
              maxDelayIndex={7}
            />
            <div>
              <Button
                onClick={event => {
                  event.preventDefault();
                  dispatch(actions.getNextDateTimePeriodOptions());
                }}
                buttonStyle="outline"
              >
                <IconSmile smileDirection={'down'} />
                <Translate id={'request_flow.business.get_more_dates'} />
              </Button>
            </div>
          </div>
        )}
        <div className={'pb-1 pt-1'}>
          <b>
            <Translate id={'request_flow.business.headings.extra_services'} />
          </b>
        </div>
        <Field
          name={BusinessFields.EXTRA_DRIVER}
          label={String(translate('request_flow.business.fields.extra_driver.label'))}
          description={String(translate('request_flow.business.fields.extra_driver.description'))}
          type={'checkbox'}
          border={true}
          suffix={<span />}
          component={RfInput}
        />
        {isAssemblyAvailable && (
          <Field
            name={BusinessFields.ASSEMBLY}
            label={String(translate('request_flow.business.fields.assembly.label'))}
            description={String(translate('request_flow.business.fields.assembly.description'))}
            type={'checkbox'}
            border={true}
            suffix={<span />}
            component={RfInput}
          />
        )}
      </SheetLayoutContent>
    </>
  );
};

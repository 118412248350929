import * as React from 'react';

interface Props {
  extraClasses?: string;
  children: React.ReactNode;
}

const Row = ({ extraClasses, children }: Props): JSX.Element => {
  return <div className={'row ' + (extraClasses ? extraClasses : '')}>{children}</div>;
};
export default Row;

import createSagaMiddleware from 'redux-saga';

import { logException, logError } from '../../utils/basics';

const sagaMiddleware = createSagaMiddleware({
  // This middleware will handle uncaught errors from Sagas.
  onError(error, errorInfo) {
    logError(error);
    logError(errorInfo);
    logException(error.message, errorInfo as unknown as Record<string, unknown>);
  },
});

export default sagaMiddleware;

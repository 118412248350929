import { ItemSetCreationParams, TransportRequestCreationParams } from '@brenger/api-client';

/**
 * We saw some random failing TRs happening in the wild,
 * where the product_groups field was a stringified array.
 * This function checks for that and returns a new TR with the product_groups field parsed.
 * It also returns a boolean indicating whether the TR had stringified product_groups.
 *
 * See sentry: https://brenger.sentry.io/issues/5717315853/events/633e880ea24047769583e6786bacc6a8/?project=1423309
 */

export const checkForStringifiedPsGroups = (
  tr: TransportRequestCreationParams
): { tr: TransportRequestCreationParams; hasStringifiedPsGroups: boolean } => {
  let hasStringifiedPsGroups = false;
  const newTr: TransportRequestCreationParams = {
    ...tr,
    item_sets: tr.item_sets.map(itemSet => {
      return {
        ...itemSet,
        items: itemSet.items.map(item => {
          if (typeof item.product_groups === 'string') {
            hasStringifiedPsGroups = true;
            return {
              ...item,
              product_groups: JSON.parse(item.product_groups),
            };
          }
          return item;
        }),
      };
    }),
  };
  return {
    tr: newTr,
    hasStringifiedPsGroups,
  };
};

export const correctItemSetProductGroups = (itemSets: ItemSetCreationParams[]): ItemSetCreationParams[] => {
  return itemSets.map(itemSet => {
    return {
      ...itemSet,
      items: itemSet.items.map(item => {
        if (typeof item.product_groups === 'string') {
          return {
            ...item,
            product_groups: JSON.parse(item.product_groups),
          };
        }
        return item;
      }),
    };
  });
};

import React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { RouteComponentProps } from 'react-router';
import { Field } from 'redux-form';
import {
  FlashMessage,
  Heading,
  IconDoor,
  IconElevator,
  RfInputTiles,
  SheetLayoutContent,
  SheetLayoutHeader,
} from '../../../brenger-shared-ui';
import { useTypedSelector } from '../../../hooks';
import { RootState, StopType } from '../../../typings';
import { floorAsString } from '../../../utils/floors';
import { translate } from '../../../utils/localization';
import { priceAsString } from '../../../utils/price';
import { getFloorOptionFees, getBasementOptionFees, getPickupDestinationDetails } from '../ducks';
import { HomeSituations, SubStepFieldNamesDelivery, SubStepFieldNamesPickup } from '../interface';
import { pickupDeliverySchema } from '../schema';
import { FloorOption, BasementOption } from '../typings';
import { proceed } from '../proceed';

const SubStepFloor: React.FC<RouteComponentProps> = props => {
  const stopType = props.location.pathname.includes('/pickup/') ? StopType.PICKUP : StopType.DELIVERY;
  const fieldName =
    stopType === 'pickup'
      ? SubStepFieldNamesPickup.SITUATION_HOME_FLOORS_COMBINED
      : SubStepFieldNamesDelivery.SITUATION_HOME_FLOORS_COMBINED;

  const floorOptionFees = useTypedSelector(getFloorOptionFees);
  const basementOptionFees = useTypedSelector(getBasementOptionFees);
  const locality = useTypedSelector(getPickupDestinationDetails)?.locality;
  const error = useTypedSelector((state: RootState) => state.generalTransport.error);

  /**
   * Ground level and elevator options
   */
  const options: Array<{
    value: string;
    title: string;
    desc: string;
    suffix: React.ReactNode;
    prefix: React.ReactNode;
  }> = [
    {
      value: HomeSituations.GROUND_FLOOR_AND_READY,
      title: String(translate(`request_flow.${stopType}.situation_house.ground_floor`, { locality: { locality } })),
      desc: String(
        translate(`request_flow.${stopType}.situation_house.ready_at_front_door`, { locality: { locality } })
      ),
      suffix: <span className="text--primary">{priceAsString({ price: { amount: 0 } })}</span>,
      prefix: <IconDoor />,
    },
    {
      value: HomeSituations.FLOOR_WITH_ELEVATOR,
      title: String(
        translate(`request_flow.${stopType}.situation_house.inside_with_elevator`, { locality: { locality } })
      ),
      desc: String(
        translate(`request_flow.${stopType}.situation_house.inside_with_elevator_subtitle`, { locality: { locality } })
      ),
      suffix: (
        <div>
          <span className="text--primary">{priceAsString({ price: { amount: floorOptionFees.with_elevator } })}</span>
        </div>
      ),
      prefix: <IconElevator />,
    },
    {
      value: `${HomeSituations.BASEMENT_WITHOUT_ELEVATOR}[-1]`,
      title: String(translate(`request_flow.situation.x_basement_v2`)),
      desc: translate(`request_flow.situation.floor_without_elevator`),
      suffix: (
        <div>
          <span className="text--primary">
            {priceAsString({
              price: {
                amount: basementOptionFees[String(-1) as BasementOption],
              },
            })}
          </span>
        </div>
      ),
      prefix: <span className="floor-indication custom">-1</span>,
    },
  ];

  /**
   * Floor level options
   */
  for (let i = 1; i <= 10; i++) {
    const fee = floorOptionFees[String(i) as FloorOption];
    options.push({
      value: `${HomeSituations.FLOOR_WITHOUT_ELEVATOR}[${i}]`,
      title: String(translate(`request_flow.situation.x_floor_v2`, { floor: floorAsString(i) })),

      desc: translate(`request_flow.situation.floor_without_elevator`),
      suffix: (
        <div>
          <span className="text--primary">{priceAsString({ price: { amount: fee } })}</span>
        </div>
      ),
      prefix: <span className="floor-indication custom">{i}</span>,
    });
  }

  return (
    <>
      <SheetLayoutHeader>
        <Heading size={2} extraClasses={'no-margin'}>
          <Translate
            id={`request_flow.${stopType}.situation_house.question`}
            data={{ locality: <span className={'text--primary'}>{locality}</span> }}
          />
        </Heading>
      </SheetLayoutHeader>
      <SheetLayoutContent>
        <div className={'question-wrapper'}>
          <p>
            <Translate id={'request_flow.insurance.description'} />
          </p>
          <Field
            name={fieldName}
            label={
              <Translate id={`request_flow.${stopType}.situation_house.question`} data={{ locality: { locality } }} />
            }
            type="select"
            validate={pickupDeliverySchema.situation}
            options={options}
            component={RfInputTiles}
            onChangeCallback={proceed}
          />
        </div>
        <div className={'general-errors-wrapper'}>{error && <FlashMessage type={'error'} message={error} />}</div>
      </SheetLayoutContent>
    </>
  );
};

export default SubStepFloor;

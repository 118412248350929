import React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import '../../../../assets/product-selection/itemConfig.scss';
import { FlashMessage, InputText } from '../../../../brenger-shared-ui';
import { Platform } from '../../../../generated/graphql';
import { useTbAuctions } from '../../../../hooks';
import { r } from '../../../../routes';
import { StopType } from '../../../../typings';
import { translate } from '../../../../utils/localization';
import { actions } from '../../ducks';
import { MANUAL_IRI } from './ItemConfig';

export const TbAuctionsAddItem: React.FC<{ platform: Platform; isFirstItem: boolean }> = ({
  platform,
  isFirstItem,
}) => {
  const { query, setQuery, lot, address, error, isLoading } = useTbAuctions(platform);
  const dispatch = useDispatch();
  const history = useHistory();
  const platformName = platform === Platform.Twk ? 'Troostwijk' : 'Vavato';

  React.useEffect(() => {
    if (!lot || !address) return;
    dispatch(actions.setSearchResultPs({ iri: MANUAL_IRI, auctionLot: lot }));
    dispatch(actions.setDestinationDetails(address, StopType.PICKUP));
    history.push(r.generalFlow.items.configure());
    setQuery('');
  }, [lot]);

  return (
    <div className="pb-1">
      <div className="mt-1 pb-0-5">
        {isFirstItem && <Translate id={`request_flow.auction.tb_auction_add_lot`} data={{ platform: platformName }} />}
        {!isFirstItem && (
          <Translate id={`request_flow.auction.tb_auction_add_another_lot`} data={{ platform: platformName }} />
        )}
      </div>
      <div className="ps-config--tbauctions">
        <InputText
          placeholder={translate('request_flow.auction.tb_auction_add_lot_placeholder')}
          className="w-full"
          disabled={isLoading}
          isLoading={isLoading}
          value={query}
          onChange={lotOrUrl => setQuery(lotOrUrl)}
        />
      </div>
      {error && (
        <div className="pt-1">
          <FlashMessage type="error">
            <Translate id={`request_flow.auction.errors.tb_auction_${error}`} data={{ platform: platformName }} />
          </FlashMessage>
        </div>
      )}

      <div className="pt-1">
        <Translate
          id={'request_flow.auction.tb_auction_add_normal_item'}
          data={{
            add: (
              <a
                href="#"
                onClick={e => {
                  e.preventDefault();
                  history.push(r.generalFlow.items.add());
                }}
              >
                <Translate id={'request_flow.auction.tb_auction_add_normal_item_cta'} />
              </a>
            ),
          }}
        />
      </div>
    </div>
  );
};

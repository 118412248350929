import React from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import '../../../../assets/product-selection/itemConfig.scss';
import { IconSmile } from '../../../../brenger-shared-ui';
import { useTypedSelector } from '../../../../hooks';
import { CacheKey } from '../../../../typings';
import { priceClient } from '../../../../utils/request';
import { ItemForm } from './ItemForm';
import { Translate } from 'react-localize-redux-dep-updated';

export const MANUAL_IRI = 'ManualProductGroup#Custom items';

export const ItemConfig: React.FC = () => {
  const history = useHistory();
  const selectedItem = useTypedSelector(state => state.generalTransport.product_selection.searchResult);

  if (selectedItem === null) {
    return (
      <div className="ps-config" onClick={() => history.goBack()}>
        <div className="ps-config--bg" onClick={e => e.stopPropagation()}>
          <div className="ps-modal--section">
            <Translate id="request_flow.product_selection.error_no_group_selected" />
          </div>
        </div>
      </div>
    );
  }
  return <ItemConfigWithResult iri={selectedItem.iri as string} />;
};

const ItemConfigWithResult: React.FC<{ iri: string }> = ({ iri }) => {
  const history = useHistory();
  const productGroup = useQuery(
    [CacheKey.RETRIEVE_PS_PRODUCT_GROUP, iri],
    () => priceClient.productSelection.retrieveGroup({ id: encodeURIComponent(iri) }),
    {
      retry: 2,
    }
  );

  if (!productGroup.data || productGroup.isLoading || productGroup.isError) {
    return (
      <div
        className="ps-config ps-config--no-result"
        onClick={productGroup.isLoading ? undefined : () => history.goBack()}
      >
        <div className="ps-config--bg" onClick={e => e.stopPropagation()}>
          <div className="ps-modal--section">
            {productGroup.isLoading && <IconSmile spinning={true} />}
            {productGroup.isError && (productGroup.error as { message: string })?.message}
          </div>
        </div>
      </div>
    );
  }
  return <ItemForm productGroup={productGroup.data} />;
};

import React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { useHistory } from 'react-router-dom';
import { Button, CirclePerson2, Dialog, Modal } from '../../../brenger-shared-ui';
import { clarity } from '../../../configs/clarity';
import { useTypedSelector } from '../../../hooks';
import { r } from '../../../routes';
import { RootState } from '../../../typings';
import { getActiveLanguageSettings } from '../../../utils/localization';
import { QuoteRejectedReasonsBusiness } from '../interface';

export const RejectedByQuotesModal: React.FC = () => {
  const history = useHistory();
  const price = useTypedSelector((state: RootState) => state.business.transport_request.price);
  const [modalReason, setModalReason] = React.useState<QuoteRejectedReasonsBusiness | null>(null);

  React.useEffect(() => {
    if (price.loading || !price.rejected_reason) {
      setModalReason(null);
      return;
    }
    clarity.setTag('business_quote_rejected', price.rejected_reason);
    setModalReason(price.rejected_reason);
  }, [price.rejected_reason, price.loading]);

  const languageSettings = getActiveLanguageSettings();
  const transVars = {
    phone: <a href={'tel:' + languageSettings.contact.phone.business}>{languageSettings.contact.phone.business}</a>,
    email: <a href={'mailto:' + languageSettings.contact.email.business}>{languageSettings.contact.email.business}</a>,
    link: (
      <a
        target="_blank"
        href={`https://www.brenger.nl/${getActiveLanguageSettings().labels.contentSite}/business/contact`}
        rel="noreferrer"
      >
        <Translate id="request_flow.business_tr_rejected.modal.support_link" />
      </a>
    ),
  };
  {
    /* 
    Reasons not to show the GF button:
    - If geo failed, there is no point in sending customers to the general flow 
    - When it is a blocked non guaranteed reason as well in GF
    */
  }
  const showGfButton =
    modalReason &&
    modalReason !== QuoteRejectedReasonsBusiness.GEO_FAILED &&
    modalReason !== QuoteRejectedReasonsBusiness.ITEM_ONE_OVER_200KG;

  return (
    <Dialog isActive={Boolean(modalReason)}>
      <Modal
        header={
          <div className={'text--center'}>
            <Translate id={'request_flow.business_tr_rejected.modal.title'} />
          </div>
        }
        open={Boolean(modalReason)}
        onlyToggleByButton={true}
        style={'blue'}
        size={'small'}
      >
        <div className={'text--center pb-1'}>
          <CirclePerson2 />
        </div>
        <div className={'pb-1'}>
          <Translate id={'request_flow.business_tr_rejected.explain.' + modalReason} data={transVars} />
        </div>
        <div className={'pb-1'}>
          {showGfButton && <Translate id={'request_flow.business_tr_rejected.proceed_text'} data={transVars} />}
          {!showGfButton && (
            <Translate id={'request_flow.business_tr_rejected.proceed_making_adjustments_explain'} data={transVars} />
          )}
        </div>
        {showGfButton && (
          <Button
            extraClasses={'no-margin'}
            fullWidth={true}
            type={'button'}
            buttonStyle={'secondary'}
            onClick={() => history.push(r.generalFlow.index())}
          >
            <Translate id={'request_flow.business_tr_rejected.proceed_general_flow_button'} />
          </Button>
        )}
        <Button fullWidth={true} type={'button'} buttonStyle={'outline'} onClick={() => setModalReason(null)}>
          <Translate id={'request_flow.business_tr_rejected.proceed_here_button'} />
        </Button>
      </Modal>
    </Dialog>
  );
};

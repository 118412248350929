import * as React from 'react';
import classNames from 'classnames';

import './input.scss';
import { InputProps } from './Input';

interface Props extends InputProps {
  type?: string;
  customIsValid?: boolean;
  extraClasses?: string; // TODO rename extraClassNames and Add to InputProps
}

const InputWrapper: React.FC<Props & React.HTMLAttributes<Props>> = ({
  type,
  input,
  label,
  meta,
  border = false,
  customIsValid,
  extraClasses,
  children,
  style = {},
}) => {
  const isValid = typeof customIsValid !== 'undefined' ? customIsValid : meta.valid;
  return (
    <div
      style={style}
      className={classNames(
        'input-el',
        `input-el--${type}`,
        {
          'input-el--has-value': input.value || input.value === 0 || meta.autofilled,
          'input-el--has-focus': meta.active,
          'input-el--bordered': border,
          'input-el--no-label': !label,
          'input-el--is-valid': isValid,
        },
        extraClasses
      )}
    >
      {children}
    </div>
  );
};
export default InputWrapper;

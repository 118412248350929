import React from 'react';
import './icons.scss';

interface Props {
  color?: 'white' | 'blue';
}

const IconInfo: React.FC<React.SVGAttributes<SVGElement> & Props> = props => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 18" {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-203.000000, -312.000000)" fill={props.color === 'blue' ? '#0073FA' : '#FFF'}>
          <g transform="translate(20.000000, 307.000000)">
            <g transform="translate(184.000000, 6.000000)">
              <path d="M8,16.8 C3.1398942,16.8 -0.8,12.8601058 -0.8,8 C-0.8,3.1398942 3.1398942,-0.8 8,-0.8 C12.8601058,-0.8 16.8,3.1398942 16.8,8 C16.8,12.8601058 12.8601058,16.8 8,16.8 Z M8,15.2 C11.9764502,15.2 15.2,11.9764502 15.2,8 C15.2,4.0235498 11.9764502,0.8 8,0.8 C4.0235498,0.8 0.8,4.0235498 0.8,8 C0.8,11.9764502 4.0235498,15.2 8,15.2 Z" />
              <path
                d="M7.2,6.60869565 C7.2,6.16686785 7.5581722,5.80869565 8,5.80869565 C8.4418278,5.80869565 8.8,6.16686785 8.8,6.60869565 L8.8,12.173913 C8.8,12.6157408 8.4418278,12.973913 8,12.973913 C7.5581722,12.973913 7.2,12.6157408 7.2,12.173913 L7.2,6.60869565 Z"
                transform="translate(8.000000, 9.391304) rotate(-180.000000) translate(-8.000000, -9.391304) "
              />
              <path
                d="M7.2,3.82608696 C7.2,3.38425916 7.5581722,3.02608696 8,3.02608696 C8.4418278,3.02608696 8.8,3.38425916 8.8,3.82608696 L8.8,4.52173913 C8.8,4.96356693 8.4418278,5.32173913 8,5.32173913 C7.5581722,5.32173913 7.2,4.96356693 7.2,4.52173913 L7.2,3.82608696 Z"
                transform="translate(8.000000, 4.173913) rotate(-180.000000) translate(-8.000000, -4.173913) "
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconInfo;

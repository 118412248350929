import { push } from 'connected-react-router';
import React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { useDispatch } from 'react-redux';
import { Button } from '../../../../brenger-shared-ui';
import { useTypedSelector } from '../../../../hooks';
import { RootState } from '../../../../typings';
import { getCrossDomainSetup } from '../../../../utils/basics';
import { r } from '../../../../routes';

interface Props {
  id: string;
}

export const ButtonsGf: React.FC<Props> = props => {
  const dispatch = useDispatch();
  const isLoggedIn = useTypedSelector((state: RootState) => state.user.loggedIn);
  const crossDomain = getCrossDomainSetup();
  // If we are logged in we don't want to show the button
  // In case we are cross domain we don't support logging in yet, so we shouldn't show a button
  if (isLoggedIn || crossDomain !== null) {
    return null;
  }
  return (
    <Button
      fullWidth={true}
      onClick={() => dispatch(push(r.user.register({ trId: props.id })))}
      buttonStyle={'outline'}
    >
      <Translate id={`request_flow.general.thank_you.make_account`} />
    </Button>
  );
};

import * as React from 'react';
import { BrengerLogo, IconSmile } from '../../basics/icons';
import { ProgressSteps } from '../../other/progresssteps';
import { ProgressBar } from '../../other/progressbar';
import './progressheader.scss';
import { getActiveLanguageSettings } from '../../../../utils/localization';

interface Props {
  currentStep: number;
  totalSteps: number;
  goBack: React.MouseEventHandler;
  goBackText: string;
  goBackAlwaysAvailable?: boolean;
  homeUrl?: string;
  logoOnClick?(MouseEvent): void;
}

export const ProgressHeader: React.FC<Props> = props => {
  const body = document.body;
  const currentStep = props.currentStep;
  const langSettings = getActiveLanguageSettings();

  const checkProcess = (): void => {
    if (currentStep > props.totalSteps) {
      body.classList.add('bd-progress-header-finished', 'bd-hide-menu');
      body.classList.remove('bd-progress-header-loaded');
    } else {
      body.classList.remove('bd-progress-header-finished', 'bd-hide-menu');
    }
  };

  // Display / hide the menu
  React.useEffect(() => {
    checkProcess();
    body.classList.add('bd-progress-header-loaded');
    return () => {
      body.classList.remove('bd-progress-header-loaded', 'bd-progress-header-finished', 'bd-hide-menu');
    };
  }, []);

  React.useEffect(() => {
    checkProcess();
  }, [props.currentStep]);

  const updateLang = (newLang?: string): void => {
    const pathName: string[] = window.location.pathname.split('/');
    pathName.splice(0, 2);
    window.location.href = [`/${newLang || 'en'}`, ...pathName].join('/');
  };

  return (
    <header className={'progress-header'}>
      {(props.goBackAlwaysAvailable || (currentStep !== 1 && currentStep >= 2)) && (
        <span className={'go-back trigger'} title={props.goBackText} onClick={props.goBack}>
          <IconSmile />
          <span className={'go-back-text'}>{props.goBackText}</span>
        </span>
      )}
      {currentStep === 1 && <div className={'progress-header--placeholder'}>&nbsp;</div>}
      <div className={'progress-header--logo-wrapper'}>
        <a href={langSettings.home_page} onClick={props.logoOnClick}>
          <BrengerLogo />
        </a>
      </div>
      <ProgressSteps currentStep={props.currentStep} totalSteps={props.totalSteps} />
      <ProgressBar currentStep={props.currentStep} totalSteps={props.totalSteps} />
      {langSettings.altLanguages && (
        <div className={'input-el input-el--select input-el--select--lang input-el--no-label'}>
          {langSettings.altLanguages.map((altLang, i) => (
            <span
              className="text--primary underline font--bold trigger"
              onClick={() => updateLang(altLang.lang)}
              key={altLang.lang + i}
            >
              {altLang.label}
            </span>
          ))}
        </div>
      )}
    </header>
  );
};

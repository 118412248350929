import { CarryingHelp } from '@brenger/api-client';
import cn from 'classnames';
import React, { SyntheticEvent } from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { useDispatch } from 'react-redux';
import {
  FlashMessage,
  Heading,
  InputTiles,
  SheetLayoutContent,
  SheetLayoutContentWrapper,
  SheetLayoutHeader,
} from '../../../brenger-shared-ui';
import { useServiceAdvisoryHelp, useTypedSelector } from '../../../hooks';
import FooterLayout from '../containers/FooterLayout';
import { actions, getStopHelp } from '../ducks';
import { proceed } from '../proceed';

export const SubStepHelpCombined: React.FC = () => {
  const helpOptions = useServiceAdvisoryHelp();
  const hasAdvised = helpOptions.advised.length > 0;
  const destination = useTypedSelector(state => state.generalTransport.destination);
  const [showError, setShowError] = React.useState(false);
  const help = useTypedSelector(getStopHelp);
  const dispatch = useDispatch();

  const fieldNameAdvised = 'help_combined_advised';
  const fieldNameOther = 'help_combined_other';
  const handleEscape = (e: SyntheticEvent): void => {
    e.preventDefault();
    const fieldName = helpOptions.advised.find(
      option => (option.value as CarryingHelp) === 'equipment_provided_by_customer'
    )
      ? fieldNameAdvised
      : fieldNameOther;
    const el = document.querySelector(
      `[data-qa-input-name="${fieldName}"][data-qa-input-value="equipment_provided_by_customer"]`
    );
    if (!el) {
      return;
    }
    // Scroll into view and make the card blink
    el.scrollIntoView({ behavior: 'smooth' });
    el.classList.add('input-el--tile--highlight');
    el.classList.remove('input-el--tile--highlight-none');
    // Clean up class so we can do it again if needed
    setTimeout(() => {
      el.classList.add('input-el--tile--highlight-none');
    }, 2000);
  };

  const getAdvisedTitle = (): 'needed_for_transport' | 'most_popular' | 'advised_for_transport' => {
    // If there is an escape, we show advised as "needed"
    if (helpOptions.hasEscape) return 'needed_for_transport';
    // If one of the advised option is free, we show as "most popular"
    if (
      hasAdvised &&
      helpOptions.advised.find(helpOption => helpOption.value === 'needed' || helpOption.value === 'not_needed')
    )
      return 'most_popular';
    return 'advised_for_transport';
  };

  React.useEffect(() => {
    dispatch(actions.setProgress(5));
    // Set the default on mount, choice get reset
    dispatch(actions.setChoice({ carrying_help: 'not_needed' }));
  }, []);

  const continueToNextPath = (): void => {
    proceed();
    dispatch(actions.getPrice());
  };

  const onSubmit = (): void => {
    // if default value, we still need to select so show error
    if (help === 'not_needed') {
      setShowError(true);
      return;
    }
    continueToNextPath();
  };

  return (
    <SheetLayoutContentWrapper>
      <SheetLayoutHeader>
        <Heading size={2}>
          <Translate
            id={'request_flow.help_carrying_question'}
            data={{
              pickup: <span className={'text--primary'}>{destination.pickup?.locality}</span>,
              delivery: <span className={'text--primary'}>{destination.delivery?.locality}</span>,
            }}
          />
        </Heading>
      </SheetLayoutHeader>
      <SheetLayoutContent>
        <div className={'question-wrapper'}>
          {hasAdvised && (
            <>
              <div className={'pb-0-5'}>
                <div
                  className={cn('pb-0-5', {
                    'text--primary': getAdvisedTitle() !== 'most_popular',
                    'text--grey': getAdvisedTitle() === 'most_popular',
                  })}
                >
                  <b>
                    <Translate id={`form.${getAdvisedTitle()}`} />
                  </b>
                </div>
                <InputTiles
                  value={help}
                  id={fieldNameAdvised}
                  name={fieldNameAdvised}
                  options={helpOptions.advised}
                  onClick={continueToNextPath}
                  onChange={newHelp => dispatch(actions.setChoice({ carrying_help: newHelp as CarryingHelp }))}
                />
                {helpOptions.hasEscape && (
                  <div className="pb-0-5" style={{ marginTop: '-.6em' }}>
                    <a
                      style={{
                        textDecoration: 'none',
                        fontSize: '.9em',
                      }}
                      href="#"
                      onClick={handleEscape}
                    >
                      <Translate id="request_flow.help_carrying.escape" />
                    </a>
                  </div>
                )}
              </div>

              <div className={'text--grey pb-0-5'}>
                <b>
                  <Translate id={'form.other_options'} />
                </b>
              </div>
            </>
          )}
          <InputTiles
            value={help}
            id={fieldNameOther}
            name={fieldNameOther}
            options={helpOptions.other}
            onClick={continueToNextPath}
            onChange={newHelp => dispatch(actions.setChoice({ carrying_help: newHelp as CarryingHelp }))}
          />
          {showError && (
            <FlashMessage type="error">
              <Translate id="request_flow.errors.select_required" />
            </FlashMessage>
          )}
        </div>
      </SheetLayoutContent>
      <FooterLayout customSubmitFunction={onSubmit} />
    </SheetLayoutContentWrapper>
  );
};

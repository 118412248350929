import React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { useDispatch } from 'react-redux';
import { Button, CirclePerson2, Dialog, Modal } from '../../../brenger-shared-ui';
import { clarity } from '../../../configs/clarity';
import { useTypedSelector } from '../../../hooks';
import { RootState } from '../../../typings';
import { getActiveLanguageSettings } from '../../../utils/localization';
import { actions as flowActions, getReferrer } from '../ducks';
import { ResetFlowAction } from '../typings';

export const RejectedByRouteCalculationModal: React.FC = () => {
  const dispatch = useDispatch();
  const resetFLow = (): ResetFlowAction => dispatch(flowActions.resetFlow(true));
  const rejectReason = useTypedSelector((state: RootState) => state.generalTransport.price.rejected);
  const referrer = useTypedSelector(getReferrer);
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const languageSettings = getActiveLanguageSettings();

  React.useEffect(() => {
    if (!rejectReason || rejectReason !== 'route.calculation_not_possible') {
      setShowModal(false);
      return;
    }
    clarity.setTag(rejectReason);
    setShowModal(true);
  }, [rejectReason]);

  const transVars = {
    phone: <a href={'tel:' + languageSettings.contact.phone.customer}>{languageSettings.contact.phone.customer}</a>,
    email: <a href={'mailto:' + languageSettings.contact.email.customer}>{languageSettings.contact.email.customer}</a>,
    link: (
      <a
        target="_blank"
        href={`https://www.brenger.nl/${getActiveLanguageSettings().labels.contentSite}/contact`}
        rel="noreferrer"
      >
        <Translate id="request_flow.errors.route_calculation_rejected.modal.support_link" />
      </a>
    ),
  };
  return (
    <Dialog isActive={showModal}>
      <Modal
        header={
          <div className={'text--center'}>
            <Translate id={'request_flow.errors.route_calculation_rejected.modal.title'} />
          </div>
        }
        open={showModal}
        onlyToggleByButton={true}
        style={'blue'}
        size={'small'}
      >
        <div className={'text--center pb-1'}>
          <CirclePerson2 />
        </div>
        <div className={'pb-1'}>
          <Translate id={'request_flow.errors.route_calculation_rejected.modal.explain'} data={transVars} />
        </div>
        <div className={'pb-1'}>
          <Translate id={'request_flow.errors.route_calculation_rejected.modal.proceed_text'} data={transVars} />
        </div>
        <Button
          fullWidth={true}
          type={'button'}
          buttonStyle={'outline'}
          onClick={() => {
            resetFLow();
            window.location.href = referrer;
          }}
        >
          <Translate id={'request_flow.errors.route_calculation_rejected.modal.proceed_here_button'} />
        </Button>
      </Modal>
    </Dialog>
  );
};

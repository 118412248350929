import { GeoAutocompleteAddress } from '@brenger/api-client';
import { useForm } from '@brenger/react';
import * as React from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { Col, InputSelect, InputText, InputTextarea, Row } from '../../../../brenger-shared-ui';
import { useTypedSelector } from '../../../../hooks';
import { useDebounce } from '../../../../hooks/useDebounce';
import { CacheKey, StopType } from '../../../../typings';
import { logError } from '../../../../utils/basics';
import { getGeoAutocomplete, retrieveLocationDetails } from '../../../../utils/geo';
import { translate } from '../../../../utils/localization';
import { getSuspicions } from '../../../../utils/textScanning';
import { validateLine1, validateRequired, warnLine1 } from '../../../../utils/validation';
import { actions, getHistoricalAddressesForStop } from '../../ducks';
import { AddressError, ContactPageError } from './AddressError';
import { ContactPageAddress } from './ContactStop';

interface Props {
  forceShowErrors: boolean;
  widgetAddress: GeoAutocompleteAddress | null;
  type: StopType;
  setContactPageAddress(addr: ContactPageAddress | null): void;
  contactPageError: ContactPageError;
  setContactPageError(error: ContactPageError): void;
  setIsAddressLoading(isLoading: boolean): void;
}

export const AddressFields: React.FC<Props> = ({
  forceShowErrors,
  type,
  widgetAddress,
  setContactPageAddress,
  contactPageError,
  setContactPageError,
  setIsAddressLoading,
}) => {
  const rehydrate = useTypedSelector(state => state.generalTransport.contact[type]);
  const hAddresses = useTypedSelector(state => getHistoricalAddressesForStop(state, type));
  const dispatch = useDispatch();

  const form = useForm({
    persist: {
      key: `gf_contact_address_${type}`,
      type: 'session',
    },
    initialState: {
      historical: '-1',
      postal_code: rehydrate?.address?.postal_code || widgetAddress?.postal_code || '',
      line1: rehydrate?.address?.line1 || [widgetAddress?.line1, widgetAddress?.line2].filter(Boolean).join(' ') || '',
      locality: rehydrate?.address?.locality || widgetAddress?.locality || '',
      instructions: rehydrate?.instructions || '',
    },
    validators: {
      postal_code: val => validateRequired(translate('form.fields.address.postal_code.label'), val),
      line1: validateLine1,
      locality: val => !val,
    },
  });

  const pc = form.data.postal_code.value;
  const line1 = form.data.line1.value;
  const instructions = form.data.instructions.value;

  // We use this endpoint only for pre-filling postal code, so no other details of the address will be copied or used
  const query = useDebounce(`${line1},${form.data.locality.value},${widgetAddress?.country_code}`, 500);
  const postalCodeLookup = useQuery(
    [CacheKey.RETRIEVE_POSTAL_CODE, query],
    () => getPostalCode(query, widgetAddress?.locality as string),
    {
      enabled: !!line1,
    }
  );

  React.useEffect(() => {
    setIsAddressLoading(postalCodeLookup.isLoading);
  }, [postalCodeLookup.isLoading]);

  React.useEffect(() => {
    if (postalCodeLookup.data) {
      form.set({ postal_code: postalCodeLookup.data });
    }
  }, [postalCodeLookup.data]);

  React.useEffect(() => {
    if (pc && line1) {
      setContactPageAddress({
        line1,
        postal_code: pc,
        instructions: instructions,
      });
    } else {
      setContactPageAddress(null);
    }
  }, [pc, line1, instructions]);

  const onHistoricalChange = (historical: string): void => {
    const address = hAddresses.find((_, i) => Number(historical) === i);
    if (address) {
      form.set({
        historical,
        line1: address.line1,
        postal_code: address.postal_code,
        locality: address.locality,
      });
      return;
    }
    form.set({ historical });
  };

  const onBlurInstructions = (): void => {
    const suspicions = getSuspicions(instructions);
    dispatch(actions.setInstructionSuspicion({ stopType: type, suspicions }));
  };
  return (
    <Row>
      {!!hAddresses.length && (
        <Col xs={12} extraClasses="pb-1">
          <InputSelect
            options={[
              {
                label: translate('form.fields.address.historical_addresses.label'),
                value: '-1',
              },
              ...hAddresses.map((ha, i) => {
                return {
                  label: `${ha.line1}, ${ha.postal_code}, ${ha.locality}`,
                  value: String(i),
                };
              }),
            ]}
            value={form.data.historical.value}
            onChange={historical => onHistoricalChange(historical)}
            id={`${type}_historical`}
            addEmptyOption={false}
          />
        </Col>
      )}
      <Col xs={12} extraClasses="pb-1">
        <InputText
          value={form.data.line1.value}
          onChange={l => form.set({ line1: l })}
          id={`${type}_line1`}
          placeholder={translate('form.fields.address.line1.label')}
          meta={{
            error: form.getError('line1'),
            warning: warnLine1(form.data.line1.value),
            showError: forceShowErrors || form.data.line1.isDirty,
          }}
        />
      </Col>
      <Col xs={12} sm={4} extraClasses="pb-1">
        <InputText
          value={form.data.postal_code.value}
          onChange={postal_code => form.set({ postal_code: postal_code.trim() })}
          id={`${type}_postal_code`}
          placeholder={translate('form.fields.address.postal_code.label')}
          meta={{
            error: form.getError('postal_code'),
            showError: forceShowErrors || form.data.postal_code.isDirty,
          }}
        />
      </Col>
      <Col xs={12} sm={8} extraClasses="pb-1">
        <InputText
          value={form.data.locality.value}
          onChange={locality => form.set({ locality })}
          id={`${type}_locality`}
          disabled={true}
          placeholder={translate('form.fields.address.locality.label')}
        />
      </Col>
      <Col xs={12} extraClasses="pb-1">
        <InputTextarea
          value={form.data.instructions.value}
          onChange={i => form.set({ instructions: i })}
          id={`${type}_instructions`}
          placeholder={translate('form.fields.address.instructions.label')}
          onBlur={onBlurInstructions}
        />
      </Col>
      {contactPageError !== 'none' && (
        <Col xs={12} extraClasses="pb-1">
          <AddressError type={contactPageError} setMessage={setContactPageError} />
        </Col>
      )}
    </Row>
  );
};

const getPostalCode = async (query: string, locality: string): Promise<string | null> => {
  try {
    const search = await getGeoAutocomplete(query);
    if (!search.length) {
      return null;
    }
    const result = await retrieveLocationDetails(search[0]);
    if (result.address.locality === locality) {
      return result.address.postal_code || null;
    }
    return null;
  } catch (e) {
    logError(e);
    return null;
  }
};

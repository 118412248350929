import { Price, QuoteNote, QuotePrice } from '@brenger/api-client';
import cn from 'classnames';
import React, { ReactNode } from 'react';
import { Translate } from 'react-localize-redux-dep-updated';

import { getOldPrice, getValidDiscount, priceAsString } from '../../../../utils/price';
import { IconSmile } from '../../basics/icons';
import './pricedisplay.scss';
interface Props {
  header?: ReactNode;
  loading?: boolean;
  priceInCents?: number; // we still need this when dealing with simple flows that don't interact with pricing
  quotePrice?: QuotePrice; // use this when the flow is interacting with pricing
  quoteNotes?: QuoteNote[]; // use this when the flow is interacting with pricing
  failedAttempts?: boolean;
  exclVat?: boolean;
  hideOldPrice?: boolean;
}

export const PriceDisplay: React.FC<Props> = props => {
  let price: Partial<Price> = {
    amount: props.priceInCents || 0,
  };
  if (props.quotePrice) {
    price = props.quotePrice[props.exclVat ? 'excl_vat' : 'incl_vat'];
  }

  if (price.amount === 0) {
    return null;
  }

  const discount = getValidDiscount(props.quoteNotes || []);
  let oldPrice = '';
  if (discount?.type === 'percentage') {
    oldPrice = getOldPrice(price, discount?.data || 0);
  }
  return (
    <div
      data-qa-id="price-display"
      data-qa-id-price-loading={props.loading ? 'true' : 'false'}
      className={cn(
        'price-display',
        { 'price-display-failure': props.failedAttempts },
        { 'price-display--is-loading': props.loading },
        { 'price-display--is-visible': !props.loading },
        { 'price-display--is-special': discount?.type === 'percentage' || discount?.type === 'flat' },
        { 'price-display--is-special--flat': discount?.type === 'flat' },
        { 'price-display--is-simple': props.priceInCents }
      )}
    >
      {props.header && <div className={'price-display--head'}>{props.header}</div>}
      <div className={'price-display--layout'}>
        {!props.hideOldPrice && oldPrice && (
          <div className={'price-display--old-price'}>
            <span>{oldPrice}</span>
          </div>
        )}
        {discount?.type === 'flat' && (
          <div className={'price-display--special-copy'}>
            <Translate id="request_flow.price_discount.flat" />
          </div>
        )}
        <span className={'price-display--layout'}>{priceAsString({ price, addPlusMinusIndicator: false })}</span>
      </div>
      <div className={'price-display--loading-element'}>
        <IconSmile spinning={true} />
      </div>
    </div>
  );
};

export default PriceDisplay;

import { QuoteNotGuaranteedReason } from '@brenger/api-client';
import React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { FlashMessage } from '../../../brenger-shared-ui';
import { translate } from '../../../utils/localization';
import { QuoteRejectedReason } from '../typings';

interface Props {
  reasons: (QuoteNotGuaranteedReason | QuoteRejectedReason)[];
}

export const BlockedNotGuaranteedReasonList: React.FC<Props> = props => {
  return (
    <div className={'blocked-reason-list-messages'}>
      <div className={'text--bold pt-1 pb-0-5'}>
        <Translate id={'request_flow.not_guaranteed.explain.heading'} />
      </div>
      {props.reasons.map((reason, index) => {
        return (
          <div key={index} className="pb-0-5">
            <FlashMessage type={'error'} message={String(translate('request_flow.not_guaranteed.explain.' + reason))} />
          </div>
        );
      })}
    </div>
  );
};

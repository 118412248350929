import { push } from 'connected-react-router';
import _get from 'lodash/get';
import React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Field, getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import '../../../assets/user/user.scss';
import {
  Button,
  Heading,
  RfInputSwitch,
  SectionWrapper,
  SheetLayoutContent,
  SheetLayoutContentWrapper,
  SheetLayoutHeader,
} from '../../../brenger-shared-ui';
import { Col, Row } from '../../../brenger-shared-ui/components/layout/grid';
import FlashMessage from '../../../brenger-shared-ui/components/notice/flashmessage/FlashMessage';
import { RootState } from '../../../typings';
import { urlParser } from '../../../utils/basics';
import CompanyDetails from '../components/CompanyDetails';
import PersonalDetails from '../components/PersonalDetails';
import SocialButtons from '../components/SocialButtons';
import { actions } from '../ducks';
import { UserForms } from '../typings';
import { r } from '../../../routes';

// FIX ME: Inject a proper form value interface
type FormValues = Record<string, unknown>;

type ReduxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

type Form = InjectedFormProps<FormValues, ReduxProps>;

type Props = ReduxProps & Form;

class Register extends React.Component<Props> {
  public render(): JSX.Element {
    const userType = _get(this.props.form, 'user.type');
    const isBusiness = userType ? userType === 'business' : Boolean(urlParser().get('business'));
    const hasErrors = this.props.errors !== '';
    return (
      <>
        <SectionWrapper extraClasses={'register-wrapper'} sectionStyle={'white'} centerContent={true}>
          <form onSubmit={this.props.handleSubmit}>
            <SheetLayoutContentWrapper>
              <SheetLayoutHeader>
                <Heading size={2}>
                  <Translate id={'authentication.register.headline'} />
                </Heading>
              </SheetLayoutHeader>
              <SheetLayoutContent>
                <p>
                  <Translate id={'authentication.login.brief'} />
                  <span
                    className={'trigger text--underline text--primary'}
                    onClick={() => this.props.push(r.user.login({ trId: urlParser().get('transport_request') }))}
                  >
                    <Translate id={'authentication.login.button'} />
                  </span>
                </p>
                <SocialButtons socialAuth={this.props.socialAuth} />
                <br />
                <Field
                  type={'checkbox'}
                  name={'user.type'}
                  options={[
                    {
                      name: 'private',
                      value: 'private',
                      label: <Translate id={'request_flow.contact.is_private'} />,
                    },
                    {
                      name: 'business',
                      value: 'business',
                      label: <Translate id={'request_flow.contact.is_business'} />,
                    },
                  ]}
                  component={RfInputSwitch}
                />
                {isBusiness && <CompanyDetails />}
                <PersonalDetails />
                <Row>
                  <Col xs={12} sm={12}>
                    {hasErrors && <FlashMessage type={'error'} message={this.props.errors} />}
                    <Button
                      fullWidth={true}
                      loading={this.props.submitting}
                      disabled={this.props.submitting || this.props.pristine || !this.props.valid}
                      buttonStyle={'secondary'}
                    >
                      <Translate id={'authentication.register.button'} />
                    </Button>
                  </Col>
                </Row>
              </SheetLayoutContent>
            </SheetLayoutContentWrapper>
          </form>
        </SectionWrapper>
      </>
    );
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState) => ({
  submitting: state.user.loading,
  errors: state.user.errors,
  form: getFormValues(UserForms.REGISTER)(state),
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: Dispatch) => ({
  socialAuth: service => dispatch(actions.socialAuthStart(service, false)),
  push: (url: string) => dispatch(push(url)),
});

export default reduxForm<FormValues, Props>({
  form: UserForms.REGISTER,
  destroyOnUnmount: false,
  initialValues: {
    user: {
      type: urlParser().get('business') ? 'business' : 'private',
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      account: {
        address: {
          country_code: 'NL',
        },
      },
    },
  },
  onSubmit: (details, dispatch) => {
    dispatch(actions.register(details));
  },
})(connect(mapStateToProps, mapDispatchToProps)(Register));

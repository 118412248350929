import { GeoAutocomplete, RetrieveAutocompleteParams } from '@brenger/api-client';
import axios, { CancelTokenSource } from 'axios';
import cn from 'classnames';
import React from 'react';
import { useQuery } from 'react-query';
import { WrappedFieldProps } from 'redux-form';
import { useClickedOutside } from '../../../../hooks/useClickOutside';
import { useDebounce } from '../../../../hooks/useDebounce';
import { CacheKey } from '../../../../typings';
import { routePlannerClient, routePlannerClientToken } from '../../../../utils/request';
import { IconCross, IconMarker, IconSmile } from '../../basics/icons';
import '../input/input.scss';
import InputFeedback from '../input/InputFeedback';
import InputWrapper from '../input/InputWrapper';
import './locationautocomplete.scss';

interface Custom {
  label?: string;
}

type InputProps = WrappedFieldProps & Custom;

let source: CancelTokenSource | undefined = undefined;

const retrieveAutocomplete = (params: RetrieveAutocompleteParams): Promise<GeoAutocomplete[]> => {
  if (source !== undefined) source.cancel();
  source = axios.CancelToken.source();
  return routePlannerClient.geo.retrieveAutocomplete({ ...(params || {}), cancelToken: source.token });
};

const LocationAutocomplete: React.FC<InputProps> = props => {
  const [searchValue, setSearchValue] = React.useState('');
  // Display value has default value when the input has GeoLocationDetails
  const [searchDisplayValue, setSearchDisplayValue] = React.useState('');
  const [placeId, setPlaceId] = React.useState('');
  const [showOptions, setShowOptions] = React.useState(false);
  const [highlighted, setHighlighted] = React.useState(-1);
  const debounced = useDebounce<string>(searchValue, 200);

  // Handles selectable options
  const autoCompleteOptions = useQuery(
    [CacheKey.RETRIEVE_GEO_PLACE, debounced || ''],
    () => retrieveAutocomplete({ query: debounced, sessionToken: routePlannerClientToken }),
    {
      // Do not begin making network requests until user has entered at least three chars
      enabled: !!debounced || debounced.length > 3,
    }
  );
  const options = (searchValue.length < 3 ? [] : autoCompleteOptions.data) || [];

  // Waits for the place id to kick off and holds end result
  const place = useQuery(
    [CacheKey.RETRIEVE_GEO_LOCATION, placeId || ''],
    () =>
      routePlannerClient.geo.retrieveLocationDetails({
        placeId: placeId || '',
        sessionToken: routePlannerClientToken,
        includeHouseNumber: true,
      }),
    {
      enabled: !!placeId,
    }
  );

  // Make sure that the label stays visible
  React.useEffect(() => {
    if (props.input.value?.label) {
      setSearchDisplayValue(props.input.value.label);
    }
  }, []);

  // Toggles option list
  React.useEffect(() => {
    setShowOptions(Boolean(autoCompleteOptions.data?.length));
  }, [autoCompleteOptions.data]);

  // Passes the succesfull result to the redux-form input
  React.useEffect(() => {
    if (place.data) {
      const result = {
        ...place.data,
        address: {
          ...place.data.address,
          country_name: undefined,
          secondary_subdivision: undefined,
          municipality: place.data.address.secondary_subdivision,
          lat: place.data.address.latitude,
          lng: place.data.address.longitude,
        },
      };
      props.input.onChange(result);
    }
  }, [place.data]);

  // When place id changes, so when a select happens, close options
  React.useEffect(() => {
    setShowOptions(false);
  }, [placeId]);

  // handles selecting a result
  const selectOption = (item: GeoAutocomplete): void => {
    setPlaceId(item.place_id);
    setSearchDisplayValue(item.label);
  };

  // Takes care of clicks outside of the autocomplete
  const autoCompleteWrapper = React.useRef(null);
  useClickedOutside(autoCompleteWrapper, () => {
    setShowOptions(false);
  });

  return (
    <div ref={autoCompleteWrapper}>
      <InputWrapper
        label={props.label}
        input={props.input}
        meta={props.meta}
        type={cn('autocomplete', props.input.name, {
          'input-el--autocomplete--is-loading': autoCompleteOptions.isLoading,
        })}
      >
        <label htmlFor={props.input.name}>
          <div className={'input-el--autocomplete--icon-wrapper'}>
            <IconSmile spinning={true} />
            <IconMarker />
          </div>
        </label>
        <label
          htmlFor={props.input.name}
          className={'input-el--autocomplete--clear'}
          onClick={() => {
            props.input.onChange(null);
            setSearchValue('');
            setSearchDisplayValue('');
          }}
        >
          <IconCross />
        </label>
        <input
          data-clarity-unmask="true"
          autoComplete="off"
          autoCorrect="off"
          spellCheck="false"
          id={props.input.name}
          onFocus={props.input.onFocus}
          onChange={event => {
            props.input.onChange(null);
            setSearchDisplayValue(event.target.value);
            setSearchValue(event.target.value);
          }}
          onKeyDown={e => {
            if (['ArrowUp', 'ArrowDown', 'enter'].includes(e.key)) {
              e.preventDefault();
            }
            const highlightedOrFirst = highlighted < 0 ? 0 : highlighted;
            const actions = {
              arrowUp: () => setHighlighted(highlighted < 0 ? -1 : highlighted - 1),
              arrowDown: () => setHighlighted(highlighted === options.length - 1 ? highlighted : highlighted + 1),
              enter: () => selectOption(options[highlightedOrFirst]),
              tab: () => selectOption(options[highlightedOrFirst]),
            };
            if (!actions[e.key]) {
              return;
            }
            actions[e.key]();
          }}
          value={searchDisplayValue}
        />
        <label className={'input-el--label'} htmlFor={props.input.name}>
          {props.label}
        </label>

        <div className={cn('autocomplete-list', { 'autocomplete-list--show': showOptions })}>
          {(options || []).map((item, index) => {
            return (
              <div
                key={index}
                className={cn('autocomplete-list--item', { highlighted: highlighted === index })}
                onClick={() => {
                  selectOption(item);
                }}
              >
                {item.label}
              </div>
            );
          })}
        </div>

        <InputFeedback input={props.input} meta={props.meta} error={props.meta.error} successMessage={false} />
      </InputWrapper>
    </div>
  );
};

export default LocationAutocomplete;

import { InstructionSuspicion } from '@brenger/api-client';
import { hasFloorInstruction, hasPhoneInstruction, hasTimeInstruction } from '@brenger/utils';
import { getActiveLanguageSettings } from './localization';

export const getSuspicions = (text: string): InstructionSuspicion[] => {
  /**
   * Langs to be checked
   */
  const { labels } = getActiveLanguageSettings();
  /**
   * List of suspicions
   */
  const suspicions: InstructionSuspicion[] = [];
  if (hasFloorInstruction(text, labels.textScanLangs)) {
    suspicions.push('floor_level_suspected');
  }
  if (hasTimeInstruction(text, labels.textScanLangs)) {
    suspicions.push('time_request_suspected');
  }
  if (hasPhoneInstruction(text, labels.textScanLangs)) {
    suspicions.push('phone_number_suspected');
  }
  return suspicions;
};

import cn from 'classnames';
import * as React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import '../../../assets/general-flow/footer.scss';
import {
  Button,
  Col,
  Container,
  IconArrow,
  IconPhone,
  IconSmile,
  PriceDisplay,
  Row,
  SheetLayoutFooter,
} from '../../../brenger-shared-ui';
import { RootState } from '../../../typings';
import { getActiveLanguageSettings } from '../../../utils/localization';
import { getValidDiscount } from '../../../utils/price';
import { ContactNotification } from '../components/notifications/ContactNotification';
import { DefaultSpecialPrice } from '../components/notifications/DefaultSpecialPrice';
import { ExtraCareGlassNotification } from '../components/notifications/ExtraCareGlass';
import { ExtraCareScooterNotification } from '../components/notifications/ExtraCareScooter';
import { NotGuaranteedNotification } from '../components/notifications/NotGuaranteedNotification';
import { OutsideOfServiceAreaNotification } from '../components/notifications/OutsideOfServiceAreaNotification';
import { ProductSelectionFloorService } from '../components/notifications/ProductSelectionFloorService';
import { TransportSavingsNotification } from '../components/notifications/TransportSavings';
import { TransportRequestValidationModal } from '../components/TransportRequestValidationModal';
import { actions as flowActions, getToPaymentButtonTranslation, isLoading, isSubmitDisabled } from '../ducks';
import { GeneralFlowForms } from '../interface';
import { RejectedByRouteCalculationModal } from './RejectedByRouteCalculationModal';

interface OwnProps {
  customSubmitFunction?(): void;
  customIsLoading?: boolean;
}

const FooterLayout: React.FC<ReduxType> = props => {
  const exclVat = Boolean(props.vatNumber);
  // Do we have a discount?
  const discount = getValidDiscount(props.quoteNotes || []);
  const hasSpecialPrice = discount?.type === 'percentage' || discount?.type === 'flat';
  // AB-test header
  const showAbHeader = document.body.classList.contains('show-savings');

  let headerTrans = exclVat ? 'request_flow.price.total' : 'request_flow.price.heading';
  if (showAbHeader && hasSpecialPrice) {
    headerTrans =
      discount?.type === 'percentage'
        ? 'request_flow.price.including_discount_percentage'
        : 'request_flow.price.including_discount_flat';
  }
  const langSettings = getActiveLanguageSettings();
  const withContactBar = ['de-DE'].includes(langSettings.labels.full);
  return (
    <SheetLayoutFooter>
      <Container>
        {props.transport.notifications.visible && (
          <div className={`footer-notification-bar ${props.transport.notifications.type}-notification`}>
            {/* eslint-disable-next-line @typescript-eslint/naming-convention */}
            <p dangerouslySetInnerHTML={{ __html: props.transport.notifications.message }} />
            <span onClick={props.hideNotification}>x</span>
          </div>
        )}
        <div className={cn('relative', { 'notifications--with-contact-bar': withContactBar })}>
          {/* show notification when the transport job is not guaranteed */}
          <NotGuaranteedNotification />
          {/* show notification on contact page to tell the user that the price he sees is limited to this day - ABTested */}
          <ContactNotification />
          <DefaultSpecialPrice />
          <ExtraCareScooterNotification />
          <ExtraCareGlassNotification />
          <TransportSavingsNotification />
          <OutsideOfServiceAreaNotification />
          <ProductSelectionFloorService />
        </div>
        <Row>
          {withContactBar && (
            <div className="sheet-layout--footer-contact">
              <Translate
                id={'request_flow.contact_us_by_phone'}
                data={{
                  phone: (
                    <div style={{ display: 'inline-block' }}>
                      <IconPhone />
                      <a href={`tel:${langSettings.contact.phone.customer}`}>{langSettings.contact.phone.customer}</a>
                    </div>
                  ),
                }}
              />
            </div>
          )}
          {/* error notification when we have failure price api call */}
          <Col xs={5} sm={6} extraClasses={'footer-action-bar hidden--lg-up'}>
            <div onClick={props.toggleSheet}>
              <PriceDisplay
                failedAttempts={props.transport.price.failedAttempts}
                loading={props.transport.price.isLoading}
                header={
                  <>
                    <IconSmile />
                    <Translate id={headerTrans} data={{ percentage: discount?.data }} />
                    {exclVat && (
                      <small style={{ margin: '0 0 0 .3em', display: 'inline-block' }}>
                        <Translate id={'request_flow.price.ex_vat'} />
                      </small>
                    )}
                  </>
                }
                exclVat={exclVat}
                quotePrice={props.quotePrice}
                quoteNotes={props.quoteNotes}
              />
            </div>
          </Col>
          <Col xs={7} sm={6} extraClasses={'offset-lg-6'}>
            <Button
              onClick={props.customSubmitFunction}
              dataQaId="continue-general-flow"
              loading={props.isLoading}
              disabled={props.isSubmitDisabled}
              buttonStyle={'secondary'}
              iconPos={'right'}
              fullWidth={true}
            >
              <Translate id={props.nextButtonTrans} />
              <IconArrow />
            </Button>
            <RejectedByRouteCalculationModal />
            <TransportRequestValidationModal />
          </Col>
        </Row>
      </Container>
    </SheetLayoutFooter>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  transport: state.generalTransport,
  form: state.form[GeneralFlowForms.DELIVERY],
  isLoading: ownProps.customIsLoading || isLoading(state),
  nextButtonTrans: getToPaymentButtonTranslation(state),
  isSubmitDisabled: ownProps.customIsLoading || isSubmitDisabled(state),
  quotePrice: state.generalTransport.quote?.price,
  quoteNotes: state.generalTransport.quote?.notes,
  vatNumber: state.generalTransport.contact.customer?.company_details?.vat_number,
  customSubmitFunction: ownProps.customSubmitFunction,
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: Dispatch) => ({
  toggleSheet: () => dispatch(flowActions.toggleSheet()),
  hideNotification: () => dispatch(flowActions.hideNotification()),
});

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(FooterLayout);

import * as React from 'react';
import classnames from 'classnames';
import './pagination.scss';
import { List } from '@brenger/api-client';
import { paginationControls } from '../../../../utils/request';

interface Props {
  list: List<unknown> | null | undefined;
  goToPage(page: number): void;
}

const Pagination: React.FC<Props> = ({ list, goToPage }) => {
  if (!list) {
    return null;
  }
  const controls = paginationControls.getCoreControls(list);
  if (!controls.isPaginated || controls.totalPageItems < 1) {
    return null;
  }
  return (
    <div className={'pagination'}>
      {new Array(controls.totalPageItems).map((_, i) => {
        const pageNumber = i + 1;
        return (
          <button
            type={'button'}
            key={i}
            className={classnames('pagination--item', {
              'pagination--item--active': controls.currentPageNumber === pageNumber,
            })}
            onClick={() => goToPage(pageNumber)}
          >
            {pageNumber}
          </button>
        );
      })}
    </div>
  );
};

export default Pagination;

import React from 'react';
import { Field } from 'redux-form';
import { PersonSchema } from '../schema';
import { UserProperties } from '../typings';
import { RfInput } from '../../../brenger-shared-ui';
import { getActiveLanguageSettings, translate } from '../../../utils/localization';
import { Translate } from 'react-localize-redux-dep-updated';

export const PersonalDetails = (): JSX.Element => {
  return (
    <>
      <Field
        required
        validate={PersonSchema[UserProperties.FIRST_NAME]}
        component={RfInput}
        type="text"
        name={'user.first_name'}
        label={translate('form.fields.user.first_name.label')}
      />
      <Field
        required
        validate={PersonSchema[UserProperties.LAST_NAME]}
        component={RfInput}
        type="text"
        name={'user.last_name'}
        label={translate('form.fields.user.last_name.label')}
      />
      <Field
        required
        validate={PersonSchema[UserProperties.EMAIL]}
        component={RfInput}
        type="email"
        name={'user.email'}
        label={translate('form.fields.user.email.label')}
      />
      <Field
        required
        validate={PersonSchema[UserProperties.PASSWORD]}
        component={RfInput}
        type="password"
        name={'user.new_password'}
        label={translate('form.fields.user.password.label')}
      />
      <Field
        // required
        validate={PersonSchema[UserProperties.PHONE]}
        component={RfInput}
        type="number"
        name={'user.phone'}
        label={translate('form.fields.user.phone.label')}
      />
      <Field
        required
        name={'user.termsofuse'}
        type={'checkbox'}
        validate={PersonSchema[UserProperties.TERMSOFUSE]}
        label={translate('request_flow.delivery.agree_general_terms', {
          condition_link: (
            <a
              href={`https://www.brenger.nl/${getActiveLanguageSettings().labels.contentSite}/terms`}
              target={'_blank'}
              rel="noreferrer"
            >
              <Translate id={'request_flow.delivery.general_terms'} />
            </a>
          ),
        })}
        component={RfInput}
      />
    </>
  );
};

export default PersonalDetails;

import { getLocation } from 'connected-react-router';
import React, { ReactNode } from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { Field } from 'redux-form';
import {
  FlashMessage,
  Heading,
  IconEcoFriendly,
  RfInputTiles,
  SheetLayoutContent,
  SheetLayoutHeader,
} from '../../../brenger-shared-ui';
import { useTypedSelector } from '../../../hooks';
import { RootState, StopType } from '../../../typings';
import { formatDate, printDatesAsString } from '../../../utils/datetime';
import { TIME_SLOT_LENGTH } from '../../../utils/global';
import { translate } from '../../../utils/localization';
import { priceAsString } from '../../../utils/price';
import { getFlowTitleByStepAndSituation, getStopAvailableDate, getStopSituation, getStopTimeOptions } from '../ducks';
import { pickupDeliverySchema } from '../schema';
import { TimeCustomOptionsBusinessHours } from './TimeCustomOptionsBusinessHours';
import { TimeCustomOptionsList } from './TimeCustomOptionsList';
import cn from 'classnames';
import { r } from '../../../routes';
import { proceed } from '../proceed';

export interface TimeOptionTile {
  value: {
    start: string | null;
    end: string | null;
  };
  title: string;
  suffix: string | ReactNode;
  desc?: string;
}

export const SubStepTime: React.FC = () => {
  // get state
  const { pathname } = useTypedSelector(getLocation);
  const stopType = pathname === r.generalFlow.time.index() ? StopType.PICKUP : StopType.DELIVERY;
  // The date is also included in the time options, but we use the selector more convenient,
  // and more correct for the user has selected two dates.
  const selectedDate = useTypedSelector((state: RootState) => getStopAvailableDate(state, stopType));
  // Get options
  const optionsFull = useTypedSelector((state: RootState) => getStopTimeOptions(state, stopType, 'full'));
  // situation
  const situation = useTypedSelector((state: RootState) => getStopSituation(state, stopType));
  const isPickupAndAuctionOrStore = stopType === 'pickup' && (situation === 'auction' || situation === 'store');

  // cosmetics
  const title = useTypedSelector((state: RootState) =>
    getFlowTitleByStepAndSituation(state, stopType === 'pickup' ? 'timePickup' : 'timeDelivery')
  );
  const subTitle = useTypedSelector((state: RootState) =>
    getFlowTitleByStepAndSituation(state, stopType === 'pickup' ? 'timeCustomPickup' : 'timeCustomDelivery')
  );
  const error = useTypedSelector((state: RootState) => state.generalTransport.error);

  // Get the full time slots
  const optionsFullTiles: TimeOptionTile[] = optionsFull
    /**
     * Simple sort on price change, so that the cheapest option is the first one
     */
    .sort((a, b) => (a.price_change?.incl_vat.amount || 0) - (b.price_change?.incl_vat.amount || 0))
    .map(option => {
      const isMostBenificial = optionsFull.length === 1 || (option.price_change?.incl_vat.amount ?? 0) < 0;
      return {
        value: {
          start: option.start,
          end: option.end,
        },
        title: String(
          translate('request_flow.time.first_option.label_' + situation, {
            start: formatDate(option.start || '', 'hour-minute'),
            end: formatDate(option.end || '', 'hour-minute'),
          })
        ),
        suffix: (
          <span className={cn({ 'text--yellow': !isMostBenificial, 'text--green': isMostBenificial })}>
            {isMostBenificial && <IconEcoFriendly />}
            {priceAsString({ price: { amount: option.price_change?.incl_vat.amount } })}
          </span>
        ),
      };
    });

  return (
    <>
      <SheetLayoutHeader>
        <Heading size={2}>
          <Translate
            id={title}
            data={{
              date: <span className={'text--primary'}>{printDatesAsString(selectedDate)}</span>,
            }}
          />
        </Heading>
      </SheetLayoutHeader>
      <SheetLayoutContent>
        <div className={'question-wrapper'}>
          {optionsFull.length !== 0 && (
            <>
              <div className={'pb-1'}>
                <div className="text--bold pb-0-5">
                  <Translate id={'request_flow.time.first_option.introduction_' + situation} />
                </div>
                <Field
                  compact={!isPickupAndAuctionOrStore}
                  name={`${stopType}`}
                  validate={pickupDeliverySchema.time}
                  options={optionsFullTiles}
                  component={RfInputTiles}
                  onChangeCallback={proceed}
                />
              </div>
              <div className="pb-0-5">
                <div className="text--bold">
                  <Translate id={subTitle} />
                </div>
                {isPickupAndAuctionOrStore && (
                  <div>
                    <Translate
                      id={'request_flow.time.custom_time_explain'}
                      data={{
                        time_slot: TIME_SLOT_LENGTH,
                      }}
                    />
                  </div>
                )}
              </div>
            </>
          )}
          {isPickupAndAuctionOrStore && <TimeCustomOptionsBusinessHours />}
          {!isPickupAndAuctionOrStore && <TimeCustomOptionsList stopType={stopType} />}
          {error && (
            <div className={'general-errors-wrapper'}>
              <FlashMessage type={'error'} message={error} />
            </div>
          )}
        </div>
      </SheetLayoutContent>
    </>
  );
};

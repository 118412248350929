import React from 'react';
import { connect } from 'react-redux';
import { getFormValues, InjectedFormProps, reduxForm } from 'redux-form';

import { FlashMessage, SheetLayoutContent, SheetLayoutContentWrapper } from '../../../brenger-shared-ui';
import { FieldNamesProductPayment, GeneralFlowForms } from '../interface';
import { RootState } from '../../../typings';
import { actions } from '../ducks';
import FooterLayout from './FooterLayout';
import { Route, Switch } from 'react-router';
import { ProductPaymentOptIn } from '../components/ProductPaymentOptIn';
import { ProductPaymentAmount } from '../components/ProductPaymentAmount';
import { Dispatch } from 'redux';
import { r } from '../../../routes';

export interface ProductPaymentFormValues {
  [FieldNamesProductPayment.OPT_IN]?: boolean;
  [FieldNamesProductPayment.AMOUNT]?: string; // yes, somehow redux form saves the value as string
}

type ReduxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

type Form = InjectedFormProps<ProductPaymentFormValues, ReduxProps>;

type Props = ReduxProps & Form;

export const getProductPaymentFormValues = (state: RootState): undefined | ProductPaymentFormValues => {
  return getFormValues(GeneralFlowForms.PRODUCT_PAYMENT)(state) as undefined | ProductPaymentFormValues;
};

const ProductPayment: React.FC<Props> = ({ setProgress, errors, handleSubmit }) => {
  React.useEffect(() => {
    setProgress();
  }, []);

  return (
    <SheetLayoutContentWrapper>
      <form onSubmit={handleSubmit}>
        <Switch>
          <Route exact={true} path={r.generalFlow.productPayment.index()} component={ProductPaymentOptIn} />
          <Route exact={true} path={r.generalFlow.productPayment.amount()} component={ProductPaymentAmount} />
        </Switch>
        {errors && (
          <SheetLayoutContent>
            <div className={'general-errors-wrapper'}>
              <FlashMessage type={'error'} message={errors} />
            </div>
          </SheetLayoutContent>
        )}
        <FooterLayout />
      </form>
    </SheetLayoutContentWrapper>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState) => ({
  errors: state.generalTransport.error,
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: Dispatch) => ({
  setProgress: () => dispatch(actions.setProgress(3)),
});

export default reduxForm<ProductPaymentFormValues, Props>({
  form: GeneralFlowForms.PRODUCT_PAYMENT,
  destroyOnUnmount: false,
  onSubmit: (values: ProductPaymentFormValues, dispatch) => {
    dispatch(actions.submitForm(GeneralFlowForms.PRODUCT_PAYMENT, values));
  },
})(connect(mapStateToProps, mapDispatchToProps)(ProductPayment));

import { Account, Address, CountryCode, User as IUser } from '@brenger/api-client';
import { Nullable } from '../../typings';

export enum UserActionTypes {
  CREATE_ACCOUNT_INIT = 'USER/CREATE_ACCOUNT_INIT',
  SOCIAL_AUTH_START = 'USER/SOCIAL_AUTH_START',
  SOCIAL_AUTH_SUCCESS = 'USER/SOCIAL_AUTH_SUCCESS',
  SOCIAL_AUTH_FAILED = 'USER/SOCIAL_AUTH_FAILED',
  REGISTER_USER_START = 'USER/REGISTER_USER_START',
  REGISTER_USER_SUCCESS = 'USER/REGISTER_USER_SUCCESS',
  REGISTER_USER_FAILED = 'USER/REGISTER_USER_FAILED',
  REGISTER_USER_ERROR_RESET = 'USER/REGISTER_USER_ERROR_RESET',
  LOGIN_USER_START = 'USER/LOGIN_USER_START',
  LOGIN_USER_SUCCESS = 'USER/LOGIN_USER_SUCCESS',
  LOGIN_USER_FAILED = 'USER/LOGIN_USER_FAILED',
  RESET_PASS_START = 'USER/RESET_PASS_START',
  RESET_PASS_SUCCESS = 'USER/RESET_PASS_SUCCESS',
  RESET_PASS_FAILED = 'USER/RESET_PASS_FAILED',
  NEW_PASS_START = 'USER/NEW_PASS_START',
  NEW_PASS_SUCCESS = 'USER/NEW_PASS_SUCCESS',
  NEW_PASS_FAILED = 'USER/NEW_PASS_FAILED',
  SET_NEW_PASS_TOKEN = 'USER/SET_NEW_PASS_TOKEN',
  SET_USER_DETAILS = 'USER/SET_USER_DETAILS',
  SET_ACCOUNT_TYPE = 'USER/SET_ACCOUNT_TYPE',
  SET_WHAT_IS_MY_COUNTRY = 'USER/SET_WHAT_IS_MY_COUNTRY',
  RETRIEVE_CURRENT_USER = 'USER/RETRIEVE_CURRENT_USER',
  RETRIEVE_CURRENT_COUNTRY = 'USER/RETRIEVE_CURRENT_COUNTRY',
  SET_ACCOUNT_CONFIG = 'USER/SET_ACCOUNT_CONFIG',
  SET_BILLING_ADDRESS = 'USER/SET_BILLING_ADDRESS',
}

export enum UserErrorTitles {
  USERNAME_EXIST = 'username is taken',
}

export enum UserProperties {
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
  PASSWORD = 'password',
  EMAIL = 'email',
  PHONE = 'phone',
  TERMSOFUSE = 'termsofuse',
}

export enum Company {
  NAME = 'name',
  LINE1 = 'line1',
  COUNTRY = 'country',
  POSTAL_CODE = 'postal_code',
  LOCALITY = 'locality',
  COC_NUMBER = 'coc_number',
  VAT_NUMBER = 'vat_number',
}

export enum UserForms {
  REGISTER = '@user/register',
  LOGIN = '@user/login',
  PASSWORD_NEW = '@user/password_new',
  PASSWORD_RESET = '@user/password_reset',
}

export interface PasswordReset {
  loading: boolean;
  error: string;
  success: boolean;
}

export interface PasswordNew {
  token: string;
  loading: boolean;
  error: string;
  success: boolean;
}

export interface UserDetails {
  first_name: string;
  last_name: string;
  password: string;
  email: string;
  phone: string;
  account: Account;
}
export interface User {
  id: Nullable<string>;
  fetching: boolean;
  loggedIn: boolean;
  loggedInMethod: Nullable<string>;
  userData: Nullable<IUser>;
  billingAddress: Nullable<Address>;
  errors: string;
  details: UserDetails | null;
  loading: boolean;
  registered: boolean;
  isBusiness: boolean;
  what_is_my_country: CountryCode;
  password_reset: PasswordReset;
  password_new: PasswordNew;
}

import * as React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { Button, Dialog, Modal } from '../../../brenger-shared-ui';
import van from '../../../brenger-shared-ui/assets/images/van@2x.png';
import { useTypedSelector } from '../../../hooks';
import { RootState } from '../../../typings';
import { proceed } from '../proceed';
import { actions, getFirstAndLastFlexDates } from '../ducks';
import { formatDate } from '../../../utils/datetime';
import { useDispatch } from 'react-redux';
import { translate } from '../../../utils/localization';

export const FlexDatesModal: React.FC = () => {
  const isActive = useTypedSelector((state: RootState) => state.generalTransport.layout.modal_flex_dates);
  const dates = useTypedSelector(getFirstAndLastFlexDates);
  const dispatch = useDispatch();

  return (
    <Dialog isActive={isActive}>
      <Modal onlyToggleByButton={true} open={isActive} style={'white'} size={'small'}>
        <div className={'time-pref-modal'}>
          <div className={'text--center pb-1'}>
            <div className={'text--primary text--bold'}>
              <Translate id={'request_flow.flex_dates.modal.title_top'} />
            </div>
            <img className={'van-assets-image'} src={van} />
          </div>
          <div
            className="pb-1"
            dangerouslySetInnerHTML={{
              // eslint-disable-next-line @typescript-eslint/naming-convention
              __html: translate('request_flow.flex_dates.modal.explain', {
                first_date: `<span class="text--primary text--bold">${formatDate(
                  new Date(dates?.first || ''),
                  'day-month-long'
                )}</span>`,
                last_date: `<span class="text--primary text--bold">${formatDate(
                  new Date(dates?.last || ''),
                  'day-month-long'
                )}</span>`,
              }),
            }}
          />
          <Button
            buttonStyle={'secondary'}
            fullWidth={true}
            onClick={() => {
              proceed();
              dispatch(actions.setShowFlexDatesModal(false));
            }}
          >
            <Translate id={'request_flow.time.modal.ok'} />
          </Button>
        </div>
      </Modal>
    </Dialog>
  );
};

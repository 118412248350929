import * as React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { Button, Dialog, Modal } from '../../../../brenger-shared-ui';
import { useTypedSelector } from '../../../../hooks';
import { SessionStorageKeys, StopType } from '../../../../typings';
import { getStopInstructionSuspicion } from '../../ducks';

interface Props {
  stopType: StopType;
}

export const ContactSuspicionModal: React.FC<Props> = ({ stopType }) => {
  const suspicions = useTypedSelector(state => getStopInstructionSuspicion(state, stopType));
  const [isModalActive, setIsModalActive] = React.useState(false);
  const storageKey =
    stopType === StopType.PICKUP
      ? SessionStorageKeys.INSTRUCTION_SUS_PICKUP
      : SessionStorageKeys.INSTRUCTION_SUS_DELIVERY;
  const isFloorLevelServiceAvailable =
    useTypedSelector(state => state.generalTransport.quote?.service_advisory?.floor_service) !== 'not_available';

  React.useEffect(() => {
    const susString = JSON.stringify(suspicions);
    if (susString === sessionStorage.getItem(storageKey)) {
      setIsModalActive(false);
      return;
    }
    const shouldBeActive = Boolean(suspicions.length);
    if (shouldBeActive) {
      sessionStorage.setItem(storageKey, susString);
    }
    setIsModalActive(Boolean(suspicions.length));
  }, [suspicions.join(',')]);

  return (
    <Dialog isActive={isModalActive}>
      <Modal
        open={isModalActive}
        header={<Translate id="request_flow.instruction_suspicion.modal_title" />}
        onClick={() => setIsModalActive(false)}
      >
        <div>
          <Translate id="request_flow.instruction_suspicion.modal_body" />
        </div>
        <ul>
          {suspicions.map((suspicion, i) => {
            if (suspicion === 'floor_level_suspected' && !isFloorLevelServiceAvailable) {
              return (
                <li key={i}>
                  <Translate id={`request_flow.instruction_suspicion.floor_level_suspected_no_service`} />
                </li>
              );
            }
            return (
              <li key={i}>
                <Translate id={`request_flow.instruction_suspicion.${suspicion}`} />
              </li>
            );
          })}
        </ul>
        <p>
          <Translate id="request_flow.instruction_suspicion.modal_footer" />
        </p>
        <div className="text--center">
          <Button buttonStyle="secondary" onClick={() => setIsModalActive(false)}>
            <Translate id="request_flow.instruction_suspicion.button" />
          </Button>
        </div>
      </Modal>
    </Dialog>
  );
};

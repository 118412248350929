import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';
import { Dispatch } from 'redux';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { SheetLayoutContentWrapper } from '../../../brenger-shared-ui';
import { RootState } from '../../../typings';
import SubStepFloor from '../components/SubStepHomeFloorCombined';
import { actions as flowActions, getDeliveryStep } from '../ducks';
import { GeneralFlowForms } from '../interface';
import FooterLayout from './FooterLayout';
import { r } from '../../../routes';

// FIX ME: Inject a proper form value interface
type FormValues = Record<string, unknown>;

type ReduxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

type Form = InjectedFormProps<FormValues, ReduxProps>;

type Props = ReduxProps & Form;

class Delivery extends React.Component<Props> {
  public componentDidMount(): void {
    this.props.setProgress(this.props.step);
  }
  public render(): JSX.Element {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <SheetLayoutContentWrapper>
          <Route exact={true} path={r.generalFlow.delivery.floor()} component={SubStepFloor} />
          <FooterLayout />
        </SheetLayoutContentWrapper>
      </form>
    );
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState) => ({
  step: getDeliveryStep(state),
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: Dispatch) => ({
  setProgress: step => dispatch(flowActions.setProgress(step)),
});

export default reduxForm<FormValues, Props>({
  form: GeneralFlowForms.DELIVERY,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  onSubmit: (formResults, dispatch) => {
    dispatch(flowActions.submitDelivery(formResults));
  },
})(connect(mapStateToProps, mapDispatchToProps)(Delivery));

import * as React from 'react';
import { InputProps } from '../input/Input';
import InputFeedback from '../input/InputFeedback';
import InputWrapper from '../input/InputWrapper';
import './inputnumber.scss';

interface Custom {
  minVal: boolean | number;
}

type InputNumberProps = Custom & InputProps;

const InputNumber = ({
  input,
  meta,
  label,
  type,
  successMessage,
  minVal,
  className,
  required,
}: InputNumberProps): JSX.Element => {
  if (minVal && minVal > input.value) {
    input.onChange(Number(minVal));
  }
  return (
    <div className={'input-el--number-outer'}>
      <label
        htmlFor={input.name}
        className={
          'input-el--number--modify input-el--number--modify-decrease' +
          (Number(input.value) === minVal ? ' is-disabled' : '')
        }
        onClick={Number(input.value) === minVal ? undefined : () => input.onChange(Number(input.value) - 1)}
      />

      <InputWrapper label={label} input={input} meta={meta} type={'number'}>
        <input
          className={className}
          id={input.name}
          type={type}
          {...input}
          value={input.value}
          data-clarity-unmask="true"
        />
        <label className={'input-el--label' + (required ? ' input-el--label--required' : '')} htmlFor={input.name}>
          {label}
        </label>
        <InputFeedback input={input} meta={meta} error={meta.error} successMessage={successMessage} />
      </InputWrapper>
      <label
        htmlFor={input.name}
        className={'input-el--number--modify input-el--number--modify-increase'}
        onClick={() => input.onChange(Number(input.value) + 1)}
      />
    </div>
  );
};

export default InputNumber;

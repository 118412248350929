import { Price, QuoteNote } from '@brenger/api-client';
import theNetherlands from '../assets/images/nl.jpg';
import { getActiveLanguageSettings } from './localization';

interface PriceAsString {
  price?: Partial<Price>;
  addPlusMinusIndicator?: boolean;
  withDoubleZeroes?: boolean;
}

export const priceAsString = ({
  price,
  addPlusMinusIndicator = true,
  withDoubleZeroes = false,
}: PriceAsString): string => {
  let amount = price?.amount || 0;

  // When the amount is positive and we want a plus indicator, we need to add it manually
  const indicator = amount >= 0 ? '+' : '-';

  // When the amount is negative, we don't want another "minus" popup, so we need to multiply the amount by -1
  if (amount < 0) {
    amount = amount * -1;
  }

  // get locale settings
  const {
    labels: { short, countryCode, currency },
  } = getActiveLanguageSettings();

  // Check if we need to print only round numbers
  const priceArray = centsToPriceArray(amount);
  // We print round numbers when decimals are 00 and we don't want to explicitly print that
  const showRoundNumbers = priceArray[1] === '00' && !withDoubleZeroes;

  // When max Significant Digits is set, it only prints decimals if there are any, when not set it prints decimals according to currency style
  // Set up international number formatting
  let formattedAmount = new Intl.NumberFormat(`${short}-${countryCode}`, {
    style: 'currency',
    currency: price?.currency || currency,
  }).format(amount / 100);

  // Previous way of stripping with significant digits gave a weird result, it round 235,- to 240,-
  if (showRoundNumbers) {
    formattedAmount = formattedAmount.slice(0, -3);
  }

  // Do we want an indicator?
  // Yes please
  if (addPlusMinusIndicator) {
    return `${indicator} ${formattedAmount}`;
  }
  // No, just the formatted amount please
  return formattedAmount;
};

/**
 * This function returns
 */
export const centsToPriceArray = (cents: number): string[] => {
  if (cents === 0) {
    return ['0', '00'];
  }
  return (cents / 100).toFixed(2).split('.');
};

// Util to convert current price into old price based on discount amount
export const getOldPrice = (price: Partial<Price>, discount: number): string => {
  const oldPrice = {
    amount: (price?.amount || 0) / (1 - discount / 100),
    currency: price.currency,
  };
  return priceAsString({ price: oldPrice, addPlusMinusIndicator: false });
};

// A list of discounts that this notification cares about
type SpecialPriceType = 'percentage' | 'flat' | 'other';

interface Discount {
  note: QuoteNote;
  type: SpecialPriceType;
  icon?: string;
  svg?: 'circle-person';
  data?: number;
}
// The order of these discounts matter, first match in this list will be served by the `getValidDiscount()` below
const validDiscounts: Array<Discount> = [
  { note: 'remote_20_percent_campaign', type: 'percentage', data: 10, icon: theNetherlands },
  { note: 'flanders_2020_apr', type: 'percentage', data: 10, icon: theNetherlands },
  { note: 'belgium_2020_apr', type: 'percentage', data: 10, icon: theNetherlands },
  { note: 'zeeland_2020_apr', type: 'percentage', data: 10, icon: theNetherlands },
  { note: 'zeeland_tunnel_incentive', type: 'other', icon: theNetherlands },
  // Default 10% discount
  { note: 'flex_account_15_percent_campaign', type: 'percentage', data: 15, svg: 'circle-person' },
  { note: 'nl_10_percent_campaign', type: 'percentage', data: 10, svg: 'circle-person' },
  { note: 'be_10_percent_campaign', type: 'percentage', data: 10, svg: 'circle-person' },
  { note: 'es_10_percent_campaign', type: 'percentage', data: 10, svg: 'circle-person' },
  { note: 'fr_10_percent_campaign', type: 'percentage', data: 10, svg: 'circle-person' },
  { note: 'de_10_percent_campaign', type: 'percentage', data: 10, svg: 'circle-person' },
];

export const getValidDiscount = (notes: QuoteNote[]): Discount | undefined =>
  validDiscounts.find(({ note }) => notes.includes(note));

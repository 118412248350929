/*
/* BRENGER NORMAILISATION UTILS
/* ================================================== */

import { getCountryCode } from '../modules/GeneralFlow/schema';

export const normalizePostalCode = (value: string, previousValue: string, allValues): string => {
  value = value.trim();
  if (!value) {
    return value;
  }
  const countryCode = getCountryCode(allValues);
  if (countryCode !== 'NL') {
    return value;
  }
  return normalizeDutchPostalCode(value);
};

export const normalizeDutchPostalCode = (value: string): string => {
  const lastTwoCharacters = value.slice(-2).toUpperCase().replace(' ', '');
  const firstPart = value.substr(0, value.length - 2).replace(' ', '');
  if (!lastTwoCharacters.match(/[a-z]{2}/i) || !firstPart.match(/[0-9]{4}/i)) {
    /* If we don't have a matching pattern, return the value untouched */
    return value;
  }
  return `${firstPart} ${lastTwoCharacters}`;
};

export const normalizeFirstLetterCaps = (value: string): string => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const normalizeOnlyLettersNumbersInCaps = (value: string): string => {
  return value
    .trim()
    .replace(/[^a-zA-Z0-9]+/g, '')
    .toUpperCase();
};

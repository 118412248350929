import React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { Field } from 'redux-form';

import {
  FlashMessage,
  Heading,
  IconAuction,
  IconSmile,
  ImageCdn,
  RfInputTiles,
  SheetLayoutContent,
  SheetLayoutHeader,
} from '../../../brenger-shared-ui';
import { useTypedSelector } from '../../../hooks';
import { RootState } from '../../../typings';
import { getActiveLanguageSettings, translate } from '../../../utils/localization';
import { AuctionTypes, SubStepFieldNamesPickup } from '../interface';
import { pickupDeliverySchema } from '../schema';
import '../../../assets/auctions/auctions.scss';
import { proceed } from '../proceed';

export const SubStepAuctionTypes: React.FC = () => {
  const error = useTypedSelector((state: RootState) => state.generalTransport.error);
  const otherOption = {
    value: AuctionTypes.OTHERS,
    title: String(translate('request_flow.situation.other')),
    suffix: <IconSmile />,
    prefix: <IconAuction />,
  };

  const options = {
    de: [
      {
        value: AuctionTypes.LOT_TISSIMO,
        title: 'Lot-Tissimo',
        suffix: <IconSmile />,
        prefix: <ImageCdn src={'partners/lot-tissimo.jpg'} height={30} width={30} alt={'Lot Tissimo'} />,
      },
      {
        value: AuctionTypes.JUSTIZ_AUKTION,
        title: 'Justiz Auktion',
        suffix: <IconSmile />,
        prefix: <ImageCdn src={'partners/Justiz-Auktion.png'} height={30} width={30} alt={'Justiz Auktion'} />,
      },
    ],
  }[getActiveLanguageSettings().labels.short] || [
    {
      value: AuctionTypes.TROOSTWIJK,
      title: 'Troostwijk',
      suffix: <IconSmile />,
      prefix: <ImageCdn src={'partners/troostwijk-logo.svg'} height={30} width={30} alt={'Troostwijk'} />,
    },
    {
      value: AuctionTypes.ONLINEVEILINGMEESTERS,
      title: 'Online Veilingmeester',
      suffix: <IconSmile />,
      prefix: <ImageCdn src={'partners/onlineveiling.png'} height={30} width={30} alt={'Online Veilingmeester'} />,
    },
    {
      value: AuctionTypes.VAVATO,
      title: 'Vavato',
      suffix: <IconSmile />,
      prefix: <ImageCdn src={'partners/vavato.png'} height={30} width={30} alt={'Vavato'} />,
    },
    {
      value: AuctionTypes.BELGA,
      title: 'Belga',
      suffix: <IconSmile />,
      prefix: <ImageCdn src={'partners/belga.jpg'} height={30} width={30} alt={'Belga'} />,
    },
  ];
  return (
    <>
      <SheetLayoutHeader>
        <Heading size={2}>
          <Translate id={'request_flow.auction.select_auction'} />
        </Heading>
      </SheetLayoutHeader>
      <SheetLayoutContent>
        <div className={'auction-types question-wrapper'}>
          <Field
            name={SubStepFieldNamesPickup.AUCTION_TYPE}
            type="select"
            validate={pickupDeliverySchema.auction_type}
            options={[...options, otherOption]}
            component={RfInputTiles}
            onChangeCallback={proceed}
          />
        </div>
        <div className={'general-errors-wrapper'}>
          {error && error !== '' && <FlashMessage type={'error'} message={error} />}
        </div>
      </SheetLayoutContent>
    </>
  );
};

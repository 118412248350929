import { IconArrowLeft, IconSearch } from '@brenger/react';
import cn from 'classnames';
import { getLocation } from 'connected-react-router';
import React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { useDispatch } from 'react-redux';
import { matchPath } from 'react-router';
import { Button, IconCross } from '../../../../brenger-shared-ui';
import { useTypedSelector } from '../../../../hooks';
import { translate } from '../../../../utils/localization';
import { actions } from '../../ducks';
import { InputText } from '../../../../brenger-shared-ui/';
import { r } from '../../../../routes';

interface Props {
  backHandler(): void;
  closeHandler(): void;
  autoFocus: boolean;
}

export const PS_SEARCH_ID = 'ps-search-input';

export const ItemAddHead: React.FC<Props> = ({ closeHandler, backHandler, autoFocus }) => {
  const dispatch = useDispatch();
  const queryVal = useTypedSelector(state => state.generalTransport.product_selection.query);
  const { pathname } = useTypedSelector(getLocation);
  const match = matchPath<{ iri: string }>(pathname, r.generalFlow.items.configure());
  return (
    <>
      <div className="ps-search--desk-head">
        <IconArrowLeft
          onClick={backHandler}
          height={'1.4em'}
          width={'1.4em'}
          className={cn('text--primary', { hide: !match?.params.iri })}
        />
        {/* Placeholder for when the back arrow is not present */}
        <div style={{ height: '1.4em', width: '1.4em' }} className={cn({ hide: !!match?.params.iri })} />
        <b>
          <Translate id="request_flow.product_selection.add_items" />
        </b>{' '}
        <IconCross onClick={closeHandler} />
      </div>
      <div className="ps-search--head flex flex--vc ps-modal--section bordered bp">
        <Button
          type="button"
          buttonStyle="transparant"
          onClick={backHandler}
          extraClasses="ps-modal--back text--primary"
        >
          <IconArrowLeft height={'1.4em'} width={'1.4em'} style={{ marginBottom: '-.2em' }} />
        </Button>
        <IconSearch
          className={'ps-search--icon-mag'}
          style={{ color: '#7C90A8', marginRight: '.5em', marginBottom: '-0.1em' }}
        />
        <InputText
          data-qa-id="ps-search-input"
          data-clarity-unmask="true"
          autoFocus={autoFocus}
          autoComplete={'off'}
          id={PS_SEARCH_ID}
          type={'search'}
          onChange={val => dispatch(actions.setQueryPs(val))}
          value={queryVal || ''}
          placeholder={translate('request_flow.product_selection.search_item_name')}
          className={'ps-search--input'}
        />
        {queryVal && (
          <div onClick={() => dispatch(actions.setQueryPs(null))} className={'ps-search--input-clear'}>
            <IconCross />
          </div>
        )}
      </div>
    </>
  );
};

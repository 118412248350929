import { GeoAutocomplete, GeoPointShort, GeoLocationDetails, RouteMeta } from '@brenger/api-client';
import { routePlannerClient, routePlannerClientToken } from './request';
import { normalizeDutchPostalCode } from './normalisation';

export function calculateRoute(pickup: GeoPointShort, delivery: GeoPointShort): Promise<RouteMeta> {
  return routePlannerClient.geo.retrieveRoute({ pickup, delivery });
}

export function getGeoAutocomplete(query: string): Promise<GeoAutocomplete[]> {
  return routePlannerClient.geo.retrieveAutocomplete({ sessionToken: routePlannerClientToken, query });
}

export const retrieveLocationDetails = async (
  item: GeoAutocomplete,
  includeHouseNumber?: boolean
): Promise<GeoLocationDetails> => {
  return routePlannerClient.geo.retrieveLocationDetails({
    sessionToken: routePlannerClientToken,
    placeId: item.place_id,
    includeHouseNumber,
  });
};

export const filterGeoResultsOnPostalCode = (
  results: GeoLocationDetails[],
  postalCode: string
): GeoLocationDetails[] => {
  const strippedPostalCode = postalCode.replace(' ', '');
  const normalizedPostalCode = normalizeDutchPostalCode(postalCode);
  return results.filter(result => {
    const hasIncompleteDutchPostalCode =
      result.address.country_code === 'NL' && /^\d+$/.test(result.address.postal_code || '');
    if (hasIncompleteDutchPostalCode) {
      return (
        strippedPostalCode.includes(result.address.postal_code || '') ||
        normalizedPostalCode.includes(result.address.postal_code || '')
      );
    }
    return (
      (result.address.postal_code || '').includes(strippedPostalCode) ||
      (result.address.postal_code || '').includes(normalizedPostalCode)
    );
  });
};
export const retrieveCachedLocationDetails = async (
  query: string,
  filter: string
): Promise<GeoLocationDetails | null> => {
  const storage = sessionStorage.getItem('brenger_geo_cache');
  const cache = storage ? (JSON.parse(storage) as { [key: string]: GeoLocationDetails }) : {};
  let result = undefined as GeoLocationDetails | undefined;
  if (Object.keys(cache).length > 0) {
    result = cache[query];
  }
  if (result) {
    return result;
  }
  const geo = await getGeoAutocomplete(`${query}`);
  if (geo.length === 0) {
    return null;
  }
  const geoFilteredResult = geo.find(filterResult => filterResult.label.includes(filter)) || geo[0];
  result = await retrieveLocationDetails(geoFilteredResult);
  if (!result) {
    return null;
  }
  sessionStorage.setItem(
    'brenger_geo_cache',
    JSON.stringify({
      ...cache,
      [query]: result,
    })
  );
  return result;
};

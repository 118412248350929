import { ProductSelectionResult } from '@brenger/api-client';
import axios, { CancelTokenSource } from 'axios';
import React from 'react';

import { logError } from '../../utils/basics';
import { getActiveLanguageSettings } from '../../utils/localization';
import { priceClient } from '../../utils/request';
import { IconSmile, Label } from '../../brenger-shared-ui';

/**
 * This tool is mainly for content / marketing purposes.
 * It makes it easy to lookup PS group IDs that they can use for prefilling item step
 */
export const PsTool: React.FC = () => {
  const [queryVal, setQueryVal] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [results, setResults] = React.useState<(ProductSelectionResult & { label: string })[]>([]);

  const locale: string = getActiveLanguageSettings().labels.full.replace('-', '_');

  React.useEffect(() => {
    search(queryVal);
  }, [queryVal]);

  const search = async (query: string): Promise<void> => {
    if (query.length < 3) return;
    setLoading(true);
    try {
      const groups = await productSelectionSearch(query);
      const groupsWithLabels = groups.map(group => {
        return {
          ...group,
          label: group.name[locale],
        };
      });
      // save results
      setResults(groupsWithLabels);
      setLoading(false);
    } catch (e) {
      logError(e);
      setResults([]);
      setLoading(false);
    }
  };

  const copyThis = (value: string): void => {
    navigator.clipboard.writeText(value);
    alert(`Copied "${value}" to clipboard`);
  };

  return (
    <div style={{ padding: '1vh 1vw', maxWidth: '1000px', margin: 'auto' }}>
      <div className="flex flex--vc" style={{ margin: '0 0 1em 0' }}>
        <input
          placeholder={'Search for product groups'}
          value={queryVal}
          onChange={e => setQueryVal(e.currentTarget.value)}
        />
        <div
          onClick={() => copyThis(queryVal)}
          style={{ textDecoration: 'underline', marginLeft: '1em', cursor: 'pointer' }}
          title="click to copy input value"
        >
          Copy search value 📋
        </div>
      </div>
      <div>
        {loading && <IconSmile spinning={true} />}
        {!loading &&
          results.map((result, i) => {
            return (
              <div key={i} style={{ borderTop: '1px solid black', padding: '.5em 0' }}>
                <div className="flex">
                  {result.label}
                  {result.detected_material?.map(material => {
                    return (
                      <Label type="black-outline" style={{ margin: '0 0 0 .5em' }} key={material.id}>
                        {material.label?.[locale]}
                      </Label>
                    );
                  })}
                  <div
                    title={'click to copy: ' + result.iri}
                    style={{ textDecoration: 'underline', marginLeft: '1em', cursor: 'pointer' }}
                    onClick={() => copyThis(String(result.iri))}
                  >
                    Copy ID 📋
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

let source: CancelTokenSource | undefined = undefined;

const productSelectionSearch = (query: string): Promise<ProductSelectionResult[]> => {
  if (source !== undefined) source.cancel();
  source = axios.CancelToken.source();
  return priceClient.productSelection.search({ query, cancelToken: source.token });
};

import { Address, PaymentStatus, TransportRequest } from '@brenger/api-client';
import { CO2Savings, getCO2SavingsForKilometers, getIdFromIri } from '@brenger/utils';
import { useQuery } from 'react-query';
import { CacheKey } from '../typings';
import { coreClient } from '../utils/request';

export type ThankYouPageType = 'general' | 'business' | 'upsell' | 'opp' | 'not_guaranteed' | 'payment_link';

export interface ThankYouDataParams {
  type: ThankYouPageType;
  id: string;
}

export interface ThankYouData {
  isLoading: boolean;
  error?: null | 'payment failed';
  type?: ThankYouPageType;
  trId?: string;
  transKey?: string;
  to?: string;
  from?: string;
  distance?: number;
  savings?: CO2Savings;

  refetch?(): void;
}

export const useThankYouData = (params: ThankYouDataParams): ThankYouData => {
  const { isLoading, data } = useQuery([CacheKey.RETRIEVE_THANK_YOU_DATA, params.id], () => trFetcher(params));

  /**
   * Loading
   */
  if (!data) return { isLoading };

  /**
   * Failed
   */
  if (data.state === 'failed') return { isLoading: false, error: 'payment failed', trId: data.trId };

  /**
   * Success
   */
  const type = getThankYouType(params);
  const distance = data.tr?.internal_attributes.route?.distance || 0;
  const to = (data.tr?.pickups[0]?.address as Address | undefined)?.locality || '';
  const from = (data.tr?.deliveries[0]?.address as Address | undefined)?.locality || '';
  const savings = getCO2SavingsForKilometers(distance);
  return {
    isLoading: false,
    type,
    trId: data.trId,
    transKey: getThankYouTransKey(type),
    to,
    from,
    distance,
    savings,
  };
};

const getThankYouType = (params: ThankYouDataParams): ThankYouPageType => {
  // make sure we do everything lowercase
  let type = (params.type || '').toLowerCase() as ThankYouPageType;
  // Safeguard to make sure we handle the thank you page correctly
  const supportedTypes: ThankYouPageType[] = ['business', 'general', 'not_guaranteed', 'opp', 'upsell', 'payment_link'];
  if (!supportedTypes.includes(type)) {
    type = 'general';
  }
  return type;
};

const getThankYouTransKey = (type: ThankYouPageType): string => {
  switch (type) {
    case 'not_guaranteed':
      return 'general.thank_you_not_guaranteed';
    case 'business':
      return 'business.thank_you';
    case 'upsell':
      return 'edit_flow.thank_you';
    case 'opp':
      return 'opp.thank_you';
    default:
      return 'general.thank_you';
  }
};

type TrFetchState = 'success' | 'failed';

interface TrFetcher {
  trId?: string;
  tr: TransportRequest | null;
  state: TrFetchState;
}

const trFetcher = async ({ type, id }: ThankYouDataParams): Promise<TrFetcher> => {
  try {
    let trId: string | undefined;
    let state: TrFetchState = 'success';

    // In case of a mollie payment we only reach this page when the payment succeeded.
    // When OPP we need check if the payment reached its final state
    if (type === 'opp') {
      let payment = await coreClient.payments.retrieve({ id });
      const finalState: PaymentStatus[] = ['paid', 'failed', 'canceled', 'expired'];

      for (let index = 0; index < 5 && !finalState.includes(payment.status); index++) {
        // wait for 2 secs to retry
        await new Promise(res => setTimeout(res, 2000));
        // try again
        payment = await coreClient.payments.retrieve({ id });
      }
      // We only care about being paid or not
      state = payment.status === 'paid' ? 'success' : 'failed';

      trId = getIdFromIri(payment.transport_request);
      if (!trId) {
        throw new Error('Failed to retrieve trId');
      }
    } else {
      try {
        const payment = await coreClient.payments.retrieve({ id });
        trId = getIdFromIri(payment.transport_request);
      } catch (error) {
        trId = id;
      }
    }

    if (!trId) {
      throw new Error('Failed to retrieve trId');
    }

    const tr = await coreClient.transportRequests.retrieve({ id: trId });

    return {
      trId,
      tr,
      state,
    };
  } catch {
    return {
      tr: null,
      state: 'failed',
    };
  }
};

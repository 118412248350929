import * as React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { useDispatch } from 'react-redux';
import { change } from 'redux-form';
import { Button, Dialog, Modal } from '../../../../brenger-shared-ui';
import { clarity } from '../../../../configs/clarity';
import { useTypedSelector } from '../../../../hooks';
import { useDebounce } from '../../../../hooks/useDebounce';
import { RootState, SessionStorageKeys, StopType } from '../../../../typings';
import { floorAsString } from '../../../../utils/floors';
import { priceAsString } from '../../../../utils/price';
import { getFloorLevelSuspicion, showDutchAddresLayout } from '../../ducks';
import { GeneralFlowForms, HomeSituations, SubStepFieldNamesDelivery, SubStepFieldNamesPickup } from '../../interface';
import { ContactPageAddress } from './ContactStop';
import { getHouseNumberIncludingSuffixFromLine1 } from '@brenger/utils';

interface Props {
  stopType: StopType;
  address: ContactPageAddress | null;
  setShowFloorSuspicion(isActive: boolean): void;
}

export const FloorLevelSuspicionModal: React.FC<Props> = ({ stopType, address, setShowFloorSuspicion }) => {
  const [isModalActive, setIsModalActive] = React.useState(false);
  const dispatch = useDispatch();
  const isDutchLayout = useTypedSelector(showDutchAddresLayout);
  const houseNumber = isDutchLayout ? address?.line2 : getHouseNumberIncludingSuffixFromLine1(address?.line1 || '');
  const suspicion = useTypedSelector((state: RootState) => getFloorLevelSuspicion(state, stopType, houseNumber));
  const isFloorLevelServiceAvailable =
    useTypedSelector(state => state.generalTransport.quote?.service_advisory?.floor_service) !== 'available';
  const debouncedSuspicion = useDebounce<string>(String(suspicion?.floor), 1000);
  const storageKey =
    stopType === StopType.PICKUP ? SessionStorageKeys.FLOOR_SUS_PICKUP : SessionStorageKeys.FLOOR_SUS_DELIVERY;
  const bodyTrans = `request_flow.floor_level_suspicion.modal_body_${stopType.toLowerCase()}${
    !isFloorLevelServiceAvailable ? '_floor_service_not_available' : ''
  }`;

  React.useEffect(() => {
    const susString = JSON.stringify(suspicion);
    if (susString === sessionStorage.getItem(storageKey)) {
      setIsModalActive(false);
      return;
    }
    const shouldBeActive = Boolean(suspicion);
    if (shouldBeActive) {
      sessionStorage.setItem(storageKey, susString);
      clarity.setTag('contact-step_floor-level-suspicion');
    }
    setIsModalActive(shouldBeActive);
  }, [debouncedSuspicion]);

  React.useEffect(() => {
    setShowFloorSuspicion(isModalActive);
  }, [isModalActive]);

  const applySuspicion = (choice: string): void => {
    setIsModalActive(false);
    if (stopType === StopType.PICKUP) {
      dispatch(change(GeneralFlowForms.PICKUP, SubStepFieldNamesPickup.SITUATION_HOME_FLOORS_COMBINED, choice));
    }
    if (stopType === StopType.DELIVERY) {
      dispatch(change(GeneralFlowForms.DELIVERY, SubStepFieldNamesDelivery.SITUATION_HOME_FLOORS_COMBINED, choice));
    }
  };
  if (!isModalActive) {
    return null;
  }
  return (
    <Dialog isActive={true}>
      <Modal
        onlyToggleByButton={true}
        open={true}
        size={'small'}
        header={
          <Translate
            id={'request_flow.floor_level_suspicion.modal_title'}
            data={{
              floor_count: floorAsString(suspicion?.floor),
            }}
          />
        }
      >
        <Translate
          id={bodyTrans}
          data={{
            floor_count: floorAsString(suspicion?.floor),
          }}
        />
        {isFloorLevelServiceAvailable && (
          <div className="pt-1 text--center">
            <Button
              buttonStyle="secondary"
              onClick={() => applySuspicion(`${HomeSituations.FLOOR_WITHOUT_ELEVATOR}[${suspicion?.floor}]`)}
            >
              <Translate
                id="request_flow.floor_level_suspicion.has_floor"
                data={{
                  floor_count: floorAsString(suspicion?.floor),
                  price: priceAsString({ price: { amount: suspicion?.floorFee || 0 } }),
                }}
              />
            </Button>
            <Button buttonStyle="secondary" onClick={() => applySuspicion(HomeSituations.FLOOR_WITH_ELEVATOR)}>
              <Translate
                id="request_flow.floor_level_suspicion.has_elevator"
                data={{
                  price: priceAsString({ price: { amount: suspicion?.elevatorFee || 0 } }),
                }}
              />
            </Button>
            <Button buttonStyle="tertiary" fullWidth={true} onClick={() => setIsModalActive(false)}>
              <Translate
                id={`request_flow.floor_level_suspicion.no_consequences_${stopType}`}
                data={{
                  price: priceAsString({ price: { amount: 0 } }),
                }}
              />
            </Button>
          </div>
        )}
        {!isFloorLevelServiceAvailable && (
          <div className="pt-1 text--center">
            <Button buttonStyle="primary" fullWidth={true} onClick={() => setIsModalActive(false)}>
              <Translate id={`request_flow.floor_level_suspicion.modal_continue_without_service`} />
            </Button>
          </div>
        )}
      </Modal>
    </Dialog>
  );
};

import { getLocation, push } from 'connected-react-router';
import React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { useDispatch } from 'react-redux';
import { Button, Dialog, IconSmile, Modal } from '../../../../brenger-shared-ui';
import van from '../../../../brenger-shared-ui/assets/images/van@2x.png';
import { clarity } from '../../../../configs/clarity';
import { useTypedSelector } from '../../../../hooks';
import { RootState, StopType } from '../../../../typings';
import { priceAsString } from '../../../../utils/price';
import {
  actions,
  getInitialDatePath,
  getIsDateSelectionNotAvailable,
  getSelectedDateTime,
  isRequestAboveMaxDistance,
} from '../../ducks';
import { DateStepParam } from '../../typings';

export const LocalityChangeModal: React.FC = () => {
  const dispatch = useDispatch();
  const localityChange = useTypedSelector((state: RootState) => state.generalTransport.localityChange);
  const priceLoading = useTypedSelector((state: RootState) => state.generalTransport.price.isLoading);
  const dateSelectionNotAvailable = useTypedSelector(getIsDateSelectionNotAvailable);
  const isAboveMaxDistance = useTypedSelector((state: RootState) => isRequestAboveMaxDistance(state));
  const isAboveMaxDateDistance = useTypedSelector((state: RootState) => isRequestAboveMaxDistance(state, true));
  const isDateDistanceChanged =
    !dateSelectionNotAvailable &&
    (isAboveMaxDistance !== localityChange?.above_max_distance_old ||
      isAboveMaxDateDistance !== localityChange?.above_max_date_distance_old);
  const switchedToTrWithDates = !dateSelectionNotAvailable && localityChange?.date_selection_not_available_old;
  const selectedDates = useTypedSelector((state: RootState) =>
    getSelectedDateTime(state, localityChange?.stopType || StopType.PICKUP)
  );
  const dateSelectionRequired =
    (switchedToTrWithDates && !selectedDates.length) || (!switchedToTrWithDates && isDateDistanceChanged);
  const datePath = useTypedSelector(getInitialDatePath);
  const { pathname } = useTypedSelector(getLocation);
  const oldPrice = localityChange?.quote_old?.price.incl_vat?.amount || 0;
  const newPrice = useTypedSelector((state: RootState) => state.generalTransport.quote?.price.incl_vat.amount) || 0;
  const priceTrans = oldPrice === newPrice ? 'equal' : oldPrice > newPrice ? 'lower' : 'higher';

  React.useEffect(() => {
    if (!localityChange) {
      return;
    }
    if (isDateDistanceChanged) {
      clarity.setTag('locality_change_date_distance');
      return;
    }
    clarity.setTag('locality_change');
  }, [localityChange]);

  /**
   * When to show this modal?
   * - LocalityChange should not be null and
   * - To be safe: We only want to show pickup changes on the pickup page and vice versa
   */
  if (!localityChange || !pathname.includes(localityChange?.stopType.toLowerCase())) {
    return null;
  }

  const transVars = {
    locality_old: localityChange?.locality_old,
    locality_new: localityChange?.locality_new,
    price_old: (
      <b className={'old-price text--primary'}>
        {priceAsString({ price: { amount: oldPrice }, addPlusMinusIndicator: false })}
      </b>
    ),
    price_new: (
      <b className={'text--primary'}>{priceAsString({ price: { amount: newPrice }, addPlusMinusIndicator: false })}</b>
    ),
    price_diff: (
      <b className={'text--primary'}>
        {priceAsString({ price: { amount: newPrice - oldPrice }, addPlusMinusIndicator: false })}
      </b>
    ),
  };

  const continueAction = (e): void => {
    e.preventDefault();
    /* Prevent quick visitors from not seeing consequences */
    if (priceLoading) {
      return;
    }
    /* When switched to national or date requirements are influenced, then we send the user back to dates */
    if (dateSelectionRequired) {
      dispatch(push(`${datePath}?${DateStepParam.DONT_RESET}`));
    }
    /* In all cases we set the change to null, to hide the modal (and when dates can stay the same, the user can continue on the contact page) */
    dispatch(actions.setLocalityChange(null));
  };

  return (
    <Dialog isActive={true}>
      <Modal open={true} onClick={continueAction} style={'white'} size={'small'}>
        <div className={'text--bold text--primary text--center'}>
          <Translate id={'request_flow.locality_change.modal.title_' + localityChange?.stopType.toLowerCase()} />
        </div>
        <div className={'text--center pb-1 pt-1'}>
          <img className={'van-assets-image'} src={van} />
        </div>
        <div className={'pb-1'}>
          <Translate
            id={'request_flow.locality_change.modal.text_' + localityChange?.stopType.toLowerCase()}
            data={transVars}
          />
        </div>
        {priceLoading && (
          <div className={'pb-1'}>
            <IconSmile spinning={true} /> <Translate id={'request_flow.locality_change.modal.loading_price'} />
          </div>
        )}
        {!priceLoading && (
          <div className={'pb-1'}>
            <Translate id={`request_flow.locality_change.modal.text_price_${priceTrans}`} data={transVars} />
          </div>
        )}
        {!switchedToTrWithDates && isDateDistanceChanged && (
          <div className={'pb-1'}>
            <Translate id={`request_flow.locality_change.modal.text_date`} />
          </div>
        )}
        {switchedToTrWithDates && dateSelectionRequired && (
          <div className={'pb-1'}>
            <Translate id={`request_flow.locality_change.modal.text_date_switch_to_national`} />
          </div>
        )}
        {switchedToTrWithDates && !dateSelectionRequired && (
          <div className={'pb-1'}>
            <Translate id={`request_flow.locality_change.modal.text_date_switch_to_national_dates_present`} />
          </div>
        )}
        <Button disabled={priceLoading} type={'button'} buttonStyle={'secondary'} onClick={continueAction}>
          <Translate id={'request_flow.locality_change.modal.cta' + (dateSelectionRequired ? '_dates' : '')} />
        </Button>
      </Modal>
    </Dialog>
  );
};

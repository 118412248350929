import { CarryingHelpExplicitEscape, CarryingHelpServices } from '@brenger/api-client';
import React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import {
  IconPersonOne,
  IconPersonTwo,
  IconTailgate,
  IconTailgateExtraPerson,
  IconTailgatePalletJack,
  TileOption,
} from '../brenger-shared-ui';
import { RootState } from '../typings';
import { translate } from '../utils/localization';
import { priceAsString } from '../utils/price';
import { useTypedSelector } from './useTypedSelector';

interface OrderedHelpOptions {
  advised: TileOption[];
  other: TileOption[];
  hasEscape: boolean;
}

type HelpOptionValue = CarryingHelpServices | CarryingHelpExplicitEscape;

export const useServiceAdvisoryHelp = (): OrderedHelpOptions => {
  const service = useTypedSelector((state: RootState) => state.generalTransport.quote?.service_advisory);
  const priceList = useTypedSelector((state: RootState) => state.generalTransport.quote?.price_list);
  const hasEscape = !!service?.help_options_has_escape;
  /**
   * Help option map
   */
  const helpOptions: { [key in HelpOptionValue]: TileOption } = {
    needed: {
      value: 'needed',
      title: String(translate(`request_flow.pickup.help_carrying.option_2`)),
      desc: <Translate id={`request_flow.pickup.help_carrying.option_2_explain`} />,
      suffix: <span className={'text--primary'}>{priceAsString({ price: { amount: 0 } })}</span>,
      prefix: <IconPersonOne />,
    },
    extra_driver: {
      value: 'extra_driver',
      title: String(translate(`request_flow.pickup.help_carrying.option_3`)),
      desc: <Translate id={`request_flow.pickup.help_carrying.option_3_explain`} />,
      suffix: (
        <span className={'text--primary'}>
          {priceAsString({ price: { amount: priceList?.['carrying_help.extra_driver'] || 0 } })}
        </span>
      ),
      prefix: <IconPersonTwo />,
    },
    equipment_tailgate: {
      value: 'equipment_tailgate',
      title: String(translate('request_flow.weight_equipment_heavy.equipment_tailgate')),
      desc: <Translate id={'request_flow.weight_equipment_heavy.equipment_tailgate_explain'} />,
      suffix: (
        <span className={'text--primary'}>
          {priceAsString({ price: { amount: priceList?.['carrying_help.equipment_tailgate'] || 0 } })}
        </span>
      ),
      prefix: <IconTailgate />,
    },
    equipment_tailgate_extra_driver: {
      value: 'equipment_tailgate_extra_driver',
      title: String(translate('request_flow.weight_equipment_heavy.equipment_tailgate_extra_driver')),
      desc: <Translate id={'request_flow.weight_equipment_heavy.equipment_tailgate_extra_driver_explain'} />,
      suffix: (
        <span className={'text--primary'}>
          {priceAsString({ price: { amount: priceList?.['carrying_help.tailgate_extra_driver'] || 0 } })}
        </span>
      ),
      prefix: <IconTailgateExtraPerson />,
    },
    equipment_tailgate_pallet_jack: {
      value: 'equipment_tailgate_pallet_jack',
      title: String(translate('request_flow.weight_equipment_heavy.equipment_tailgate_with_pallet_jack')),
      desc: <Translate id={'request_flow.weight_equipment_heavy.equipment_tailgate_with_pallet_jack_explain'} />,
      suffix: (
        <span className={'text--primary'}>
          {priceAsString({ price: { amount: priceList?.['carrying_help.equipment_tailgate_pallet_jack'] || 0 } })}
        </span>
      ),
      prefix: <IconTailgatePalletJack />,
    },
    equipment_provided_by_customer: {
      value: 'equipment_provided_by_customer',
      title: String(translate('request_flow.weight_equipment_heavy.equipment_provided_by_customer')),
      desc: <Translate id={'request_flow.weight_equipment_heavy.equipment_provided_by_customer_explain'} />,
      suffix: <span className={'text--primary'}>{priceAsString({ price: { amount: 0 } })}</span>,
    },
  };

  const advised: HelpOptionValue[] = service?.help_options_advised || [];
  let other: HelpOptionValue[] = service?.help_options || [];

  // If escape is present we should add it as last option of "other"
  if (hasEscape) {
    other = other.concat('equipment_provided_by_customer');
  }

  /**
   * Map the help values to a HelpOption
   * Add escape value
   */
  return {
    advised: advised.map(option => helpOptions[option]).filter(Boolean) as TileOption[],
    other: other.map(option => helpOptions[option]).filter(Boolean) as TileOption[],
    hasEscape,
  };
};

import {
  AddressCreateParams,
  CarryingHelp,
  ContactCreateParams,
  GeoAutocompleteAddress,
  InstructionSuspicion,
  ProductSelectionResult,
  Quote,
  StopSituation,
} from '@brenger/api-client';
import { StopType } from '../../typings';
import { TransformedLot } from '../../utils/tbAuctionsMethods';
import { HomeSituationType } from './components/SubStepHomeSituation';
import { DateFormValues } from './containers/Date';
import { ProductPaymentFormValues } from './containers/ProductPayment';
import { TimeFormValues } from './containers/Time';
import { ExpectedUtmParams, GeneralFlowForms, ItemWithPSAndLot } from './interface';

export enum GeneralFlowActionTypes {
  SET_STEP_LOADING = `@@GeneralFlow/SET_STEP_LOADING`,
  SET_DESTINATION_DETAILS = `@@GeneralFlow/SET_DESTINATION_DETAILS`,
  SET_DESTINATION_META = `@@GeneralFlow/SET_DESTINATION_META`,
  RESET_DESTINATION_DETAILS = `@@GeneralFlow/RESET_DESTINATION_DETAILS`,
  POP_FOOTER_NOTIFICATION = `@@GeneralFlow/POP_FOOTER_NOTIFICATION`,
  HIDE_FOOTER_NOTIFICATION = `@@GeneralFlow/HIDE_FOOTER_NOTIFICATION`,
  SET_SUBMITTING_STATUS = `@@GeneralFlow/SET_SUBMITTING_STATUS`,
  SET_PRICE = `@@GeneralFlow/SET_PRICE`,
  SET_PRICE_STRUCTURE = `@@GeneralFlow/SET_PRICE_STRUCTURE`,
  SET_PRICE_LOADING = `@@GeneralFlow/SET_PRICE_LOADING`,
  SET_EXAMPLE_OPEN = `@@GeneralFlow/SET_EXAMPLE_OPEN`,
  SET_EXAMPLE_PRICES = `@@GeneralFlow/SET_EXAMPLE_PRICES`,
  RESET_PRICE = `@@GeneralFlow/RESET_PRICE`,
  PUSH_NOTIFICATION = `@@GeneralFlow/PUSH_NOTIFICATION`,
  SET_PROGRESS_STEP = `@@GeneralFlow/SET_PROGRESS_STEP`,
  TOGGLE_SHEET = `@@GeneralFlow/TOGGLE_SHEET`,
  CREATE_TRANSPORT_REQUEST = `@@GeneralFlow/CREATE_TRANSPORT_REQUEST`,
  SUBMIT_PICKUP = `@@GeneralFlow/SUBMIT_PICKUP`,
  SUBMIT_DELIVERY = `@@GeneralFlow/SUBMIT_DELIVERY`,
  SUBMIT_WEIGHT = `@@GeneralFlow/SUBMIT_WEIGHT`,
  CREATE_TRANSPORT_REQUEST_START = `@@GeneralFlow/CREATE_TRANSPORT_REQUEST_START`,
  CREATE_TRANSPORT_REQUEST_SUCCESS = `@@GeneralFlow/CREATE_TRANSPORT_REQUEST_SUCCESS`,
  CREATE_TRANSPORT_REQUEST_FAILURE = `@@GeneralFlow/CREATE_TRANSPORT_REQUEST_FAILURE`,
  RESET_FLOW = `@@GeneralFlow/RESET_FLOW`,
  SET_TRANSPORT_UUID = `@@GeneralFlow/SET_TRANSPORT_UUID`,
  SET_REFERRER = `@@GeneralFlow/SET_REFERRER`,
  SUBMIT_WIDGET = `@@GeneralFlow/SUBMIT_WIDGET`,
  SUBMIT_ITEMS = `@@GeneralFlow/SUBMIT_ITEMS`,
  SET_QUOTE = `@@GeneralFlow/SET_QUOTE`,
  ITEM_SETS_CHANGE = `@@GeneralFlow/ITEM_SETS_CHANGE`,
  PRICE_API_RETRY_ATTEMPT_INCREMENT = `@@GeneralFlow/PRICE_API_RETRY_ATTEMPT_INCREMENT`,
  RESET_RETRY_ATTEMPTS = `@@GeneralFlow/RESET_RETRY_ATTEMPTS`,
  SET_PRICE_LIST = `@@GeneralFlow/SET_PRICE_LIST`,
  GET_PRICE = `@@GeneralFlow/GET_PRICE`,
  POST_DRAFT_QUOTES = `@@GeneralFlow/POST_DRAFT_QUOTES`,
  SET_HISTORICAL_ADDRESSES = `@@GeneralFlow/SET_HISTORICAL_ADDRESSES`,
  SUBMIT_MP_SELLER_FORM = `@@GeneralFlow/SUBMIT_MP_SELLER_FORM`,
  ADD_LAST_VISIT_TIME = `@@GeneralFlow/ADD_LAST_VISIT_TIME`,
  SUBMIT_FORM = `@@GeneralFlow/SUBMIT_FORM`,
  LOCALITY_CHANGE = `@@GeneralFlow/LOCALITY_CHANGE`,
  SET_INSTRUCTION_SUSPICION = `@@GeneralFlow/SET_INSTRUCTION_SUSPICION`,
  SET_VAT_NUMBER = `@@GeneralFlow/SET_VAT_NUMBER`,
  SET_VAT_NUMBER_LOADING = `@@GeneralFlow/SET_VAT_NUMBER_LOADING`,
  SET_REJECTED_BY_QUOTES = `@@GeneralFlow/SET_REJECTED_BY_QUOTES`,
  SET_SHOW_TIME_MODAL = `@@GeneralFlow/SET_SHOW_TIME_MODAL`,
  SET_SHOW_FLEX_DATES_MODAL = `@@GeneralFlow/SET_SHOW_FLEX_DATES_MODAL`,
  RESET_ERROR = `@@GeneralFlow/RESET_ERROR`,
  ITEMS_SHOW_MODAL = `@@GeneralFlow/ITEMS_SHOW_MODAL`,
  SET_TR_VALIDATION_ERROR = `@@GeneralFlow/SET_TR_VALIDATION_ERROR`,
  SET_QUERY_PS = `@@GeneralFlow/SET_QUERY_PS`,
  SET_SEARCH_RESULT_PS = `@@GeneralFlow/SET_SEARCH_RESULT_PS`,
  ADD_ITEM_PS = `@@GeneralFlow/ADD_ITEM_PS`,
  REMOVE_ITEM_PS = `@@GeneralFlow/REMOVE_ITEM_PS`,
  UPDATE_ITEM_PS = `@@GeneralFlow/UPDATE_ITEM_PS`,
  REVIEWED_ITEM_PS = `@@GeneralFlow/REVIEWED_ITEM_PS`,
  SET_PREFILL_PS = `@@GeneralFlow/SET_PREFILL_PS`,
  SET_CONTACT_DETAILS = `@@GeneralFlow/SET_CONTACT_DETAILS`,
  SET_COMPANY_DETAILS = `@@GeneralFlow/SET_COMPANY_DETAILS`,
  SET_STOP_ADDRESS = `@@GeneralFlow/SET_STOP_ADDRESS`,
  SET_HELP = `@@GeneralFlow/SET_HELP`,
  SET_UTM_DETAILS = `@@GeneralFlow/SET_UTM_DETAILS`,
  SET_CHOICE = `@@GeneralFlow/SET_CHOICE`,
  RESET_CHOICE = `@@GeneralFlow/RESET_CHOICE`,
  SET_AB = `@@GeneralFlow/SET_AB`,
  RESET_AB = `@@GeneralFlow/RESET_AB`,
}

export enum AccountType {
  INDIVIDUAL = 'individual',
  BUSINESS = 'business',
}

export enum DateStepParam {
  DONT_RESET = 'do_not_reset_date=true',
}

export interface SetQuoteAction {
  type: GeneralFlowActionTypes.SET_QUOTE;
  payload: {
    quote: Quote;
    removePrice: boolean;
  };
}

export interface ToggleStepLoadingAction {
  type: GeneralFlowActionTypes.SET_STEP_LOADING;
  payload: boolean;
}

export interface SetDestinationDetailsAction {
  type: GeneralFlowActionTypes.SET_DESTINATION_DETAILS;
  payload: GeoAutocompleteAddress | null;
  stopType: StopType;
}
export interface SetDestinationMetaAction {
  type: GeneralFlowActionTypes.SET_DESTINATION_META;
  payload: null | {
    utm_account?: string;
  };
  stopType: StopType;
}

export interface ResetFlowAction {
  type: GeneralFlowActionTypes.RESET_FLOW;
  payload: {
    resetDestination: boolean;
  };
}

export interface GeneralFlowAction {
  type: GeneralFlowActionTypes.ADD_LAST_VISIT_TIME | GeneralFlowActionTypes.POST_DRAFT_QUOTES;
}

export interface ItemsShowModalAction {
  type: GeneralFlowActionTypes.ITEMS_SHOW_MODAL;
  payload: boolean;
}

export type SubmitForm =
  | GeneralFlowForms.DATE
  | GeneralFlowForms.ITEMS
  | GeneralFlowForms.PRODUCT_PAYMENT
  | GeneralFlowForms.TIME
  | GeneralFlowForms.TERMS;

export type SubmitValues = ProductPaymentFormValues | DateFormValues | TimeFormValues;

export interface SubmitFormAction {
  type: GeneralFlowActionTypes.SUBMIT_FORM;
  meta: {
    form: SubmitForm;
  };
  payload?: SubmitValues;
}

export interface SetLocalityChangePayload {
  stopType: StopType;
  locality_old: string;
  locality_new: string;
  quote_old: Quote | null;
  above_max_distance_old: boolean;
  above_max_date_distance_old: boolean;
  date_selection_not_available_old: boolean;
}
export interface SetLocalityChangeAction {
  type: GeneralFlowActionTypes.LOCALITY_CHANGE;
  payload: SetLocalityChangePayload | null;
}

export interface SetShowModalAction {
  type: GeneralFlowActionTypes.SET_SHOW_TIME_MODAL | GeneralFlowActionTypes.SET_SHOW_FLEX_DATES_MODAL;
  payload: boolean;
}

export interface SetInstructionSuspicionPayload {
  suspicions: InstructionSuspicion[] | null;
  stopType: StopType;
}
export interface SetInstructionSuspicionAction {
  type: GeneralFlowActionTypes.SET_INSTRUCTION_SUSPICION;
  payload: SetInstructionSuspicionPayload;
}

export type QuoteRejectedReason = 'area.no_service_for_at_least_one_stop' | 'route.calculation_not_possible';
export interface RejectedByQuotesAction {
  type: GeneralFlowActionTypes.SET_REJECTED_BY_QUOTES;
  payload: QuoteRejectedReason | null;
}

export type FloorOption = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | 'with_elevator';
export type FloorOptionsWithFees = { [key in FloorOption]: number };

export type BasementOption = '-1' | 'with_elevator';
export type BasementOptionsWithFees = { [key in BasementOption]: number };

export type TransportRequestValidationError =
  | 'selected_date_not_available'
  | 'selected_time_not_available'
  | 'should_select_date'
  | 'should_select_time'
  | 'failed_price_request'
  | 'same_address_for_pickup_delivery'
  | 'pickup_has_invalid_coordinates'
  | 'delivery_has_invalid_coordinates';

export interface SetTRValidationErrorAction {
  type: GeneralFlowActionTypes.SET_TR_VALIDATION_ERROR;
  payload: TransportRequestValidationError | null;
}

export interface SetQueryPsAction {
  type: GeneralFlowActionTypes.SET_QUERY_PS;
  payload: string | null;
}

export interface SetSearchResultPsAction {
  type: GeneralFlowActionTypes.SET_SEARCH_RESULT_PS;
  payload: Partial<ProductSelectionResult & { auctionLot: TransformedLot }> | null;
}
export interface AddItemPsAction {
  type: GeneralFlowActionTypes.ADD_ITEM_PS;
  payload: ItemWithPSAndLot;
}
export interface RemoveItemPsAction {
  type: GeneralFlowActionTypes.REMOVE_ITEM_PS;
  payload:
    | {
        uuid: string;
      }
    | {
        all: boolean;
      };
}
export interface UpdateItemPsAction {
  type: GeneralFlowActionTypes.UPDATE_ITEM_PS;
  payload: {
    uuid: string;
    item: ItemWithPSAndLot;
  };
}
export interface ReviewedItemPsAction {
  type: GeneralFlowActionTypes.REVIEWED_ITEM_PS;
  payload: {
    uuid: string;
    reviewed: boolean;
  };
}

export type PsPrefillOtherActionType = 'CONFIG' | 'SEARCH';
export type PsPrefillProductActionType = 'PREFILL';
export type PsPrefillActionType = PsPrefillOtherActionType | PsPrefillProductActionType;

export interface PsPrefillOtherAction {
  /** what to do?
   * 1) CONFIG - select product and make the user start at config screen
   * 2) SEARCH - only set the search query, make the user start at the search screen
   */
  type: PsPrefillOtherActionType;
  /**
   * Value can be a product IRI or Value for search
   */
  value: string;
}
export interface PSPrefillProductAction {
  /**
   * Add product to item set, user ends up at items screen
   */
  type: PsPrefillProductActionType;
  /**
   * Value is a product IRI
   */
  value: string;
  /**
   * Should the user be warned beforeabout a prefilled item, before continueing in the flow?
   */
  shouldWarn: boolean;
}

export interface SetPrefillPsAction {
  type: GeneralFlowActionTypes.SET_PREFILL_PS;
  payload: PsPrefillOtherAction | PSPrefillProductAction | null;
}

export type ContactType = 'customer' | StopType;

export interface SetContactDetailsAction {
  type: GeneralFlowActionTypes.SET_CONTACT_DETAILS;
  payload: {
    contact_type: ContactType;
    details: ContactCreateParams | null;
  };
}

export interface CompanyDetails {
  company_name: string;
  coc_number: string;
  vat_number: string;
  address: Pick<AddressCreateParams, 'line1' | 'postal_code' | 'locality' | 'country_code'>;
}

export interface ContactCustomer {
  contact_details: ContactCreateParams;
  company_details?: CompanyDetails;
}
export interface ContactStop {
  contact_details: ContactCreateParams;
  address?: AddressCreateParams | null;
  instructions?: string;
  address_is_prefilled?: boolean;
}

export interface SetCompanyDetailsAction {
  type: GeneralFlowActionTypes.SET_COMPANY_DETAILS;
  payload: CompanyDetails | null;
}

export interface SetStopAddressAction {
  type: GeneralFlowActionTypes.SET_STOP_ADDRESS;
  payload: {
    stop_type: StopType;
    address: AddressCreateParams | null;
    instructions?: string;
    address_is_prefilled?: boolean;
  };
}
export interface SetUtmDetails {
  type: GeneralFlowActionTypes.SET_UTM_DETAILS;
  payload: null | { [key in ExpectedUtmParams]?: string };
}

export interface GfChoices {
  situation?: StopSituation;
  home_situation?: HomeSituationType;
  carrying_help?: CarryingHelp;
}
export type GfChoicesKey = keyof GfChoices;

export interface SetChoiceAction {
  type: GeneralFlowActionTypes.SET_CHOICE;
  payload: GfChoices;
}

export interface ResetChoiceAction {
  type: GeneralFlowActionTypes.RESET_CHOICE;
  payload: GfChoicesKey[] | 'ALL';
}

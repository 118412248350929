import React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import classNames from 'classnames';

import { useTypedSelector } from '../../../../hooks';
import { useNotification } from '../../../../hooks/useNotification';
import { CirclePerson2, Toast } from '../../../../brenger-shared-ui';
import { getQuote } from '../../ducks';
import { getLocation } from 'connected-react-router';

export const ProductSelectionFloorService: React.FC = () => {
  const notification = useNotification('product-selection--floor-service');
  const { pathname } = useTypedSelector(getLocation);
  const floorServiceAvailability = useTypedSelector(getQuote)?.service_advisory?.floor_service;

  React.useEffect(() => {
    if (pathname.includes('floor') && floorServiceAvailability === 'warn') {
      notification.add();
    } else if (pathname.includes('contact') && floorServiceAvailability === 'not_available') {
      notification.add();
    } else {
      notification.remove();
    }
  }, [pathname, floorServiceAvailability]);

  return (
    <div className={classNames('info-message-container', { 'info-message--visible': notification.isVisible })}>
      <Toast prefix={<CirclePerson2 />} handleClose={notification.removeForOneSession}>
        <div style={{ paddingRight: '15px' }}>
          <b>
            <Translate
              id={`request_flow.product_selection.notification.floor_service_${floorServiceAvailability}.title`}
            />
          </b>
        </div>
        <div>
          <Translate
            id={`request_flow.product_selection.notification.floor_service_${floorServiceAvailability}.text`}
          />
        </div>
      </Toast>
    </div>
  );
};

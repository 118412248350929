import { CountryCode } from '@brenger/api-client';
import { useForm } from '@brenger/react';
import * as React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { useDispatch } from 'react-redux';
import { Col, FlashMessage, InputSelect, InputText, Row } from '../../../../brenger-shared-ui';
import { useTypedSelector } from '../../../../hooks';
import { useVatNumberValidator } from '../../../../hooks/useVatNumberValidator';
import { LanguageSettings, allLanguageSettings, translate } from '../../../../utils/localization';
import { actions } from '../../ducks';
import { validateRequired } from '../../../../utils/validation';

interface Props {
  forceShowErrors: boolean;
}

export const BusinessFields: React.FC<Props> = ({ forceShowErrors }) => {
  const dispatch = useDispatch();
  const savedDetails = useTypedSelector(state => state.generalTransport.contact?.customer?.company_details);

  const form = useForm({
    persist: {
      key: 'gf_contact_company_details',
      type: 'session',
    },
    initialState: {
      company_name: savedDetails?.company_name || '',
      coc_number: savedDetails?.coc_number || '',
      vat_number: savedDetails?.vat_number || '',
      line1: savedDetails?.address?.line1 || '',
      postal_code: savedDetails?.address?.postal_code || '',
      locality: savedDetails?.address?.locality || '',
      country_code: savedDetails?.address?.country_code || '',
    },
    validators: {
      company_name: val => validateRequired(translate('form.fields.account.account_name.label'), val),
      coc_number: val => validateRequired(translate('form.fields.account.coc_number.label'), val),
      vat_number: val => validateRequired(translate('form.fields.account.vat_number.label'), val),
      line1: val => validateRequired(translate('form.fields.address.line1.label'), val),
      postal_code: val => validateRequired(translate('form.fields.address.postal_code.label'), val),
      locality: val => validateRequired(translate('form.fields.address.locality.label'), val),
      country_code: val => validateRequired(translate('form.fields.address.country_code.label'), val),
    },
  });
  const { isVatLoading, isVatValid } = useVatNumberValidator(form.data.vat_number.value);

  React.useEffect(() => {
    if (isVatLoading) return;
    if (isVatValid) save();
  }, [isVatValid, isVatLoading]);

  const save = (): void => {
    dispatch(
      actions.setCompanyDetails(
        form.hasErrors
          ? null
          : {
              company_name: form.data.company_name.value,
              coc_number: form.data.coc_number.value,
              vat_number: form.data.vat_number.value,
              address: {
                line1: form.data.line1.value,
                postal_code: form.data.postal_code.value,
                locality: form.data.locality.value,
                country_code: form.data.country_code.value as CountryCode,
              },
            }
      )
    );
  };

  // Supported countries are based on supported languages + BE
  const countryOptions = Object.keys(allLanguageSettings).map(lang => {
    const langSetting = allLanguageSettings[lang] as LanguageSettings;
    return {
      label: langSetting.labels.countryCode.toUpperCase(),
      value: langSetting.labels.countryCode.toUpperCase(),
    };
  });
  countryOptions.push({
    label: 'BE',
    value: 'BE',
  });

  return (
    <>
      <Row>
        <Col xs={12} extraClasses="pb-1">
          <InputText
            placeholder={translate('form.fields.account.account_name.label')}
            value={form.data.company_name.value}
            onChange={company_name => form.set({ company_name })}
            onBlur={save}
            meta={{
              error: form.getError('company_name'),
              showError: forceShowErrors || form.data.company_name.isDirty,
            }}
          />
        </Col>
        <Col xs={12} extraClasses="pb-1">
          <InputText
            placeholder={translate('form.fields.account.coc_number.label')}
            value={form.data.coc_number.value}
            onChange={coc_number => form.set({ coc_number })}
            onBlur={save}
            meta={{
              error: form.getError('coc_number'),
              showError: forceShowErrors || form.data.coc_number.isDirty,
            }}
          />
        </Col>
        <Col xs={12} extraClasses="pb-1">
          <InputText
            placeholder={translate('form.fields.account.vat_number.label')}
            value={form.data.vat_number.value}
            onChange={vat_number => form.set({ vat_number })}
            isLoading={isVatLoading}
            onBlur={save}
            meta={{
              error: form.getError('vat_number'),
              showError: forceShowErrors || form.data.vat_number.isDirty,
            }}
          />
          {isVatValid && (
            <>
              <div className="pb-0-5" />
              <FlashMessage type="info">
                <Translate id="form.fields.account.vat_number.validated" />
              </FlashMessage>
            </>
          )}
        </Col>
        <Col xs={12} extraClasses="pb-1">
          <InputText
            placeholder={translate('form.fields.address.line1.label')}
            value={form.data.line1.value}
            onChange={line1 => form.set({ line1 })}
            onBlur={save}
            meta={{
              error: form.getError('line1'),
              showError: forceShowErrors || form.data.line1.isDirty,
            }}
          />
        </Col>
        <Col xs={12} extraClasses="pb-1">
          <InputText
            placeholder={translate('form.fields.address.postal_code.label')}
            value={form.data.postal_code.value}
            onChange={postal_code => form.set({ postal_code })}
            onBlur={save}
            meta={{
              error: form.getError('postal_code'),
              showError: forceShowErrors || form.data.postal_code.isDirty,
            }}
          />
        </Col>
        <Col xs={12} extraClasses="pb-1">
          <InputText
            placeholder={translate('form.fields.address.locality.label')}
            value={form.data.locality.value}
            onChange={locality => form.set({ locality })}
            onBlur={save}
            meta={{
              error: form.getError('locality'),
              showError: forceShowErrors || form.data.locality.isDirty,
            }}
          />
        </Col>
        <Col xs={12} extraClasses="pb-1">
          <InputSelect
            id={'business_country'}
            placeholder={translate('form.fields.address.country.label')}
            value={form.data.country_code.value}
            onChange={country_code => form.set({ country_code })}
            onBlur={save}
            options={countryOptions}
            meta={{
              error: form.getError('country_code'),
              showError: forceShowErrors || form.data.country_code.isDirty,
            }}
          />
        </Col>
      </Row>
      <div style={{ paddingBottom: '2rem' }} />
    </>
  );
};

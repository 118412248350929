import React from 'react';
import '../../assets/api-settings/layout.scss';
import ApiSettingsLayout from './components/Layout';
import { Overview } from './components/OverView';

export const ApiSettings: React.FC = () => {
  return (
    <ApiSettingsLayout>
      <Overview />
    </ApiSettingsLayout>
  );
};

import {
  atLeastOneLetter,
  atLeastOneNumberRegex,
  email,
  maxLength,
  minLength,
  numeric,
  postalCodeRegexNL,
  required,
  testRegex,
} from '../../utils/validation';
import { translate } from '../../utils/localization';
import _get from 'lodash/get';

export const CompanySchema = {
  name: value =>
    required(
      value,
      translate('form.fields.default.required', { field: translate('form.fields.account.account_name.label') })
    ),
  line1: [
    value =>
      required(
        value,
        translate('form.fields.default.required', { field: translate('form.fields.address.line1.label') })
      ),
    (value, allValues) => {
      const countryCode = _get(allValues, 'user.account.address.country_code', '');
      if (countryCode === 'NL') {
        return testRegex(value, atLeastOneNumberRegex, translate('form.fields.address.line1.valid_with_number'));
      }
    },
    (value, allValues) => {
      const countryCode = _get(allValues, 'user.account.address.country_code', '');
      if (countryCode === 'NL') {
        return testRegex(value, atLeastOneLetter, translate('form.fields.address.line1.valid'));
      }
    },
  ],
  country: value =>
    required(
      value,
      translate('form.fields.default.required', { field: translate('form.fields.address.country.label') })
    ),
  postal_code: [
    value =>
      required(
        value,
        translate('form.fields.default.required', { field: translate('form.fields.address.postal_code.label') })
      ),
    (value, allValues) => {
      const countryCode = _get(allValues, 'user.account.address.country_code', '');
      if (countryCode === 'NL') {
        return testRegex(value, postalCodeRegexNL, translate('form.fields.address.postal_code.valid'));
      }
      return undefined;
    },
  ],
  locality: value =>
    required(
      value,
      translate('form.fields.default.required', { field: translate('form.fields.address.locality.label') })
    ),
  coc_number: value =>
    required(
      value,
      translate('form.fields.default.required', { field: translate('form.fields.account.coc_number.label') })
    ),
  vat_number: value =>
    required(
      value,
      translate('form.fields.default.required', { field: translate('form.fields.account.vat_number.label') })
    ),
};

export const PersonSchema = {
  first_name: value =>
    required(
      value,
      translate('form.fields.default.required', { field: translate('form.fields.user.first_name.label') })
    ),
  last_name: value =>
    required(
      value,
      translate('form.fields.default.required', { field: translate('form.fields.user.last_name.label') })
    ),
  phone: [
    value =>
      required(value, translate('form.fields.default.required', { field: translate('form.fields.user.phone.label') })),
    value => minLength(value, 10, translate('form.fields.user.phone.valid')),
    value => maxLength(value, 14, translate('form.fields.user.phone.valid')),
    value => numeric(value, translate('form.fields.user.phone.valid')),
  ],
  email: [
    value =>
      required(value, translate('form.fields.default.required', { field: translate('form.fields.user.email.label') })),
    value => email(value, translate('form.fields.user.email.valid')),
  ],
  new_password: [
    value =>
      required(
        value,
        translate('form.fields.default.required', { field: translate('form.fields.user.new_password.label') })
      ),
    value => minLength(value, 8, translate('form.fields.user.new_password.valid', { min: 8, max: 36 })),
    value => maxLength(value, 36, translate('form.fields.user.new_password.valid', { min: 8, max: 36 })),
  ],
  password: [
    value =>
      required(
        value,
        translate('form.fields.default.required', { field: translate('form.fields.user.password.label') })
      ),
    value => minLength(value, 1, translate('form.fields.user.password.valid')),
    value => maxLength(value, 36, translate('form.fields.user.password.valid')),
  ],
  termsofuse: [
    value =>
      required(value, translate('form.fields.default.required', { field: translate('form.fields.user.tou.label') })),
  ],
};

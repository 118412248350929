import { Quote } from '@brenger/api-client';
import { getLocation, push } from 'connected-react-router';
import { store } from '../..';
import { r } from '../../routes';
import { RootState, StopType } from '../../typings';
import { logError, logException } from '../../utils/basics';
import { delay } from '../../utils/global';
import { translate } from '../../utils/localization';
import { priceClient } from '../../utils/request';
import {
  actions,
  getDifferentDeliveryDate,
  getInitialDatePath,
  getIsDateSelectionNotAvailable,
  getIsMarketplaceUtmSource,
  getPickupSituation,
  getPriceRequestParams,
  getProductPaymentOptIn,
  getQuote,
  getSkipTimeOptions,
  isFloorServiceAvailable,
} from './ducks';
import { GfChoicesKey } from './typings';

interface ProceedAction {
  path?: string;
  showTimeModal?: boolean;
  missingChoice?: GfChoicesKey;
}

interface Proceed {
  pathnameOverwrite?: string | null;
  withDelay?: boolean;
  updatePriceFirst?: boolean;
}

/**
 * Wrapper function that gets the proceed action and executes it.
 */
export const proceed = async (args?: Proceed): Promise<ProceedAction | null> => {
  if (args?.updatePriceFirst) {
    const quote = await fetchPrice();
    if (quote) store.dispatch(actions.setQuote(quote));
  }
  const state = store.getState() as RootState;
  const proceedAction = getProceedAction(state, args?.pathnameOverwrite);
  // Wait for animation to end?
  if (args?.withDelay !== false) await delay();
  // Execute next path
  if (proceedAction?.path) store.dispatch(push(proceedAction.path));
  if (proceedAction?.showTimeModal) store.dispatch(actions.setShowTimeModal(true));
  if (proceedAction?.missingChoice)
    store.dispatch(actions.pushNotification(translate('request_flow.errors.select_required')));
  else {
    store.dispatch(actions.pushNotification(null));
  }
  // Bad news when proceedAction is null, so best to report it to Sentry.
  if (proceedAction === null) {
    const { router, generalTransport } = state;
    logException('Proceed function called without correct outcome', {
      router,
      generalTransport,
    });
  }
  // Return what we did so we can determine if succeeded
  return proceedAction;
};

const fetchPrice = async (): Promise<Quote | null> => {
  const state = store.getState() as RootState;
  let result: Quote | null = null;
  try {
    store.dispatch(actions.setPriceLoading(true));
    const params = getPriceRequestParams(state);
    if (params) result = await priceClient.quotes.create(params);
  } catch {
    logError('Pricing failed to update during proceed function');
  }
  store.dispatch(actions.setPriceLoading(false));
  return result;
};

/**
 * getProceedAction selector
 * Basically a big switch statement that returns the next path
 * Some cases need "more state" than current path, then it reaches for another sub selector
 */
export const getProceedAction = (state: RootState, pathnameOverwrite?: string | null): ProceedAction | null => {
  const pathname = pathnameOverwrite || getLocation(state).pathname;
  switch (pathname) {
    // pickup index
    case r.generalFlow.pickup.index():
      return getProceedActionPickupIndex(state);
    // auction + home situation
    case r.generalFlow.pickup.auction():
    case r.generalFlow.homeSituation.index():
      return { path: r.generalFlow.items.index() };
    // items
    case r.generalFlow.items.index():
      return getProceedActionItems(state);
    // Product payment
    case r.generalFlow.productPayment.index():
      return getProceedActionProductPayment(state);
    case r.generalFlow.productPayment.amount():
      return { path: r.generalFlow.date.index() };
    // date
    case r.generalFlow.date.index():
    case r.generalFlow.date.delivery():
      return getProceedActionDates(state, pathname);
    // time
    case r.generalFlow.time.index():
      return { path: r.generalFlow.time.delivery() };
    case r.generalFlow.time.delivery():
      return { path: r.generalFlow.help.index() };
    // help
    case r.generalFlow.help.index():
      return getProceedActionHelp(state);
    // floor
    case r.generalFlow.pickup.floor():
      return { path: r.generalFlow.delivery.floor() };
    case r.generalFlow.delivery.floor():
      return { path: r.generalFlow.contact.index() };
    // contact
    case r.generalFlow.contact.index():
      return { path: r.generalFlow.contact.pickup() };
    case r.generalFlow.contact.pickup():
      return { path: r.generalFlow.contact.delivery() };
    case r.generalFlow.contact.delivery():
      return getProceedActionContactDelivery(state);
    // invoice
    case r.generalFlow.pickup.invoice():
      return { path: r.generalFlow.terms.index() };
  }

  return null;
};

const getProceedActionPickupIndex = (state: RootState): ProceedAction | null => {
  const situation = state?.generalTransport.choices?.situation;
  if (!situation) {
    return {
      missingChoice: 'situation',
    };
  }
  switch (situation) {
    case 'home':
      return { path: r.generalFlow.homeSituation.index() };
    case 'auction':
      return { path: r.generalFlow.pickup.auction() };
    case 'book_a_van':
    case 'store':
      return { path: r.generalFlow.items.index() };
  }
  return null;
};

const getProceedActionItems = (state: RootState): ProceedAction => {
  const pickupSituation = getPickupSituation(state);
  const homeSituation = state.generalTransport.choices?.home_situation;
  const quote = getQuote(state);
  const isMarketplaceUser = getIsMarketplaceUtmSource(state);
  const showProductPayment =
    quote?.guaranteed.directly_payable &&
    pickupSituation !== 'auction' &&
    (isMarketplaceUser || ['marktplaats', 'facebook_marketplace', '2dehands'].includes(homeSituation || ''));
  if (showProductPayment) {
    return { path: r.generalFlow.productPayment.index() };
  }

  const datesNotAvailable = getIsDateSelectionNotAvailable(state);
  if (datesNotAvailable) {
    return { path: r.generalFlow.help.index() };
  }
  return { path: getInitialDatePath(state) };
};

const getProceedActionProductPayment = (state: RootState): ProceedAction => {
  const optIn = getProductPaymentOptIn(state);
  if (optIn) {
    return { path: r.generalFlow.productPayment.amount() };
  }
  return { path: r.generalFlow.date.index() };
};

const getProceedActionDates = (state: RootState, currentPath: string): ProceedAction => {
  /**
   * When path is pickup AND the customer selected diff delivery date
   * => go to delivery date selection
   */
  if (currentPath === r.generalFlow.date.index() && getDifferentDeliveryDate(state)) {
    return { path: r.generalFlow.date.delivery() };
  }
  /**
   * When time selection is available
   * => go to time selection
   */
  const skipPickupTime = getSkipTimeOptions(state, StopType.PICKUP);
  const skipDeliveryTime = getSkipTimeOptions(state, StopType.DELIVERY);

  if (!skipPickupTime || !skipDeliveryTime) {
    return { path: !skipPickupTime ? r.generalFlow.time.index() : r.generalFlow.time.delivery() };
  }
  /**
   * In any other case we are dealing with a situation where time selection is not available.
   * => Inform user
   */
  return { showTimeModal: true };
};

const getProceedActionHelp = (state: RootState): ProceedAction => {
  if (!isFloorServiceAvailable(state)) {
    return { path: r.generalFlow.contact.index() };
  }
  return { path: r.generalFlow.pickup.floor() };
};

const getProceedActionContactDelivery = (state: RootState): ProceedAction => {
  const situation = getPickupSituation(state);
  if (['auction', 'store'].includes(situation || '')) {
    return { path: r.generalFlow.pickup.invoice() };
  }
  return { path: r.generalFlow.terms.index() };
};

import * as React from 'react';
import './grid.scss';

interface Props {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  extraClasses?: string;
  children: React.ReactNode;
}

export const Col = ({ xs = 0, sm = 0, md = 0, lg = 0, xl = 0, extraClasses = '', children }: Props): JSX.Element => {
  const gridClasses = ['col'];
  if (xs > 0) {
    gridClasses.push('col-' + xs);
  }
  if (sm > 0) {
    gridClasses.push('col-sm-' + sm);
  }
  if (md > 0) {
    gridClasses.push('col-md-' + md);
  }
  if (lg > 0) {
    gridClasses.push('col-lg-' + lg);
  }
  if (xl > 0) {
    gridClasses.push('col-xl-' + xl);
  }
  if (extraClasses.length > 0) {
    gridClasses.push(extraClasses);
  }
  return <div className={gridClasses.join(' ')}>{children}</div>;
};
export default Col;

import * as React from 'react';
import classNames from 'classnames';

import { IconSmile } from '../icons';
import './button.scss';
import { MouseEventHandler } from 'react';

interface Props {
  dataQaId?: string;
  buttonStyle?: ButtonStyleOption;
  extraClasses?: string;
  centered?: boolean;
  size?: 'sm';
  loading?: boolean;
  fullWidth?: boolean;
  iconPos?: 'left' | 'right';
  onClick?: MouseEventHandler;
}

export type ButtonStyleOption =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'outline'
  | 'delete'
  | 'grey'
  | 'is-disabled'
  | 'transparant';

type AllProps = Props & React.ButtonHTMLAttributes<Props> & React.AnchorHTMLAttributes<Props>;

const Button: React.FC<AllProps> = ({
  buttonStyle = 'primary',
  extraClasses = '',
  href,
  size,
  loading = false,
  target,
  disabled = false,
  fullWidth = false,
  onClick,
  iconPos,
  style,
  children,
  type = 'submit',
  dataQaId,
}) => {
  const className = classNames(
    'btn',
    `btn--${buttonStyle}`,
    {
      ['btn--loading']: loading,
      [`btn--size-${size}`]: size,
      [`btn--icon-${iconPos}`]: iconPos,
      ['btn--is-disabled']: disabled || loading,
      ['btn--full-width']: fullWidth,
    },
    extraClasses
  );

  if (href) {
    return (
      <a
        data-qa-id={dataQaId}
        className={className}
        href={href}
        target={target}
        style={disabled || loading ? { ...(style || {}), pointerEvents: 'none' } : style}
      >
        {children}
        {loading && <IconSmile spinning={true} />}
      </a>
    );
  }

  return (
    <button
      data-qa-id={dataQaId}
      style={style}
      className={className}
      onClick={onClick}
      disabled={disabled || loading}
      type={type}
    >
      {children}
      {loading && <IconSmile spinning={true} />}
    </button>
  );
};
export default Button;

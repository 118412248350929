import { SandboxToken } from '@brenger/api-client';

export type Environment = 'development' | 'production';

export interface ApiSettings {
  environment: Environment;
  sandbox: SandboxToken;
}

export enum ApiSettingsActionTypes {
  SET_ENVIRONMENT = '@@ApiSettings/SET_ENVIRONMENT',
  LOGIN_SANDBOX_START = '@@ApiSettings/LOGIN_SANDBOX_START',
  LOGIN_SANDBOX_SUCCESS = '@@ApiSettings/LOGIN_SANDBOX_SUCCESS',
  LOGIN_SANDBOX_FAILED = '@@ApiSettings/LOGIN_SANDBOX_FAILED',
  SET_SANDBOX_TOKEN = '@@ApiSettings/SET_SANDBOX_TOKEN',
}

export interface SetEnvironmentAction {
  type: ApiSettingsActionTypes.SET_ENVIRONMENT;
  payload: Environment;
}

export interface LoginSandboxStartAction {
  type: ApiSettingsActionTypes.LOGIN_SANDBOX_START;
}

export interface LoginSandboxResultAction {
  type: ApiSettingsActionTypes.LOGIN_SANDBOX_SUCCESS | ApiSettingsActionTypes.LOGIN_SANDBOX_FAILED;
}

export interface SetSandboxTokenAction {
  type: ApiSettingsActionTypes.SET_SANDBOX_TOKEN;
  payload: SandboxToken;
}

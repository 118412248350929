import React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { Field } from 'redux-form';
import {
  Heading,
  RfInputTiles,
  SectionWrapper,
  SheetLayoutContent,
  SheetLayoutHeader,
} from '../../../brenger-shared-ui';
import { useTypedSelector } from '../../../hooks';
import { translate } from '../../../utils/localization';
import { FieldNamesProductPayment } from '../interface';
import { productPaymentSchema } from '../schema';
import { proceed } from '../proceed';

export const ProductPaymentOptIn: React.FC = () => {
  const homeSituation = useTypedSelector(state => state.generalTransport.choices?.home_situation);
  return (
    <>
      <SheetLayoutHeader>
        <Heading size={2} extraClasses={'no-margin pb-0-5'}>
          <Translate
            id={'request_flow.headers.product_payment_opt_in'}
            data={{
              market_place:
                homeSituation !== 'acquaintances'
                  ? translate(`request_flow.home_situation.fields.type.options.${homeSituation}.name`)
                  : '',
            }}
          />
        </Heading>
      </SheetLayoutHeader>
      <SectionWrapper centerContent={false} sectionStyle={'white'}>
        <SheetLayoutContent>
          <Field
            validate={productPaymentSchema.opt_in}
            name={FieldNamesProductPayment.OPT_IN}
            options={[
              {
                title: translate('request_flow.product_payment.fields.opt_in.options.yes.label'),
                desc: translate('request_flow.product_payment.fields.opt_in.options.yes.desc'),
                value: true,
              },
              {
                title: translate('request_flow.product_payment.fields.opt_in.options.no.label'),
                desc: translate('request_flow.product_payment.fields.opt_in.options.no.desc'),
                value: false,
              },
            ]}
            component={RfInputTiles}
            onChangeCallback={proceed}
          />
        </SheetLayoutContent>
      </SectionWrapper>
    </>
  );
};

import React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router';
import { useHistory } from 'react-router-dom';
import {
  IconSmile,
  SheetLayoutContent,
  SheetLayoutContentWrapper,
  SheetLayoutHeader,
} from '../../../../brenger-shared-ui';
import { useTypedSelector } from '../../../../hooks';
import { MAX_WEIGHT } from '../../../../utils/global';
import FooterLayout from '../../containers/FooterLayout';
import { actions, getTbAuctionPlatform } from '../../ducks';
import { Header } from './Header';
import { ItemAdd } from './ItemAdd';
import { ItemDisplay } from './ItemDisplay';
import { ItemEdit } from './ItemEdit';

import { PsLangs } from '@brenger/api-client';
import { useModalState } from '@brenger/react';
import '../../../../assets/product-selection/index.scss';
import { clarity } from '../../../../configs/clarity';
import { r } from '../../../../routes';
import { getActiveLanguageSettings } from '../../../../utils/localization';
import { priceClient } from '../../../../utils/request';
import { proceed } from '../../proceed';
import { ItemPrefilledModal } from '../ItemPrefilledModal';
import { TbAuctionsAddItem } from './TbAuctionsAddItem';
import { FlexDatesModal } from '../FlexDatesModal';

export const ProductSelection: React.FC = () => {
  const langCode = getActiveLanguageSettings().labels.full.replace('-', '_') as PsLangs;
  const dispatch = useDispatch();
  const items = useTypedSelector(state => state.generalTransport.product_selection.items) || [];
  const action = useTypedSelector(state => state.generalTransport.product_selection.prefill);
  const history = useHistory();
  const [isLoading, setIsLoading] = React.useState(false);
  const prefillModal = useModalState();
  const tbAuctionPlatform = useTypedSelector(getTbAuctionPlatform);

  React.useEffect(() => {
    clarity.setTag('ps_new');
    dispatch(actions.setProgress(3));
  }, []);

  React.useEffect(() => {
    // Don't execute when multiple items are present
    if (items.length > 1) return;
    handleAction();
  }, [items]);

  const handleAction = async (): Promise<void> => {
    // nothing to execute here, move along
    if (!action || isLoading) return;

    // set loading flag first
    setIsLoading(true);
    dispatch(actions.removeItemPs({ all: true }));
    // Failed flag so we can check later if everything went according to plan
    let isFailed = false;
    switch (action.type) {
      case 'SEARCH':
        // Pushing the user straight to search with a prefilled query
        dispatch(actions.setQueryPs(action.value));
        history.push(r.generalFlow.items.add());
        break;
      case 'CONFIG':
        // Pushing the user straight to config and set the product we matched for him/her
        dispatch(
          actions.setSearchResultPs({
            iri: action.value,
          })
        );
        history.push(r.generalFlow.items.configure());
        break;
      case 'PREFILL':
        try {
          // fetch the product group
          const productGroup = await priceClient.productSelection.retrieveGroup({
            id: encodeURIComponent(action.value),
          });
          // dispatch an add item action
          dispatch(
            actions.addItemPs({
              title: productGroup.name[langCode],
              width: productGroup.items?.[0]?.width_suggested_cm as number,
              length: productGroup.items?.[0]?.length_suggested_cm as number,
              height: productGroup.items?.[0]?.height_suggested_cm as number,
              weight: productGroup.items?.[0]?.weight_suggested_kg || 0,
              count: 1,
              product_groups: [productGroup.iri],
              ps_temp: {
                group: productGroup || null,
                isManual: false,
                properties: {},
                origin: action,
                fullTitle: productGroup.name[langCode],
              },
            })
          );
        } catch (e) {
          isFailed = true;
          // eslint-disable-next-line no-console
          console.log(e);
        }
    }
    // all systems green?
    if (!isFailed) {
      // clean up - we don't want to execute over and over, just prefill once
      dispatch(actions.setPrefillPs(null));
    }
    setIsLoading(false);
  };

  return (
    <>
      <SheetLayoutContentWrapper>
        {isLoading && <IconSmile spinning={true} centered={true} />}
        {!isLoading && (
          <>
            <SheetLayoutHeader>
              <Header hasItems={!!items.length} />
            </SheetLayoutHeader>

            <SheetLayoutContent>
              <>
                {items.map((item, i) => {
                  return <ItemDisplay data-qa-id={`ps-item-display-${i}`} key={item.ps_temp.uuid} item={item} />;
                })}
              </>
              {tbAuctionPlatform && <TbAuctionsAddItem platform={tbAuctionPlatform} isFirstItem={!items.length} />}
              <div className="ps-warn-suffix">
                <Translate
                  id={'request_flow.product_selection.max_transport_weight'}
                  data={{
                    weightInKg: MAX_WEIGHT,
                  }}
                />
              </div>
            </SheetLayoutContent>
          </>
        )}
      </SheetLayoutContentWrapper>
      <ItemPrefilledModal
        isOpen={prefillModal.isActive}
        onCancel={() => {
          prefillModal.close();
          // when more then one item exists, we only close the modal
          if (items.length > 1) {
            return;
          }
          // when there is only one item then send them directly to the edit screen
          history.push(r.generalFlow.items.edit({ uuid: items?.[0]?.ps_temp.uuid as string }));
        }}
        onConfirm={proceed}
      />
      <FlexDatesModal />
      <FooterLayout
        customSubmitFunction={() => {
          // When we prefill items, we want to warn the user if they didn't reviewed the item config
          if (items.some(itemSet => itemSet.ps_temp.origin?.type === 'PREFILL' && !itemSet.ps_temp.reviewed)) {
            prefillModal.open();
            return;
          }
          // else we need at least one item set to continue
          if (items.length) proceed();
        }}
      />
      <Switch>
        <Route exact={false} path={r.generalFlow.items.add()} component={ItemAdd} />
        <Route path={r.generalFlow.items.edit({})} component={ItemEdit} />
      </Switch>
    </>
  );
};

import React, { Component } from 'react';

import './tooltip.scss';
import { IconInfo } from '../../../';

interface Props {
  color: 'white' | 'blue';
}

class Tooltip extends Component<React.PropsWithChildren & Props> {
  private tooltip = React.createRef<HTMLDivElement>();
  private tooltipBody = React.createRef<HTMLDivElement>();

  public componentDidMount(): void {
    this.addClassTooltip();
  }
  public render(): JSX.Element {
    return (
      <div
        className={'brenger_tooltip brenger_tooltip-' + this.props.color}
        ref={this.tooltip}
        onClick={event => event.preventDefault()}
      >
        <IconInfo color={this.props.color} />
        <div className={'brenger_tooltip-body'} ref={this.tooltipBody}>
          {this.props.children}
        </div>
      </div>
    );
  }
  public addClassTooltip(): void {
    const tooltip = this.tooltip.current;
    const tooltipBody = this.tooltipBody.current;
    if (!tooltip || !tooltipBody) {
      return;
    }
    const positionRec = tooltipBody.getBoundingClientRect() as DOMRect;

    if (positionRec.left < 0) {
      tooltip.classList.add('brenger_tooltip-right');
    } else {
      tooltip.classList.remove('brenger_tooltip-right');
    }
    if (positionRec.right < 0) {
      tooltip.classList.add('brenger_tooltip-left');
    } else {
      tooltip.classList.remove('brenger_tooltip-left');
    }
  }
}

export default Tooltip;

import { add, isBefore, isSameDay, isSameMinute, set } from 'date-fns';
import React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { Field } from 'redux-form';
import { RfSelect } from '../../../brenger-shared-ui';
import { useTypedSelector } from '../../../hooks';
import { RootState, StopType } from '../../../typings';
import { formatDate } from '../../../utils/datetime';
import { TIME_END_MAX, TIME_SLOT_LENGTH, TIME_START_MIN } from '../../../utils/global';
import { getTimeFormValues } from '../containers/Time';
import { getStopAvailableDate } from '../ducks';
import { pickupDeliverySchema } from '../schema';

interface BusinessHourOption {
  label: string;
  value: string;
}

interface HoursAndMinutes {
  hours: number;
  minutes: number;
}

export const TimeCustomOptionsBusinessHours: React.FC = () => {
  const selectedDatePickupForm = useTypedSelector((state: RootState) => getStopAvailableDate(state, StopType.PICKUP));
  // deconstruct selected date, to properly feed it to a date constructor, this is to accomadate the special kind special diet of Mr. Safari.
  const [selectedYear, selectedMonth, selectedDate] = selectedDatePickupForm?.[0]?.split('-'); // pricing format, so: year-month-date
  // set selected date
  const selectedPickupDate = set(new Date(), {
    year: Number(selectedYear),
    month: Number(selectedMonth) - 1,
    date: Number(selectedDate),
  });
  /**
   * Holds selected time, as iso string
   */
  const selectedStartTime = useTypedSelector(getTimeFormValues)?.pickup?.start;

  /**
   * Start time
   * We check if pickup is today and see if the local time relative to language setting is after 06:00 in the morning
   * if so:
   * - Pickup start can be earliest at 2 hours later then that
   * - Pickup end can be earliest at 6 hours later then that, because it should account for the default time slot
   * if not:
   * - Pickup start can be earliest at 08:00
   * - Pickup end can be earliest at 12:00
   **/
  const timeAhead = 2;
  const getStartTime = (type: 'start' | 'end'): HoursAndMinutes => {
    const now = new Date();
    const isPickupToday = isSameDay(selectedPickupDate, now);
    const defaultStartTime =
      type === 'start'
        ? { hours: TIME_START_MIN, minutes: 0 }
        : { hours: TIME_START_MIN + TIME_SLOT_LENGTH, minutes: 0 };
    if (isPickupToday && now.getHours() > TIME_START_MIN - timeAhead) {
      const adjustStartTime = add(now, { hours: type === 'start' ? timeAhead : timeAhead + TIME_SLOT_LENGTH });
      return {
        hours: adjustStartTime.getHours(),
        minutes: 0,
      };
    }
    return defaultStartTime;
  };

  // Time options will always be calculated back to UTC (value), the label will be in local time,
  const getTimeOptions = (timeStart: HoursAndMinutes, timeEnd: HoursAndMinutes): BusinessHourOption[] => {
    let dateTime = set(selectedPickupDate, { hours: timeStart.hours, minutes: timeStart.minutes, seconds: 0 });
    const endDateTime = set(selectedPickupDate, { hours: timeEnd.hours, minutes: timeEnd.minutes, seconds: 0 });
    const options: BusinessHourOption[] = [];
    while (isSameMinute(dateTime, endDateTime) || isBefore(dateTime, endDateTime)) {
      const value = dateTime.toISOString();
      options.push({
        label: formatDate(value, 'hour-minute'),
        value,
      });
      dateTime = add(dateTime, { minutes: 30 });
    }
    return options;
  };

  const getStartTimeOptions = (): BusinessHourOption[] => {
    const startAt = getStartTime('start');
    return getTimeOptions(startAt, { hours: TIME_END_MAX - TIME_SLOT_LENGTH, minutes: 0 });
  };

  const getEndTimeOptions = (): BusinessHourOption[] => {
    const startDtp = selectedStartTime ? add(new Date(selectedStartTime), { hours: TIME_SLOT_LENGTH }) : undefined;

    // If we have a selected start time, then take into account the default timeslot and start generating time options from that time onward
    const startAt = startDtp ? { hours: startDtp.getHours(), minutes: startDtp.getMinutes() } : getStartTime('end');
    return getTimeOptions(startAt, { hours: TIME_END_MAX, minutes: 0 });
  };

  // Generating these options is quite heavy and shouldn't be done on every re-render
  // So start times will only be generated on mount
  const startOptions = React.useMemo(getStartTimeOptions, []);
  // End times will be regenerated when start time is selected
  const endOptions = React.useMemo(getEndTimeOptions, [selectedStartTime]);

  return (
    <div className="trigger input-el--tile--option delay-index-0 input-el--tile--option--align-top input-el--tile--option--time">
      <div className={'input-el--tile--option--time-fields pt-1'}>
        <Field
          validate={pickupDeliverySchema.pickup_time_start}
          name={`pickup.start`}
          label={<Translate id={'request_flow.time.fields.time_start.label'} />}
          options={startOptions}
          component={RfSelect}
        />
        <Field
          validate={pickupDeliverySchema.pickup_time_end}
          name={`pickup.end`}
          label={<Translate id={'request_flow.time.fields.time_end.label'} />}
          options={endOptions}
          component={RfSelect}
        />
      </div>
    </div>
  );
};

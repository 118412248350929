import React from 'react';
import { InputWrapper } from './InputWrapper';
import { InputMeta } from '.';

interface InputChipsProps {
  id: string;
  title: string;
  multiple: boolean;
  value?: string[];
  onChange(selected: string[]): void;
  options: { label: string; value: string }[];
  meta?: InputMeta;
}

export const InputChips: React.FC<InputChipsProps> = ({ id, value, title, multiple, onChange, options, meta }) => {
  const update = (newVal: string): void => {
    if (!multiple) {
      onChange([newVal]);
      return;
    }
    const valSet = new Set(value);
    if (valSet.has(newVal)) {
      valSet.delete(newVal);
    } else {
      valSet.add(newVal);
    }
    onChange([...valSet]);
  };
  return (
    <InputWrapper {...meta}>
      <div className="ps-config--chips">
        {title}
        <div className="ps-config--chips-choices">
          {options.map((option, i) => {
            return (
              <div key={i} className="ps-config--chips-choice">
                <input
                  id={id + '_' + i}
                  type={multiple ? 'checkbox' : 'radio'}
                  name={id}
                  onChange={() => update(option.value)}
                  checked={value?.includes(option.value)}
                />
                <label data-qa-id={id + '_' + i} className={'ps-config--chips-choice--label'} htmlFor={id + '_' + i}>
                  {option.label}
                </label>
              </div>
            );
          })}
        </div>
      </div>
    </InputWrapper>
  );
};

interface InputChipsOnClickProps {
  id: string;
  title: string;
  value?: string;
  options: { label: string; value: string; onClick(): void }[];
}

export const InputChipsOnClick: React.FC<InputChipsOnClickProps> = ({ id, value, title, options }) => {
  return (
    <div className="ps-config--chips">
      {title}
      <div className="ps-config--chips-choices">
        {options.map((option, i) => {
          return (
            <div key={i} className="ps-config--chips-choice">
              <input
                id={id + '_' + i}
                type={'radio'}
                name={id}
                defaultChecked={value === option.value}
                onClick={option.onClick}
              />
              <label className={'ps-config--chips-choice--label'} htmlFor={id + '_' + i}>
                {option.label}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

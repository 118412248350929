import React from 'react';
import { IconSmile } from '../basics/icons';
import cn from 'classnames';
import { InputMeta, InputWrapper } from './';

type ModifiedSelect = Omit<React.InputHTMLAttributes<HTMLSelectElement>, 'onChange'>;
interface InputSelect extends ModifiedSelect {
  onChange: (value: string) => void;
  addEmptyOption?: boolean;
  options: {
    label: string;
    value: string;
  }[];
  meta?: InputMeta;
}

export const InputSelect: React.FC<InputSelect> = ({
  options,
  addEmptyOption = true,
  onChange,
  style,
  className,
  meta,
  ...unused
}) => {
  return (
    <InputWrapper {...meta}>
      <div className={'relative'}>
        <select
          onChange={(event: React.SyntheticEvent): void => {
            event.preventDefault();
            const target = event.target as HTMLSelectElement;
            onChange?.(target.value);
          }}
          style={{
            WebkitAppearance: 'none',
            MozAppearance: 'none',
            appearance: 'none',
            backgroundImage: 'none',
            ...style,
          }}
          className={cn('input-default', className)}
          {...unused}
        >
          {addEmptyOption && <option />}
          {options.map((item, index) => (
            <option key={index} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
        <div
          style={{
            position: 'absolute',
            top: '1.4rem',
            right: '1.5rem',
            transform: 'scale(.8)',
            pointerEvents: 'none',
          }}
        >
          <IconSmile smileDirection="down" />
        </div>
      </div>
    </InputWrapper>
  );
};

import * as React from 'react';
import './sheetlayout.scss';
import { IconSmile } from '../../basics/icons';

interface Props {
  isNavigating?: boolean;
  navForwards?: boolean;
  sheetOpen?: boolean;
  loading?: boolean;
  sidebar: React.ReactNode;
}

export const SheetLayout: React.FC<React.PropsWithChildren & Props> = ({
  isNavigating = false,
  navForwards = true,
  sheetOpen = false,
  loading = false,
  sidebar,
  children,
}) => {
  return (
    <section
      className={
        'sheet-layout sheet-layout--nav-' +
        (navForwards ? 'forwards' : 'backwards') +
        (sheetOpen ? ' sheet-layout--is-active' : '') +
        (isNavigating ? ' sheet-layout--is-navigating' : '')
      }
    >
      <main className={'sheet-layout--main'}>
        <div className={'sheet-layout--main-container'}>
          <div>{!loading ? children : <IconSmile spinning={true} />}</div>
        </div>
      </main>
      <aside className={'sheet-layout--sidebar'}>{sidebar}</aside>
    </section>
  );
};
export default SheetLayout;

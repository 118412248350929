import { fork } from 'typed-redux-saga';
import { generalTransportSagas } from '../../modules/GeneralFlow/sagas';
import { userSagas } from '../../modules/User/sagas';
import { baseSagas } from './baseSagas';
import { businessSaga } from '../../modules/BusinessFlow/sagas';
import { apiSettingsSagas } from '../../modules/ApiSettings/sagas';

export function* rootSaga(): Generator {
  yield* fork(baseSagas);
  yield* fork(userSagas);
  yield* fork(generalTransportSagas);
  yield* fork(businessSaga);
  yield* fork(apiSettingsSagas);
}

import { CO2Savings } from '@brenger/utils';
import React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { useDispatch } from 'react-redux';
import {
  Button,
  CirclePerson2,
  Col,
  Heading,
  IconCheckmarkCircle,
  Row,
  SectionWrapper,
  ShareButtons,
} from '../../../../brenger-shared-ui';
import { Config } from '../../../../config';
import { ThankYouPageType } from '../../../../hooks';
import { SessionStorageKeys } from '../../../../typings';
import { translate } from '../../../../utils/localization';
import { actions as bfActions } from '../../../BusinessFlow/ducks';
import { actions as gfActions } from '../../../GeneralFlow/ducks';
import { BfButtons } from './ButtonsBf';
import { ButtonsGf } from './ButtonsGf';

interface Props {
  type?: ThankYouPageType;
  trId?: string;
  transKey?: string;
  to?: string;
  from?: string;
  distance?: number;
  savings?: CO2Savings;
}

export const Success: React.FC<Props> = ({ type, trId, transKey, to, from, distance, savings }) => {
  const dispatch = useDispatch();
  /**
   * On success we clear transaction events flag and reset the flows
   */
  React.useEffect(() => {
    sessionStorage.removeItem(SessionStorageKeys.IS_TRANSACTION_EVENT_FIRED);
    switch (type) {
      case 'opp':
      case 'not_guaranteed':
      case 'general':
        dispatch(gfActions.resetFlow(true));
        break;
      case 'business':
        dispatch(bfActions.resetFlow());
        break;
    }
  }, [type]);

  return (
    <Col xs={12}>
      <SectionWrapper sectionStyle={'primary'} centerContent={true}>
        <Row>
          <Col xs={12} extraClasses={'text--center thank-you-opening'}>
            <CirclePerson2 />
            <IconCheckmarkCircle />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={8} extraClasses={'offset-md-2'}>
            <Row>
              <Col xs={12} extraClasses={'pt-1 pb-1'}>
                <Heading size={4}>
                  <Translate id={`request_flow.${transKey}.title`} />
                </Heading>
              </Col>
              <Col xs={12}>
                <div
                  dangerouslySetInnerHTML={{
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    __html: String(translate(`request_flow.${transKey}.further_steps`)),
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </SectionWrapper>
      <SectionWrapper sectionStyle={'white'} centerContent={true}>
        <Row>
          <Col xs={12} md={8} extraClasses={'offset-md-2 pb-1'}>
            {/* In case of the edit flow, it is really sober, only a "go to status page" button and thats it */}
            {type !== 'upsell' && (
              <Heading size={5}>
                <Translate id={`request_flow.${transKey}.sub_title`} />
              </Heading>
            )}
            {type !== 'business' && trId && (
              <Button fullWidth={true} href={`${Config.TRACKING_BASE_URL}/${trId}`} buttonStyle={'primary'}>
                <Translate id={`request_flow.general.thank_you.track_and_trace_request`} />
              </Button>
            )}
          </Col>
          {/* We don't show the fun facts yet for these flows */}
          {!['upsell', 'business'].includes(type as ThankYouPageType) && (
            <>
              <Col xs={12} md={8} extraClasses={'offset-md-2 pb-1 pt-1'}>
                <div>
                  <b>
                    <Translate id={`request_flow.general.thank_you.did_you_know_title`} />
                  </b>
                  <div
                    className={'bold--secondary'}
                    dangerouslySetInnerHTML={{
                      // eslint-disable-next-line @typescript-eslint/naming-convention
                      __html: String(
                        translate(`request_flow.general.thank_you.did_you_know_text`, {
                          co2: savings?.inKg,
                          hamburgers: `${savings?.inHamburgers} 🍔`, // Yes emoji time!!
                          shower_turns: `${savings?.inShowerTurns} 🚿`, // Yes emoji time!!
                          to,
                          from,
                          distance: `${distance?.toFixed(1)} km`,
                        })
                      ),
                    }}
                  />
                </div>
              </Col>
              <Col xs={12} md={8} extraClasses={'offset-md-2 pb-1'}>
                <ShareButtons
                  message={translate(' request_flow.general.thank_you.shared_text ', {
                    co2: savings?.inKg,
                    hamburgers: savings?.inHamburgers,
                    shower_turns: savings?.inShowerTurns,
                    to,
                    from,
                    distance: `${distance?.toFixed(1)} km`,
                  })}
                />
              </Col>
            </>
          )}
          <Col xs={12} md={8} extraClasses={'offset-md-2 pb-1'}>
            {type === 'business' && <BfButtons />}
            {(type === 'general' || type === 'not_guaranteed') && <ButtonsGf id={trId as string} />}
          </Col>
        </Row>
      </SectionWrapper>
    </Col>
  );
};

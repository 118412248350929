import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';
import { Dispatch } from 'redux';
import { InjectedFormProps, getFormValues, reduxForm } from 'redux-form';
import { SheetLayoutContentWrapper } from '../../../brenger-shared-ui';
import { r } from '../../../routes';
import { RootState } from '../../../typings';
import { SubStepAuctionTypes } from '../components/SubStepAuctionTypes';
import SubStepFloor from '../components/SubStepHomeFloorCombined';
import { SubStepInvoice } from '../components/SubStepInvoice';
import { actions as flowActions, getPickupSituation, getPickupStep } from '../ducks';
import { AuctionTypes, GeneralFlowForms } from '../interface';
import FooterLayout from './FooterLayout';

export interface PickupFormValues {
  pickup: {
    auction_type: AuctionTypes;
  };
}

type ReduxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

type Form = InjectedFormProps<PickupFormValues, ReduxProps>;

type Props = ReduxProps & Form;

export const getPickupFormValues = (state: RootState): null | Partial<PickupFormValues> => {
  return getFormValues(GeneralFlowForms.PICKUP)(state) as null | Partial<PickupFormValues>;
};

class Pickup extends React.Component<Props> {
  public componentDidMount(): void {
    this.props.setProgress(this.props.step);
  }
  public render(): JSX.Element {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <SheetLayoutContentWrapper>
          <Route exact={true} path={r.generalFlow.pickup.auction()} component={SubStepAuctionTypes} />
          <Route
            exact={true}
            path={r.generalFlow.pickup.invoice()}
            render={() => <SubStepInvoice error={this.props.transportError} situation={this.props.pickupSituation} />}
          />
          <Route exact={true} path={r.generalFlow.pickup.floor()} component={SubStepFloor} />
          <FooterLayout />
        </SheetLayoutContentWrapper>
      </form>
    );
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState) => ({
  transportError: state.generalTransport.error,
  step: getPickupStep(state),
  pickupSituation: getPickupSituation(state),
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: Dispatch) => ({
  setProgress: step => dispatch(flowActions.setProgress(step)),
});

export default reduxForm<PickupFormValues, Props>({
  form: GeneralFlowForms.PICKUP,
  destroyOnUnmount: false,
  onSubmit: (formResults, dispatch) => {
    dispatch(flowActions.submitPickup(formResults));
  },
})(connect(mapStateToProps, mapDispatchToProps)(Pickup));

import React from 'react';
import { SectionWrapper } from '../../../brenger-shared-ui';
import { RouteComponentProps } from 'react-router';
import { InjectedFormProps } from 'redux-form';
import IconSmile from '../../../brenger-shared-ui/components/basics/icons/IconSmile';
import { Translate } from 'react-localize-redux-dep-updated';

type Props = InjectedFormProps<unknown> & RouteComponentProps;

class VerifySocialAuth extends React.Component<Props> {
  public render(): JSX.Element {
    return (
      <SectionWrapper sectionStyle={'white'}>
        <IconSmile spinning={true} /> <Translate id={'authentication.login.redirect_message'} />
      </SectionWrapper>
    );
  }
}

export default VerifySocialAuth;

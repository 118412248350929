import * as React from 'react';
import './icons.scss';

const IconPersonTwo = (): JSX.Element => {
  return (
    <svg className={'IconPersonTwo'} width="37" height="40" viewBox="0 0 37 40">
      <g fill="#0073FA" fillRule="evenodd">
        <path d="M19.105 38.078h16.592v-2.97c0-3.794-3.004-6.881-6.695-6.881H25.8c-3.692 0-6.695 3.087-6.695 6.881v2.97zm17.144 1.136H18.553a.56.56 0 0 1-.553-.568v-3.538c0-4.42 3.5-8.017 7.8-8.017h3.202c4.3 0 7.8 3.597 7.8 8.017v3.538a.56.56 0 0 1-.553.568z" />
        <path d="M22.685 39.214a.56.56 0 0 1-.553-.568v-5.493a.56.56 0 0 1 .553-.568.56.56 0 0 1 .552.568v5.493a.56.56 0 0 1-.552.568M32.7 39.214a.56.56 0 0 1-.552-.568v-5.493a.56.56 0 0 1 .553-.568.56.56 0 0 1 .552.568v5.493a.56.56 0 0 1-.552.568M29.493 27.778L28.31 30.21a.663.663 0 0 1-1.199 0l-1.183-2.432h3.565zM34.208 20.035a.556.556 0 0 1-.542-.465c-.54-3.014-3.1-5.202-6.085-5.202-2.986 0-5.545 2.188-6.085 5.202a.555.555 0 0 1-.643.456.567.567 0 0 1-.444-.661c.637-3.553 3.653-6.133 7.172-6.133 3.519 0 6.535 2.58 7.171 6.133a.567.567 0 0 1-.544.67" />
        <path d="M27.58 28.227c-3.518 0-6.534-2.58-7.17-6.132a.567.567 0 0 1 .443-.662.554.554 0 0 1 .643.456c.54 3.014 3.099 5.202 6.085 5.202 2.986 0 5.545-2.188 6.085-5.202a.554.554 0 0 1 .643-.456c.3.057.499.353.443.662-.636 3.553-3.652 6.132-7.171 6.132" />
        <g>
          <path d="M1.105 38.078h16.592v-2.97c0-3.794-3.004-6.881-6.695-6.881H7.8c-3.692 0-6.695 3.087-6.695 6.881v2.97zm17.144 1.136H.553A.56.56 0 0 1 0 38.646v-3.538c0-4.42 3.5-8.017 7.8-8.017h3.202c4.3 0 7.8 3.597 7.8 8.017v3.538a.56.56 0 0 1-.553.568z" />
          <path d="M4.685 39.214a.56.56 0 0 1-.553-.568v-5.493a.56.56 0 0 1 .553-.568.56.56 0 0 1 .552.568v5.493a.56.56 0 0 1-.552.568M14.7 39.214a.56.56 0 0 1-.552-.568v-5.493a.56.56 0 0 1 .553-.568.56.56 0 0 1 .552.568v5.493a.56.56 0 0 1-.552.568M11.493 27.778L10.31 30.21a.663.663 0 0 1-1.199 0l-1.183-2.432h3.565zM16.208 20.035a.556.556 0 0 1-.542-.465c-.54-3.014-3.1-5.202-6.085-5.202-2.986 0-5.545 2.188-6.085 5.202a.555.555 0 0 1-.643.456.567.567 0 0 1-.444-.661c.637-3.553 3.653-6.133 7.172-6.133 3.519 0 6.535 2.58 7.171 6.133a.567.567 0 0 1-.544.67" />
          <path d="M9.58 28.227c-3.518 0-6.534-2.58-7.17-6.132a.567.567 0 0 1 .443-.662.554.554 0 0 1 .643.456c.54 3.014 3.099 5.202 6.085 5.202 2.986 0 5.545-2.188 6.085-5.202a.554.554 0 0 1 .643-.456c.3.057.499.353.443.662-.636 3.553-3.652 6.132-7.171 6.132" />
        </g>
      </g>
    </svg>
  );
};

export default IconPersonTwo;

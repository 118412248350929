import * as React from 'react';
import './icons.scss';

export const IconSituationAcquaintances = (): JSX.Element => {
  return (
    <svg className={'IconAcquaintances'} width="22" height="20" fill="none" viewBox="0 0 22 20">
      <path
        d="M17.749 8.83a4.787 4.787 0 002.18-4.012A4.792 4.792 0 0015.142.03 4.79 4.79 0 0011 2.421 4.79 4.79 0 006.858.031 4.792 4.792 0 002.07 4.818c0 1.679.869 3.158 2.18 4.013A6.868 6.868 0 000 15.174v2.416a2.373 2.373 0 002.37 2.37h17.26A2.373 2.373 0 0022 17.59v-2.416a6.868 6.868 0 00-4.251-6.343zm-2.607-7.51a3.502 3.502 0 013.498 3.498 3.502 3.502 0 01-3.498 3.498 3.502 3.502 0 01-3.497-3.498 3.502 3.502 0 013.497-3.498zm-4.144 8.39a6.858 6.858 0 00-1.533-.88A4.824 4.824 0 0011 7.216c.378.65.905 1.205 1.534 1.615a6.845 6.845 0 00-1.536.88zM3.36 4.818A3.502 3.502 0 016.858 1.32a3.502 3.502 0 013.497 3.498 3.502 3.502 0 01-3.497 3.498A3.502 3.502 0 013.36 4.818zm7.295 13.853H2.37a1.082 1.082 0 01-1.082-1.081v-2.416a5.575 5.575 0 015.569-5.57 5.575 5.575 0 015.569 5.57v2.416c0 .596-.486 1.081-1.082 1.081h-.69zM20.71 17.59c0 .596-.485 1.081-1.081 1.081h-6.176c.167-.324.262-.692.262-1.081v-2.416a6.83 6.83 0 00-1.752-4.573 5.56 5.56 0 013.178-.996 5.575 5.575 0 015.569 5.569v2.416z"
        className="cls-1"
      />
    </svg>
  );
};

import * as React from 'react';
import './icons.scss';

const IconDoor = (): JSX.Element => {
  return (
    <svg className={'IconDoor'} version="1.1" x="0px" y="0px" viewBox="0 0 115.2 84">
      <g>
        <path
          d="M31.4,39.7c1.1,0,2-0.9,2-2V4h48.2v33.7c0,1.1,0.9,2,2,2s2-0.9,2-2V2c0-1.1-0.9-2-2-2H31.4c-1.1,0-2,0.9-2,2v35.7
      C29.4,38.8,30.3,39.7,31.4,39.7z"
        />
        <path
          d="M102,80H85.7V47.7c0-1.1-0.9-2-2-2s-2,0.9-2,2V80H33.4V47.7c0-1.1-0.9-2-2-2s-2,0.9-2,2V80H13.2c-1.1,0-2,0.9-2,2
      s0.9,2,2,2H102c1.1,0,2-0.9,2-2S103.1,80,102,80z"
        />
        <path d="M68.3,39.8c-1.1,0-2,0.9-2,2s0.9,2,2,2h7.5c1.1,0,2-0.9,2-2s-0.9-2-2-2H68.3z" />
      </g>
    </svg>
  );
};

export default IconDoor;

import React from 'react';
import { Dialog } from '../../../../brenger-shared-ui';
import '../../../../assets/product-selection/modal.scss';
import cn from 'classnames';
interface Props {
  isActive: boolean;
  closeHandler(): void;
  closeByBackdrop?: boolean;
  isPrompt?: boolean;
}

export const Modal: React.FC<React.PropsWithChildren & Props> = ({
  isActive,
  closeHandler,
  children,
  closeByBackdrop = true,
  isPrompt = false,
}) => {
  return (
    <Dialog isActive={isActive}>
      <div className="modal--backdrop" onClick={closeByBackdrop ? closeHandler : undefined}>
        <div className="sheet-layout--main">
          <div className="sheet-layout--main-container" style={{ height: '100vh' }}>
            <div className="sheet-layout--container" style={{ height: '100vh' }}>
              <div
                className={cn('ps-modal--content', { 'ps-modal--prompt': isPrompt })}
                onClick={e => e.stopPropagation()}
              >
                <div className={cn({ 'ps-modal--prompt--inner': isPrompt })}>{children}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

import * as React from 'react';
import './icons.scss';

export const IconSituationOther = (): JSX.Element => {
  return (
    <svg width="22" height="19" viewBox="0 0 22 19" fill="none">
      <path
        d="M21.6483 8.29644L13.7034 0.351282C13.4766 0.124487 13.1743 0 12.852 0C12.5293 0 12.2272 0.124666 12.0005 0.351282L11.2791 1.0728C11.0525 1.29924 10.9276 1.60169 10.9276 1.92418C10.9276 2.24649 11.0525 2.55913 11.2791 2.78557L15.9141 7.43075H1.18853C0.524597 7.43075 0 7.95052 0 8.61463V9.63467C0 10.2988 0.524597 10.871 1.18853 10.871H15.9667L11.2793 15.5421C11.0527 15.7689 10.9278 16.0631 10.9278 16.3856C10.9278 16.7077 11.0527 17.0062 11.2793 17.2328L12.0006 17.952C12.2274 18.1788 12.5295 18.3024 12.8522 18.3024C13.1745 18.3024 13.4768 18.1772 13.7036 17.9504L21.6485 10.0054C21.8759 9.77794 22.0009 9.47423 22 9.15139C22.0007 8.82747 21.8759 8.52359 21.6483 8.29644Z"
        className="cls-1"
      />
    </svg>
  );
};

import { getLocation, goBack } from 'connected-react-router';
import React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { RootState } from 'src/typings';
import '../../assets/general-flow/options.scss';
import '../../assets/general-flow/sidebar.scss';
import '../../assets/general-flow/smallStyling.scss';
import '../../assets/general-flow/widget.scss';
import { ProgressHeader, SheetLayout, SheetLayoutContentWrapper, StepIndicator } from '../../brenger-shared-ui';
import { useTypedSelector } from '../../hooks';
import { translate } from '../../utils/localization';
import { HelpModal } from './components/HelpModal';
import { ProductSelection } from './components/ProductSelection';
import { NewContact } from './components/contact';
import DateContainer from './containers/Date';
import Delivery from './containers/Delivery';
import Destination from './containers/Destination';
import { SubStepHomeSituation } from './components/SubStepHomeSituation';
import { OppPayment } from './containers/OppPayment';
import Pickup from './containers/Pickup';
import ProductPayment from './containers/ProductPayment';
import SidebarLayout from './containers/SidebarLayout';
import Terms from './containers/Terms';
import TimeContainer from './containers/Time';
import { actions as flowActions } from './ducks';
import { SubStepHelpCombined } from './components/SubStepHelpCombined';
import { r } from '../../routes';
import { SubStepSituation } from './components/SubStepSituation';
import { appendAbPixel } from '../../utils/eventTracking';

export const FLOW_TYPE = 'general';

export const GeneralFlow: React.FC = () => {
  const dispatch = useDispatch();
  const { pathname } = useTypedSelector(getLocation);
  const progressStep = useTypedSelector((state: RootState) => state.generalTransport.layout.step);
  const abTests = useTypedSelector((state: RootState) => state.generalTransport.ab);
  const ref = useTypedSelector((state: RootState) => state.generalTransport.referrer);
  const layout = useTypedSelector((state: RootState) => state.generalTransport.layout);

  React.useEffect(() => {
    window.document.title = 'Brenger';
    if (layout.step < 6) {
      dispatch(flowActions.setVatNumber(''));
    }
    // mark it for styling
    document.body.classList.add('transport-request-flow');
    // plus clean it up
    return document.body.classList.remove('transport-request-flow');
  }, []);

  /**
   * FIXME: Here to proof if the AB tool is doing the job
   * Can be deleted later
   * Basically triggers a conversion evertime the flow loads.
   */
  React.useEffect(() => {
    if (abTests && abTests.length && ref) {
      abTests.forEach(test => {
        appendAbPixel(test.id, ref);
      });
    }
  }, [JSON.stringify(abTests), ref]);

  return (
    <>
      <ProgressHeader
        goBack={() => dispatch(goBack())}
        goBackText={String(translate('nav.previous'))}
        currentStep={progressStep}
        totalSteps={7}
      />
      <SheetLayout sheetOpen={layout.sheet} sidebar={<SidebarLayout />} loading={layout.step_loading}>
        <SheetLayoutContentWrapper className={'sheet-layout--content-wrapper--top-header'}>
          <div className={'sheet-layout--container'}>
            <StepIndicator currentStep={layout.step}>
              {pathname.includes('invoice') ? (
                <Translate id={'request_flow.auction.lot_add_invoice'} />
              ) : (
                <Translate id={'request_flow.headers.step_' + layout.step} />
              )}
              <HelpModal />
            </StepIndicator>
          </div>
        </SheetLayoutContentWrapper>
        <Switch>
          <Route path={r.generalFlow.destination.index()} exact={true} component={Destination} />
          <Route exact={true} path={r.generalFlow.pickup.index()} component={SubStepSituation} />
          <Route path={r.generalFlow.homeSituation.index()} component={SubStepHomeSituation} />
          <Route path={r.generalFlow.items.index()} exact={false} component={ProductSelection} />
          <Route path={r.generalFlow.help.index()} component={SubStepHelpCombined} />
          <Route path={r.generalFlow.productPayment.index()} component={ProductPayment} />
          <Route path={r.generalFlow.date.index()} component={DateContainer} />
          <Route path={r.generalFlow.time.index()} component={TimeContainer} />
          <Route path={r.generalFlow.pickup.index()} component={Pickup} />
          <Route path={r.generalFlow.delivery.index()} component={Delivery} />
          <Route path={r.generalFlow.contact.index()} exact={false} component={NewContact} />
          <Route path={r.generalFlow.terms.index()} component={Terms} />
          <Route path={r.generalFlow.oppPayment({})} component={OppPayment} />
          <Redirect to={r.generalFlow.destination.index()} />
        </Switch>
      </SheetLayout>
    </>
  );
};

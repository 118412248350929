import React from 'react';
import { Col, Row } from '../../../brenger-shared-ui';
import { Translate } from 'react-localize-redux-dep-updated';

interface Props {
  socialAuth(Social: string): void;
}

const SocialButtons: React.FC<Props> = props => {
  return (
    <Row>
      <Col xs={12} sm={6}>
        <a className="loginBtn loginBtn--facebook" onClick={() => props.socialAuth('facebook')}>
          <Translate id={'authentication.login.fb_button_label'} />
        </a>
      </Col>
      <Col xs={12} sm={6}>
        <a className="loginBtn loginBtn--google" onClick={() => props.socialAuth('google')}>
          <Translate id={'authentication.login.google_button_label'} />
        </a>
      </Col>
    </Row>
  );
};

export default SocialButtons;

import React from 'react';
import './icons.scss';

export const IconTrash2: React.FC<React.SVGAttributes<SVGElement>> = props => (
  <svg width=".8em" height="1em" viewBox="0 0 13 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M11.6908 1.12082H7.96587V0.812645C7.96587 0.36438 7.59343 0 7.13488 0H5.38696C4.92858 0 4.55597 0.364217 4.55597 0.812645V1.12082H0.83099C0.372606 1.12082 0 1.48504 0 1.93347V2.80213C0 3.05428 0.114596 3.25039 0.28649 3.41863C0.257841 3.50268 0.257841 3.58673 0.257841 3.67078L1.11745 15.2434C1.1461 15.6636 1.51851 16 1.94844 16H10.6019C11.0316 16 11.4043 15.6638 11.4329 15.2434L12.2639 3.67078C12.2639 3.58673 12.2353 3.50268 12.2353 3.41863C12.4071 3.27854 12.5217 3.05441 12.5217 2.80213V1.93347C12.5219 1.48521 12.1493 1.12082 11.6909 1.12082H11.6908ZM5.38708 0.84066H7.135L7.16365 1.12082H5.41573L5.38708 0.84066ZM11.6622 1.96148L11.6908 2.77412H9.62776H8.99734H7.39268H5.30097H3.23793H1.66193H1.28949H0.831107C0.831107 2.49396 0.831107 2.24166 0.802458 1.96148H11.6622H11.6622ZM10.602 15.1315H1.97719L1.08892 3.58653H11.4332L10.602 15.1315Z" />
    <path d="M3.80091 13.913H3.82575C4.02449 13.8855 4.17353 13.6929 4.17353 13.4728L3.5275 4.55892C3.50265 4.33886 3.32875 4.17383 3.13001 4.17383C2.93127 4.20134 2.78223 4.39389 2.78223 4.61396L3.42826 13.5279C3.45311 13.7479 3.62701 13.913 3.8009 13.913H3.80091Z" />
    <path d="M6.60844 13.913C6.79395 13.913 6.95627 13.721 6.95627 13.5015L6.95639 4.58526C6.95639 4.36582 6.79407 4.17383 6.60857 4.17383C6.42306 4.17383 6.26074 4.36583 6.26074 4.58526V13.5011C6.26062 13.7207 6.42294 13.9127 6.60844 13.9127L6.60844 13.913Z" />
    <path d="M8.69477 13.913H8.71957C8.91793 13.913 9.06668 13.7479 9.09148 13.5279L9.73627 4.61396C9.76106 4.39389 9.61229 4.20135 9.38916 4.17383C9.1908 4.17383 9.01724 4.31137 8.99244 4.55892L8.34766 13.4728C8.34752 13.6929 8.49629 13.8854 8.69477 13.913H8.69477Z" />
  </svg>
);

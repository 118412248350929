import { ItemSetCreationParams } from '@brenger/api-client';

export const getItemSetsVolumeAsString = (itemSets: ItemSetCreationParams[]): string => {
  if (!itemSets) {
    return '';
  }
  const objectsSizes: string[] = [];
  itemSets.map(itemSet => {
    if (itemSet.items) {
      if (typeof itemSet.items[0] === 'string') {
        return;
      }
      itemSet.items.forEach(item => {
        const quantity = Number(item.count);
        const volume: number[] = [];
        if (item.length) {
          volume.push(item.length);
        }
        if (item.width) {
          volume.push(item.width);
        }
        if (item.height) {
          volume.push(item.height);
        }
        if (volume.length > 0) {
          objectsSizes.push(volume.join('x') + ' cm' + (quantity > 1 ? ' (' + quantity + 'x)' : ''));
        }
      });
    }
  });

  return objectsSizes.join(', ');
};

/**
 * Get total volume in cm3
 */
export const getItemSetsVolume = (itemSets: ItemSetCreationParams[]): number => {
  const objectsSizes: number[] = [];
  itemSets.map(itemSet => {
    itemSet.items.map(item => {
      const volume: number[] = [];
      if (item.count) {
        volume.push(Number(item.count));
      }
      if (item.length) {
        volume.push(Number(item.length));
      }
      if (item.width) {
        volume.push(Number(item.width));
      }
      if (item.height) {
        volume.push(Number(item.height));
      }
      if (volume.length === 4) {
        objectsSizes.push(volume.reduce((a, b) => a * b));
      }
    });
  });
  if (objectsSizes.length === 0) {
    return 0;
  }
  return objectsSizes.reduce((a, b) => a + b);
};

/**
 * Get total volume in cm3
 */
export const getTotalCountItems = (itemSets: ItemSetCreationParams[]): number => {
  let count = 0;
  itemSets.forEach(itemSet => {
    itemSet.items.forEach(item => {
      if (item.count) {
        count = count + item.count;
      }
    });
  });
  return count;
};

import { IconPencil } from '@brenger/react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, IconCamera, IconMinus, IconPlus } from '../../../../brenger-shared-ui';
import { userDataClient } from '../../../../utils/request';

import cn from 'classnames';
import { useDispatch } from 'react-redux';
import '../../../../assets/product-selection/itemDisplay.scss';
import { IconTrash2 } from '../../../../brenger-shared-ui/components/basics/icons/IconTrash2';
import { actions } from '../../ducks';
import { ItemWithPSAndLot } from '../../interface';
import { r } from '../../../../routes';

interface Props {
  item: ItemWithPSAndLot;
}

export const ItemDisplay: React.FC<Props> & React.DOMAttributes<HTMLBaseElement> = ({ item, ...unused }) => {
  const history = useHistory();
  return (
    <div className="card card--bordered ps--item-display pb-1" {...unused}>
      <div className="flex">
        <ItemDisplayImage image={item.job_image} />
        <div style={{ flexGrow: 1 }}>
          <div className="ps--item-display--title">
            <Button
              type="button"
              extraClasses="ps--item-display--edit"
              buttonStyle="transparant"
              onClick={() => history.push(r.generalFlow.items.edit({ uuid: item.ps_temp.uuid as string }))}
            >
              <b>{item.ps_temp.fullTitle}</b>
              <IconPencil />
            </Button>
          </div>
          <div className="pb-1 text--grey">
            {item.length} x {item.width} x {item.height} cm
          </div>
          <ItemDisplayCount item={item} />
        </div>
      </div>
    </div>
  );
};

const ItemDisplayCount: React.FC<Props> = ({ item }) => {
  const dispatch = useDispatch();
  const { count } = item;
  const update = (newCount: number): void => {
    dispatch(actions.getPrice());
    if (newCount > 0) {
      dispatch(
        actions.updateItemPs({
          uuid: item.ps_temp.uuid as string,
          item: {
            ...item,
            count: newCount,
          },
        })
      );
      return;
    }
    dispatch(actions.removeItemPs({ uuid: item.ps_temp.uuid as string }));
  };
  return (
    <div className={'ps-config--count ps--item-display--counter'}>
      <div className="ps-config--count-value flex">
        <Button
          type={'button'}
          buttonStyle={'transparant'}
          onClick={() => update(count - 1)}
          extraClasses={cn('flex flex--vc ps-config--count-trigger', {
            'ps-config--count--delete': count < 2,
            'ps-config--count--decrease': count > 1,
          })}
        >
          {count === 1 ? <IconTrash2 className="text--red" /> : <IconMinus />}
        </Button>
        <div className="ps-config--count--number">{count}</div>
        <Button
          onClick={() => update(count + 1)}
          type={'button'}
          buttonStyle={'transparant'}
          extraClasses="flex flex--vc ps-config--count-trigger ps-config--count--increase"
        >
          <IconPlus />
        </Button>
      </div>
    </div>
  );
};

const ItemDisplayImage: React.FC<{ image?: string | null }> = ({ image }) => {
  if (image) {
    return <img className="ps--item-display--image" src={userDataClient.getBaseForIri(image, { h: 160, w: 160 })} />;
  }
  return (
    <div className="ps--item-display--no-image flex flex--c">
      <IconCamera />
    </div>
  );
};

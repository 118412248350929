import * as React from 'react';
import './progresssteps.scss';

interface Props {
  currentStep: number;
  totalSteps: number;
}

export const ProgressSteps = ({ currentStep, totalSteps }: Props): JSX.Element => {
  return (
    <div className={'progress-steps'}>
      {Array(totalSteps)
        .fill('')
        .map((el, index) => (
          <div
            className={
              'progress-step' +
              ' progress-step--' +
              (index + 1 < currentStep ? 'done' : '') +
              (index + 1 === currentStep ? 'active' : '')
            }
            key={'progress_step_' + index}
          >
            {index + 1}
          </div>
        ))}
      / {totalSteps}
    </div>
  );
};
export default ProgressSteps;

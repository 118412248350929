import { Locale } from '@brenger/api-client';
import { LOCATION_CHANGE } from 'connected-react-router';
import { actionTypes as formTypes } from 'redux-form';
import { all, delay, fork, put, select, take, takeEvery, takeLatest } from 'typed-redux-saga';
import { persistor } from '../../';
import { GeneralFlowActionTypes } from '../../modules/GeneralFlow/typings';
import { getWhatIsMyCountry, actions as userActions } from '../../modules/User/ducks';
import { UserActionTypes } from '../../modules/User/typings';
import { BaseActionTypes } from '../../typings';
import { getActiveLanguageSettings } from '../../utils/localization';
import { coreClient, priceClient, routePlannerClient } from '../../utils/request';
import { actions as baseActions } from '../ducks/baseReducer';
import { productDimensionSaga } from './productDimensions';
import { uploadSagas } from './uploadSaga';

export function* getInitialData(): Generator {
  // First figur out what country by IP we are dealing with
  yield* put(userActions.retrieveCurrentCountry());
  // Setup language header
  // - For CORE & GEO we stick to the languages we support
  // - For PRICING we want to make one exception: Belgium.
  let lang: Locale = getActiveLanguageSettings().labels.full;
  // Set core & geo lang
  coreClient.updateDefaultHeaders({ 'X-User-Locale': lang });
  routePlannerClient.updateDefaultHeaders({ 'X-User-Locale': lang });
  const countryCode = yield* select(getWhatIsMyCountry);
  // So for pricing we would like to know if we are in Belgium and if we are dutch
  if (countryCode === 'BE' && lang === 'nl-NL') {
    // In this case we switch to flemish / dutch to better match product selection results
    lang = 'nl-BE';
  }
  // Set pricing lang
  priceClient.updateDefaultHeaders({ 'X-User-Locale': lang });

  // Kick off user loading
  yield* put(userActions.retrieveCurrentUser());
  // Wait for translation and user data to come in
  yield* all([take('@@localize/ADD_TRANSLATION_FOR_LANGUAGE'), take(UserActionTypes.SET_USER_DETAILS)]);

  // Small delay to process the translation payload
  yield* delay(100);
  // Done loading
  yield* put(baseActions.stopLoading());
}

export function cleanLocalStorage(): void {
  persistor.purge();
}

export function focusOnFieldsInView(action): void {
  const isAndroid = navigator.userAgent.toLowerCase().indexOf('android') > -1;
  const inputEl = document.getElementById(action.meta.field);
  if (!isAndroid || !inputEl) {
    return;
  }
  // let's go!
  const yCoordinate = inputEl.getBoundingClientRect().top + window.pageYOffset;
  window.scrollTo({
    top: yCoordinate - 70,
    behavior: 'smooth',
  });
}

export function* uponSubmitFailedScrollToFirstError(action): Generator {
  if (!action.error) {
    return;
  }
  const error = document.querySelector('.input-el--feedback--error');
  yield* delay(100);
  if (error) {
    error.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
  }
}

export function foldInMenuAndFlowClass(): void {
  const navItems = document.querySelectorAll('.main-nav--items .nav-item');
  for (let i = 0; i < navItems.length; ++i) {
    navItems[i].classList.remove('sub-nav--is-open');
  }
  // add class when we are in the new flow
  const body = document.querySelector('body');
  if (!body) {
    return;
  }
  if (window.location.pathname.indexOf(`/customer`) > -1) {
    body.classList.add('render-app');
  } else {
    body.classList.remove('render-app');
  }
}

// eslint-disable-next-line require-yield
export function* qaSagaAction(action: unknown): Generator {
  document.dispatchEvent(new CustomEvent('qa-saga-action', { detail: action }));
}

export function* basicSideEffects(): Generator {
  yield* takeLatest(BaseActionTypes.START_APP_LOADING, getInitialData);
  yield* takeLatest(BaseActionTypes.CLEAN_LOCAL_STORAGE, cleanLocalStorage);
  yield* takeLatest(LOCATION_CHANGE, foldInMenuAndFlowClass);
  yield* takeLatest(formTypes.FOCUS, focusOnFieldsInView);
  yield* takeLatest(formTypes.SET_SUBMIT_FAILED, uponSubmitFailedScrollToFirstError);
  yield* takeEvery(
    [BaseActionTypes.STOP_APP_LOADING, GeneralFlowActionTypes.SET_PRICE_LOADING, GeneralFlowActionTypes.SET_QUOTE],
    qaSagaAction
  );
}

export function* baseSagas(): Generator {
  yield* fork(basicSideEffects);
  yield* fork(uploadSagas);
  yield* fork(productDimensionSaga);
}

import { push } from 'connected-react-router';
import React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { connect } from 'react-redux';
import { useQuery } from 'react-query';
import { Dispatch } from 'redux';
import '../../../assets/user/user.scss';
import { Button, IconCheckmark, Pagination, SectionWrapper } from '../../../brenger-shared-ui';
import { CacheKey, RootState } from '../../../typings';
import { priceAsString } from '../../../utils/price';
import { coreClient } from '../../../utils/request';
import { getLoggedInUser } from '../ducks';
import { r } from '../../../routes';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const Invoices: React.FC<Props> = props => {
  const [page, setPage] = React.useState(1);
  const invoices = useQuery([CacheKey.RETRIEVE_INVOICES], () => coreClient.invoices.list({ page }));
  React.useEffect(() => {
    if (props.userDetails.loggedIn) {
      return;
    }
    props.goToPath(r.user.login());
  }, []);
  return (
    <>
      <SectionWrapper extraClasses={'invoices-wrapper'} sectionStyle={'white'} centerContent={true}>
        <h2>
          <Translate id={'bundled.essentials.factuur'} />
        </h2>
        <table style={{ width: '100%' }}>
          <thead>
            <tr>
              <td>ID</td>
              <td>
                <Translate id={'transport_request.price'} /> <Translate id={'request_flow.price.inc_vat'} />
              </td>
              <td>
                <Translate id={'request_flow.price.vat'} />
              </td>
              <td>
                <Translate id={'request_flow.invoices.paid'} />
              </td>
              <td>
                <Translate id={'transport_request.download_invoice'} />
              </td>
            </tr>
          </thead>
          <tbody>
            {(invoices.data?.['hydra:member'] || []).map((invoice, i) => (
              <tr key={i}>
                <td>{invoice.invoice_number}</td>
                <td style={{ width: '100px' }}>
                  {priceAsString({
                    price: { amount: invoice.amount_incl_vat, currency: invoice.currency },
                    addPlusMinusIndicator: false,
                  })}
                </td>
                <td style={{ width: '100px' }}>
                  {priceAsString({
                    price: { amount: invoice.vat_amount, currency: invoice.currency },
                    addPlusMinusIndicator: false,
                  })}
                </td>
                <td>
                  {invoice.paid && <IconCheckmark />}
                  {!invoice.paid && '-'}
                </td>
                <td>
                  <Button
                    onClick={() => window.open(invoice.invoice_url, '_blank')}
                    size={'sm'}
                    buttonStyle={'secondary'}
                  >
                    <Translate id={'transport_request.download_invoice'} />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination list={invoices.data} goToPage={setPage} />
      </SectionWrapper>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState) => ({
  userDetails: getLoggedInUser(state),
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: Dispatch) => ({
  goToPath: path => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);

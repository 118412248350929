import React from 'react';
import './icons.scss';

const IconCheckmarkCircle = (): JSX.Element => {
  return (
    <svg className="IconCheckmarkCircle" width="25" height="25" viewBox="0 0 25 25">
      <g fill="#0073FA" fillRule="evenodd">
        <path d="M24.263 11.78a.539.539 0 0 1-.536-.475C23.075 5.595 18.206 1.29 12.402 1.29 6.6 1.29 1.73 5.596 1.077 11.305a.539.539 0 0 1-.597.472.536.536 0 0 1-.477-.593C.718 4.934 6.048.22 12.403.22c6.353 0 11.683 4.714 12.398 10.965a.537.537 0 0 1-.538.596M12.402 24.963C6.048 24.963.718 20.249.003 13.998a.537.537 0 0 1 .477-.593c.299-.034.564.178.597.472.653 5.71 5.522 10.015 11.325 10.015 5.804 0 10.673-4.306 11.325-10.015a.537.537 0 0 1 .598-.472c.296.034.51.299.476.593-.715 6.25-6.045 10.965-12.399 10.965" />
        <path d="M10.276 17.13a.541.541 0 0 1-.382-.157.532.532 0 0 1 0-.758l7.318-7.252a.543.543 0 0 1 .763 0 .532.532 0 0 1 0 .757l-7.317 7.253a.541.541 0 0 1-.382.157" />
        <path d="M10.276 17.13a.541.541 0 0 1-.382-.157l-3.065-3.038a.532.532 0 0 1 0-.757.543.543 0 0 1 .764 0l3.065 3.037a.532.532 0 0 1 0 .758.541.541 0 0 1-.382.157" />
      </g>
    </svg>
  );
};

export default IconCheckmarkCircle;

import React from 'react';
import { InputMeta, InputWrapper, ModifiedInput } from '.';
import './InputSwitch.scss';
import cn from 'classnames';

interface InputRadioProps extends ModifiedInput {
  onChange: (value: string) => void;
  options: {
    label: string | React.ReactNode;
    value: string;
  }[];
  meta?: InputMeta;
}

export const InputSwitch: React.FC<InputRadioProps> = ({
  options,
  onChange,
  id,
  name,
  value,
  className,
  meta,
  ...unused
}) => {
  return (
    <InputWrapper {...meta}>
      <div className={cn('input-switch', 'flex', className)} {...unused}>
        {options.map((option, i) => {
          return (
            <React.Fragment key={i}>
              <input
                id={`${id}_${i}`}
                type={'radio'}
                name={name}
                onChange={() => onChange(option.value)}
                checked={value === option.value}
                value={option.value}
              />
              <label htmlFor={`${id}_${i}`}>{option.label}</label>
            </React.Fragment>
          );
        })}
      </div>
    </InputWrapper>
  );
};

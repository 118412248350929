import * as React from 'react';
import { useDispatch } from 'react-redux';

import { useTypedSelector } from '.';
import { actions, Notification } from '../state/ducks/notifications';

// This helper class is to assist with accessing the notifications in session storage.
// See useNotification hook implementation for usage.
class NotificationSessionStorage {
  private storageKey: string;
  constructor(storageKey: string) {
    this.storageKey = storageKey;
  }

  public includes(notification: Notification): boolean {
    const notifications = this.list();
    return notifications.includes(notification);
  }

  public add(notification: Notification): void {
    const notifications = this.list();
    sessionStorage.setItem(this.storageKey, JSON.stringify(notifications.concat(notification)));
  }

  private list(): Notification[] {
    const notifications = sessionStorage.getItem(this.storageKey);
    return notifications ? JSON.parse(notifications) : [];
  }
}

const notificationSessionStorage = new NotificationSessionStorage('seen_notifications');

interface UseNotification {
  isVisible: boolean;
  add(): void;
  remove(): void;
  removeForManySessions(): void;
  removeForOneSession(): void;
}

export const useNotification = (notification: Notification): UseNotification => {
  const dispatch = useDispatch();
  const { active, hasSeen } = useTypedSelector(state => state.notifications);
  const latestNotification = active[active.length - 1];

  // Remove the notification when the hook unmounts.
  React.useEffect(() => {
    return () => {
      if (active.includes(notification)) {
        dispatch(actions.removeActiveNotification(notification));
      }
    };
  }, []);

  return {
    isVisible: notification === latestNotification,
    add() {
      // Add checks to prevent noisey dispatching and duplicate additions.
      const hasAcknowledged = hasSeen.includes(notification);
      const hasAcknowledgedDuringSession = notificationSessionStorage.includes(notification);
      const isActive = active.includes(notification);
      if (!isActive && !hasAcknowledged && !hasAcknowledgedDuringSession) {
        dispatch(actions.addActiveNotification(notification));
      }
    },
    remove() {
      if (active.includes(notification)) {
        dispatch(actions.removeActiveNotification(notification));
      }
    },
    removeForManySessions() {
      // This list is persisted in local storage (due to our usage of redux-persist).
      dispatch(actions.setNotificationAsSeen(notification));
    },
    removeForOneSession() {
      notificationSessionStorage.add(notification);
      dispatch(actions.removeActiveNotification(notification));
    },
  };
};

import * as React from 'react';
import { InputProps } from './Input';
import './inputfeedback.scss';

const InputFeedback = ({ meta, error, successMessage }: InputProps): JSX.Element => {
  if ((typeof error !== 'string' || (!error && !successMessage)) && typeof meta.warning === 'undefined') {
    return <div />;
  }
  return (
    <div className={'input-el--feedback'}>
      {meta.warning && !error && <div className={'input-el--feedback--warning'}>{meta.warning}</div>}
      {meta.touched && error && <div className={'input-el--feedback--error'}>{error}</div>}
      {!error && successMessage && <div className={'input-el--feedback--success'}>{successMessage}</div>}
    </div>
  );
};

export default InputFeedback;

import { push } from 'connected-react-router';
import React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { useDispatch } from 'react-redux';

import { Button } from '../../../../brenger-shared-ui';
import { Config } from '../../../../config';
import { useTypedSelector } from '../../../../hooks';
import { r } from '../../../../routes';
import { getNewTransportRequestLink } from '../../../BusinessFlow/ducks';

export const BfButtons: React.FC = () => {
  const newTrLink = useTypedSelector(getNewTransportRequestLink);
  const dispatch = useDispatch();
  return (
    <>
      <Button fullWidth={true} onClick={() => dispatch(push(r.user.dashboard()))} buttonStyle={'primary'}>
        <Translate id={`request_flow.business.thank_you.go_to_dashboard`} />
      </Button>
      <Button fullWidth={true} onClick={() => window.location.assign(Config.TRACKING_BASE_URL)} buttonStyle={'primary'}>
        <Translate id={`request_flow.business.thank_you.go_to_overview`} />
      </Button>
      <Button fullWidth={true} onClick={() => dispatch(push(newTrLink))} buttonStyle={'secondary'}>
        <Translate id={`request_flow.business.thank_you.place_request`} />
      </Button>
    </>
  );
};

import { ApolloClient, InMemoryCache, gql, HttpLink } from '@apollo/client';
import { Config } from '../config';
import { LotDetails, Platform } from '../generated/graphql';
import { logError } from './basics';
import fetch from 'cross-fetch';

export const tbAuctionsClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({ uri: Config.TB_AUCTIONS_GRAPHQL_ENDPOINT, fetch }),
});

// We probably don't need all these fields, but when exploring they were useful
export const TB_AUCTION_GET_LOT = gql`
  query GetLot($displayId: String!, $locale: String!, $platform: Platform!) {
    lotDetails(displayId: $displayId, locale: $locale, platform: $platform) {
      auction {
        id
        isNeedsDelivery
        documents {
          name
          url
        }
      }
      collectionDays {
        address1
        address2
        postalCode
        city
        countryCode
        days {
          startDate
          endDate
          isByAppointment
          email
        }
        name
      }
      lot {
        title
        displayId
        description {
          additionalInformation
          description
        }
        remarks
        documents {
          name
          url
        }
        quantity
        appearance
        images {
          url
          alt
          order
        }
        attributes {
          name
          value
          unit
        }
      }
    }
  }
`;

export const fetchTbAuctionLotById = async (
  id: string,
  locale: 'nl' | 'en' | 'de',
  platform: Platform = Platform.Twk
): Promise<{ lotDetails: LotDetails | null; error: string | null }> => {
  try {
    const tbAuctionQuery = await tbAuctionsClient.query<{ lotDetails: LotDetails }>({
      query: TB_AUCTION_GET_LOT,
      variables: {
        displayId: id,
        platform,
        locale,
      },
    });
    if (tbAuctionQuery.error) {
      throw new Error(tbAuctionQuery.error.message);
    }
    return {
      lotDetails: tbAuctionQuery.data.lotDetails,
      error: null,
    };
  } catch (e) {
    logError(e);
    return {
      lotDetails: null,
      error: e.message ? e.message : e,
    };
  }
};

import React from 'react';

export const IconMinus: React.FC<React.SVGAttributes<SVGElement>> = props => {
  return (
    <svg
      width="1em"
      height="0.15em"
      viewBox="0 0 12 2"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0 0.75C0 0.551088 0.0790178 0.360322 0.21967 0.21967C0.360322 0.0790178 0.551088 0 0.75 0H11.25C11.4489 0 11.6397 0.0790178 11.7803 0.21967C11.921 0.360322 12 0.551088 12 0.75C12 0.948912 11.921 1.13968 11.7803 1.28033C11.6397 1.42098 11.4489 1.5 11.25 1.5H0.75C0.551088 1.5 0.360322 1.42098 0.21967 1.28033C0.0790178 1.13968 0 0.948912 0 0.75Z" />
    </svg>
  );
};

import React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from '../../../brenger-shared-ui/components/layout/grid';
import { Button, Heading, RfInputPassword, SectionWrapper } from '../../../brenger-shared-ui';
import { push } from 'connected-react-router';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { actions } from '../ducks';
import '../../../assets/user/user.scss';
import { PersonSchema } from '../schema';
import { translate } from '../../../utils/localization';
import { FlashMessage } from '../../../brenger-shared-ui/';
import { Translate } from 'react-localize-redux-dep-updated';
import { UserForms } from '../typings';
import { RootState } from 'src/typings';
import { Dispatch } from 'redux';
import { r } from '../../../routes';

// FIX ME: Inject a proper form value interface
type FormValues = Record<string, unknown>;

type ReduxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

type Form = InjectedFormProps<FormValues, ReduxProps>;

type Props = ReduxProps & Form;

const PasswordNew: React.FC<Props> = props => {
  return (
    <SectionWrapper extraClasses={'login-wrapper'} sectionStyle={'white'} centerContent={true}>
      <form onSubmit={props.handleSubmit}>
        <Heading size={2}>
          <Translate id={'authentication.new.headline'} />
        </Heading>
        <Row>
          <Col xs={12} extraClasses={'pt-1'}>
            <Field
              validate={PersonSchema.new_password}
              name={'user.password'}
              type="password"
              component={RfInputPassword}
              label={translate('form.fields.user.new_password.label')}
            />
          </Col>
          {typeof props.error === 'string' && props.error !== '' && (
            <Col xs={12} sm={12} extraClasses={'login-errors'}>
              <FlashMessage type={'error'} message={props.error} />
            </Col>
          )}
          {props.success && (
            <Col xs={12} sm={12} extraClasses={'login-success'}>
              <FlashMessage type={'success'} message={String(translate('authentication.new.success'))} />
              <div className={'proceed-to-login'}>
                <Translate
                  id={'authentication.new.login_as'}
                  data={{
                    customer: (
                      <a href="javascript:void(0);" onClick={() => props.openLogin()}>
                        <Translate id={'authentication.new.customer'} />
                      </a>
                    ),
                    driver: (
                      <a href="https://driver.brenger.nl/nl/driver/login">
                        <Translate id={'authentication.new.driver'} />
                      </a>
                    ),
                  }}
                />
              </div>
            </Col>
          )}
          <Col xs={12} sm={12}>
            <Button
              fullWidth={true}
              loading={props.submitting}
              disabled={props.submitting || props.pristine || !props.valid}
              buttonStyle={'secondary'}
            >
              <Translate id={'authentication.new.button'} />
            </Button>
          </Col>
        </Row>
      </form>
    </SectionWrapper>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState) => ({
  submitting: state.user.password_new.loading,
  success: state.user.password_new.success,
  error: state.user.password_new.error,
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: Dispatch) => ({
  openLogin: () => dispatch(push(r.user.login())),
});

export default reduxForm<FormValues, Props>({
  form: UserForms.PASSWORD_NEW,
  destroyOnUnmount: false,
  onSubmit: (details, dispatch) => dispatch(actions.newPass(details)),
})(connect(mapStateToProps, mapDispatchToProps)(PasswordNew));

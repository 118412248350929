import React from 'react';
import './apploader.scss';
import { IconSmile } from '../../basics/icons';
import { getActiveLanguageSettings } from '../../../../utils/localization';

export const AppLoader = (): JSX.Element => {
  const language = getActiveLanguageSettings();
  return (
    <section className={'loading-app-placeholder'}>
      <div>
        <IconSmile spinning={true} /> {language.strings.loading}
        {'...'}
      </div>
    </section>
  );
};

export default AppLoader;

import React, { MouseEventHandler, ReactNode } from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { Field, FieldArray, GenericFieldArray, WrappedFieldArrayProps } from 'redux-form';
import {
  FlashMessage,
  IconTrash,
  RfInput,
  RfInputFileUploads,
  RfInputHidden,
  RfInputNumber,
  RfInputWithProductExamples,
  SectionWrapper,
  SheetLayoutContent,
} from '../../../brenger-shared-ui';
import { FormNames } from '../../../typings';
import { translate } from '../../../utils/localization';
import { ItemFields } from '../../GeneralFlow/interface';
import { itemSchema, parseInteger } from '../../GeneralFlow/schema';
export interface Props {
  formName: FormNames;
  title?: string | ReactNode;
  showSizeWarning?: boolean;
  showProductSuggestions?: boolean;
  showBookAVan?: boolean;
  businessFlow?: boolean;
}

// DEPRECATED
// This is the way we can extend the FieldArray to be a custom component with optional custom props
// https://github.com/DefinitelyTyped/DefinitelyTyped/pull/35723
const FieldArrayCustom = FieldArray as new () => GenericFieldArray<Record<string, never>, Props>;

const ItemSets: React.FC<Props & WrappedFieldArrayProps> = ({
  title,
  fields,
  showSizeWarning,
  showBookAVan = false,
  businessFlow = false,
  showProductSuggestions = true,
  formName,
}) => {
  return (
    <>
      {fields.map((itemSet, index) => (
        <FieldArrayCustom
          name={`${itemSet}.items`}
          title={title}
          component={Items}
          showSizeWarning={showSizeWarning}
          showProductSuggestions={showProductSuggestions}
          showBookAVan={showBookAVan}
          businessFlow={businessFlow}
          formName={formName}
          key={index}
        />
      ))}
    </>
  );
};

export default ItemSets;

const Items: React.FC<Props & WrappedFieldArrayProps> = ({
  title,
  fields,
  showSizeWarning,
  showProductSuggestions = true,
  businessFlow = false,
  formName,
}) => {
  return (
    <>
      <SectionWrapper centerContent={false} extraClasses={'item-config-section'} sectionStyle={'primary-light'}>
        <SheetLayoutContent>
          {title && <div className={'item-config-sub-title'}>{title}</div>}
          <div className={'item-config-content'}>
            <div className="item-config-list">
              {fields.map((item, index) => (
                <Item
                  key={index}
                  item={item}
                  index={index}
                  handleRemove={() => fields.remove(index)}
                  businessFlow={businessFlow}
                  showProductSuggestions={showProductSuggestions}
                  formName={formName}
                />
              ))}
            </div>
          </div>
          {showSizeWarning && (
            <FlashMessage type={'info'} message={String(translate('request_flow.what.item.size_warning'))} />
          )}
        </SheetLayoutContent>
      </SectionWrapper>
      <SheetLayoutContent>
        <div className={'item-config--actions'}>
          <span
            className={'btn btn--outline btn--full-width'}
            onClick={() => fields.push({ count: 1 })}
            data-qa-id={'itemsets_item_add'}
          >
            <Translate id={'request_flow.what.item.add' + (businessFlow ? '_business' : '')} />
          </span>
        </div>
      </SheetLayoutContent>
    </>
  );
};

interface ItemProps {
  item: string;
  index: number;
  handleRemove: MouseEventHandler;
  businessFlow: boolean;
  showProductSuggestions: boolean;
  formName: FormNames;
}

const Item: React.FC<ItemProps> = ({ item, index, handleRemove, businessFlow, showProductSuggestions, formName }) => {
  return (
    <>
      {index === 1 && !businessFlow && (
        <div className={'text--explain'}>
          <Translate id={'request_flow.what.item.items_price_explain'} />
        </div>
      )}
      <div className={'item-wrapper'}>
        <div className={'item-wrapper--title'}>
          <Field
            validate={itemSchema[ItemFields.TITLE]}
            name={`${item}.${ItemFields.TITLE}`}
            type={'text'}
            component={showProductSuggestions ? RfInputWithProductExamples : RfInput}
            label={String(translate('request_flow.what.fields.title.label'))}
            autoComplete={false}
          />
          <div className={'item-wrapper--desc pb-0-5'}>
            <Translate id={'request_flow.what.item.volume_title'} />
          </div>
        </div>
        <div className={'item-wrapper--volume-fields'}>
          <div className={'fields'}>
            <Field
              validate={itemSchema[ItemFields.LENGTH]}
              name={`${item}.${ItemFields.LENGTH}`}
              type="number"
              normalize={parseInteger}
              min={1}
              append={'cm'}
              component={RfInput}
              label={String(translate('request_flow.what.fields.length.label'))}
              autoComplete={false}
            />
            <Field
              validate={itemSchema[ItemFields.WIDTH]}
              name={`${item}.${ItemFields.WIDTH}`}
              type="number"
              normalize={parseInteger}
              min={1}
              append={'cm'}
              component={RfInput}
              label={String(translate('request_flow.what.fields.width.label'))}
              autoComplete={false}
            />
            <Field
              validate={itemSchema[ItemFields.HEIGHT]}
              name={`${item}.${ItemFields.HEIGHT}`}
              normalize={parseInteger}
              type="number"
              min={1}
              append={'cm'}
              component={RfInput}
              label={String(translate('request_flow.what.fields.height.label'))}
              autoComplete={false}
            />
            <Field name={`${item}.weight`} type="hidden" component={RfInputHidden} />
          </div>
        </div>
        <div className={'item-wrapper--quantity'}>
          <Field
            validate={itemSchema[ItemFields.COUNT]}
            name={`${item}.${ItemFields.COUNT}`}
            normalize={parseInteger}
            type="number"
            minVal={1}
            component={RfInputNumber}
            label={String(translate('request_flow.what.fields.quantity.label'))}
            autoComplete={false}
          />
        </div>
        <div className={'item-wrapper--image'}>
          <RfInputFileUploads fieldName={`${item}.image`} formName={formName} accept={'image/*'} renderPreview={true} />
        </div>
        <div className={'item-wrapper--remove-container'}>
          <span
            data-qa-id={'itemsets_item_remove'}
            className={'item-wrapper--remove'}
            title={String(translate('request_flow.what.item.remove'))}
            onClick={index > 0 ? handleRemove : undefined}
          >
            <IconTrash />
          </span>
        </div>
      </div>
    </>
  );
};

import React from 'react';
import { useMutation } from 'react-query';
import { Button } from '../../../brenger-shared-ui';
import { coreClient } from '../../../utils/request';

interface Props {
  id: string;
  webhook: string;
  onSuccess(): void;
}

export const InputWebHook: React.FC<Props> = ({ id, webhook, onSuccess }) => {
  const [value, setValue] = React.useState(webhook);
  const updateKey = useMutation(coreClient.apiKeys.update, { onSuccess });
  const submit = (e): void => {
    e.preventDefault();
    if (value === webhook) {
      // nothing to update
      return;
    }
    updateKey.mutate({
      id,
      webhook_url: value,
    });
  };
  return (
    <form onSubmit={submit} style={{ margin: 0 }} className={'flex flex--vc'}>
      <div style={{ margin: '0 .5em 0 0' }}>Webhook:</div>
      <div
        className={'input-el input-el--no-label'}
        style={{ width: '100%', maxWidth: '40rem', margin: '0.2em 0 0 0' }}
      >
        <input value={value} onChange={e => setValue(e.target.value)} />
      </div>
      <Button loading={updateKey.isLoading} disabled={webhook === value} buttonStyle={'primary'}>
        Update
      </Button>
    </form>
  );
};
